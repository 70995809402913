import { Component, Dispatch, SetStateAction } from "react";
import ReactDOM from "react-dom";
import { FormWithRedirect, Loading, useNotify } from "react-admin";
import Cropper from "react-cropper";
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import "cropperjs/dist/cropper.css";
import cropper from "cropperjs";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


const App = () => (
    <div style={{ fontFamily: "sans-serif", textAlign: "center" }}>
        <LabelSample />
    </div>
);

interface IProps {
    defaultLabel?: {
        url?: any,
    };
    storeSlugs?: any;
    setState?: any;
    setLabelSet?: Dispatch<SetStateAction<undefined>>;
}

interface IState {
    src: any,
    cropResult: string,
    labelSet: {},
    barcodeMOE: number,
    priceMOE: number,
    oldPriceMOE: number,
    nameMOE: number,
    skuMOE: number,
    seedMOE: number,
    cropHeight: number,
    nameCropped: string,
    priceCropped: string,
    oldPriceCropped: string,
    skuCropped: string,
    barcodeCropped: string,
    seedCropped: string,
    nameHeight: number,
    priceHeight: number,
    oldPriceHeight: number,
    skuHeight: number,
    barcodeHeight: number,
    seedHeight: number,
    alreadySetStoreSlugs: boolean,
    dropdownStatus: boolean,
    selectedStore: "Select Store",
}

const src = "";
let labelObject: any = {};
let alreadySet: any = false;
let labelArea: any = 0;


export default class LabelSample extends Component<IProps, IState>{
  
    private cropper: cropper | undefined;
    private labelObject: any;
    private labelArea: any;
    constructor(props: any) {
        super(props);
        this.state = {
            src,
            cropResult: "",
            labelSet: {},
            barcodeMOE: 0,
            priceMOE: 0,
            oldPriceMOE: 0,
            nameMOE: 0,
            skuMOE: 0,
            seedMOE: 0,
            cropHeight: 0,
            nameCropped: "",
            priceCropped: "",
            oldPriceCropped: "",
            skuCropped: "",
            barcodeCropped: "",
            seedCropped: "",
            nameHeight: 0,
            priceHeight: 0,
            oldPriceHeight: 0,
            skuHeight: 0,
            barcodeHeight: 0,
            seedHeight: 0,
            alreadySetStoreSlugs: false,
            dropdownStatus: false,
            selectedStore: "Select Store",
        };
        this.cropImage = this.cropImage.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
        this.onChange = this.onChange.bind(this);



    };
   

    initializeCropper(e: any) {
        this.cropper = e;
    }

    onChange(e: any) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result });
        };
        reader.readAsDataURL(files[0]);
    }

    cropImage() {
        if (typeof this.cropper!.getCroppedCanvas() === "undefined") {
            return;
        }
        this.setState({
            cropResult: this.cropper!.getCroppedCanvas().toDataURL()
        });
    }

    useDefaultImage() {
        this.setState({ src: this.props.defaultLabel?.url });
        this.labelObject.src = this.props.defaultLabel?.url;
    }

    handleChange(e: any) {
        labelObject.store = e;
        this.setState({ dropdownStatus: false, selectedStore: e });
    }

    handleClick = () => {
        this.setState({
            cropHeight: this.cropper!.getData().height,
        })
    }
    removeNameCropped = () => {
        this.setState({ nameCropped: "" });

    }
    removePriceCropped = () => {
        this.setState({ priceCropped: "" });

    }
    removeOldPriceCropped = () => {
        this.setState({ oldPriceCropped: "" });

    }
    removeSkuCropped = () => {
        this.setState({ skuCropped: "" });

    }
    removeBarcodeCropped = () => {
        this.setState({ barcodeCropped: "" });

    }
    removeSeedCropped = () => {
        this.setState({ seedCropped: "" });

    }

    handleInitialCrop = () => {
        this.setState({ src: this.cropper!.getCroppedCanvas().toDataURL() })
        labelObject.width = this.cropper!.getData().width;
        labelObject.height = this.cropper!.getData().height;
        this.labelArea = (labelObject.height * labelObject.width);
        labelObject.croppedInitial = this.cropper!.getCroppedCanvas();
    }

    handleSet(type: any) {
        if (type === "name") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper!.getData().x > 0) left = this.cropper!.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            bottom = top + this.cropper!.getData().height;
            labelObject.productNameRect = { left, top, right, bottom }
            this.setState({ nameCropped: this.cropper!.getCroppedCanvas().toDataURL() })
            this.setState({ nameHeight: this.cropper!.getData().height })
            labelObject.productNameMinHeight = this.cropper!.getData().height / 2;
            this.setState({ nameMOE: this.calculateMarginOfError(labelObject.productNameRect) });
            labelObject.productNameMOE = this.calculateMarginOfError(labelObject.productNameRect);
            labelObject.croppedName = this.cropper!.getCroppedCanvas();
        }
        if (type === "sku") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper!.getData().x > 0) left = this.cropper!.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            bottom = top + this.cropper!.getData().height;
            labelObject.skuRect = { left, top, right, bottom }
            this.setState({ skuCropped: this.cropper!.getCroppedCanvas().toDataURL() })
            this.setState({ skuHeight: this.cropper!.getData().height })
            labelObject.skuMinHeight = this.cropper!.getData().height / 2;
            this.setState({ skuMOE: this.calculateMarginOfError(labelObject.skuRect) });
            labelObject.skuMOE = this.calculateMarginOfError(labelObject.skuRect);
            labelObject.croppedSku = this.cropper!.getCroppedCanvas();
        }
        if (type === "price") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper!.getData().x > 0) left = this.cropper!.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            bottom = top + this.cropper!.getData().height;
            labelObject.priceRect = { left, top, right, bottom }
            this.setState({ priceCropped: this.cropper!.getCroppedCanvas().toDataURL() })
            this.setState({ priceHeight: this.cropper!.getData().height })
            labelObject.priceMinHeight = this.cropper!.getData().height / 2;
            this.setState({ priceMOE: this.calculateMarginOfError(labelObject.priceRect) });
            labelObject.priceMOE = this.calculateMarginOfError(labelObject.priceRect);
            labelObject.croppedPrice = this.cropper!.getCroppedCanvas();
        }
        if (type === "oldPrice") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper!.getData().x > 0) left = this.cropper!.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            bottom = top + this.cropper!.getData().height;
            labelObject.oldPriceRect = { left, top, right, bottom }
            this.setState({ oldPriceCropped: this.cropper!.getCroppedCanvas().toDataURL() })
            this.setState({ oldPriceHeight: this.cropper!.getData().height })
            labelObject.oldPriceMinHeight = this.cropper!.getData().height / 2;
            this.setState({ oldPriceMOE: this.calculateMarginOfError(labelObject.oldPriceRect) });
            labelObject.oldPriceMOE = this.calculateMarginOfError(labelObject.oldPriceRect);
            labelObject.croppedOldPrice = this.cropper!.getCroppedCanvas();
        }
        if (type === "barcode") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper!.getData().x > 0) left = this.cropper!.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            bottom = top + this.cropper!.getData().height;
            labelObject.barcodeRect = { left, top, right, bottom }
            this.setState({ barcodeCropped: this.cropper!.getCroppedCanvas().toDataURL() })
            this.setState({ barcodeHeight: this.cropper!.getData().height })
            labelObject.barcodeMinHeight = this.cropper!.getData().height / 2;
            this.setState({ barcodeMOE: this.calculateMarginOfError(labelObject.barcodeRect) });
            labelObject.barcodeMOE = this.calculateMarginOfError(labelObject.barcodeRect);
            labelObject.croppedBarcode = this.cropper!.getCroppedCanvas();
        }

        if (type === "seed") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper!.getData().x > 0) left = this.cropper!.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            bottom = top + this.cropper!.getData().height;
            labelObject.seedRect = { left, top, right, bottom }
            this.setState({ seedCropped: this.cropper!.getCroppedCanvas().toDataURL() })
            this.setState({ seedHeight: this.cropper!.getData().height })
            labelObject.seedMinHeight = this.cropper!.getData().height / 2;
            this.setState({ seedMOE: this.calculateMarginOfError(labelObject.seedRect) });
            labelObject.seedMOE = this.calculateMarginOfError(labelObject.seedRect);
            labelObject.croppedSeed = this.cropper!.getCroppedCanvas();
        }
    }

    calculateMarginOfError(rect: any) {

        let area = (rect.right - rect.left) * (rect.bottom - rect.top);
        let formulaResult = (this.labelArea * 1.4) / (this.labelArea * 0.12727 + area);
        if (formulaResult > 10) return 10;
        if (formulaResult < 2) return 2;
        if (Number(formulaResult.toFixed(1).charAt(1)) > 5) return Number(formulaResult.toFixed(0)) + 1;
        return Number(formulaResult.toFixed(0));
    }


    render() {
        if (this.props.defaultLabel && !alreadySet) {
            alreadySet = true;
            this.setState({ src: this.props.defaultLabel.url })
        }
        if (this.props.storeSlugs && !this.state.alreadySetStoreSlugs) {
            this.setState({ alreadySetStoreSlugs: true });
        }

        if (!this.state.alreadySetStoreSlugs) {
            return <Loading></Loading>
        }
        return (
            <FormWithRedirect
                {...this.props}
                render={formProps => (
                    <form>
                        <Card>
                            <Box p="1em">
                                <Box display="flex">
                                    <Box flex={2} mr="1em">

                                        <Typography variant="h6" gutterBottom>Cropper</Typography>

                                        <Box mt="1em" />
                                        <Cropper
                                            style={{ height: 340, width: '100%' }}
                                            preview=".img-preview"
                                            guides={true}
                                            src={this.state.src}
                                            onInitialized={(instance) => {
                                                this.initializeCropper(instance);
                                            }}
                                            viewMode={1}
                                            cropBoxMovable={true}
                                            zoomable={false}
                                            movable={false}

                                        />
                                        
                                        <Box mt="1em" />
                                        <input type="file" onChange={this.onChange} />
                                        {/* <ImageInput source="pictures" label="Related pictures" accept="image/*">
                                            <ImageField source="src" title="title" />
                                        </ImageInput> */}
                                        <Box mt="1em" />
                                        <Button onClick={this.state.selectedStore !== "Select Store" ? this.handleInitialCrop : () => alert('Please Selected Store!!! ') }> Initial Crop  </Button>
                                        <Button onClick={() => { this.handleSet("name") }}> Select Name  </Button>
                                        <Button onClick={() => { this.handleSet("price") }}> Select Price  </Button>
                                        <Button onClick={() => { this.handleSet("oldPrice") }}> Select OldPrice  </Button>
                                        <Button onClick={() => { this.handleSet("sku") }}> Select Sku  </Button>
                                        <Button onClick={() => { this.handleSet("barcode") }}> Select Barcode  </Button>
                                        <Button onClick={() => { this.handleSet("seed") }}> Select Seed  </Button>
                                        {/* <Button color="primary" onClick={() => { this.props.setLabelSet(labelObject) }}> Create  </Button> */}
                                    </Box>

                                    <Box flex={1} ml="1em">

                                    <FormControl variant="outlined" >
                                            <InputLabel id="demo-simple-select-outlined-label">Select Store</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Selected Store"
                                                defaultValue={this.state.selectedStore}
                                                style={{ width: 150 }}
                                            >
                                                {this.props.storeSlugs.map((e: any) => {
                                                    return <MenuItem value={e} onClick={() => this.handleChange(e)}>{e}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                        <Box mt="1em" />
                                        <TextField
                                            value={this.state.skuMOE}
                                            id="outlined-number"
                                            label="SKU Margin of Error"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            value={this.state.barcodeMOE}
                                            id="outlined-number"
                                            label="Barcode Margin of Error"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            value={this.state.nameMOE}
                                            id="outlined-number"
                                            label="Name Margin of Error"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            value={this.state.priceMOE}
                                            id="outlined-number"
                                            label="Price Margin of Errorumber"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            value={this.state.oldPriceMOE}
                                            id="outlined-number"
                                            label="OldPrice Margin of Error"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            value={this.state.seedMOE}
                                            id="outlined-number"
                                            label="Seed Margin of Error"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box flex={1} ml="1em">

                                        <TextField
                                            onChange={(e: any) => labelObject.skuMinHeight = Number(e.target.value)}
                                            id="outlined-number"
                                            label="SKU Minimum Height"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e: any) => labelObject.barcodeMinHeight = Number(e.target.value)}
                                            id="outlined-number"
                                            label="Barcode Minimum Height"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e: any) => labelObject.productNameMinHeight = Number(e.target.value)}
                                            id="outlined-number"
                                            label="Name Minimum Height"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e: any) => labelObject.priceMinHeight = Number(e.target.value)}
                                            id="outlined-number"
                                            label="Price Minimum Height"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e: any) => labelObject.oldPriceMinHeight = Number(e.target.value)}
                                            id="outlined-number"
                                            label="OldPrice Minimum Height"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                    </Box>
                                    <Box flex={1} ml="1em">

                                        <TextField
                                            value={this.cropper ? this.cropper.getCropBoxData().height : ""}
                                            id="outlined-number"
                                            label="Cropper Height "
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            value={(this.cropper) ? 0.5 * this.cropper.getCropBoxData().height : ""}
                                            id="outlined-number"
                                            label="0.5 * Cropper Height "
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />


                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e: any) => labelObject.seed = Number(e.target.value)}
                                            id="outlined-number"
                                            label="Seed"                            
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e: any) => labelObject.skuRegex = Number(e.target.value)}
                                            id="outlined-number"
                                            label="Sku Regex"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        <TextField
                                            onChange={(e) => labelObject.aspectRatioPercentage = e.target.value}
                                            id="outlined-number"
                                            label="AspectRatioPercentage"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box mt="1em" />
                                        
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <div style={{ display: "inline-flex", width: "50px" }}></div>
                                    <div style={{ display: "inline-flex" }}>
                                        <div>
                                            <div style={{ height: "50px" }}></div>
                                            <div>
                                                <p> Name Rect </p>
                                                <img src={this.state.nameCropped}></img>
                                                <IconButton aria-label="delete" onClick={this.removeNameCropped}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <hr />
                                            <div>
                                                <p> Price Rect </p>
                                                <img src={this.state.priceCropped}></img>
                                                <IconButton aria-label="delete" onClick={this.removePriceCropped}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <hr />
                                            <div>
                                                <p> OldPrice Rect </p>
                                                <img src={this.state.oldPriceCropped}></img>
                                                <IconButton aria-label="delete" onClick={this.removeOldPriceCropped}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <hr />
                                            <div>
                                                <p> Sku Rect </p>
                                                <img src={this.state.skuCropped}></img>
                                                <IconButton aria-label="delete" onClick={this.removeSkuCropped}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <hr />
                                            <div>
                                                <p> Barcode Rect </p>
                                                <img src={this.state.barcodeCropped}></img>
                                                <IconButton aria-label="delete" onClick={this.removeBarcodeCropped}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <hr />
                                            <div>
                                                <p> Seed Rect </p>
                                                <img src={this.state.seedCropped}></img>
                                                <IconButton aria-label="delete" onClick={this.removeSeedCropped} >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                        </Card>
                    </form >

                )}
            />
        );
    }
}

ReactDOM.render(<App />, document.getElementById("root"));
