import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useDataProvider } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import { useNotify } from 'react-admin';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    unit: {
        width: "100%",

        marginBottom: "4%",
        alignSelf: "center",
        color: "#ffffff",
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
}));

const ControlledOpenSelect = (props: any) => {
    const classes = useStyles();
    const [depthOne, setDepthOne] = useState<any>();
    const [depthTwo, setDepthTwo] = useState<any>();
    const [depthThree, setDepthThree] = useState<any>();
    const [depthFour, setDepthFour] = useState<any>();
    const [categoryList1, setCategoryList1] = useState<any>([]);
    const [categoryList2, setCategoryList2] = useState<any>([]);
    const [categoryList3, setCategoryList3] = useState<any>([]);
    const [categoryList4, setCategoryList4] = useState<any>([]);
    const [booltr, setbooltr] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [productList, setProductList] = useState([]);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const notify = useNotify();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    const [catNames, setCatNames] = useState(["Category1", "Category2", "Category3", "Category4"]);
    const [today, setToday] = useState(new Date());

    useEffect(() => {
        setProductList(props.productList);

        if (props.transferData.length !== 0 && typeof props.transferData[0] !== "undefined") {

            setbooltr(false);
            setCategoryList1([props.transferData[0]]);
            setCategoryList2([props.transferData[0].subCategories]);
            setCategoryList3([props.transferData[1].subCategories]);
            setCategoryList4([props.transferData[2].subCategories]);
            setDepthOne(props.transferData[0]);
            setDepthTwo(props.transferData[1]);
            setDepthThree(props.transferData[2]);
            setDepthFour(props.transferData[3]);
            setDisabled(true);
            setCatNames([props.transferData[0].categoryName, props.transferData[1].name, props.transferData[2].name, props.transferData[3].name]);



        } else {
            dataProvider.getList('categories', {
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'title', order: 'ASC' },
                filter: { depth: 1 },
            })
                .then(({ data }) => {
                    // const list=data.map((data)=>data);
                    // console.log('calıstı1');

                    setCategoryList11(data);
                    setLoading(false);
                })
                .catch(error => {

                    setLoading(false);
                })
        }
    }, [props.transferData, props.productList]);

    const setCategoryList11 = (data: any) => {
        setCategoryList1(data);
    };
    const setCategoryList22 = (data: any) => {
        setCategoryList2(data);
    };
    const setCategoryList33 = (data: any) => {
        setCategoryList3(data);
    };
    const setCategoryList44 = (data: any) => {
        setCategoryList4(data);
    };


    const handleChange = (event: any) => {
        if (booltr) {
            setDepthOne({ name: event.target.value.categoryName, id: event.target.value.id });

            setCategoryList22(event.target.value.subCategories);
        } else {
            setDepthOne(event.target.value);
        }
    };
    const handleChange1 = (event: any) => {

        dataProvider.getOne('categories', {
            id: event.target.value.id
        },
        )
            .then(({ data }) => {
                // const list=data.map((data)=>data);
                // console.log('calıstı1')
                if (booltr) {
                    setDepthTwo(event.target.value);
                    setCategoryList33(data.subCategories);
                    setLoading(false);
                } else {
                    setDepthTwo(event.target.value);
                }
            })
            .catch(error => {
                setLoading(false);
            });

    };
    const handleChange2 = (event: any) => {

        dataProvider.getOne('categories', {
            id: event.target.value.id
        },
        )
            .then(({ data }) => {
                // const list=data.map((data)=>data);
                // console.log('calıstı1')
                if (booltr) {
                    setDepthThree(event.target.value);
                    setCategoryList44(data.subCategories);
                    setLoading(false);
                } else {
                    setDepthThree(event.target.value);
                }
            })
            .catch(error => {

                setLoading(false);
            });


    };
    const handleChange3 = (event: any) => {
        setDepthFour(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };
    const handleClose3 = () => {
        setOpen3(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpen1 = () => {
        setOpen1(true);
    };
    const handleOpen2 = () => {
        setOpen2(true);
    };
    const handleOpen3 = () => {
        // setCategoryList44(depthThree ? depthTwo.subCategories : {});
        setOpen3(true);
    };
    const handleClick = () => {
        let categoryIds0 = [];
        let categoryNames0 = [];
        let category = "";
        if (depthOne) { categoryIds0.push(depthOne.id) };
        if (depthTwo) { categoryIds0.push(depthTwo.id); };
        if (depthThree) { categoryIds0.push(depthThree.id); };
        if (depthFour) { categoryIds0.push(depthFour.id) };


        if (depthOne) { depthOne.name ? categoryNames0.push(depthOne.name) : categoryNames0.push(depthOne.categoryName) };

        if (depthTwo) { categoryNames0.push(depthTwo.name); category = depthTwo.name; };
        if (depthThree) { categoryNames0.push(depthThree.name); category = depthThree.name };
        if (depthFour) { categoryNames0.push(depthFour.name); category = depthFour.name };

        if (typeof categoryIds0[3] === 'undefined') {
            categoryIds0.splice(3, 1)
            categoryNames0.splice(3, 1);
        };

        if (typeof categoryIds0[2] === 'undefined') {
            categoryIds0.splice(2, 1);
            categoryNames0.splice(2, 1);
        };

        let productIds = productList.map((p: any) => p.id);
        dataProvider.updateMany("products", { ids: productIds, data: { categoryIds: categoryIds0, categoryList: categoryNames0, category: category, jobType: `CATEGORY_MERGE` } }).then(
            (resp) => {
                notify("Product category updated succesfully.")
                window.location.reload();
            }
        ).catch((resp) => {
            notify("Product category smthng went wrong", resp)
        })
    }

    return (
        <div style={{ padding: "3%" }}>
            <TextField label="Category One" variant="filled"
                select
                id="demo-simple-select"
                onChange={handleChange}
                value={depthOne}
                disabled={disabled}
                className={classes.unit}

                style={{ backgroundColor: "#30526a", color: "#ffffff", borderRadius: 5, }}
            >
                {categoryList1.map((object: any) => <MenuItem value={object}> {object.categoryName}</MenuItem>)}
            </TextField>
            <TextField label="Category Two" variant="filled"
                select
                id="demo-simple-select"
                onChange={handleChange1}
                value={depthTwo}
                disabled={disabled}
                className={classes.unit}
                style={{ backgroundColor: "#30526a", color: "#ffffff", borderRadius: 5, }}
            >
                {categoryList2.map((object: any) => <MenuItem value={object}> {object.name}</MenuItem>)}
            </TextField>

            <TextField label="Category Three" variant="filled"
                select
                id="demo-simple-select"
                onChange={handleChange2}
                value={depthThree}
                disabled={disabled}
                className={classes.unit}
                style={{ backgroundColor: "#30526a", color: "#ffffff", borderRadius: 5, }}
            >
                {categoryList3.map((object: any) => <MenuItem value={object}> {object.name}</MenuItem>)}
            </TextField>

            <TextField label="Category Four" variant="filled"
                select
                id="demo-simple-select"
                onChange={handleChange3}
                value={depthFour}
                disabled={disabled}
                className={classes.unit}
                style={{ backgroundColor: "#30526a", color: "#ffffff", borderRadius: 5, }}
            >
                {categoryList4.map((object: any) => <MenuItem value={object}> {object.name}</MenuItem>)}
            </TextField>

            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon style={{ display: "indline-block" }} />}
                style={{ marginLeft: "200px", backgroundColor: "#76ff03", color: "#000000", width: "100px", height: "40px", marginTop: "20px" }}
                onClick={handleClick}
            >
                <p style={{ fontWeight: "bold" }}>Kaydet</p>
            </Button>
        </div>
    );
};
export default ControlledOpenSelect;