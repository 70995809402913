
export const sanitizeFields = (filter: any) => {
    Object.keys(filter).forEach((key) => {
        if (typeof filter[key] === 'undefined' || filter[key] === '') {
            delete filter[key];
        }
    });
}


export const assignFields = (fields: any, obj: any) => {
    Object.keys(fields).forEach((key) => {
        obj[key] = fields[key]
    })
}