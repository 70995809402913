import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    NumberInput,
}
    from 'react-admin';

import { Box, Card, CardContent, Typography } from '@material-ui/core';

const PostTemplateEdit: FC<EditProps> = props => {

    return (
        <Edit
            component="div"
            {...props}
        >
            <PostTemplateForm />
        </Edit>
    );
};

const PostTemplateForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect

            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Post Template Edit'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name"
                                                resource="posttemplates"
                                                fullWidth
                                            />
                                        </Box>

                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="numberOfProducts"
                                                resource="posttemplates"
                                                fullWidth
                                            />

                                        </Box>

                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="logoColor"
                                                resource="posttemplates"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput
                                                source="numberOfTitles"
                                                resource="posttemplates"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>

                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="payload"
                                                resource="posttemplates"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="storyPayload"
                                                resource="posttemplates"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="exampleUrl"
                                                resource="posttemplates"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />
                                    <Box mt="1em" />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="posttemplates"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default PostTemplateEdit;
