import React from 'react';
import { useState, useEffect } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProductCard from './ProductCard';
import ProductIdentifierCard from './ProductIdentifierCard';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: '100%',
            width: "95%",
            paddingLeft: "2%",
            paddingRight: "2%",
            marginTop: '1em'
        },

        option: {
            fontSize: 15,
            '& > span': {
                marginRight: 10,
                fontSize: 18,

            },
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: "100%",
        },
    })
);


export default function DeMerge(props: any) {

    const classes = useStyles(props);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    //const [selectedStore, setSelectedStore] = useState<any>(window.localStorage.getItem("selectedStore"));
    const [allStores, setAllStores] = useState<any>([]);
    const [productList, setProductList] = useState<any>([]);
    const [productIdentifierList, setProductIdentifierList] = useState<any>([]);
    const [product, setProduct] = useState<any>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dataProvider.getList('products', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'title', order: 'ASC' },
            filter: {needDeMerge : true}
        }).then(res => {
            setProduct(res.data[0]);
        }).catch(error => {
            notify("Get products error: " + error)
        });
    }, [refresh]);

    useEffect(() => {
        if(product?.needDeMerge){
            dataProvider
            .getList('productidentifiers', {
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'title', order: 'ASC' },
                filter: { productId: product?.id }
            })
            .then(resp => {
                setProductIdentifierList(resp.data)
            })
        }
   
    }, [product])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleClickProductList()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const handleClickProductList = () => {
        if (searchTerm.length > 2) {
            setLoading(true)
            dataProvider
                .getList('products', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: { query: searchTerm }
                })
                .then(response => {
                    setProductList(response.data)
                    setProductIdentifierList([])
                })
        }
    }

    const handleAutoComplete = (value: any) => {
        setProductIdentifierList([])
        setProduct(value);
        if (value) {
            dataProvider
                .getList('productidentifiers', {
                    pagination: { page: 1, perPage: 25 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: { productId: value?.id }
                })
                .then(resp => {
                    setProductIdentifierList(resp.data)
                })
        }
    }
    const handleNewProduct = () => {
        setProduct(null);
        setProductIdentifierList(null)
    }

    return (
        <Paper className={classes.paper} >
            <Grid container spacing={3}>
                {/* <Grid item xs>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="selected-store">Selected Store</InputLabel>
                        <Select
                            labelId="select-store"
                            id="store-select"
                            value={selectedStore}
                            label="Selected Store"
                            onChange={(e: any) => { setSelectedStore(e.target.value); window.localStorage.setItem("selectedStore", e.target.value); }}
                        >
                            {
                                allStores.map((store: any) => (
                                    <MenuItem value={store.slug} >{store.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* <Grid item xs={6}>
                </Grid> */}
            {!product ? <Grid item xs>  <Autocomplete
                    id="product-select"
                    style={{ paddingTop: "8px" }}
                    options={productList}
                    classes={{
                        option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option: any) => option.name}
                    loading={loading}
                    onChange={(event, value) => { handleAutoComplete(value) }}
                    renderOption={(option) => (
                        <React.Fragment>
                            {option.name}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a product"
                            variant="outlined"
                            onChange={(e) => { setSearchTerm(e.target.value) }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                        />
                    )}
                />

                </Grid> : <br/> }
                
                <Grid container spacing={3} style={{margin: '1%'}}>
                    <Grid item xs>
                        <div style={{textAlign: "center" , margin: '2%'}}>
                    <Button variant="contained" color="primary" style={{alignItems: "center"}} onClick={()=> {handleNewProduct()} }>
                        Yeni Urun 
                    </Button>
                    </div>
                        <ProductCard product={product} />
                    </Grid>
                    <Grid item xs>
                        <ProductIdentifierCard productIdentifier={productIdentifierList} setProductIdentifierList={setProductIdentifierList} product={product} setProduct={setProduct} refresh={refresh} setRefresh={setRefresh} />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>

    );

}