import React, { useCallback, useEffect } from 'react';
import {
    Datagrid,
    List,
    ListProps,

    TextField,

} from 'react-admin';

import { ReactElement } from 'react';


const MappingNameList = (props: ListProps): ReactElement => {
    return (
        <List
            {...props}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid>
                <TextField source="name" />
                <TextField source="value" />
            </Datagrid>
        </List>
    );


};

export default MappingNameList;

