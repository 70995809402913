import { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker, GoogleMapProps } from "react-google-maps";
import Geocode from "react-geocode";
import { SelectStore } from './SelectStore'
import { TextField } from '@material-ui/core';



Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string);
Geocode.setLanguage("tr");
Geocode.enableDebug();

// AIzaSyCP-qYvfxwv8OzyIWXfrXBvw-w5ljS3CWE
interface IRecipeProps {
    labelId?: any,
    label?: any,
    center?: any;
    zoom?: any;
    google?: any;
    height?: any;
    store?: any;
}

interface IRecipeState {
    address: any,
    city: any,
    state: any,
    name: any,
    googleMapURL: any,
    labelId: any,
    store: any,
    label: any,
    mapPosition: any,
    markerPosition: any,
}

class Map extends Component<IRecipeProps, IRecipeState> {

    constructor(props: any) {
        super(props);
        this.state = {
            address: '',
            city: '',
            state: '',
            name: '',
            googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&language=tr&region=TR` as string,
            labelId: this.props.labelId,
            store: props.store,
            label: this.props.label,
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            }
        }
    }
    /**
     * Get the current address from the default map position and set those values in the state
     */

    componentDidMount() {
        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    state = this.getState(addressArray);

                // console.log('city', city, state);

                this.setState({
                    address: (address) ? address : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                })
            },
            error => {
                // console.error(error);
            }
        ).catch((e: any) => {
            console.log(e);

        });
    };
    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps: any, nextState: any): any {
        if (
            this.state.markerPosition.lat !== this.props.center.lat ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.state !== nextState.state
        ) {
            return true
        } else if (this.props.center.lat === nextProps.center.lat) {
            return false
        }
    }
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray: any) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */


    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = (addressArray: any) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };
    /**
     * And function for city,state and address input
     * @param event
     */
    onChange = (event: any) => {
        this.setState({ name: event.target.value });
    };
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = (event: any) => {

    };

    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event: any) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            (response): any => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    state = this.getState(addressArray);
                this.setState({
                    address: (address) ? address : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                })
            },

        );
    };

    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = (place: any) => {
        //  console.log('plc', place);
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            state = this.getState(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();

        // Set these values in the state.
        this.setState({
            address: (address) ? address : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
    };


    render() {
        const AsyncMap: any = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    //google={this.props.google}

                    >
                        {/* InfoWindow on top of marker */}
                        <InfoWindow
                            //onClose={this.onInfoWindowClose as any}
                            position={{ lat: (this.state.markerPosition.lat + 0.00005), lng: this.state.markerPosition.lng }}
                        >
                            <div>
                                <span style={{ padding: 0, margin: 0 }}>{this.state.address}</span>
                            </div>
                        </InfoWindow>
                        {/*Marker*/}
                        <Marker
                            //google={this.props.google}
                            //name={'Dolores park'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                        />
                        <Marker />
                        {/* For Auto complete Search Box */}

                    </GoogleMap>
                )
            )
        );
        let map;
        if (this.props.center.lat !== undefined) {

            map =
                <div>
                    <div>

                        <AsyncMap
                            googleMapURL={this.state.googleMapURL}
                            loadingElement={
                                <div style={{ height: `100%` }} />
                            }
                            containerElement={
                                <div style={{ height: this.props.height }} />
                            }
                            mapElement={
                                <div style={{ height: `100%` }} />
                            }

                        />
                        <br />

                        <TextField id="outlined-basic" label="Address" variant="outlined" onChange={this.onChange} value={this.state.address} fullWidth />


                        <div>
                            <SelectStore allData={this.state} />
                        </div>
                    </div>
                </div>
        } else {
            map = <div style={{ height: this.props.height }} />
        }
        return (map)
    }
}
export default Map