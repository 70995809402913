import { useState, useEffect } from "react";
import { useDataProvider, Loading } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const SimilarProducts = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState<any>();
    const dataProvider = useDataProvider();
    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();

    useEffect(() => {
        if (props.searchTerm) {
            dataProvider.getList('products', {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'title', order: 'ASC' },
                filter: { query: props.searchTerm }
            })
                .then(({ data }) => {
                    data.some(d => {
                        if (d.categoryIds.length > 0) {
                            setProduct(data[0]);
                        }
                    })
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                });
        }
    }, [props]);

    const handleClick = () => {
        let c0 = { id: product.categoryIds[0], categoryName: product.categoryList[0] }
        let c1 = { id: product.categoryIds[1], name: product.categoryList[1] }
        let c2 = { id: product.categoryIds[2], name: product.categoryList[2] }
        let c3 = { id: product.categoryIds[3], name: product.categoryList[3] }
        let list = [c0, c1, c2, c3];
        props.outCategories(list);
    };

    return (loading ? <Loading></Loading> : product ?
        <div>
            <div className={classes.root}>
                <Button variant="contained" color="secondary" onClick={handleClick}>
                    <ArrowBackIcon></ArrowBackIcon> AKTAR
                </Button>
            </div>
            <div>
                <div><img src={"product.images[0].url"} style={{ width: "400px", maxHeight: "200px" }}></img>
                    <h4 style={{ maxWidth: "400px", height: "20px", paddingLeft: "10px" }}>{product.name}<hr></hr>
                        {product.categoryList[0]}<hr />
                        {product.categoryList[1]}<hr />
                        {product.categoryList[2]}<hr />
                        {product.categoryList[3]}<hr />
                    </h4>
                </div>
            </div>
        </div>
        : <div>Üzgünüm Benzer ürün cıkmadi.</div>
    );
};
export default SimilarProducts;