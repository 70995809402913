import React from 'react';
import { useMutation, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

/**
 * It provide to decline wrong campaigns. 
 * We find the data which we want to update by taking id of campaign submission.
 * The is coming from parent component which is campainSubmission.
 *  
 */

export const DeclineButton = ({ campaignSubmission }: { campaignSubmission: any }) => {

    const notify = useNotify();
    const [approve, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'campaignsubmissions',
            payload: { id: campaignSubmission[0].id, data: { id: campaignSubmission[0].id, isReviewed: true, isDeclined: true } }
        },
        {
            //undoable: true,
            onSuccess: ({ data }) => {
                notify('Campaign Submission reddedildi', 'info', {}, true);
                window.location.reload();
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return <Button
        size='large'
        color="primary"
        className={'m-1'} onClick={approve} disabled={loading} endIcon={<CloseIcon />} >Reddet</Button>;
};


