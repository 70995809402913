import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import { minPrice, maxPrice } from "../Helper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "1em",
    },
    paper: {
      height: "100%",
      width: "95%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    media: {
      height: 200,
      objectFit: "contain",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {
      paddingBottom: "0.5em",
    },
    titleProductName: {
      height: "0.5em",
      paddingBottom: "0.5em",
    },
    titleProductPrices: {
      paddingTop: "1em",
    },
    titleDivider: {
      margin: theme.spacing(10),
    },
    actionSpacer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
  })
);
export interface Product {
  product: any;
  distancePrice: any;
}

export default function SelectStore(props: any) {
  const classes = useStyles(props);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [selectedStore, setSelectedStore] = useState<any>(
    window.localStorage.getItem("selectedStore")
  );
  const [selectedCategory, setSelectedCategory] = useState<any>(
    window.localStorage.getItem("selectedCategory")
  );
  const [allStores, setAllStores] = useState<any>([]);
  const [allCategories, setAllCategories] = useState<any>([]);
  const [productList, setProductList] = useState<any>();
  const [sortedArray, setSortedArray] = useState<any>(
    JSON.parse(window.localStorage.getItem("productList")!)
  );
  const [selectedTemplate, setTemplate] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [getProducts, setGetProducts] = useState<boolean>(false)
  let postToSend: any = {};


  useEffect(() => {
    dataProvider
      .getList("categories", {
        pagination: { page: 1, perPage: 50 },
        sort: { field: "categoryName", order: "ASC" },
        filter: { store: 'zingo', depth: 1 },
      })
      .then(async (res) => {
        setAllCategories([...res.data])
      })

    dataProvider
      .getList("stores", {
        pagination: { page: 1, perPage: 50 },
        sort: { field: "title", order: "ASC" },
        filter: {},
      })
      .then((res) => {
        setAllStores([...res.data]);
      }).catch(_ => {
        notify("Mağaza Kategori Hatası!", "warning")
      })
  }, [])

  useEffect(() => {
    dataProvider
      .getList("products", {
        pagination: { page: page, perPage: 25 },
        sort: { field: "title", order: "ASC" },
        filter: {
          noticablePriceDifferenceExists: true,
          noticablePriceDifferenceStore: selectedStore,
          category: selectedCategory
        },
      })
      .then((response) => {
        var myDistanceArray: any[] = [];
        response.data?.map((product: any, index: any) => {
          var myDistanceObject = {} as Product;
          var distancePrice = 0;
          var maxPrice = 0;
          var minPrice = 1000;
          product.prices.map((price: any, index: any) => {
            if (Number(price.price) > maxPrice) {
              maxPrice = Number(price.price);
            }
            if (Number(price.price) < minPrice) {
              minPrice = Number(price.price);
            }
            distancePrice = maxPrice - minPrice;
          });
          myDistanceObject.product = product;
          myDistanceObject.distancePrice = distancePrice;
          myDistanceArray.push(myDistanceObject);
        });

        var sortedMyDistanceArray: any[] = myDistanceArray.sort(
          (obj1, obj2) => {
            if (obj1.distancePrice > obj2.distancePrice) {
              return -1;
            }

            if (obj1.distancePrice < obj2.distancePrice) {
              return 1;
            }
            return 0;
          }
        );

        const sortedDistanceArray1 = [];
        for (let i = 0; i < sortedMyDistanceArray.length; i++) {
          sortedDistanceArray1.push(sortedMyDistanceArray[i].product);
        }

        setSortedArray(sortedDistanceArray1);
        window.localStorage.setItem(
          "productList",
          JSON.stringify(sortedDistanceArray1)
        );
        setProductList(sortedDistanceArray1);
      }).catch((error) => {
        notify("Get product error: " + error, "warning");
      });
  }, [selectedStore, page, getProducts]);

  useEffect(() => { setPage(1); setGetProducts(!getProducts) }, [selectedCategory])

  const handleClickProduct = async (event: any, index: any) => {
    postToSend.product = event;
    postToSend.payload = selectedTemplate.payload;
    postToSend.logoColor = selectedTemplate.logoColor;
    allStores.forEach((s: any) => {
      if (s.slug === event.prices[maxPrice(event)].store) {
        postToSend.store1 = s;
      } else if (s.slug === event.prices[minPrice(event)].store) {
        postToSend.store2 = s;
      }
    });
    props.setPost(postToSend);
    props.setSelectedStore1();
    props.setSelectedStore2();
    props.setProduct(event);
    props.setPage(1);
  };
  const handleProductReport = (product: any) => {
    dataProvider.update('products', {
      id: product.id,
      data: { id: product.id, needDeMerge: true },
      previousData: { title: "previous title" } as any
    }).then((_) => {
      notify('Hatali Urunu Bildirdiniz. Teşekkürler!')
    }).catch((error) => {
      notify('Bir problem yaşıyorum!', "warning")
    });
  }

  const handleClickProductList = (value: any) => {
    if (value) {
      setSortedArray([value]);
    } else {
      setSortedArray(productList);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs>
          <FormControl variant="outlined" className={classes.formControl}>

            <InputLabel id="selected-store">Selected Store</InputLabel>
            <Select
              labelId="select-store"
              id="store-select"
              value={selectedStore}
              label="Selected Store"
              onChange={(e: any) => {
                setSelectedStore(e.target.value);
                window.localStorage.setItem("selectedStore", e.target.value);
              }}
            >
              {allStores.map((store: any) => (
                <MenuItem value={store.slug}>{store.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <div style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "100%" }}>
            <button onClick={() => { if (page > 1) setPage(page - 1) }}>{"<<" + page}</button>
          </div>
        </Grid>

        <Grid item xs>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="selected-category">Category</InputLabel>
            <Select
              labelId="select-category"
              id="store-category"
              value={selectedCategory}
              label="Selected Category"
              onChange={(e: any) => {
                setSelectedCategory(e.target.value);
                window.localStorage.setItem("selectedCategory", e.target.value);
              }}
            >
              {allCategories.map((store: any) => {
                return <MenuItem value={store.categoryName}>{store.categoryName}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <div style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "100%" }}>
            <button onClick={() => { if (page > 0) setPage(page + 1) }}>{page + ">>"}</button>
          </div>
        </Grid>

        <Grid item xs>
          <Autocomplete
            id="product-select"
            style={{ paddingTop: "8px" }}
            options={sortedArray}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option: any) => option.name}
            onChange={(_, value) => {
              handleClickProductList(value);
            }}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a product"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.root}>
        {sortedArray ? (
          sortedArray.map((product: any, index: any) => (
            <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={2} item>
              <Card style={{ height: "100%" }}>


                {product.postCreateDate ? (
                  <Typography variant="h6" component="h2" align="justify">
                    {"PostDate : "} {product.postCreateDate.substring(5, 10)}
                  </Typography>
                ) : (
                  <></>
                )}
                <img
                  src={
                    product && product.images && product.images[0]
                      ? product.images[0].url
                      : ``
                  }
                  className={classes.media}
                  onClick={() => handleClickProduct(product, index)}
                ></img>

                <CardContent className={classes.titleProductName}>
                  <Typography variant="h5" component="h2" align="center">
                    {product.name}
                  </Typography>
                </CardContent>
                <Divider variant="middle" className={classes.titleDivider} />

                <CardContent className={classes.titleProductPrices}>
                  {product.prices.map((price: any) => (
                    <Typography variant="h6" component="h2" align="left">
                      {price && price.url && price.url !== null ? (
                        <Link
                          href={
                            price && price.url && price.url !== null
                              ? price.url
                              : ""
                          }
                          target="_blank"
                        >
                          {price.store} :
                        </Link>
                      ) : (
                        price.store
                      )}
                      {` : ${price.price}`}
                    </Typography>
                  ))}
                </CardContent>
                {/* <CardActions
                                classes={{ spacing: classes.actionSpacer }}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => handleClickProduct(product, index)}
                                    size="large"
                                >
                                    Select
                                    </Button>
                            </CardActions> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '1%'
                  }}
                >
                  <Button variant="contained" color="primary" style={{ marginTop: "1em" }} onClick={() => { handleProductReport(product) }} >
                    Hatali Urunu Bildir
                  </Button>
                </div>
              </Card>
            </Grid>
          ))
        ) : (
          <br />
        )}
      </Grid>
    </Paper>
  );
}
