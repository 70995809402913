import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';

const CategorylessProduct = (props: any) => {
    const [product, setProduct] = useState<any>();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getList('products', {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'title', order: 'ASC' },
            filter: { notExistsCategory: true, isMergeSlider: true }
        })
            .then(({ data }) => {
                let searchTerm = data[0].name;
                props.setsearchTerm(searchTerm);
                setProduct(data[0])
                props.outProduct(data[0]);
                setLoading(false);

            }).catch((error) => {
                console.log(error);
            });

    }, []);


    return (
        (product) ? <div style={{ textAlign: "center" }}><img src={product.images[0] ? product.images[0].url : ""} style={{ width: "400px", maxHeight: "300px" }}></img>
            <h2 style={{ fontWeight: "bold", maxWidth: "400px", maxHeight: "100px", alignSelf: "center", margin: "10%" }}>{product.name}</h2>

        </div>
            : <Loading />
    );

};
export default CategorylessProduct;