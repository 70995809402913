import "./istatistikkart.css";
import { CardText, Row, Col, CardImg } from "reactstrap";
import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxHeight: 215,
    maxWidth: 445,
    margin: "1%",
  },
});

export const StatisticArea = ({ title, totalNumber, icon, basepath }: any) => {
  const classes = useStyles();

  const history = useHistory();

  const handleClick = () => {
    history.push(`/${basepath}`);
  };

  return (
    <Card className={classes.root} onClick={handleClick}>
      <CardActionArea>
        <CardContent style={{ textAlign: "center" }}>{icon}</CardContent>
        <CardContent style={{ textAlign: "center", fontSize: "20px" }}>
          {title}
        </CardContent>
        <CardContent style={{ textAlign: "center", fontSize: "20px" }}>
          {totalNumber}
        </CardContent>
      </CardActionArea>
    </Card>
    // <Card
    //   style={{
    //     backgroundColor: "#ffffff",
    //     borderRadius: 5,
    //     marginBottom: "3%",
    //     height: "20%",
    //   }}
    // >
    //   {
    //     <CardHeader
    //       title={title}
    //       style={{ color: "#ffffff", backgroundColor: "#30526a" }}
    //     ></CardHeader>
    //   }
    //   <CardContent style={{ color: "#000000" }}>
    //     <Row>
    //       <Col>{icon}</Col>
    //       <Col>
    //         <CardText className={"cardText"} style={{ color: "#000000" }}>
    //           {totalNumber}
    //         </CardText>
    //       </Col>
    //     </Row>
    //   </CardContent>
    // </Card>
  );
};
