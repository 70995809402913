import React, { FC } from 'react';

import {
    List,
    ListProps,
    Datagrid,
    TextField,
    NumberField,
    useRecordContext,

} from 'react-admin';


import CityShow from './CityShow';
import CityFilter from './CityFilter';



const CityList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            filters={<CityFilter />}
            perPage={5}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<CityShow />}>
                <TextField source="name" sortable={false}/>
                <NumberField source="stores.length" label="Number of Stores" sortable={false}/>
                <NumberField source="location.coordinates[1]" label="Latitude" sortable={false}/>
                <NumberField source="location.coordinates[0]" label="Longitude" sortable={false}/>
            </Datagrid>
        </List>
    );
};



export default CityList;
