import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';

export const CitySearch = ({ callbackFromParent, searchKey }: { callbackFromParent: any, searchKey: any }) => {

    const dataProvider = useDataProvider();
    const [cityList, setCityList] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();


    const [selectedItem, setSelectedItem] = useState<any>();


    useEffect(() => {
        dataProvider.getList('cities', {
            filter: {

                q: searchKey,
            },
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'title', order: 'ASC' },

        })
            .then((data) => {
                setSelectedItem(data.data[0]);
                callbackFromParent(data.data[0]);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }, [searchKey]);


    const selectData = (item: any) => {


        // console.log('Selected city', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item)

    }


    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (!cityList) return null;
    if (selectedItem) return null;

    if (searchKey.length < 2) return null;

    const textMiddle = (value: any) => {

        if (selectedItem) {
            return <div>{selectedItem.name}</div>
        }
        else {
            return <div>{value}</div>
        }

    }

    return (


        <div>

            {cityList.map((item: any) =>
                <Container key={item.id}>

                    <div key={item.id} className={"customListItem"}>


                        <Row>
                            <Col xs="9"><span onClick={() => selectData(item)} >{textMiddle(item.name)} </span></Col>
                        </Row>



                    </div>
                </Container>
            )}

        </div>
    )



};

