import LabelSetIcon from '@material-ui/icons/VideoLabel';

import LabelSetList from './LabelSetList';
import LabelSetEdit from './LabelSetEdit';


export default {
    list: LabelSetList,
    edit: LabelSetEdit,
    icon: LabelSetIcon,
};
