import React, { useCallback, useEffect, useState } from 'react';
import {
    Create,
    Datagrid,
    Filter,
    FilterProps,
    ImageField,
    Link,
    List,
    ListProps,
    NumberField,
    NumberInput,
    SearchInput,
    SimpleForm,
    TextField,
    TextInput,
    useDataProvider,
    useNotify,
    useTranslate,
} from 'react-admin';

import { ReactElement } from 'react';
import { GridList, GridListTile, GridListTileBar } from '@material-ui/core';

const PostTemplateFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <NumberInput source="numberOfTitles" />
        <NumberInput source="numberOfProducts" />
    </Filter>
);

const PostTemplateList = (props: ListProps): ReactElement => {

    return (
        <List
            {...props}
            filters={<PostTemplateFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit" >
                {/* <TextField source="id" /> */}
                <TextField source="name" />
                <TextField source="logoColor" />
                <TextField source="payload" label="placeid" />
                <NumberField source="numberOfProducts" />
                <NumberField source="numberOfTitles" />
                <ImageField source="exampleUrl" label="Preview" />
            </Datagrid>
        </List>
    );
};
export const PostTemplateCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="payload" />
            <NumberInput source="numberOfProducts" />
            <TextInput source="exampleUrl" />
            <TextInput source="logoColor" />
            <NumberInput source="numberOfTitles" />
        </SimpleForm>
    </Create>
);

export default PostTemplateList;

