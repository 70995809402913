import React from "react";
import { useNotify, useDataProvider } from "react-admin";
import Button from "@material-ui/core/Button";
import * as helper from "../shared/HelperFunctions";
import AddPriceIcon from "@material-ui/icons/AddCircle";

export const AddPrice = ({
  labelText,
  sku,
  barcode,
  price,
  oldPrice,
  label,
  discountX,
  discountY,
  discountDescription,
  cardIsNeeded,
  discountPercent,
  discountMin,
  discountPrice,
  isExistStartDate,
  isExistFinishDate,
  startDate,
  finishDate,
  product,
  selectDiscountKind,
  selectedUnit,
  productAmount,
  unitPriceUnit,
  unitPrice,
  isSelectedAnyUnit,
}: any) => {
  //Selected unit ???

  const notify = useNotify();
  const dataProvider = useDataProvider();

  let record: any = {};

  if (selectDiscountKind !== "nothing") {
    createDiscount(
      record,
      discountX,
      discountPercent,
      discountMin,
      discountY,
      selectDiscountKind,
      discountDescription,
      discountPrice,
      cardIsNeeded,
      isExistStartDate,
      startDate,
      isExistFinishDate,
      finishDate
    );
  }

  record.productId = label.productId;
  record.storeId = label.storeId;
  record.store = label.store;
  record.price = price;
  record.oldPrice = oldPrice;
  record.productName = product.name;

  if (isSelectedAnyUnit) {
    let unitPrice: any = {};
    unitPrice.price = price;
    unitPrice.unit = { unit: unitPriceUnit, amount: 1 };
    unitPrice.unit.kind = assignMeasurementType(unitPriceUnit);

    record.unitPrice = unitPrice;
  }

  const labelSubmissionUpdate = () => {
    let record: any = { id: label.id, productId: label.productId };

    record.sku = sku;
    record.barcode = barcode;
    record.price = price;
    record.oldPrice = oldPrice;
    record.label = labelText;
    record.isReviewed = true;
    record.reviewResult = "ACCEPTED";

    dataProvider
      .update("labelsubmissions", {
        id: label.id,
        data: { ...record },
        previousData: { title: "previous title" } as any,
      })
      .then((response) => {
        dataProvider
          .getList("productidentifiers", {
            filter: { productId: product.id },
            pagination: { page: 1, perPage: 5 },
            sort: { field: "title", order: "ASC" },
          })
          .then((resp) => {
            dataProvider
              .update("productidentifiers", {
                id: resp.data[0].id,
                data: { id: resp.data[0].id, barcode: barcode },
                previousData: { title: "previous title" } as any,
              })
              .then(() => {
                dataProvider.update("products", {
                  id: product.id,
                  data: { id: product.id, barcode: barcode },
                  previousData: { title: "previous title" } as any,
                });
              });
          });
        notify("Label Submission updated");
        window.location.reload();
      })
      .catch((error) => {
        notify(`Label Submission updated error: ${error.message}`, "warning");
      });
  };

  const updateProductById = () => {
    let unitOfMeasurement = { kind: "", amount: 0, unit: "" };

    switch (selectedUnit) {
      case "KG":
      case "G":
      case "MG":
        unitOfMeasurement.kind = "WEIGHT";
        break;

      case "M":
      case "CM":
      case "MM":
        unitOfMeasurement.kind = "LENGTH";
        break;

      case "L":
      case "ML":
        unitOfMeasurement.kind = "VOLUME";
        break;

      case "QTY":
        unitOfMeasurement.kind = "QUANTITY";
        break;

      case "SRV":
        unitOfMeasurement.kind = "SERVING";
        break;
    }
    unitOfMeasurement.unit = selectedUnit;
    unitOfMeasurement.amount = productAmount;

    dataProvider
      .update("products", {
        id: label.productId,
        data: { id: label.productId, unitOfMeasurement },
        previousData: { title: "previous title" } as any,
      })
      .then((response) => {
        notify("Product updated");
      })
      .catch((error) => {
        notify(`Product updated error: ${error.message}`, "warning");
      });
  };

  const createPrice = () => {
    let priceId: any = "";
    dataProvider
      .create("prices", { data: { ...record } })
      .then((response) => {
        notify("New Price Added");
        priceId = response.data.id;

        labelSubmissionUpdate();
      })
      .catch((error) => {
        notify(`New Price error: ${error.message}`, "warning");
      });
    //--------------------------------------
  };

  const updateIdentifier = (identifier: any) => {
    let data = {
      id: identifier.id,
      sku: sku,
      barcode: barcode,
      label: labelText,
      productName: product.name,
      brand: product.brand,
    };

    helper.sanitizeFields(data);

    dataProvider
      .update("productidentifiers", {
        id: identifier.id,
        data,
        previousData: { title: "previous title" } as any,
      })
      .then((response) => {
        notify("Product Identifier updated");
      })
      .catch((error) => {
        notify(`Product Identifier updated error: ${error.message}`, "warning");
      });
  };

  const getProductIdentifier = () => {
    dataProvider
      .getList("productidentifiers", {
        filter: { storeId: label.storeId, sku: sku, barcode: barcode },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "title", order: "ASC" },
      })
      .then((response) => {
        if (response.total) {
          const identifier = response.data[0];
          if (identifier.productId === label.productId) {
            updateIdentifier(identifier);
          }

          if (
            (typeof identifier.sku === "undefined" && sku !== "") ||
            (typeof identifier.barcode === "undefined" && barcode !== "") ||
            (typeof identifier.label === "undefined" && labelText !== "") ||
            (typeof identifier.productName === "undefined" &&
              product.name !== "") ||
            (typeof identifier.brand === "undefined" && product.brand !== "")
          ) {
            //sku yok
            updateIdentifier(identifier);
          }

          if (selectedUnit) {
            updateProductById();
          }
        }
        createPrice();
      })
      .catch((error) => {
        notify(`Label updated error: ${error.message}`, "warning");
      });
  };

  return (
    <Button
      color="primary"
      className={"mt-2"}
      onClick={getProductIdentifier}
      size="large"
      endIcon={<AddPriceIcon />}
      style={{ float: "right", textAlign: "center" }}
    >
      Add price
    </Button>
  );
};

function assignMeasurementType(selectedUnit: any) {
  switch (selectedUnit) {
    case "KG":
    case "G":
    case "MG":
      return "WEIGHT";
      break;
    case "M":
    case "CM":
    case "MM":
      return "LENGTH";
      break;
    case "L":
    case "CL":
    case "ML":
      return "VOLUME";
      break;
    case "QTY":
      return "QUANTITY";
      break;
    case "SRV":
      return "SERVING";
      break;
    default:
      break;
  }
}

function createDiscount(
  record: any,
  discountX: any,
  discountPercent: any,
  discountMin: any,
  discountY: any,
  selectDiscountKind: any,
  discountDescription: any,
  discountPrice: any,
  cardIsNeeded: any,
  isExistStartDate: any,
  startDate: any,
  isExistFinishDate: any,
  finishDate: any
) {
  record.discountRule = {};
  if (discountX) {
    record.discountRule.x = discountX;
  }
  if (discountPercent) {
    record.discountRule.x = discountPercent;
  }
  if (discountMin) {
    record.discountRule.x = discountMin;
  }
  if (discountY) {
    record.discountRule.y = discountY;
  }
  record.discountRule.kind = selectDiscountKind;
  record.discountDescription = discountDescription;
  if (discountPrice !== 0) {
    record.discountRule.discountPrice = discountPrice;
  }
  record.discountRule.loyaltyCard = cardIsNeeded;
  if (isExistStartDate === true) {
    record.discountRule.validFrom = startDate;
  }
  if (isExistFinishDate === true) {
    record.discountRule.validTo = finishDate;
  }
}
