import { List, ListItem, ListItemText } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import * as React from 'react'
import { elasticParenthesesRemover } from '../Helper';

export const ProductSearch = ({ callbackFromParent, searchKey }: { callbackFromParent: any, searchKey: any }) => {

    const dataProvider = useDataProvider();
    const [productList, setProductList] = useState<any[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [hide, setHide] = useState(Boolean);

    useEffect(() => {
        setSelectedItem(false);

        searchKey = elasticParenthesesRemover(searchKey);
        dataProvider.getList('products', {
            filter: { query: searchKey},
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
        })
            .then(({ data }) => {
                if (typeof data !== 'undefined') {
                    setProductList(data);
                    setLoading(false);
                } else {
                    alert("something wrong here")
                }
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [searchKey]);

    const selectData = (item: any) => {
        setSelectedItem(item);
        callbackFromParent(item);
        setHide(true);
    }
    if (loading) return <Loading />;
    if (hide) return null;
    if (!productList) return null;
    if (searchKey === '') return null;
    return (

        <List dense={true} style={{ fontSize: "10px" , marginLeft: '5px', width: '200px'}} >
            {productList.filter((item, idx) => idx < 10).map((item, index) => (
                <ListItem
                    key={item.id}
                    button
                    onClick={() => selectData(item)}
                >
                    <ListItemText
                        primary={item.name}
                    />
                </ListItem>
            ))}
        </List>

    )
};