import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { Button, Form, FormGroup, Alert, Input, FormText } from 'reactstrap';
import { useDataProvider } from 'react-admin';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import Cookies from 'js-cookie';
import LoginAlert from "./LoginAlert";



const MyLoginPage = ({ }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginErr, setLoginErr] = useState();
    const [messageOpen, setMessageOpen] = useState(false);
    // const notify = useNotify();
    // const login = useLogin();
    // const notify = useNotify();
    // const dataProvider = useDataProvider();
    const [backgroundImageUrl, setbackgroundIMageUrl] = useState("https://wallpapercave.com/wp/wp6974484.jpg");
    const changeEmai = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(e.target.value)
    }

    const changePassword = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(e.target.value)
    }

    const onKeyDown = (event: { key: string; preventDefault: () => void; stopPropagation: () => void; }) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            loginUser();
        }
    }

    const loginUser = async () => {
        let loginDto = { username: email, password: password }
        let url = process.env.REACT_APP_AUTH_URL;
        await axios({
            method: 'post',
            url: url,
            headers: {},
            data: loginDto,
            withCredentials: true,
            baseURL: '/auth/login'

        }).then(
            (res) => {
                //console.log(res)
                Cookies.set("userName", res.data.userName.toString())
                Cookies.set("userId", res.data.userId.toString())
                Cookies.set("userRole", res.data.userRole.toString())
                Cookies.set("accessToken", res.data.accessToken.toString())
                // notify("Login Successful")
                window.location.replace("#/");
                window.location.reload();
            }
        ).catch((err) => {
            console.log(err)
            setLoginErr(err.message);
            setMessageOpen(true)
        }
        );
    }



    return (
        <div style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: "100vh",
            margin: 0,
            paddingTop: "2%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"

        }} >
            <LoginAlert isValid={messageOpen} setIsValid={setMessageOpen} err={loginErr}></LoginAlert>

            <Card style={{
                width: "500px", alignItems: "center", padding: "2%", display: "flex", position: 'absolute', top: '10%',
                justifyContent: "center",
                backgroundColor: 'rgba(52, 52, 52, 0.8)'
            }}>

                <img src={"https://lh3.googleusercontent.com/eUQdQYHxh_kFcupqaBT11wLUEkGk3aGRZZvflCmYr7BcXiRTVL8cA7CJ4kDHpE0Z-Q=s180-rw"}
                    style={{ alignSelf: "center", borderRadius: 5 }}
                ></img>


                <FormGroup style={{ margin: "2%" }}>
                    <h2 style={{ color: "#ffffff", margin: "4%" }} >Zingo BackTeam</h2>
                    <Input type="email" value={email} onChange={changeEmai} placeholder="Username" autoComplete="off" style={{ margin: "4%" }} />
                    <Input type="password" value={password} onChange={changePassword} onKeyDown={onKeyDown} placeholder="Password" style={{ margin: "4%" }} />
                    <Button color="secondary" type="submit" onClick={loginUser} style={{ marginLeft: "5%" }} >Login</Button>
                </FormGroup>

            </Card>
        </div>

    );
};

export default MyLoginPage;
