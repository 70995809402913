import CityIcon from '@material-ui/icons/Apartment';
import CityList from './CityList';
import CityFilter from './CityFilter';


export default {
    list: CityList,
    filter: CityFilter,
    icon: CityIcon,
};
