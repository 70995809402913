import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    NumberField,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
} from 'react-admin';
import TownShow from './TownShow';
import TownFilter from './TownFilter';


const TownList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            filters={<TownFilter />}
            perPage={5}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<TownShow />}>
                <TextField source="name" />
                <TextField source="city" />
                <NumberField source="location.coordinates[1]" label="Latitude" />
                <NumberField source="location.coordinates[0]" label="Longitude" />
                {/* <ReferenceManyField label="Districts" reference="districts" target="townId">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceManyField> */}
            </Datagrid>
        </List>
    );
};

export default TownList;
