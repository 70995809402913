import  { useState} from 'react';
import Button from '@material-ui/core/Button';
import Cropper from 'react-cropper';
import { TextField } from '@material-ui/core';
let nameFromImage = "";
let rotateCount=0
const CropperComponent = ({ callbackSelectedProduct, callbackText, myCallbackRotate,imageUrl,callbackBarcode,callbackUnit, callbackBrand, callbackSku, lockdown }: 
  { callbackSelectedProduct: any , callbackText:any, myCallbackRotate:any,imageUrl:any,callbackBarcode:any,callbackUnit:any,callbackBrand:any,callbackSku:any,lockdown:any  }) => {
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState<any>();

  const getCropData = (condition:any) => {
    if (typeof cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    let x1 = cropper.getData().x;
    let x2 = cropper.getData().x + cropper.getData().width;
    let y1 = cropper.getData().y;
    let y2 = cropper.getData().y + cropper.getData().height;

    nameFromImage="";
    const extractedJSON = JSON.parse(callbackSelectedProduct.extractedString as string);
    
    extractedJSON?.forEach((el: any) => {
        if (el.boundingPoly.vertices[0].x > x1 &&
            el.boundingPoly.vertices[0].y > y1 &&
            el.boundingPoly.vertices[1].x < x2 &&
            el.boundingPoly.vertices[2].y <y2) {
            nameFromImage = nameFromImage + " " + el.description;
        }
    });
    sendCallBack(condition,nameFromImage);
    
    setCropData(cropper.getCroppedCanvas().toDataURL());
    myCallbackRotate(rotateCount);
    rotateCount=0
  };
  const sendCallBack = (type:any,data:any) => {
    if(type=='name'){
      callbackText(data);
    }else if(type=='barcode'){
      callbackBarcode(data);
    }else if(type=='unit'){
      callbackUnit(data);
    }else if(type == 'brand'){
      callbackBrand(data);
    }else if(type == 'sku' ){
      callbackSku(data);
    }
  }
  const rotateImage=async()=>  {
    cropper.rotate(90);
    rotateCount = (rotateCount+1)%4
  }
  return (
      <div style={{ display: "flex"}}>
        <Cropper
          style={{maxWidth:'640px',maxHeight:'640px',minWidth:'640px',minHeight:'640px'}}
          preview=".img-preview"
          src={imageUrl}
          zoomable={false}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
        <div style={{display: "flex", flexDirection:'column', alignItems: 'center', }}>
          <div>
              <Button style={{backgroundColor: '#92BBE1',marginTop:'10px', width: '175px'}} onClick={() => rotateImage()}disabled={lockdown}>Rotate Image</Button>
          </div>
          <div>
            <Button style={{backgroundColor: '#92BBE1', marginLeft:'10px',marginRight:'10px', marginTop:'10px', width: '175px'}}  onClick={() => getCropData('name')}disabled={lockdown} >Crop Product Name</Button>
          </div>
          <div>
            <Button style={{backgroundColor: '#92BBE1', marginLeft:'10px',marginRight:'10px', marginTop:'10px', width: '175px'}}  onClick={() => getCropData('barcode')}disabled={lockdown}>Crop Barcode</Button>
          </div>
          <div>
            <Button style={{backgroundColor: '#92BBE1', marginLeft:'10px',marginRight:'10px', marginTop:'10px', width: '175px'}}  onClick={() => getCropData('unit')}disabled={lockdown}>Crop Unit Measurement</Button>
          </div>
          <div>
            <Button style={{backgroundColor: '#92BBE1', marginLeft:'10px',marginRight:'10px', marginTop:'10px', width: '175px'}}  onClick={() => getCropData('brand')}disabled={lockdown}>Crop Brand Name</Button>
          </div>
          <div>
            <Button style={{backgroundColor: '#92BBE1', marginLeft:'10px',marginRight:'10px', marginTop:'10px', width: '175px'}}  onClick={() => getCropData('sku')}disabled={lockdown}>Crop Sku</Button>
          </div>       
        </div>
      </div>
  );
};
export default CropperComponent;