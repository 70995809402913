import { FC, useState } from "react";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import { useMediaQuery, Theme, Box } from "@material-ui/core";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
} from "react-admin";

import cities from "../cities";
import towns from "../towns";
import districts from "../districts";
import neighborhoods from "../neighborhoods";
import brands from "../brands";
import stores from "../stores";
import storelocations from "../storelocations";
import products from "../productszingo";
import productidentifiers from "../productidentifiers";
import categories from "../categories";
import SubMenu from "./SubMenu";
import { AppState } from "../types";
import comments from "../comments";
import mappings from "../mappings";
import labelsubmissions from "../labelsubmissions";
import posttemplates from "../posttemplates";
import servertools from "../servertools";
import mergebarcodes from "../mergebarcodes";
import mergeproducts from "../mergeproducts";
import mergesiblings from "../mergesiblings";
import mergesimilars from "../mergesimilars";
import commentsubmission from "../commentsubmission";
import campaignsubmissions from "../campaignsubmissions";
import mergebrands from "../mergebrands";
import declinedproducts from "../declinedproducts";
import postsubmission from "../postsubmission";
import categorylinker from "../categorylinker";
import mergeproductbyname from "../mergeproductbyname";
import demerge from "../demerge";
import jobactivity from "../jobactivities";
import mergesubmissionsreview from "../mergesubmissionsreview";
import scrapperresults from "../scrapperresults";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CropFreeIcon from '@material-ui/icons/CropFree';
import scraperesults from "../scraperesults";

type MenuName =
  | "menuEntities"
  | "menuSales"
  | "menuActions"
  | "menuStores"
  | "menuLabels"
  | "menuMerges"
  | "menuBarcodes"
  | "menuServer";

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    menuEntities: false,
    menuSales: false,
    menuActions: false,
    menuStores: false,
    menuLabels: false,
    menuMerges: false,
    menuBarcodes: false,
    menuServer: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={1}>
      {" "}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <SubMenu
        handleToggle={() => handleToggle("menuEntities")}
        isOpen={state.menuEntities}
        sidebarIsOpen={open}
        name="Entities"
        icon={<brands.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/brands`}
          primaryText={translate(` Brands`, {
            smart_count: 2,
          })}
          leftIcon={<brands.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to={`/scraperesults`}
          primaryText={translate(` ScrapeResults`, {
            smart_count: 2,
          })}
          leftIcon={<scraperesults.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/products`}
          primaryText={translate(` Products`, {
            smart_count: 2,
          })}
          leftIcon={<products.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/productidentifiers`}
          primaryText={translate(` Product Identifiers`, {
            smart_count: 2,
          })}
          leftIcon={<productidentifiers.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/categories`}
          primaryText={translate(` Categories`, {
            smart_count: 2,
          })}
          leftIcon={<categories.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/comments`}
          primaryText={translate(` Comments`, {
            smart_count: 2,
          })}
          leftIcon={<comments.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to={`/mappings`}
          primaryText={translate(` Mappings`, {
            smart_count: 2,
          })}
          leftIcon={<mappings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/posttemplates`}
          primaryText={translate(` Post Templates`, {
            smart_count: 2,
          })}
          leftIcon={<posttemplates.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        sidebarIsOpen={open}
        name="Locations"
        icon={<cities.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/cities`}
          primaryText={translate(` Cities`, {
            smart_count: 2,
          })}
          leftIcon={<cities.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/towns`}
          primaryText={translate(` Towns`, {
            smart_count: 2,
          })}
          leftIcon={<towns.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/districts`}
          primaryText={translate(` Districts`, {
            smart_count: 2,
          })}
          leftIcon={<districts.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/neighborhoods`}
          primaryText={translate(` Neighborhoods`, {
            smart_count: 2,
          })}
          leftIcon={<neighborhoods.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuStores")}
        isOpen={state.menuStores}
        sidebarIsOpen={open}
        name="Stores"
        icon={<stores.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/stores`}
          primaryText={translate(` Stores`, {
            smart_count: 2,
          })}
          leftIcon={<stores.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/storelocations`}
          primaryText={translate(` Store Locations`, {
            smart_count: 2,
          })}
          leftIcon={<storelocations.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/addStoresToCity`}
          primaryText={translate(`City Stores`, {
            smart_count: 2,
          })}
          leftIcon={<servertools.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/addStoresToTown`}
          primaryText={translate(`Town Stores`, {
            smart_count: 2,
          })}
          leftIcon={<servertools.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuActions")}
        isOpen={state.menuActions}
        sidebarIsOpen={open}
        name="Actions"
        icon={<AssignmentTurnedInIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/postsubmissions`}
          primaryText={translate(`Post Submissions`, {
            smart_count: 2,
          })}
          leftIcon={<postsubmission.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/labelsubmissions`}
          primaryText={translate(` Label Submissions`, {
            smart_count: 2,
          })}
          leftIcon={<scraperesults.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/labelsubmissionscropreview`}
          primaryText={translate(` Crop Review`, {
            smart_count: 2,
          })}
          leftIcon={<scraperesults.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/newproductsubmissions`}
          primaryText={translate(` Product Submission`, {
            smart_count: 2,
          })}
          leftIcon={<scraperesults.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/pricetags`}
          primaryText={translate(` Price Tag Submission`, {
            smart_count: 2,
          })}
          leftIcon={<scraperesults.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/campaignsubmissions`}
          primaryText={translate(`Campaign Submissions`, {
            smart_count: 2,
          })}
          leftIcon={<campaignsubmissions.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/linkcategories`}
          primaryText={translate(`Link Category`, {
            smart_count: 2,
          })}
          leftIcon={<categorylinker.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/unitofmeasurement`}
          primaryText={translate(`Unit Of Measurement`, {
            smart_count: 2,
          })}
          leftIcon={<mergebarcodes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/commentsubmissions`}
          primaryText={translate(`Comment Submissions`, {
            smart_count: 2,
          })}
          leftIcon={<commentsubmission.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/declinedproducts`}
          primaryText={translate(`Declined Products`, {
            smart_count: 2,
          })}
          leftIcon={<declinedproducts.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

      </SubMenu>


      <SubMenu
        handleToggle={() => handleToggle("menuMerges")}
        isOpen={state.menuMerges}
        sidebarIsOpen={open}
        name="Merges"
        icon={<mergebarcodes.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/demerges`}
          primaryText={translate(`DeMerge`, {
            smart_count: 2,
          })}
          leftIcon={<demerge.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/mergeproductsbyname`}
          primaryText={translate(`MP By Name`, {
            smart_count: 2,
          })}
          leftIcon={<mergeproductbyname.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/mergesubmissionsreview`}
          primaryText={translate(`MS Review`, {
            smart_count: 2,
          })}
          leftIcon={<mergesubmissionsreview.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/mergesiblings`}
          primaryText={translate(`Merge Siblings`, {
            smart_count: 2,
          })}
          leftIcon={<mergesiblings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/mergesimilars`}
          primaryText={translate(`Merge Similars`, {
            smart_count: 2,
          })}
          leftIcon={<mergesimilars.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/mergebrands`}
          primaryText={translate(`Merge Brands`, {
            smart_count: 2,
          })}
          leftIcon={<mergebrands.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuBarcodes")}
        isOpen={state.menuBarcodes}
        sidebarIsOpen={open}
        name="Barcodes"
        icon={<CropFreeIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/barcodes`}
          primaryText={translate(`Barcodes`, {
            smart_count: 2,
          })}
          leftIcon={<productidentifiers.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/findBarcode`}
          primaryText={translate(`Find Barcode`, {
            smart_count: 2,
          })}
          leftIcon={<CropFreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/assignBarcodes`}
          primaryText={translate(`Assign Barcodes`, {
            smart_count: 2,
          })}
          leftIcon={<CropFreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/barcodeSubmissions`}
          primaryText={translate(`Barcode Submissions`, {
            smart_count: 2,
          })}
          leftIcon={<CropFreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/barcodeAliases`}
          primaryText={translate(`Barcode Aliases`, {
            smart_count: 2,
          })}
          leftIcon={<CropFreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/duplicateBarcodes`}
          primaryText={translate(`Duplicate Barcodeds`, {
            smart_count: 2,
          })}
          leftIcon={<CropFreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuServer")}
        isOpen={state.menuServer}
        sidebarIsOpen={open}
        name="Server"
        icon={<servertools.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/jobactivities`}
          primaryText={translate(`Job Activity`, {
            smart_count: 2,
          })}
          leftIcon={<jobactivity.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to={`/servertools`}
          primaryText={translate(` Server Tools`, {
            smart_count: 2,
          })}
          leftIcon={<servertools.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        
      </SubMenu>



      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
