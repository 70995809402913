import { useState, useEffect } from "react";
import { Row, Col, Alert, Input, InputGroup, FormGroup } from "reactstrap";
import { BrandSearch } from "./BrandSearch";
import { UnitSearch } from "./UnitSearch";
import {
  useDataProvider,
  Loading,
  useNotify,
  FormWithRedirect,
} from "react-admin";
import { SendProductSubmission } from "./SendProductSubmission";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DeclinedButton } from "./DeclinedButton";
import { SuspiciousButton } from "./SuspiciousButton";
import { CategoryInProductSubmission } from "./CategoryInProductSubmission";
import firebase from "firebase/app";
import "firebase/storage";
import { firebaseConfig } from "../firebaseConfig";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import ReactImageZoom from "react-image-zoom";
import LabelCropper from "./LabelCropper";

export const ProductSubmission = (props: any) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const dataProvider = useDataProvider();
  const [product, setProduct] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const notify = useNotify();

  const [myFilterBrand, setMyFilterBrand] = useState("");

  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedUnit, setSelectedUnit] = useState();

  const [myFilterUnit, setMyFilterUnit] = useState("");
  const [tensorLabel, setTensorLabel] = useState();

  const [productName, setProductName] = useState("");
  const [productLabel, setProductLabel] = useState("");
  const [productPrice, setProductPrice] = useState();
  const [productOldPrice, setProductOldPrice] = useState();
  const [productBarcode, setProductBarcode] = useState<any>();
  const [productSku, setProductSku] = useState<any>();
  const [productAmount, setProductAmount] = useState(0);
  const [labelImage, setLabelImage] = useState();

  const [selectedStoreId, setSelectedStoreId] = useState();
  const [selectedStoreSlug, setSelectedStoreSlug] = useState();
  const [selectedStoreLocationId, setSelectedStoreLocationId] = useState();
  const [barcodeOrSkuExists, setBarcodeOrSkuExists] = useState(false);
  const [checkButtonDisabled, setCheckButtonDisabled] = useState(true);
  //price discount için stateler

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [isExistStartDate, SetIsExistStartDate] = useState(false);
  const [isExistFinishDate, SetIsExistFinishDate] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [selectDiscountKind, setSelectDiscountKind] = useState("NODISCOUNT");
  const [selectDiscountName, setSelectDiscountName] = useState("İndirim Seç");
  const [discountX, setDiscountX] = useState("");
  const [discountY, setDiscountY] = useState("");
  const [discountDescription, setDiscountDescription] = useState("");
  const [cardIsNeeded, setCardIsNeeded] = useState<any>();
  const [discountPercent, setDiscountPercent] = useState("");
  const [discountMin, setDiscountMin] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [brandSearchIsNew, setBrandSearchIsNew] = useState(false);
  const [productId, setProductId] = useState("");

  const [unitPriceUnit, setSelectedUnitPriceUnit] = useState();
  const [selectedUnitPrice, setSelectedUnitPrice] = useState();
  const [myFilterUnitPriceUnit, setMyFilterUnitPriceUnit] = useState("");
  const [unitPrice, setProductAmountUnitPrice] = useState(0.0);

  const [isSelectedAnyUnit, setIsSelectedAnyUnit] = useState(false);
  const [categories, setCategories] = useState();
  // price discount stateler finish
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [imgUrl, setImgUrl] = useState();

  const [alreadyExistsStat, setAlreadyExistsStat] = useState(false);
  const [existsProducts, setExistsProducts] = useState([]);

  const [productIdentifierId, setProductIdentifierId] = useState<any>();
  if (!firebase.apps.length) {
    firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as string);
  }

  useEffect(() => {
    let storeId: any;
    dataProvider
      .getList("labelsubmissions", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "title", order: "ASC" },
        filter: {
          storeIdExists: true,
          productIdExists: false,
          isReviewed: false,
        },
      })
      .then(async ({ data }) => {
        let productBarcodeCheck;
        let productSkuCheck;
        if (typeof data[0].sku !== "undefined") {
          dataProvider
            .getList("productidentifiers", {
              pagination: { page: 1, perPage: 1 },
              sort: { field: "title", order: "ASC" },
              filter: { sku: data[0].sku },
            })
            .then((resp) => {
              if (resp.data[0]) {
                setBarcodeOrSkuExists(true);
                setProductId(resp.data[0].productId);
                setProductName(resp.data[0].productName);
                setProductIdentifierId(resp.data[0].id);
                if (resp.data[0].barcode) {
                  setProductBarcode(resp.data[0].barcode);
                  productBarcodeCheck = resp.data[0].barcode;
                }
                if (resp.data[0].sku) {
                  productSkuCheck = resp.data[0].sku;
                  setProductSku(resp.data[0].sku);
                }
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
        if (
          !productIdentifierId &&
          typeof data[0].barcode != "undefined" &&
          data[0].barcode.length
        ) {
          dataProvider
            .getList("productidentifiers", {
              pagination: { page: 1, perPage: 1 },
              sort: { field: "title", order: "ASC" },
              filter: { barcode: data[0].barcode, storeId: data[0].storeId },
            })
            .then((resp) => {
              if (resp.data[0]) {
                setBarcodeOrSkuExists(true);
                setProductId(resp.data[0].productId);
                setProductName(resp.data[0].productName);
                setProductIdentifierId(resp.data[0].id);
                if (resp.data[0].barcode)
                  setProductBarcode(resp.data[0].barcode);
                if (resp.data[0].sku) setProductSku(resp.data[0].sku);
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
        dataProvider
          .getList("stores", {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "title", order: "ASC" },
            filter: { slug: data[0].store },
          })
          .then((resp) => {
            storeId = resp.data[0].id;
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(data);
        setProduct(data);
        setLabelImage(
          await firebase
            .storage()
            .refFromURL(`gs://zingo-price-labels/labels/${data[0].image}`)
            .getDownloadURL()
        );

        if (data.length > 0) {
          setProductLabel(data[0].productName);
          // setProductDescription(data[0].description);
          setProductPrice(data[0].price);
          setProductOldPrice(data[0].oldPrice);
          if (!productBarcodeCheck) setProductBarcode(data[0].barcode);
          if (!productSkuCheck) setProductSku(data[0].sku);
          setSelectedStoreId(storeId);
          setSelectedStoreSlug(data[0].store);

          setSelectedStoreLocationId(data[0].storeLocationId);
        }
        setLoading(false);
      })
      .catch((error) => {
        //setError(error);
        setLoading(false);
      });
  }, [tensorLabel]);

  const myCallback = (dataFromChild: any) => {
    // console.log('Selected Data', JSON.stringify(dataFromChild))

    setSelectedBrand(dataFromChild);
    setMyFilterBrand(dataFromChild.name);
    setBrandSearchIsNew(false);
    //console.log(dataFromChild);
    // console.log(dataFromChild.id);
    //   console.log('Selected ... ' + JSON.stringify(selectedProduct))
  };

  const myCallbackUnit = (dataFromChild: any) => {
    // console.log('Selected Data', JSON.stringify(dataFromChild))

    setSelectedUnit(dataFromChild);
    setMyFilterUnit(dataFromChild);
    setIsSelectedAnyUnit(true);
    //Burada otomatik hesaplama yapılacak!
    //   console.log('Selected ... ' + JSON.stringify(selectedProduct))
  };

  const myCallbackCategory = (dataFromChild: any) => {
    setSelectedCategoryId(dataFromChild.id);
  };

  const convertToUnitPrice = (element: any, kind: any, price: any) => {
    let cofactor = 1;
    switch (selectedUnit as any) {
      case "KG": {
        if (kind === "G") {
          cofactor = 1 / 1000;
        }
        if (kind === "MG") {
          cofactor = 1 / 1000000;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "G": {
        if (kind === "KG") {
          cofactor = 1000;
        }
        if (kind === "MG") {
          cofactor = 1 / 1000;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "MG": {
        if (kind === "KG") {
          cofactor = 1000000;
        }
        if (kind === "G") {
          cofactor = 1000;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }

      case "M": {
        if (kind === "M") {
          cofactor = 1;
        }
        if (kind === "CM") {
          cofactor = 1 / 100;
        }
        if (kind === "MM") {
          cofactor = 1 / 1000;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "CM": {
        if (kind === "M") {
          cofactor = 100;
        }
        if (kind === "MM") {
          cofactor = 1 / 10;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "MM": {
        if (kind === "M") {
          cofactor = 1000;
        }
        if ((kind = "CM")) {
          cofactor = 10;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }

      case "L": {
        if (kind === "ML") {
          cofactor = 1 / 1000;
        }
        if (kind === "CL") {
          cofactor = 1 / 100;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "ML": {
        if (kind === "L") {
          cofactor = 1000;
        }
        if (kind === "CL") {
          cofactor = 10;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "CL": {
        if (kind === "L") {
          cofactor = 100;
        }
        if (kind === "ML") {
          cofactor = 1 / 10;
        }
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }
      case "QTY":
      case "SRV": {
        cofactor = 1;
        setProductAmountUnitPrice(
          ((price * cofactor) / element).toFixed(2) as any
        );
        break;
      }

      default:
        break;
    }
  };

  const myCallbackUnitPrice = (dataFromChild: any) => {
    // console.log('Selected Data', JSON.stringify(dataFromChild))

    setSelectedUnitPriceUnit(dataFromChild);
    setMyFilterUnitPriceUnit(dataFromChild);
    convertToUnitPrice(productAmount, dataFromChild, productPrice);

    //   console.log('Selected ... ' + JSON.stringify(selectedProduct))
  };

  //Hande for product

  const handleChange = (e: any) => {
    setMyFilterBrand(e.target.value);
    setBrandSearchIsNew(true);
  };
  const handleUnitChange = (e: any) => {
    setMyFilterUnit(e.target.value);
  };
  const handleUnitPriceUnitChange = (e: any) => {
    setMyFilterUnitPriceUnit(e.target.value);
  };
  const changeName = (e: any) => {
    setProductName(e.target.value);
  };

  const changeBarcode = (e: any) => {
    setCheckButtonDisabled(false);
    setProductBarcode(e.target.value);
  };

  const changeSku = (e: any) => {
    setCheckButtonDisabled(false);
    setProductSku(e.target.value);
  };

  const changeLabel = (e: any) => {
    setProductLabel(e.target.value);
  };

  const changePrice = (e: any) => {
    setProductPrice(e.target.value);
    convertToUnitPrice(productAmount, unitPriceUnit, e.target.value);
  };

  const changeOldPrice = (e: any) => {
    setProductOldPrice(e.target.value);
  };

  const changeAmount = (e: any) => {
    setProductAmount(e.target.value);
    convertToUnitPrice(e.target.value, unitPriceUnit, productPrice);
  };

  // handle for price
  const changeStartDate = () => {
    let current = !isExistStartDate;
    SetIsExistStartDate(current);
  };

  const changeFinishDate = () => {
    let current = !isExistFinishDate;
    SetIsExistFinishDate(current);
  };
  /// Discount Panel Handles
  const changeDiscountX = (e: any) => {
    setDiscountX(e.target.value);
  };
  const changeDiscountY = (e: any) => {
    setDiscountY(e.target.value);
  };
  const changeDiscountDescription = (e: any) => {
    setDiscountDescription(e.target.value);
  };
  const changeCardNeeded = () => {
    let current = !cardIsNeeded;
    setCardIsNeeded(current);
  };
  const changeDiscountPercent = (e: any) => {
    setDiscountPercent(e.target.value);
  };
  const changeDiscountMin = (e: any) => {
    setDiscountMin(e.target.value);
  };
  const changeDiscountPrice = (e: any) => {
    setDiscountPrice(e.target.value);
  };
  ///

  //Price discound codes

  const clickDropItem = (key: any) => {
    switch (key) {
      case "NODISCOUNT":
        setSelectDiscountKind(key);
        setSelectDiscountName("İndirim Yok");
        break;

      case "BUY1GET2YOFF":
        setSelectDiscountKind(key);
        setSelectDiscountName("1 Alana 2");

        break;

      case "BUYXGETY":
        setSelectDiscountKind(key);
        setSelectDiscountName("X Alana Y");

        break;

      case "BUYMINX":
        setSelectDiscountKind(key);
        setSelectDiscountName("Minumum X adet");

        break;

      case "ABOVEXTL":
        setSelectDiscountKind(key);
        setSelectDiscountName("X Lira Üzerine");

        break;
      default:
        //  console.log('error')
        break;
    }
  };

  // const alreadyExists = () => {

  //   if (true) {
  //     return (
  //       <Card style={{ backgroundColor: "#ffffff", height: "50%", display: "inline-block", width: "100%", alignItems: "block", }}>
  //         <CardHeader title="Already Exists" style={{ backgroundColor: "#30526a", color: "#ffffff", alignContent: "center", textAlign: "center" }} ></CardHeader>
  //         <CardContent style={{ padding: "3%", }}>
  //           {existsProducts.map((p) => {
  //             return <div style={{cursor:"pointer"}} onClick={() => {setProductId(p.id)}}>
  //               <h4>{p.name}</h4>
  //               <hr />
  //               <p>{p.barcode}</p>
  //               {p.prices.map((pr) => {
  //                 return <p>{pr.store.toUpperCase()}</p>
  //               })}
  //               <br />
  //             </div>
  //           })}
  //         </CardContent>
  //       </Card>
  //     );
  //   } else {
  //     return null;
  //   }

  // }

  const showUnitPriceOfProduct = () => {
    if (!isSelectedAnyUnit) {
      return <div></div>;
    } else {
      return (
        <div>
          <Row className={"my-2"}>
            <Col xs={"6"}>
              {" "}
              <InputGroup>
                <Input
                  value={unitPrice}
                  readOnly
                  placeholder="Amount"
                  type="number"
                />
              </InputGroup>
            </Col>
            <Col xs={"6"}>
              {" "}
              <FormGroup>
                <Input
                  type="search"
                  name="search"
                  id="filter"
                  value={myFilterUnitPriceUnit}
                  placeholder="Unit"
                  onChange={handleUnitPriceUnitChange}
                  autoComplete="off"
                />
                <UnitSearch
                  callbackFromParent={myCallbackUnitPrice}
                  searchKey={myFilterUnitPriceUnit}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      );
    }
  };

  const checkDatePanel = () => {
    return (
      <div>
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Başlangıç Tarihi Var mı?"
          onClick={() => changeStartDate()}
        />
        {showStartDatePanel()}

        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Bitiş Tarihi Var mı?"
          onClick={() => changeFinishDate()}
        />
        {showFinishDatePanel()}
      </div>
    );
  };

  const showStartDatePanel = () => {
    if (isExistStartDate === true) {
      return (
        <div>
          <div className={"mt-1"}>
            Baslangic &nbsp;
            <DatePicker
              selected={startDate}
              onChange={(date) => setFinishDate(date as any)}
            />
          </div>
        </div>
      );
    } else {
      return <div> </div>;
    }
  };

  const showFinishDatePanel = () => {
    if (isExistFinishDate === true) {
      return (
        <div>
          <div className={"mt-1"}>
            Bitiş &nbsp;
            <DatePicker
              selected={finishDate}
              onChange={(date) => setFinishDate(date as any)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          <br />{" "}
        </div>
      );
    }
  };

  const showDiscountDescription = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Discount Description
        </Typography>
        <TextareaAutosize
          id="discountDescription"
          rowsMax={4}
          aria-label="maximum height"
          placeholder={discountDescription}
          onChange={changeDiscountDescription}
          style={{ width: 500, height: 100 }}
        />
      </>
    );
  };

  const showIsCardNeed = () => {
    return (
      <FormControlLabel
        value={cardIsNeeded}
        control={<Checkbox color="primary" />}
        label="Kart Gerekiyor mu?"
        onChange={changeCardNeeded}
      />
    );
  };

  const showDiscount = (key: any) => {
    switch (key) {
      case "NODISCOUNT":
        return (
          <div>
            <Box mt="1em" /> İndirim seçilmedi!
          </div>
        );

      case "BUY1GET2YOFF":
        return (
          <div className={"mt-2"}>
            <Box mt="1em" />
            <TextField
              id="discountPercent"
              label="%"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountPercent}
              value={discountPercent}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            <TextField
              id="discountPrice"
              label="Discount Price"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountPrice}
              value={discountPrice}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            {showDiscountDescription()}
            <Box mt="1em" />
            {showIsCardNeed()}
            {checkDatePanel()}
          </div>
        );

      case "BUYXGETY":
        return (
          <div>
            <Box mt="1em" />
            <TextField
              id="discountX"
              label="X Alana"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountX}
              value={discountX}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            <TextField
              id="discountY"
              label="Y Bedava"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountY}
              value={discountY}
              style={{ width: 500 }}
            />
            <Box mt="1em" />

            {showDiscountDescription()}
            <Box mt="1em" />
            {showIsCardNeed()}
            {checkDatePanel()}
          </div>
        );

      case "BUYMINX":
        return (
          <div>
            <Box mt="1em" />
            <TextField
              id="discountMin"
              label="Buy Minumum"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountMin}
              value={discountMin}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            <TextField
              id="discountPrice"
              label="Discount Price"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountPrice}
              value={discountPrice}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            {showDiscountDescription()}
            <Box mt="1em" />
            {showIsCardNeed()}
            {checkDatePanel()}
          </div>
        );

      case "ABOVEXTL":
        return (
          <div>
            <Box mt="1em" />
            <TextField
              id="discountPrice"
              label="X TL Uzeri"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={discountPrice}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            <TextField
              id="discountPrice"
              label="Discount Price"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={changeDiscountPrice}
              value={discountPrice}
              style={{ width: 500 }}
            />
            <Box mt="1em" />
            {showDiscountDescription()}
            <Box mt="1em" />

            {showIsCardNeed()}
            {checkDatePanel()}
          </div>
        );

      default:
        break;
    }
  };

  const checkIfProductExists = () => {
    let skuToSearch = productSku;
    if (typeof productSku !== "undefined" && productSku.length > 0) {
      dataProvider
        .getList("productidentifiers", {
          pagination: { page: 1, perPage: 5 },
          sort: { field: "title", order: "ASC" },
          filter: { sku: skuToSearch },
        })
        .then((resp) => {
          if (resp.data[0]) {
            notify("Product Found!");
            setBarcodeOrSkuExists(true);
            setProductId(resp.data[0].productId);
            setProductName(resp.data[0].productName);
            setProductIdentifierId(resp.data[0].id);
            return;
          } else if (
            productBarcode.length > 0 &&
            typeof productBarcode !== "undefined"
          ) {
            dataProvider
              .getList("productidentifiers", {
                pagination: { page: 1, perPage: 5 },
                sort: { field: "title", order: "ASC" },
                filter: { barcode: productBarcode, storeId: selectedStoreId },
              })
              .then((resp) => {
                if (resp.data[0]) {
                  notify("Product Found!");
                  setBarcodeOrSkuExists(true);
                  setProductId(resp.data[0].productId);
                  setProductName(resp.data[0].productName);
                  setProductIdentifierId(resp.data[0].id);
                  return;
                } else {
                  if (productIdentifierId == "") {
                    notify("Product Not Found!", "warning");
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const emtpyPage = () => {
    let isNew = false;
    if (product.length === 0) {
      return (
        <div>
          <Alert color="danger">
            Product Submission'da ürün bulunmamaktadır!
          </Alert>
        </div>
      );
    } else {
      if (barcodeOrSkuExists) {
        return (
          <FormWithRedirect
            {...props}
            render={(formProps) => (
              <form>
                <Box p="1em">
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <ReactImageZoom
                            height={300}
                            style={{ borderRadius: 5 }}
                            img={labelImage}
                            zoomPosition="original"
                          ></ReactImageZoom>
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <LabelCropper
                            defaultLabel={labelImage}
                            productId={productId}
                          ></LabelCropper>
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productId"
                            label="Product ID"
                            defaultValue={productId}
                            variant="outlined"
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productName"
                            label="Name"
                            defaultValue={productName}
                            variant="outlined"
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productBarcode"
                            label="Barcode"
                            defaultValue={productBarcode}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                      <Box display="flex">
                        <TextField
                          id="productSKU"
                          label="SKU"
                          defaultValue={productSku}
                          variant="outlined"
                          onChange={changeSku}
                        />

                        <TextField
                          id="productLabel"
                          label="Label"
                          defaultValue={productLabel}
                          variant="outlined"
                          onChange={changeLabel}
                        />

                        <TextField
                          id="productPrice"
                          label="Price"
                          defaultValue={productPrice}
                          variant="outlined"
                          onChange={changePrice}
                        />

                        <TextField
                          id="productOldPrice"
                          label="OldPrice"
                          defaultValue={productOldPrice}
                          variant="outlined"
                          onChange={changeOldPrice}
                        />
                      </Box>
                      {showUnitPriceOfProduct()}
                      <InputLabel id="demo-simple-select-label">
                        Campaign
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                      >
                        <MenuItem
                          value={"İndirim Yok"}
                          onClick={() => clickDropItem("NODISCOUNT")}
                        >
                          İndirim Yok
                        </MenuItem>
                        <MenuItem
                          value={"1 Alana 2."}
                          onClick={() => clickDropItem("BUY1GET2YOFF")}
                        >
                          1 Alana 2.
                        </MenuItem>
                        <MenuItem
                          value={"X Alana Y"}
                          onClick={() => clickDropItem("BUYXGETY")}
                        >
                          X Alana Y
                        </MenuItem>
                        <MenuItem
                          value={"Minumum X Adet"}
                          onClick={() => clickDropItem("BUYMINX")}
                        >
                          Minumum X Adet
                        </MenuItem>
                        <MenuItem
                          value={"X lira üzerine"}
                          onClick={() => clickDropItem("ABOVEXTL")}
                        >
                          X lira üzerine
                        </MenuItem>
                      </Select>

                      {showDiscount(selectDiscountKind)}

                      <SendProductSubmission
                        isProductExists={true}
                        productIdentifierId={productIdentifierId}
                        labelSubmissionId={product[0].id}
                        productBarcode={productBarcode}
                        productId={productId}
                        productName={productName}
                        productLabel={productLabel}
                        productPrice={Number(productPrice)}
                        productOldPrice={Number(productOldPrice)}
                        productSku={productSku}
                        selectedStoreId={selectedStoreId}
                        selectedStoreSlug={selectedStoreSlug}
                        selectedStoreLocationId={selectedStoreLocationId}
                        discountX={Number(discountX)}
                        discountY={Number(discountY)}
                        discountDescription={discountDescription}
                        cardIsNeeded={cardIsNeeded}
                        discountPercent={Number(discountPercent)}
                        discountMin={Number(discountMin)}
                        discountPrice={Number(discountPrice)}
                        isExistStartDate={isExistStartDate}
                        isExistFinishDate={isExistFinishDate}
                        startDate={startDate}
                        finishDate={finishDate}
                        selectDiscountKind={selectDiscountKind}
                        unitPrice={Number(unitPrice)}
                        unitPriceUnit={unitPriceUnit}
                        selectedCategoryId={selectedCategoryId}
                      />

                      <DeclinedButton labelId={product[0].id} />
                      <SuspiciousButton labelId={product[0].id} />
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          />
        );
      } else {
        return (
          <FormWithRedirect
            {...props}
            render={(formProps) => (
              <form>
                <Card>
                  <Box p="1em">
                    <Box display="flex">
                      <Box flex={2} mr="1em">
                        <Box display="flex">
                          <Typography variant="h6" gutterBottom>
                            Cropper
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Box
                            flex={1}
                            mr="0.5em"
                            style={{ marginLeft: "35%" }}
                          >
                            <ReactImageZoom
                              width={430}
                              height={215}
                              style={{ borderRadius: 5 }}
                              img={labelImage}
                              zoomWidth={430}
                              zoomPosition="original"
                            ></ReactImageZoom>
                          </Box>
                          {/* <Box flex={1} mr="0.5em">
                            <LabelCropper
                              defaultLabel={labelImage}
                              productId={productId}
                              setTensorLabel={setTensorLabel}
                              isNewProduct={true}
                            ></LabelCropper>
                          </Box> */}
                        </Box>
                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <FormGroup>
                              <TextField
                                type="search"
                                name="search"
                                id="outlined-read-only-input"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={myFilterBrand}
                                label="Search in Brands"
                                onChange={handleChange}
                                variant="outlined"
                                style={{ width: 500 }}
                                autoComplete="off"
                              />
                              <BrandSearch
                                callbackFromParent={myCallback}
                                searchKey={myFilterBrand}
                                brandSearchIsNew={brandSearchIsNew}
                              />
                            </FormGroup>
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <TextField
                              id="productBarcode"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Barcode"
                              onChange={changeBarcode}
                              defaultValue={productBarcode}
                              style={{ width: 500 }}
                            />
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <TextField
                              id="productSku"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="SKU"
                              onChange={changeSku}
                              defaultValue={productSku}
                              style={{ width: 500 }}
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />

                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextField
                              resource={productName}
                              id="outlined-read-only-input"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Name"
                              defaultValue={productName}
                              variant="outlined"
                              onChange={changeName}
                              style={{ width: 500 }}
                            />
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <TextField
                              resource={productLabel}
                              id="outlined-read-only-input"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Label"
                              defaultValue={productLabel}
                              variant="outlined"
                              onChange={changeLabel}
                              style={{ width: 500 }}
                            />
                          </Box>
                          <Box flex={1} mr="0.5em"></Box>
                        </Box>
                        <Box mt="1em" />
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextField
                              id="productPrice"
                              label="Price"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              defaultValue={productPrice}
                              onChange={changePrice}
                              style={{ width: 500 }}
                            />
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <TextField
                              id="productoldPrice"
                              label="OldPrice"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              defaultValue={productOldPrice}
                              onChange={changeOldPrice}
                              style={{ width: 500 }}
                            />
                          </Box>
                          <Box flex={1} mr="0.5em">
                            <CategoryInProductSubmission
                              callbackFromParentCategory={myCallbackCategory}
                            />
                          </Box>
                        </Box>

                        <Box mt="1em" />
                        {/* <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <TextField
                              id="productAmount"
                              label="Amount"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              onChange={changeAmount}
                              value={productAmount}
                              style={{ width: 500 }}
                            />
                          </Box>
                          <Box mt="1em" />
                          <Box flex={1} mr="0.5em">
                            <FormGroup>
                              <TextField
                                id="filter"
                                label=" Unit"
                                defaultValue={myFilterUnit}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                onChange={handleUnitChange}
                                style={{ width: 500 }}
                              />
                              <UnitSearch
                                callbackFromParent={myCallbackUnit}
                                searchKey={myFilterUnit}
                              />
                            </FormGroup>
                          </Box>
                          <Box flex={1} mr="0.5em"></Box>
                        </Box> */}

                        {/* {showUnitPriceOfProduct()} */}
                        {/* <Box mt="1em" /> */}
                        {/* <FormControl variant="outlined" >
                                                <InputLabel htmlFor="outlined-age-native-simple">Campaign</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Campaign"
                                                    style={{ width: 500 }}
                                                >
                                                    <MenuItem value={"İndirim Yok"} onClick={() => clickDropItem("NODISCOUNT")}>İndirim Yok</MenuItem>
                                                    <MenuItem value={"1 Alana 2."} onClick={() => clickDropItem("BUY1GET2YOFF")}>1 Alana 2.</MenuItem>
                                                    <MenuItem value={"X Alana Y"} onClick={() => clickDropItem("BUYXGETY")}>X Alana Y</MenuItem>
                                                    <MenuItem value={"Minumum X Adet"} onClick={() => clickDropItem("BUYMINX")}>Minumum X Adet</MenuItem>
                                                    <MenuItem value={"X lira üzerine"} onClick={() => clickDropItem("ABOVEXTL")}>X lira üzerine</MenuItem>
                                                </Select>
                                            </FormControl> */}

                        {/* {showDiscount(selectDiscountKind)} */}
                        <Box mt="5em" />

                        <DeclinedButton labelId={product[0].id} />

                        <SuspiciousButton labelId={product[0].id} />

                        <SendProductSubmission
                          labelSubmissionId={product[0].id}
                          selectedBrand={selectedBrand}
                          productBarcode={productBarcode}
                          productId={productId}
                          productName={productName}
                          productLabel={productLabel}
                          productPrice={Number(productPrice)}
                          productOldPrice={Number(productOldPrice)}
                          productSku={productSku}
                          selectedUnit={selectedUnit}
                          productAmount={Number(productAmount)}
                          selectedStoreId={selectedStoreId}
                          selectedStoreSlug={selectedStoreSlug}
                          selectedStoreLocationId={selectedStoreLocationId}
                          discountX={Number(discountX)}
                          discountY={Number(discountY)}
                          discountDescription={discountDescription}
                          cardIsNeeded={cardIsNeeded}
                          discountPercent={Number(discountPercent)}
                          discountMin={Number(discountMin)}
                          discountPrice={Number(discountPrice)}
                          isExistStartDate={isExistStartDate}
                          isExistFinishDate={isExistFinishDate}
                          startDate={startDate}
                          finishDate={finishDate}
                          selectDiscountKind={selectDiscountKind}
                          unitPrice={Number(unitPrice)}
                          unitPriceUnit={unitPriceUnit}
                          selectedCategoryId={selectedCategoryId}
                          tensorLabel={tensorLabel}
                        />
                        <Box mt="1em" />
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </form>
            )}
          />
        );
      }
    }
  };

  if (loading) return <Loading />;
  //if (error) return <Error />;

  return <div>{emtpyPage()}</div>;
};
