import React, { FC } from 'react';

import {
    List,
    ListProps,
    Datagrid,
    TextField,
    NumberField,
} from 'react-admin';


import BrandShow from './BrandShow';
import BrandFilter from './BrandFilter';



const BrandList: FC<ListProps> = props => {

    return (
        <List
            {...props}
            filters={<BrandFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<BrandShow />}>
                <TextField source="name" />
                <NumberField source="slug" label="Slug" />
            </Datagrid>
        </List>
    );
};

export default BrandList;
