import * as React from 'react';
import {

    Edit,
    TextInput,
    NumberField,
    SimpleForm,
} from 'react-admin';


export const CategoryEdit = (props: any) => (

    <Edit {...props}>
        <SimpleForm>
            <TextInput source="categoryName" />
            <NumberField source="depth" />
        </SimpleForm>
    </Edit>

);

export default CategoryEdit;
