
import React, { useState, useEffect, FC } from 'react';
import { useDataProvider, Loading } from 'react-admin';


import { List, ListItem, ListItemText } from '@material-ui/core';


export const StoreLocationSearch = ({ callbackFromParent, searchKey, slug, labelId }: { callbackFromParent: any, searchKey: any, slug: any, labelId: any }) => {

    const dataProvider = useDataProvider();
    const [storeLocationList, setStoreLocationList] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [hide, setHide] = useState(Boolean);

    useEffect(() => {
        dataProvider.getOne('labelsubmissions', { id: labelId }).then((resp) => {
            dataProvider.getList('storelocations', {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'title', order: 'ASC' },
                filter: { insideArea: resp.data.location },
            })
                .then(({ data }) => {
                    if (typeof data !== 'undefined') {
                        let arr = [] as any;
                        data.forEach((d) => {
                            if (d.slug === slug) {
                                arr.push(d);
                            }
                        })
                        setStoreLocationList(arr);
                        arr = [];
                        setHide(false)
                    }
                    setLoading(false);
                })
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [slug]);


    const selectData = (item: any) => {
        setSelectedItem(item);
        callbackFromParent(item);
        setHide(true)
    }


    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (hide) return null;
    if (selectedItem) return null;
    if (!storeLocationList) return null;


    return (


        <List dense={true} style={{ fontSize: "10px" }}  >
            {storeLocationList.map((item: any, index: any) => (
                <ListItem
                    key={item.id}
                    button
                    onClick={() => selectData(item)}
                >
                    <ListItemText
                        primary={`${item.slug.toUpperCase()} , ${item.name}`}
                    />
                </ListItem>
            ))}
        </List>


    )
};

