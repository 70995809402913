import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    ArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ProductZingoFilter from './ProductZingoFilter';
import ProductZingoEdit from './ProductZingoEdit';


const useStyles = makeStyles(theme => ({
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const ProductZingoList: FC<ListProps> = props => {
    const classes = useStyles();
    return (
        <List
            {...props}
            filters={<ProductZingoFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <ArrayField source="prices" label="Store">
                    <SingleFieldList>
                        <ChipField source="store" />
                    </SingleFieldList>
                </ArrayField>
                <TextField source="barcode" />
                <TextField source="slug" />
            </Datagrid>
        </List>
    );
};

export default ProductZingoList;
