import React, { FC } from 'react';

import {
    Filter,
    SearchInput,
    FilterProps,

} from 'react-admin';


const BrandFilter: FC<Omit<FilterProps, 'children'>> = props => {

    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

export default BrandFilter;
