import React, { FC } from "react";

import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  useRecordContext,
} from "react-admin";

import JobActivityFilter from "./JobActivityFilter";
import JobActivityShow from "./JobActivityShow";

const JobActivityList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<JobActivityFilter />}
      perPage={10}
      sort={{ field: "date", order: "desc" }}
    >
      <Datagrid rowClick="expand" expand={<JobActivityShow />}>
        <TextField source="userName" />
        <TextField source="jobType" />
        <DateField source="date" />
        <NumberField source="count" />
      </Datagrid>
    </List>
  );
};

export default JobActivityList;
