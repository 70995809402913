import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IncomingBrands from "./IncomingBrands";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: "20px",
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    }
}));

export default function () {
    const classes = useStyles();
    const [selectedBrand, setSelectedBrand] = useState();

    return (

        <IncomingBrands outBrand={setSelectedBrand}></IncomingBrands>

    )

}