import {
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { Loading, useDataProvider, useNotify } from "react-admin";
import ActionCard from "./ActionCard";
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  card: {
    margin: theme.spacing(2),
  },
  rootCard: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "15em",
    height: "47em",
    backgroundColor: theme.palette.background.paper,
  },
  rootCardFalse: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "15em",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
  },
  cardFalse: {
    height: "100%",
  },
  media: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  mediaCard: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
    display: "block",
    margin: "auto",
    padding: "auto",
  },
  titleDivider: {
    margin: theme.spacing(5),
  },
  titleDividerMergeProduct: {
    margin: theme.spacing(2),
  },
  titleProductName: {
    height: "0.5em",
    paddingBottom: "5em",
  },
  titleProductPrices: {
    paddingTop: "2em",
  },
  button: {
    width: 512,
    height: 128,
  },
  paperTop: {
    padding: theme.spacing(2),
    width: "auto",
    textAlign: "center",
    fontSize: "2rem",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  divider: {
    marginRight: "1%",
    marginLeft: "1%",
  },
}));

export const MergeSubmissionsReview = (props: any) => {
  const [productIds, setProductIds] = useState<any>();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [products, setProducts] = useState<any>([]);
  const [refresh, setRefresh] = useState<any>(false);
  const [mergeSubmission, setMergeSubmission] = useState<any>();
  const [clickedProduct, setClickedProduct] = useState<any>();
  const [selectedProductName, setSelectedProductName] = useState<any>();
  const [selectedImages, setSelectedImages] = useState<any[]>()

  useEffect(() => {
    setSelectedProductName("")
    if (productIds && productIds.length) {
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 5 },
          sort: { field: "title", order: "ASC" },
          filter: { ids: productIds },
        })
        .then((resp) => {
          if (resp.data.length) {
            setProducts(resp.data);
          } else {
            dataProvider
              .update("mergesubmissions", {
                id: mergeSubmission.id,
                data: { id: mergeSubmission.id, isReviewed: true },
                previousData: { title: "previous title" } as any,
              }).then(_ => {
                window.location.reload()
              })
          }
        })
        .catch((err) => {
          notify("Get Products Error!" + err.message, "error");
        });
    } else {
      setProducts([])
    }
  }, [productIds]);

  const removeProduct = (product: any, index: any) => {
    setClickedProduct(product);
    const arrayProducts: any[] = [...products];
    if (arrayProducts.includes(product)) {
      for (let i = 0; i < arrayProducts.length; i++) {
        if (arrayProducts[i] === product) {
          arrayProducts.splice(i, 1);
        }
      }
    }
    else {
      arrayProducts.push(product);
    }
    setProducts(arrayProducts);
  }

  return (
    <Grid>
      <Grid item xs={12}>
        <ActionCard setProductIds={setProductIds} setMergeSubmission={setMergeSubmission} setRefresh={setRefresh} clickedProduct={clickedProduct} selectedProductName={selectedProductName} selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
      </Grid>
      <Grid container spacing={2} xs={12}>
        {products.map((product: any, index: any) => (
          <Grid key={product.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
            <Card className={classes.card}>
              <div style={{ display: "flex", float: "right" }}>
                <Button variant="contained" color="primary" onClick={() => { removeProduct(product, index) }} >
                  X
                </Button>
              </div>
              <img
                src={
                  product.images[0]
                    ? product.images[0]?.url
                    : `https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png`
                }
                onClick={() => { setSelectedImages([{ url: product.images[0]?.url }]) }}
                className={classes.media}
              ></img>
              <CardContent className={classes.titleProductName}>
                <Typography variant="h5" component="h2" align="center" onClick={() => { setSelectedProductName(product.name) }}>
                  {product.name}
                </Typography>
                <hr />
                <Typography variant="h5" component="h2" align="center" onClick={() => { setSelectedProductName(product.name) }}>
                  {product.barcode}
                </Typography>
                <hr />

              </CardContent>
              <Divider variant="middle" className={classes.titleDivider} />

              <CardContent className={classes.titleProductPrices}>
                <Typography variant="h6" component="h3" align="center">
                  {product.brand}
                </Typography>

                <Typography variant="h6" component="h3" align="center">
                  {product.category}
                </Typography>
                <Divider variant="middle" className={classes.titleDivider} />
                {product.prices.map((price: any) => (
                  <Typography
                    variant="h5"
                    component="h2"
                    align="left"
                    style={{ cursor: "pointer" }}

                  >
                    {price && price.url && price.url !== null ? (
                      <Link
                        href={
                          price && price.url && price.url !== null
                            ? price.url
                            : ""
                        }
                        target="_blank"
                      >
                        {price.store}
                      </Link>
                    ) : (
                      price.store
                    )}
                    {` : ${price.price}`}
                  </Typography>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '1%'
                  }}
                >

                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default MergeSubmissionsReview;
