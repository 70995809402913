import { useState, useEffect } from 'react';
import { useDataProvider, Loading, useNotify } from 'react-admin';
import CampaignSubmission from './CampaignSubmission'
/**
 * Campaign submission is our component which provide to manage campaigns.
 * Firstly, we are getting only one campaign from out service. Then,
 * we are taking credentials of store by using slug. Because when we scrap 
 * instagram accounts, we know which campaign is created by whom.
 * Then, we send this data to campaignSubmission component.
 */

export const CampaignSubmissionContainer = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [campaignSubmission, setCampaignSubmission] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [store, setStore] = useState({});
    const [needToExtract, setNeedToExtract] = useState(false);

    useEffect(() => {
        if (needToExtract) {
            if (!campaignSubmission[0].extractedString) {
                dataProvider.update('campaignsubmissions', { id: campaignSubmission[0].id, data: { id: campaignSubmission[0].id, isExtracted: true } } as any)
                    .then((data: any) => {
                        setCampaignSubmission([data.data]);
                        window.localStorage.setItem("extractedString", data.data.extractedString)
                        if (data.data.store) {
                            getStoreByStoreSlug(data.data.store)
                        }
                        setLoading(false)
                    })
                    .catch(error => {
                        setError(error);
                        setLoading(false);
                    })
            } else {
                notify("Campaignin extracted fieldleri çıkartılmış.")
                window.localStorage.setItem("extractedString", campaignSubmission[0].extractedString)
            }
        } else {
            dataProvider.getList('campaignsubmissions', { filter: { isReviewed: false }, pagination: { page: 1, perPage: 1 } } as any)
                .then(({ data }) => {
                    if (data.length) {
                        setCampaignSubmission(data);
                        if (data[0].store) {
                            getStoreByStoreSlug(data[0].store)
                        }
                    }
                    setLoading(false)
                })
                .catch(error => {
                    notify("Get campaign submission error!", error)
                    setLoading(false);
                })
        }
    }, [needToExtract]);


    const getStoreByStoreSlug = (store: any) => {
        dataProvider
            .getList('stores', {
                filter: { slug: store },
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'title', order: 'ASC' },
            })
            .then(response => {
                setStore(response.data[0]);
                setLoading(false);
            })
            .catch(error => {
            });
    }


    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (!campaignSubmission) return null;

    return (
        <div>
            <CampaignSubmission campaignSubmission={campaignSubmission} store={store} needToExtract={setNeedToExtract} />
        </div>
    )
};