import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    NumberField,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
} from 'react-admin';

import DistrictsShow from './DistrictsShow';
import DistrictsFilter from './DistrictsFilter';


const DistrictsList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            filters={<DistrictsFilter />}
            perPage={5}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<DistrictsShow />}>
                <TextField source="name" />
                <NumberField source="city" label="City" />
                <NumberField source="town" label="Town" />
                {/* <ReferenceManyField label="Neighborhoods" reference="neighborhoods" target="districtId">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceManyField> */}
            </Datagrid>
        </List>
    );
};

export default DistrictsList;
