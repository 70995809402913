import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';

export const BrandSearch = (props : any) => {

    const dataProvider = useDataProvider();
    const [brandList, setBrandList] = useState<Record<any, any>[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [hide, setHide] = useState(Boolean);

    useEffect(() => {

        dataProvider.getList('brands', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'title', order: 'ASC' },
            filter: { q: props.searchKey },
        })
            .then(({ data }) => {
                if (typeof data !== 'undefined') {
                    setBrandList(data);
                    setHide(false)
                }
                setLoading(false);
                props.setLoading(false)
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [props.searchKey]);


    const selectData = (item: any) => {
        setSelectedItem(item);
        props.callbackFromParent(item.name);
        props.setBrandIdToSend(item.id)
        setHide(true)
    }


    if (loading) return <Loading />;
    //if (error) return <Error {...props} />;
    if (hide) return null;
    if (props.checked) return null;
    if (props.searchKey?.lenght < 2) return null;
    if (!brandList) return null;


    return (
        <div>
            {brandList.map((item, index) =>
                <Container key={item.id}>
                    <div key={item.id} className={"customListItem"}>
                        <Row>
                            <Col xs="9"><span onClick={() => selectData(item)} > {item.name} </span></Col>
                        </Row>
                    </div>
                </Container>
            )}
        </div>
    )
};

