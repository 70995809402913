import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, Loading, Error, useNotify } from 'react-admin';
import { Avatar, Checkbox } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { CardHeader, FilledInput } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import PopupImages from "./PopupImages"
import { elasticParenthesesRemover } from "../Helper";
import { Barcode } from '../types';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 3,
    },
    listImageView: {
        height: 80,
        width: 80,
        marginLeft: 10,
    },
    tile: {
        display: "inline-block;",
        padding: theme.spacing(1),
    },
}));

const GetBarcode = (props: any) => {
    const notify = useNotify();
    let barcodeList = [];
    const classes = useStyles();
    const [barcodes, setBarcodes] = useState<Record<any, any>[]>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [clickedIndex, setClickedIndex] = useState();
    const dataProvider = useDataProvider();
    const searchTerm = props.name;

    useEffect(() => {
        if (searchTerm) {
            setBarcodes([])
            let search = elasticParenthesesRemover(searchTerm);
            dataProvider.getList('barcodes', {
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'title', order: 'ASC' },
                filter: { q: search, },
            })
                .then(({ data }) => {
                    setBarcodes([...data])
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }
    }, [searchTerm])

    if (loading) return <Loading />;
    if (error) return <Error {...props} />;
    if (!props.name) return <Loading />;

    const handleChangeName = (e: any, v: any) => {

        if (e.target.checked) {
            props.setClickedProductName(v);
        } else {
            props.setClickedProductName(searchTerm)
        }

    };

    const handleChangeBrand = (e: any, v: any) => {
        if (e.target.checked) {
            props.setClickedProductBrand(v);

        } else {
            props.setClickedProductBrand("")
        }
        //console.log("1")

    };

    const handleChangeBarcode = (e: any, v: any) => {
        if (e.target.checked) {
            props.setClickedProductBarcode(v);

        } else {
            props.setClickedProductBarcode("")
        }

    };


    const checkName = (n: any) => {
        if (!props.clickedProductName) return false;
        if (n === props.clickedProductName) {
            return true;
        } else {
            return false;
        }
    };

    const checkBrand = (n: any) => {

        if (!props.clickedProductBrand) {

            return false;
        }
        if (n === props.clickedProductBrand) {
            return true;
        } else {
            return false;
        }
    };

    const checkBarcode = (n: any) => {
        if (!props.clickedProductBarcode) return false;
        if (n === props.clickedProductBarcode) {
            return true;
        } else {
            return false;
        }
    };

    const renderedBarcodes = barcodes?.map((barcode, index) => {
        return (
            <React.Fragment key={barcode.name}  >

                <Card style={{ backgroundColor: "#ffffff", width: "100%", marginBottom: "3%" }} >
                    <CardHeader style={{ backgroundColor: "#bdc3c7", color: "#ffffff" }} title={barcode.name}>
                        <h4>{barcode.name}</h4>
                    </CardHeader>
                    <CardContent >




                        <Checkbox
                            checked={checkName(barcode.name)}
                            onChange={e => handleChangeName(e, barcode.name)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    İsim: {barcode.name}
                        <hr></hr>
                        <Checkbox

                            onChange={e => handleChangeBrand(e, barcode.brand)}
                            checked={checkBrand(barcode.brand)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    Marka: {barcode.brand}
                        <hr></hr>
                        <Checkbox
                            checked={checkBarcode(barcode.barcode)}
                            onChange={e => handleChangeBarcode(e, barcode.barcode)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    Barkod: {barcode.barcode}
                        <hr></hr>


                    </CardContent>
                    {barcode.googleImages ? <PopupImages images={barcode.googleImages} aliases={barcode.aliases}></PopupImages> : <div></div>}



                </Card>

            </React.Fragment>
        );
    });
    return (
        <div>
            {renderedBarcodes}
        </div>
    );
};



export default GetBarcode;