import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import { Badge, Button, CardContent, Checkbox, Divider, FormControlLabel, Paper, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card'
import { Loading, useDataProvider, useNotify } from 'react-admin';
import CircularProgress from '../newpostsubmissions/CircularProgress'

const useStyles = makeStyles((theme) => ({
 
    rootDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        width: 'auto',
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
      },
    gridList: {
        width: "100%",
    },
    card: {
        height: "100%" ,
        
    },
    cardFalse: {
        height: "100%" ,
        backgroundColor:"lightgray"
    },
    titleDivider: {
        margin: theme.spacing(5),
    },
    titleProductName: {
        height: '0.5em',
        paddingBottom: '5em',
    },
    titleProductPrices: {
        paddingTop: '2em',
    },
 
}));

const ProductIdentifierCard = (props: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const data = props.product;
    const dataArray = props.productIdentifier;
    const [clickCard, setClickCard] = useState <any>([false]);
    const [productIds, setProductIds] = useState<any>([])


    const handleClickCard = (value: boolean, index: any) => {
        let array = [...clickCard];
        array[index] = value;
        setClickCard(array);
        let arr = [...productIds];
        if(!arr.includes(dataArray[index].id)){
            arr.push(dataArray[index].id);
            setProductIds(arr)
        } else {
            arr.splice(arr.indexOf(dataArray[index].id), 1)
            setProductIds(arr)
        }
    }

    const handleSend = async () => {

        if(productIds.length){
            dataProvider.update("products", { id: data.id, data: { id: data.id, deMerge: true, deMergedentifierIds: productIds , needDeMerge: false}, previousData: { title: "previous title" } as any 
        }).then(resp => {
                notify("DeMerge is completed");
                setProductIds([])
                setClickCard([])
                props.setProductIdentifierList([])
                props.setProduct()
                props.setRefresh(!props.refresh)
                notify("DeMerge Completed")
            }).catch(err => {
                notify("DeMerge Error : ", err)
            })
        } else if(!dataArray.lenght &&  !productIds.lenght && data){
            dataProvider.update('products', {id: data.id, data:{id: data.id , needDeMerge: false}, previousData:{ title :'previous title'} as any }).then((response)=>{
                setProductIds([])
                setClickCard([])
                props.setProductIdentifierList([])
                props.setProduct()
                props.setRefresh(!props.refresh)
            }).catch(err => {
                notify("DeMerge Error : ", err)
            })
        }
        else{
            notify("Hiç identifier seçilmedi!")
        }
    }

    if(props.product && !props.productIdentifier.length) {
        return <Loading />
    }
    return (<>
                <Grid container spacing={1} className={classes.rootDiv} >
                    {dataArray ?
                        (
                            dataArray.map((product: any, index: any) => (
                                    <Grid xs={12} sm={6} md={4} lg={3} xl={6} item >

                                        <Card className={clickCard[index]  ?   classes.cardFalse : classes.card}  >
                                            <CardContent className={classes.titleProductName} >

                                                <Typography
                                                    variant="h5"
                                                    component="h2"
                                                    align="center"
                                                >
                                                        {product?.productName}
                                                
                                                </Typography>
                                            </CardContent>
                                            <Divider variant="middle" className={classes.titleDivider} />
                                            <div style={{textAlign: "center"}}>
                                            <Button variant="contained" color="primary" onClick={ () => { handleClickCard(!clickCard[index], index) } } >
                                                    Sec
                                                </Button>
                                                </div>
                                          
                                            <CardContent className={classes.titleProductPrices} >
                                            
                                          
                                            <Typography
                                                    variant="h6"
                                                    component="h2"
                                                    align="left"
                                                >
                                                        {'Sku :'}{product?.sku}
                                                
                                                </Typography>
                                                <Typography
                                                    variant="h6"
                                                    component="h2"
                                                    align="left"
                                                >
                                                        {'Slug :'}{product?.slug}
                                                
                                                </Typography>
                                                <Divider variant="middle" className={classes.titleDivider} />
                                              
                                            </CardContent>
                                      
                                        </Card>
                                    </Grid>
                                 
                            ))
                        ) : <br />}
        
                    </Grid >
                    <div style={{textAlign: "center", margin:'1em'}}>
                     <Button variant="contained" color="primary" onClick={handleSend} > 
                     {productIds.length ? "DeMerge" : "Skip"}
                   </Button>
                   </div>
                   </>
                                     
    );
}
export default ProductIdentifierCard;