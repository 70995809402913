import React from 'react';
import { useMutation, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';

export const DeclinedStoreButton = ({ labelId }: { labelId: any }) => {
    // 
    const notify = useNotify();



    // console.log(labelId)

    const [approve, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'labelsubmissions',
            payload: { id: labelId, data: { id: labelId, isReviewed: true, reviewResult: "DECLINED" } }
        },
        {
            //undoable: true,
            onSuccess: ({ data }) => {
                notify('Store reddedildi', 'info', {}, true);
                window.location.reload();
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return (<Button variant="outlined" size="large" color="primary" onClick={approve} disabled={loading}>
        Decline
    </Button>
    );
};


