import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, Loading, FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, Card, TextField, Typography, Link, } from '@material-ui/core';
import SkipIcon from '@material-ui/icons/SkipNext';
import DoneIcon from '@material-ui/icons/Done';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '1%',
    },
    paperLeft: {
        padding: '4px',
        marginLeft: '20px',
        marginTop: '20px',
        maxWidth: "240px",
        display: "flex",
        alignSelf: "center"
    },
    paperRight: {
        width: '76%',
        marginLeft: '20px',
        marginTop: '20px',
        display: "flex"
    },
    control: {
        padding: theme.spacing(2),
    },
    textField: {
        width: 500,
        marginLeft: '1%'
    },
    typography: {
        marginLeft: '2%',
        marginTop: '1%',
    },
}));

export const DeclinedProducts = (props: any) => {
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [product, setProduct] = useState<any>();
    const [barcode, setBarcode] = useState<any>();

    const [loading, setLoading] = useState(false);
    const imageObject = {
        id: '',
        url: '',
    };

    useEffect(() => {
        if (!product) {
            setLoading(true);
            dataProvider.getList('products', {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'title', order: 'ASC' },
                filter: { isDeclined: true }
            }).then((resp) => {
                if (resp.data.length) {
                    setProduct(resp.data[0])
                }
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [])

    const handleApprove = async (prop: any) => {
        await dataProvider.update('products', { id: product.id, data: { id: product.id, isDeclined: false, isMergeReviewed: false, barcode: barcode }, previousData: { title: "previous title" } as any }).then(async (resp) => {
            notify("Product updated!")
            window.location.reload();
        }).catch((err) => {
            notify("Product update error, " + err, "warning")
        })
    }

    const handleSkip = (prop: any) => {
        dataProvider.update('products', { id: product.id, data: { id: product.id }, previousData: { title: "previous title" } as any }).then((resp) => {
            notify('Product skipped')
            window.location.reload();
        }).catch((err) => {
            notify('Product skip error: ' + err, 'warning')
        })
    }

    const onProduct = (product: any) => {
        console.log(product)
    }


    if (loading) return <Loading></Loading>
    if (product) {
        return (<FormWithRedirect
            {...props}
            render={formProps => (
                <form>
                    <Card className={classes.root}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom className={classes.typography}>
                                    Declined Products
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <img src={(product.images[0]) ? product.images[0].url : ""} style={{
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "20%"
                                }} ></img>
                            </Grid>
                            <Grid item xs={12}>

                                <TextField
                                    className={classes.textField}
                                    id="productName"
                                    label="Product Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={product.name}
                                />

                            </Grid>


                            <Grid item xs={12}>

                                <TextField
                                    className={classes.textField}
                                    id="productBarcode"
                                    label="Barcode"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    defaultValue={product.barcode}
                                    value={barcode}
                                    onChange={(e) => { setBarcode(e.target.value) }}
                                />
                            </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        className={classes.textField}
                                        id="priceUrl"
                                        label="Price"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        value={product.prices[0]?.url}
                                        onClick={() => {window.open(product.prices[0]?.url, "_blank")}}
                                    />
                                </Grid>

                            <Grid item xs={12}>
                                {product.prices.map((price: any) => (
                                    <Typography variant="h6" component="h2" style={{ marginLeft: '2%' }}>
                                        {price && price.url && price.url !== null ? (
                                            <Link
                                                href={
                                                    price && price.url && price.url !== null
                                                        ? price.url
                                                        : ""
                                                }
                                                target="_blank"
                                            >
                                                {price.store} :
                                            </Link>
                                        ) : (
                                            price.store
                                        )}
                                        {`  ${price.price}`}
                                    </Typography>
                                ))}
                            </Grid>
                            <Grid item xs={6}>
                                <Button color="primary" endIcon={<SkipIcon />} onClick={handleSkip} size="large" style={{
                                    float: 'left',
                                    marginLeft: '5%',
                                    marginBottom: '3%',
                                    width: '5%',
                                    marginTop: '3%',
                                }}>Atla</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button color="primary" endIcon={<DoneIcon />} onClick={handleApprove} style={{
                                    float: 'right',
                                    width: '5%',
                                    marginRight: '5%',
                                    marginBottom: '3%',
                                    marginTop: '3%',
                                }} size="large">Onayla</Button>
                            </Grid>
                        </Grid>

                    </Card>
                </form >

            )}
        />

        );
    } else if (!product) {
        return <div style={{ display: "inline-flex", justifyContent: "center", backgroundColor: "#FF1212" }}><h2>Declined Product bulunmamaktadır!</h2></div>
    }
}

export default DeclinedProducts;




