import  { useEffect, useState} from 'react';
import "./cropper.css";
import Button from '@material-ui/core/Button';
import Cropper from 'react-cropper';
import { relative } from 'path';
let rotateCount=0
const PriceTagCropper = ({callbackProductName,callbackSku,callbackBarcode,callbackUnitPrice,callbackPrice,callbackPriceChangeDate,callbackCountryOfOrigin,callbackResolution,callbackRotate,imageUrl,check,clear}:
                        {callbackProductName:any,callbackSku:any,callbackBarcode:any,callbackUnitPrice:any,callbackPrice:any,callbackPriceChangeDate:any,callbackCountryOfOrigin:any,callbackResolution:any,callbackRotate:any,imageUrl:any,check:boolean,clear:any}) => {
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState<any>();
  const [productNameCropped, setProductNameCropped] = useState<any>();
  const [priceCropped, setPriceCropped] = useState<any>();
  const [unitPriceCropped, setUnitPriceCropped] = useState<any>();
  const [barcodeCropped, setBarcodeCropped] = useState<any>();
  const [priceChangeDate, setPriceChangeDateCropped] = useState<any>();
  const [skuCropped, setSkuCropped] = useState<any>();
  const [originCropped, setOriginCropped] = useState<any>();
 
  const getCoordinates = (choice:number) => {
    if (typeof cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    let x1 = cropper.getData().x;
    let x2 = cropper.getData().x + cropper.getData().width;
    let y1 = cropper.getData().y;
    let y2 = cropper.getData().y + cropper.getData().height;
    
    convertCoordinates(x1,x2,y1,y2,choice);
    
    callbackRotate(rotateCount);
    rotateCount=0
    
  }
  const clearAll = () => {
    setProductNameCropped('');
    setPriceCropped('');
    setBarcodeCropped('');
    setUnitPriceCropped('');
    setSkuCropped('');
    setPriceChangeDateCropped('');
    setOriginCropped('');
  }
  useEffect(() => { 
    clearAll();
  },[clear]);
  const convertCoordinates = (x1:any,x2:any,y1:any,y2:any,choice:any) => {

        const left = Math.round((x1*10000) / callbackResolution.width ) / 10000;
        const top = Math.round((y1*10000) / callbackResolution.height) / 10000;
        const right = Math.round((x2*10000) / callbackResolution.width ) / 10000;
        const bottom = Math.round((y2*10000) / callbackResolution.height ) / 10000;
        switch(choice){
          case 0:
            callbackProductName({
              x1,
              y1,
              x2,
              y2,
              left,
              top,
              right,
              bottom
            })
            setProductNameCropped(cropper.getCroppedCanvas().toDataURL());
            return ;
          case 1:
            callbackPrice({
              x1,
              y1,
              x2,
              y2,
              left,
              top,
              right,
              bottom
            })
            setPriceCropped(cropper.getCroppedCanvas().toDataURL());
            return ;
          case 2:
            callbackBarcode({
              x1,
              y1,
              x2,
              y2,
              left,
              top,
              right,
              bottom
            })
            setBarcodeCropped(cropper.getCroppedCanvas().toDataURL());
            return ;
          case 3:
            callbackUnitPrice({
              x1,
              y1,
              x2,
              y2,
              left,
              top,
              right,
              bottom
            })
            setUnitPriceCropped(cropper.getCroppedCanvas().toDataURL());
            return ;
          case 4:
            callbackSku({
              x1,
              y1,
              x2,
              y2,
              left,
              top,
              right,
              bottom
            })
            setSkuCropped(cropper.getCroppedCanvas().toDataURL());
            return ;
          case 5:
            callbackPriceChangeDate({
              x1,
              y1,
              x2,
              y2,
              left,
              top,
              right,
              bottom
            })
            setPriceChangeDateCropped(cropper.getCroppedCanvas().toDataURL());
            return ;
          case 6:
              callbackCountryOfOrigin({
                x1,
                y1,
                x2,
                y2,
                left,
                top,
                right,
                bottom
              })
              setOriginCropped(cropper.getCroppedCanvas().toDataURL());
              return ;
          default:
            throw new Error(`Unknown resource`);
        }
  }
  return (
    <div style = {{display: 'flex', flexDirection: 'row'}}>
        <div>
            <Cropper
              style={{minWidth:'720px',minHeight:'720px'}}
              guides={false}
              src={imageUrl}
              autoCropArea={0.1}
              zoomable={false}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          
        </div>

        <div style= {{display: 'flex',flexDirection:'column'}}>

                          <div className="productname-container">
                               <div className='centeralize' > 
                                  <img src={productNameCropped}  ></img> 
                                </div> 
                          </div>
                          <div className="productname-button">
                                      <Button className="myButton" onClick={() => getCoordinates(0)}disabled={check}>Get Product Name</Button> 
                          </div>
                          <div className = "grid-container">
                                <div className = "croppedImage">
                                  <div className='centeralize' > 
                                    <img src={priceCropped} style= {{maxWidth: '300px',maxHeight: '130px'}}></img> 
                                  </div> 
                                </div>
                                <div className = "croppedImage">
                                  <div className='centeralize' >
                                      <img src={unitPriceCropped} style= {{maxWidth: '300px',maxHeight: '130px'}}></img>  
                                    </div> 
                                </div>
                                <div> 
                                  <Button className="myButton" onClick={() => getCoordinates(1)}disabled={check}>Get Price</Button> 
                                </div>
                                <div> 
                                  <Button  className="myButton" onClick={() => getCoordinates(3)}disabled={check}>Get Unit Price</Button> 
                                </div>
                                <div className = "croppedImage" >
                                  <div className='centeralize' > 
                                    <img src={barcodeCropped} style= {{maxWidth: '300px',maxHeight: '130px'}}></img>  
                                  </div>
                                </div>
                                <div className = "croppedImage"> 
                                  <div className='centeralize' > 
                                    <img src={skuCropped}style= {{maxWidth: '300px',maxHeight: '130px'}} ></img>  
                                  </div> 
                                </div>
                                <div> 
                                  <Button  className="myButton" onClick={() => getCoordinates(2)}disabled={check}>Get Barcode</Button>
                                </div>
                                <div> 
                                    <Button className="myButton" onClick={() => getCoordinates(4)}disabled={check}>Get Sku</Button> 
                                </div>
                                <div className = "croppedImage"> 
                                  <div className='centeralize' > 
                                    <img src={originCropped} style= {{maxWidth: '300px',maxHeight: '130px'}}></img>  
                                  </div> 
                                </div>
                                <div className = "croppedImage"> 
                                  <div className='centeralize' > 
                                    <img src={priceChangeDate} style= {{maxWidth: '300px',maxHeight: '130px'}}></img>  
                                  </div> 
                                </div>
                                <div>  
                                <Button className="myButton" onClick={() => getCoordinates(6)}disabled={check}>Get Country Of Origin</Button>
                                </div>
                                <div>  
                                  <Button className="myButton" onClick={() => getCoordinates(5)}disabled={check}>Get Price Change Date</Button> 
                                </div>
                              <div> 
                          </div>
                        </div>
        </div>
    </div>
  );
};
export default PriceTagCropper;