import React, { FC } from 'react';

import {
    Filter,
    SearchInput,
    FilterProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useFilterStyles = makeStyles({
    status: { width: 150 },
});

const CategoryFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const classes = useFilterStyles();
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />

        </Filter>
    );
};

export default CategoryFilter;
