import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';
import { bool } from 'prop-types';

export const StoreSearch = ({ callbackFromParent, searchKey }: { callbackFromParent: any, searchKey: any }) => {

    const dataProvider = useDataProvider();
    const [storeList, setStoreList] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const [isSelectedBool, setIsSelectedBool] = useState(false);

    const [selectedItem, setSelectedItem] = useState<any>();

    const [placeHolderStore, setPlaceHolderStore] = useState();

    useEffect(() => {
        dataProvider.getList('stores', {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'title', order: 'ASC' },
            filter: { slug: searchKey },

        })
            .then((resp) => {
                setSelectedItem(resp.data[0]);
                callbackFromParent(resp.data[0]);
                setLoading(false);
            })
            .catch(error => {
                //setError(error);
                console.log(error);
                setLoading(false);
            })
    }, [searchKey]);


    const selectData = (item: any) => {


        //  console.log('secilen', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item)
        setIsSelectedBool(true);


    }


    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (!storeList) return null;
    if (selectedItem) return null;

    if (searchKey.length < 2) return null;

    const textMiddle = (value: any) => {

        if (selectedItem) {
            return <div>{selectedItem.name}</div>
        }
        else {
            return <div>{value}</div>
        }

    }

    return (


        <div>

            {storeList.map((item: any) =>
                <Container key={item.id}>

                    <div key={item.id} className={"customListItem"}>


                        <Row>
                            <Col xs="9"><span onClick={() => selectData(item)} >{textMiddle(item.name)} </span></Col>
                        </Row>



                    </div>
                </Container>
            )}

        </div>
    )



};

