import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, green } from '@material-ui/core/colors';

export default function CircularIndeterminate(props: any) {
    const circularProgressTheme = createMuiTheme({
        palette: {
            primary: {
                main: blue[800],
            },
        },
    });

    return (
        <div style={{ width: "100%", height: "100%", display: "block", position: "fixed", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", position: "fixed", justifyContent: "center", alignItems: "center" }}>
                <p style={{ width: "100%", height: "100%", display: "flex", position: "fixed", justifyContent: "center", alignItems: "center" }}>{(props.msg) ? props.msg : "Yükleniyor"}</p>
                <ThemeProvider theme={circularProgressTheme}>
                    <CircularProgress size="250px" style={{ display: "flex" }} color="primary" />
                </ThemeProvider>
            </div>
        </div>
    );
}