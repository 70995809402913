import React, { FC } from 'react';

import {
    List,
    ListProps,
    Datagrid,
    TextField,
    ArrayField,
} from 'react-admin';
import BarcodeFilter from './BarcodeFilter';



const BarcodeList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            filters={<BarcodeFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <TextField source="brand" />
                <TextField source="barcode" />
            </Datagrid>
        </List>
    );
};




export default BarcodeList;
