import React, { FC } from 'react';

import {
    List,
    ListProps,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
} from 'react-admin';
import CommentFilter from './CommentFilter';



const CategoryList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            filters={<CommentFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit">
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="targetType" />
                <BooleanField source="isReviewed" />
                <BooleanField source="isApproved" />
                <BooleanField source="isDenied" />
                <TextField source="city" />
                <DateField source="date" />
            </Datagrid>
        </List>
    );
};

export default CategoryList;
