import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { CardHeader, Card } from '@material-ui/core';
import { List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

function SimpleDialog(props: any) {
    const classes = useStyles();
    const { onClose, onCloseAliases, selectedValue, open, images, openAliases ,aliases } = props;


    const handleClose = () => {
        onClose(selectedValue);
    };
    const handleCloseAliases = () => {
        onCloseAliases(selectedValue);
    };

    const handleListItemClick = (value: any) => {
        onClose(value);
    };

    const setImageUrl = (url: any) => {
        props.setImageUrl(url);
    }


    return (
        open ?
        <Dialog scroll="body" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Card>
                <CardHeader title="Google Images" style={{ backgroundColor: "#bdc3c7", color: "#ffffff" }}></CardHeader>
                <div>
                    {images.map((item: { imageUrl: string | undefined; }) => {
                        return <img src={item.imageUrl} onClick={() => { setImageUrl(item.imageUrl) }} style={{ cursor: "pointer", maxWidth: "400px", maxHeight: "400px", borderRadius: 5 }}></img>
                    })}
                </div>

            </Card>


        </Dialog> 
        : openAliases ? 
            <Dialog scroll="body" onClose={handleCloseAliases} aria-labelledby="simple-dialog-title" open={openAliases}>
                <Card>
                    <CardHeader title="Aliases" style={{ backgroundColor: "#bdc3c7", color: "#ffffff" }}></CardHeader>
                    <div>
                    <List >
                        {aliases?.map((item: any) => {
                            console.log(item)
                            return (
                                <ListItem    >
                                    <ListItemText
                                        primary={item}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                        {/* {aliases.map((item : any ) => {
                            return <img src={item.imageUrl} onClick={() => { setImageUrl(item.imageUrl) }} style={{ cursor: "pointer", maxWidth: "400px", maxHeight: "400px", borderRadius: 5 }}></img>
                        })} */}
                    </div>
                </Card>
            </Dialog> 
            : 
        <br/>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,

};

export default function SimpleDialogDemo(props: any) {
    const [open, setOpen] = React.useState(false);
    const [openAliases, setOpenAliases] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpenAliases = () => {
        setOpenAliases(true);
    };
    const handleCloseAliases = (value: any) => {

        setOpenAliases(false);

    };
    const handleClose = (value: any) => {
        setOpen(false);

    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{margin: '1%'}}>
                Images
            </Button>
            <Button variant="outlined" color="primary" onClick={handleClickOpenAliases} style={{margin: '1%'}}>
                Aliases
            </Button>
            <SimpleDialog style={{ overflow: "scroll" }} open={open} openAliases={openAliases} onClose={handleClose} onCloseAliases={handleCloseAliases} images={props.images} aliases={props.aliases} setImageUrl={props.setImageUrl} {...props} />
        </div>
    );
}
