import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    UrlField,
    Filter,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';


import StoreLocationShow from './StoreLocationShow';
//import { Town } from '../types';
//import StoreLocationFilter from './StoreLocationFilter';


const useStyles = makeStyles(theme => ({
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));
const StoreLocationFilter = (props: any) => (
    <Filter  {...props}>
        <TextInput label="Search" source="q" />
        <ReferenceInput label="Store" source="storeId" reference="Store" >
            <AutocompleteInput optionText="slug" />
        </ReferenceInput>
        <TextInput source="city" optionText="City" />
    </Filter>
);

const StoreLocationList: FC<ListProps> = props => {
    const classes = useStyles();
    return (
        <List
            {...props}
            filters={<StoreLocationFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<StoreLocationShow />}>
                <TextField source="name" />
                <TextField source="city" />
                <TextField source="town" />
                <TextField source="address" />
                <TextField source="slug" />
            </Datagrid>
        </List>
    );
};

export default StoreLocationList;
