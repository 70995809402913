import React, { useState, useEffect } from "react";
import { Loading, useDataProvider, useNotify } from "react-admin";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { BrandSearch } from "./BrandSearch";
import { CategorySearch } from "./CategorySearch";
import PopupImages from "../mergebarcodes/PopupImages";

import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  FormControlLabel,
  GridList,
  GridListTile,
  GridListTileBar,
  Link,
  Popover,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: "rgba(200, 255, 255, 0.54)",
  },
  gridList: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: "#F8F8F8",
  },
  paperTop: {
    padding: theme.spacing(2),
    textAlign: "center",
    fontSize: "2rem",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  img: {
    margin: "auto",
    width: 128,
    height: 128,
  },
  media: {
    height: 200,
    objectFit: "contain",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleDivider: {
    margin: theme.spacing(0.1),
  },
  button: {
    width: 512,
    height: 128,
  },
  titleProductName: {
    height: "0.5em",
    paddingBottom: "5em",
  },
  titleProductPrices: {
    paddingTop: "5em",
  },
  divider: {
    marginRight: "5%",
    marginLeft: "5%",
  },
  rootTopCard: {
    display: "flex",
    height: "5.5em",
    width: "15em",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 221,
    height: "auto",
    margin: "1%",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  rootRow: {
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "99%",
    flexWrap: "wrap",
    justifySelf: "center",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListRow: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  rootPopCard: {
    display: "flex",
    height: "10em",
  },
  popover: {
    pointerEvents: "none",
    width: "100%",
    height: "100%",
  },
  paperPopover: {
    padding: theme.spacing(1),
  },
}));

const ProductCards = () => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [listOfProducts, setListOfProducts] = useState<Record<any, any>[]>([]);
  const [barcode, setBarcode] = useState<any>();
  const [nameToSend, setNameToSend] = useState("");
  const [brandToSend, setBrandToSend] = useState(String);
  const [brandIdToSend, setBrandIdToSend] = useState("");
  const [categoryToSend, setCategoryToSend] = useState("");
  const [pricesToSend, setPricesToSend] = useState("");
  const [listOfImagesToSend, setListOfImagesToSend] = useState<any>([]);
  const [checkedBrand, setCheckedBrand] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState(false);
  const [listOfIdentifiers, setListOfIdentifiers] = useState<any>();
  const [tenProducts, setTenProducts] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const [mappings, setMappings] = useState<any>();
  const [productsMerged, setProductsMerged] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openedPopoverId, setOpenedPopoverId] = React.useState<any>();

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: any
  ) => {
    setOpenedPopoverId(id);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setLoading(true);
    if (window.localStorage.getItem("barcode")) {
      let bar = window.localStorage.getItem("barcode");
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 25 },
          sort: { field: "title", order: "ASC" },
          filter: { barcode: bar, isMergeSlider: true },
        })
        .then((resp) => {
          setBarcode(resp.data[0].barcode);
          setListOfProducts(resp.data);
          // dataProvider
          //   .getList("products", {
          //     pagination: { page: 1, perPage: 27 },
          //     sort: { field: "title", order: "DSC" },
          //     filter: {
          //       query: resp.data[0].name,
          //       querySibling: true,
          //       brand: resp.data[0].brand,
          //       category: resp.data[0].category,
          //       store: resp.data[0].stores[0],
          //     },
          //   })
          //   .then((response) => {
          //     var array = [] as any; //Defined Product List
          //     response.data.forEach((mergeProduct) => {
          //       if (mergeProduct.id !== resp.data[0].id) {
          //         array.push(mergeProduct);
          //       }
          //     });
          //     setProductsMerged(array); //Set Products Merged
          //     setLoading(false);
          //   });
          var arr = [] as any;
          let productIds = [] as any;
          resp.data.forEach((prod) => {
            prod.prices.forEach((pri: any) => {
              arr.push(pri);
            });
            setPricesToSend(arr);
          });
          dataProvider
            .getList("productidentifiers", {
              pagination: { page: 1, perPage: 25 },
              sort: { field: "title", order: "ASC" },
              filter: { productIds },
            })
            .then(async (res) => {
              setListOfIdentifiers(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      window.localStorage.removeItem("barcode");
    } else {
      if (!tenProducts.length) {
        dataProvider
          .getList("products", {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "title", order: "ASC" },
            filter: { isMerge: true },
          })
          .then((resp) => {
            setTenProducts(resp.data);
            setRefresh(!refresh);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setBarcode(tenProducts[0].barcode);

        let sameBarcodeProducts = [] as any;
        tenProducts.forEach((p: any) => {
          if (p.barcode == tenProducts[0].barcode) {
            sameBarcodeProducts.push(p);
          }
        });
        setListOfProducts(sameBarcodeProducts);
        dataProvider
          .getList("products", {
            pagination: { page: 1, perPage: 27 },
            sort: { field: "title", order: "DSC" },
            filter: {
              query: sameBarcodeProducts[0].name,
              querySibling: true,
              brand: sameBarcodeProducts[0].brand,
              category: sameBarcodeProducts[0].category,
              store: sameBarcodeProducts[0].stores[0],
            },
          })
          .then((response) => {
            var array = [] as any; //Defined Product List
            response.data.forEach((mergeProduct) => {
              if (mergeProduct.id !== sameBarcodeProducts[0].id) {
                array.push(mergeProduct);
              }
            });
            setProductsMerged(array); //Set Products Merged
            setLoading(false);
          });
        var arr = [] as any;
        let productIds = [] as any;
        sameBarcodeProducts.forEach((prod: any) => {
          productIds.push(prod.id);
          prod.prices.forEach((pri: any) => {
            arr.push(pri);
          });
          setPricesToSend(arr);
        });
        dataProvider
          .getList("productidentifiers", {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "title", order: "ASC" },
            filter: { productIds },
          })
          .then((res) => {
            setListOfIdentifiers(res.data);
            dataProvider
              .getList("mappings", {
                pagination: { page: 1, perPage: 25 },
                sort: { field: "title", order: "ASC" },
                filter: { storeLinkId: res.data[0].linkId },
              })
              .then((res) => {
                setMappings(res.data);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [refresh]);

  useEffect(() => {
    var arr = [...listOfProducts];
    for (let i = 0; i < arr.length; i++) {
      if (productsMerged.includes(arr[i] as never)) {
        arr.splice(arr.indexOf(arr[i] as never), 1);
        setListOfProducts(arr);
      } else {
        arr.push(arr[i] as never);
        setListOfProducts([...arr]);
      }
    }
  }, []);

  const handleClick = (value: any, type: any) => {
    if (value) {
      if (type === "name") {
        if (value === nameToSend) {
          setNameToSend("");
        } else {
          setNameToSend(value);
        }
      }
      if (type === "brand") {
        if (value.brand === brandToSend) {
          setCheckedBrand(false);
          setBrandToSend("");
        } else {
          setCheckedBrand(true);
          setBrandIdToSend(value.brandId);
          setBrandToSend(value.brand);
        }
      }
      if (type === "category") {
        if (value.category === categoryToSend) {
          setCheckedCategory(false);
          setCategoryToSend("");
        } else {
          setCheckedCategory(true);
          setCategoryToSend(value.category);
        }
      }
      if (type === "image") {
        var arr = [...listOfImagesToSend];
        if (listOfImagesToSend.includes(value as never)) {
          arr.splice(arr.indexOf(value as never), 1);
          setListOfImagesToSend(arr);
        } else {
          arr.push(value as never);
          setListOfImagesToSend([...arr]);
        }
      }
    }
  };

  const handleSend = async (id: any) => {
    let arr: any[] = [];
    listOfProducts.forEach((p) => {
      arr.push(p.id);
    });
    dataProvider
      .create("mergesubmissions", {
        data: {
          productIds: arr,
          selectedName: nameToSend,
          selectedBrand: brandToSend,
          selectedCategory: categoryToSend,
          selectedImages: listOfImagesToSend,
        },
      })
      .then((_) => {
        dataProvider.update("products", {
          id: listOfProducts[0].id,
          data: {
            id: listOfProducts[0].id,
            isMergeReviewed: true,
            jobActivityType: `MERGE_SLIDER`,
          },
          previousData: { title: "previous title" } as any,
        });
        notify("Product merge is completed");
        let arr = [] as any;
        tenProducts.forEach((p: any) => {
          if (p.barcode != listOfProducts[0].barcode) {
            arr.push(p);
          }
        });
        setNameToSend("");
        setBrandIdToSend("");
        setBrandToSend("");
        setCategoryToSend("");
        setListOfImagesToSend([]);

        setTenProducts(arr);
        setRefresh(!refresh);
      })
      .catch((err) => {
        notify("Product Merge Error : ", err);
      });
  };

  const handleSkip = async () => {
    await Promise.all(
      listOfProducts.map(async (prd) => {
        await dataProvider.update("products", {
          id: prd.id,
          data: {
            id: prd.id,
            isMergeReviewed: true,
            jobActivityType: `MERGE_SLIDER`,
          },
          previousData: { title: "previous title" } as any,
        });
      })
    ).then(() => {
      notify("Skipped!");
      let arr = [] as any;
      tenProducts.forEach((p: any) => {
        if (p.barcode != listOfProducts[0].barcode) {
          arr.push(p);
        }
      });
      setNameToSend("");
      setBrandIdToSend("");
      setBrandToSend("");
      setCategoryToSend("");
      setListOfImagesToSend([]);

      setTenProducts(arr);
      setRefresh(!refresh);
    });
  };

  const handleReport = async () => {
    await Promise.all(
      listOfProducts.map(async (prd) => {
        await dataProvider.update("products", {
          id: prd.id,
          data: { id: prd.id, isDeclined: true, isMergeReviewed: true },
          previousData: { title: "previous title" } as any,
        });
      })
    ).then(() => {
      notify("Reported!");

      let arr = [] as any;
      tenProducts.forEach((p: any) => {
        if (p.barcode != listOfProducts[0].barcode) {
          arr.push(p);
        }
      });
      setNameToSend("");
      setBrandIdToSend("");
      setBrandToSend("");
      setCategoryToSend("");
      setListOfImagesToSend([]);

      setTenProducts(arr);
      setRefresh(!refresh);
    });
  };

  const handleClickGrid = (product: any) => {
    let array = [...listOfProducts];
    if (array.includes(product)) {
      array.splice(array.indexOf(product), 1);
      setListOfProducts(array);
    } else {
      array.push(product);
      setListOfProducts(array);
    }
  };

  if (!listOfIdentifiers) return <Loading></Loading>;
  if (loading) return <Loading></Loading>;
  if (!productsMerged) return <Loading></Loading>;

  return (
    <div className={classes.gridList}>
      <Grid item xs={12}>
        <Paper className={classes.paperTop}>
          <Button
            variant="contained"
            onClick={handleSkip}
            className={classes.button}
          >
            {" "}
            Atla{" "}
          </Button>
          {/* <h1 style={{ color: "#000", fontSize: "20px" }}> {barcode}</h1> */}
          <Card className={classes.rootTopCard}>
            <CardMedia
              className={classes.cover}
              image={
                listOfImagesToSend.length
                  ? listOfImagesToSend[0].url
                  : "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"
              }
              title="Selected Image"
            />
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography variant="subtitle2">{barcode}</Typography>
                <Divider variant="middle" className={classes.titleDivider} />
                <Typography
                  variant="subtitle1"
                  onChange={(e: any) => {
                    setNameToSend(e.target.value);
                  }}
                >
                  {nameToSend ? nameToSend : "Urun ismini seciniz.."}
                </Typography>
                <Divider variant="middle" className={classes.titleDivider} />
                <Typography
                  variant="subtitle1"
                  onChange={(e: any) => {
                    setBrandToSend(e.target.value);
                  }}
                  onClick={(e) => {
                    setBrandToSend("");
                  }}
                >
                  {brandToSend ? brandToSend : "Urun markasi seciniz.."}
                </Typography>
                <Divider variant="middle" className={classes.titleDivider} />
                <Typography
                  variant="subtitle1"
                  onChange={(e: any) => {
                    setCategoryToSend(e.target.value);
                  }}
                  onClick={(e) => {
                    setCategoryToSend("");
                  }}
                >
                  {categoryToSend ? categoryToSend : "Urun kategori seciniz.."}
                </Typography>
                <CategorySearch
                  checked={checkedCategory}
                  callbackFromParent={setCategoryToSend}
                  searchKey={categoryToSend}
                />

                <BrandSearch
                  checked={checkedBrand}
                  callbackFromParent={setBrandToSend}
                  setBrandIdToSend={setBrandIdToSend}
                  searchKey={brandToSend}
                  setLoading={setLoading}
                />
              </CardContent>

              <div className={classes.controls}></div>
            </div>
          </Card>
          <Button
            variant="contained"
            onClick={() => handleSend(listOfProducts[0].id)}
            className={classes.button}
          >
            {" "}
            Gönder{" "}
          </Button>
        </Paper>
      </Grid>
      <br></br>
      <Grid container spacing={2} xs={12}>
        {listOfProducts.map((product, index) => (
          <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={2} item>
            <Card style={{ height: "100%" }}>
              <CardContent className={classes.titleProductName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nameToSend === product.name ? true : false}
                      onChange={() => {
                        handleClick(product.name, "name");
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkedName"
                      color="primary"
                    />
                  }
                  label={product.name}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={brandToSend === product.brand ? true : false}
                      onChange={() => {
                        handleClick(product, "brand");
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkedBrand"
                      color="primary"
                    />
                  }
                  label={product.brand}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        categoryToSend === product.category ? true : false
                      }
                      onChange={() => {
                        handleClick(product, "category");
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      name="checkedCategory"
                      color="primary"
                    />
                  }
                  label={product.category}
                />
              </CardContent>

              <CardContent className={classes.titleProductPrices}>
                {product.prices.map((price: any) => (
                  <Typography variant="h5" component="h2" align="left">
                    {price && price.url && price.url !== null ? (
                        <Link
                          href={
                            price && price.url && price.url !== null
                              ? price.url
                              : ""
                          }
                          target="_blank"
                        >
                          {price.store} 
                        </Link>
                      ) : (
                        price.store
                      )}
                      {` : ${price.price}`}
                  </Typography>
                ))}
                {mappings && mappings.length ? (
                  <a
                    href={`https://cimri.com/aa/aa,${mappings[0].key}`}
                    target="_blank"
                  >
                    {" "}
                    link{" "}
                  </a>
                ) : (
                  <div></div>
                )}
                <br />
                SKU :{" "}
                {listOfIdentifiers[index] ? listOfIdentifiers[index].sku : ""}
                <br />
                {barcode.googleImages ? (
                  <PopupImages images={barcode.googleImages}></PopupImages>
                ) : (
                  <div></div>
                )}
                <br />
                {/* <Typography variant="subtitle1" color="primary" >{product.prices[0] ? product.prices[0].price : ""} TL</Typography> */}
                <br />
                {product.images.map((image: any) => (
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClick(image, "image");
                    }}
                    className={classes.media}
                    alt="complex"
                    src={image.url}
                  />
                ))}
                <Typography
                  onClick={handleReport}
                  variant="body2"
                  style={{ cursor: "pointer", textAlign: "end" }}
                >
                  Report!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {/* <div className={classes.rootRow}> */}
          {/* <GridList className={classes.gridListRow} cols={8} >
            {productsMerged.map((product: any, _: number) => (
              <GridListTile
                key={product.id}
                rows={1}
                onClick={() => handleClickGrid(product)}
              >
                <img
                  src={
                    product &&
                    product.images[0] &&
                    product.images[0].url !== null
                      ? product.images[0].url
                      : "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"
                  }
                  hidden={loading}
                  alt={product.name}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(e: any) => handlePopoverOpen(e, product.id)}
                  onMouseLeave={handlePopoverClose}
                />
                <Popover
                  id="popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paperPopover,
                  }}
                  open={openedPopoverId === product.id}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus={false}
                >
                  <Card className={classes.rootPopCard}>
                    <CardMedia
                      className={classes.cover}
                      image={
                        product && product.images[0]
                          ? product.images[0].url
                          : "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"
                      }
                      title="Selected Image"
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography variant="subtitle1">
                          {product.name
                            ? product.name
                            : "Urun ismini bulunamadi.."}
                        </Typography>
                        <Divider
                          variant="middle"
                          className={classes.titleDivider}
                        />
                        <Typography variant="subtitle1">
                          {product.brand
                            ? product.brand
                            : "Urun markasi bulunamadi.."}
                        </Typography>
                        <Divider
                          variant="middle"
                          className={classes.titleDivider}
                        />
                        <Typography variant="subtitle1">
                          {product.category
                            ? product.category
                            : "Urun kategorisi bulunamadi.."}
                        </Typography>
                      </CardContent>

                      <div className={classes.controls}></div>
                    </div>
                  </Card>
                </Popover>

                <GridListTileBar
                  title={product.name}
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                />
              </GridListTile>
            ))}
          </GridList>
        </div> */}
      </Grid>
    </div>
  );
};

export default ProductCards;
