import React from "react";
import { useMutation, useNotify } from "react-admin";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

export const DeclinedButton = ({ labelId }: any) => {
  //
  const notify = useNotify();

  // console.log(labelId)

  const [approve, { loading }] = useMutation(
    {
      type: "update",
      resource: "labelsubmissions",
      payload: {
        id: labelId,
        data: { id: labelId, isReviewed: true, reviewResult: "DECLINED" },
      },
    },
    {
      //undoable: true,
      onSuccess: ({ data }) => {
        notify("Product reddedildi", "info", {}, true);
        window.location.reload();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return (
    <Button
      size="large"
      color="primary"
      className={"m-1"}
      onClick={approve}
      disabled={loading}
      style={{ float: "left", textAlign: "center" }}
      endIcon={<CloseIcon />}
    >
      Reddet
    </Button>
  );
};
