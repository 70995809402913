
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { useDataProvider, Loading } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({

    root: {
        margin: 'auto',
    },
    root1: {
        '& > *': {
            margin: theme.spacing(1),
            width: 500,
        },
    },

    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 500,
        height: 270,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),



    },
}));

function not(a: any, b: any) {
    return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
    return a.filter((value: any) => b.indexOf(value) !== -1);
}

function union(a: any, b: any) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props: any) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState<any>([]);
    const [searchTerm, setSearchTerm] = useState();
    const [loading, setLoading] = useState(false);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const dataProvider = useDataProvider();

    const handleToggle = (value: any) => () => {
        const currentIndex = checked.indexOf(value as never);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value as never);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        props.outProductList(left);
    };

    const numberOfChecked = (items: any) => intersection(checked, items).length;

    const handleToggleAll = (items: any) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items) as any);
        }
        props.outProductList(left);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        props.outProductList(left);
        setChecked(not(checked, leftChecked));
        props.outProductList(left);

    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        props.outProductList(left);
        setChecked(not(checked, rightChecked));
        props.outProductList(left);

    };

    const customList = (title: any, items: any) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value: any) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <LightTooltip title={<img style={{ maxHeight: "250px", maxWidth: "250px" }} src={value.images[0] ? value.images[0].url : ""}  ></img>} >
                            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value as never) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value ? value.name : ""} secondary={value ? value.categoryList : ""} />
                            </ListItem>
                        </LightTooltip>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
    const handleChange = (e: any) => {
        if (e.target.value !== "") {
            setSearchTerm(e.target.value);
        };

    };
    useEffect(() => {

        props.root ? (left.length === 0 ? setLeft([props.root] as never) : setLeft(left)) : setLeft(left);
        props.outProductList(left);

        if (searchTerm === "") {
            props.root ? setSearchTerm(props.root.name) : setSearchTerm("" as any);
        };

        if (props.searchTerm) {
            dataProvider.getList('products', {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'title', order: 'ASC' },
                filter: { query: props.searchTerm }
            }).then(({ data }) => {
                setRight(data);

            }).catch((e) => {
                console.log(e)
            });
            setLoading(false)
        } else {
            setLoading(true)
        }


    }, [props.root, searchTerm]);

    if (loading) return <Loading></Loading>

    return (
        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid item>{customList('Seçilen', left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
          </Button>
                    <Button
                        variant="outlined"
                        size="small"

                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
          </Button>
                </Grid>
            </Grid>
            <Grid item >
                <form className={classes.root1} noValidate autoComplete="off" >
                    <TextField id="outlined-search" onChange={(e) => handleChange(e)} label="Search field" variant="outlined" defaultValue="" />
                    {customList('Öneriler', right)}
                </form>
            </Grid>

        </Grid>
    );
}
