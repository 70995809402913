import React, { useState, useEffect } from 'react';
import { Loading, Error } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';

export const UnitSearch = ({ callbackFromParent, searchKey }: any) => {

    const [unitList, setUnitList] = useState<any>();
    const [loading, setLoading] = useState(true);

    const [isSelectedBool, setIsSelectedBool] = useState(false);

    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        setUnitList([
            { type: "KG", name: "Kilogram", id: 1 }
            , { type: "G", name: "Gram", id: 2 }
            , { type: "MG", name: "Miligram", id: 3 }
            , { type: "M", name: "Metre", id: 4 }
            , { type: "CM", name: "Santimetre", id: 5 }
            , { type: "MM", name: "Milimetre", id: 6 }
            , { type: "L", name: "Litre", id: 7 }
            , { type: "CL", name: "Santilitre", id: 8 }
            , { type: "ML", name: "Mililitre", id: 9 }
            , { type: "QTY", name: "Adet", id: 10 }
            , { type: "SRV", name: "Porsiyon", id: 11 }] as any);
        setLoading(false)
    }, [searchKey, selectedItem]);


    const selectData = (item: any) => {


        //  console.log('secilen unit', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item)
        setIsSelectedBool(true);


    }


    if (loading) return <Loading />;
    if (!unitList) return null;
    if (selectedItem) return null;

    if (searchKey.length < 1) return null;

    const textMiddle = (value: any) => {

        if (selectedItem) {
            return <div>{selectedItem}</div>
        }
        else {
            return <div>{value}</div>
        }

    }


    return (


        <div>

            {unitList.map((item: any) =>
                <Container key={item.id}>

                    <div className={"customListItem"}>

                        <Row>
                            <Col xs="9"><span onClick={() => selectData(item.type)} >{textMiddle(item.name)} </span></Col>

                        </Row>

                    </div>
                </Container>
            )}

        </div>
    )



};

