import React, { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from "ra-core";
import { TextField, makeStyles, List, ListItem, ListItemText, Button, Grid, Card, CardContent, Checkbox, Divider, FormControlLabel, Typography } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import axios from 'axios';
import Cookies from "js-cookie"
import CircularIndeterminate from '../newpostsubmissions/CircularProgress';
import { RestorePageRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    textField: {
        '& > *': {
            margin: theme.spacing(1),
            width: '57ch',
        },
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        marginTop: "1em",
        backgroundColor: theme.palette.background.paper,
    },
    rootDiv: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    rootCard: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        width: "15em",
        height: "50em",
        backgroundColor: theme.palette.background.paper,
    },
    rootCardFalse: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        width: "15em",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: "100%",
    },
    card: {
        height: "100%",
        backgroundColor: "lightgray",
    },
    cardFalse: {
        height: "100%",
    },
    media: {
        height: "30%",
        width: "30%",
        objectFit: "contain",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    mediaCard: {
        height: "auto",
        width: "100%",
        objectFit: "contain",
        display: "block",
        margin: "auto",
        padding: "auto",
    },
    titleDivider: {
        margin: theme.spacing(5),
    },
    titleDividerMergeProduct: {
        margin: theme.spacing(2),
    },
    titleProductName: {
        height: "0.5em",
        paddingBottom: "5em",
    },
    titleProductPrices: {
        paddingTop: "2em",
        paddingBottom: "5em",
        height: "100%"
    },
    button: {
        width: 512,
        height: 128,
    },
    paperTop: {
        padding: theme.spacing(2),
        width: "auto",
        textAlign: "center",
        fontSize: "2rem",
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
    },
    divider: {
        marginRight: "1%",
        marginLeft: "1%",
    },
}));



export const DuplicateBarcodes = (props: any) => {

    const alpDataProvider = useDataProvider();
    const classes = useStyles();
    const notify = useNotify();
    const [products, setProducts] = useState<any>();
    const [identifiers, setIdentifiers] = useState<any>();
    const [store, setStore] = useState<any>("");
    let newNames: string[] = [];
    let newBarcodes: string[] = [];
    let storeName = "";

    useEffect(() => {
        if (Cookies.get("store")) {
            let resp: any = [];
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_ZINGO_SERVER}/product-identifiers/findDuplicateBarcodesFromStore/${Cookies.get("store")}`,
                headers: { "authorization": `Bearer ${Cookies.get("accessToken")}` },
            }).then(async (response) => {
                let productss: any = []
                resp = response.data
                resp.map((res: any) => {
                    productss.push(res.productId);
                })
                setIdentifiers(resp);
                alpDataProvider.getList("products", { filter: { ids: productss }, pagination: { page: 0, perPage: 1 }, sort: { field: "name", order: "ASC" } }).then(resp => {
                    setProducts(resp.data);
                })
            });
        }

    }, [store])

    useEffect(() => {
        if (products) {
            products.map((pr: any) => {
                newNames.push(pr.name)
                newBarcodes.push(pr.barcode)
            })
        }
    }, [products])

    const handleSave = (index: number) => {
        let identifier = identifiers.find((a: any) => a.productId == products[index].id);
        if (identifier) {
            alpDataProvider.update("productidentifiers", { id: identifier._id, data: { id: identifier._id, barcode: newBarcodes[index] } } as any).then(_ => {
                alpDataProvider.update("products", { id: products[index].id, data: { id: products[index].id, name: newNames[index], barcode: newBarcodes[index] } } as any).then(_ => {
                    notify(products[index].name + " güncellendi")
                }).catch(err => {
                    notify(products[index].name + " hata!", "warning")
                })
            })
        }
    }

    if (!Cookies.get("store")) return (
        <div style={{ display: "flex" }}>
            <TextField variant="outlined" defaultValue={Cookies.get("store")} onChange={(e) => { storeName = e.target.value }}></TextField>
            <Button fullWidth style={{ display: "block" }} variant="outlined" onClick={() => { Cookies.set("store", storeName); setStore(storeName);  }}> Mağaza Seç </Button>
        </div>
    );

    if (!products) return <CircularIndeterminate></CircularIndeterminate>

    return (
        <div>

            <Button fullWidth style={{ display: "block" }} variant="outlined" onClick={() => { window.location.reload() }}> Yeni Ürün Getir </Button>
            <div style={{ display: "flex" }}>
                {products ? (
                    products.map((product: any, index: any) => (
                        <Grid xs={12} sm={6} md={4} lg={3} xl={3} >
                            <Card className={classes.cardFalse}>
                                <a href={product.images[0]?.url} target="_blank">
                                    <img
                                        src={
                                            product.images[0]
                                                ? product.images[0]?.url
                                                : `https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png`
                                        }
                                        className={classes.media}
                                    ></img>
                                </a>
                                <CardContent className={classes.titleProductPrices}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={product.name}
                                        onChange={e => { newNames[index] = e.target.value }}
                                    ></TextField>
                                    <br />
                                    <TextField disabled
                                        variant="outlined"
                                        fullWidth
                                        value={product.category}
                                    ></TextField>
                                    <br />
                                    <TextField disabled
                                        variant="outlined"
                                        fullWidth
                                        value={product.brand}
                                    ></TextField>
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        defaultValue={product.barcode}
                                        onChange={e => { newBarcodes[index] = e.target.value }}
                                    ></TextField>
                                    <br />
                                    <div style={{ height: "10px" }}></div>
                                    <Button variant="outlined" fullWidth onClick={() => { handleSave(index) }}>Kaydet</Button>
                                    <div style={{ height: "10px" }}></div>

                                    {product.prices.map((price: any) => (
                                        <a href={price.url} target="_blank">
                                            <Typography variant="h5" component="h2" align="left">
                                                {price.store}: {price.price}
                                            </Typography>
                                        </a>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <br />
                )}

            </div>
        </div>
    )
}

export default DuplicateBarcodes
