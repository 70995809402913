import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Paper from '@material-ui/core/Paper';
import { useDataProvider, useNotify } from 'ra-core';
import { Loading } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 400,
    },
    paper: {
        padding: 24,
        margin: 30,
        width: "35%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    },

    paperRight: {
        padding: 24,
        margin: 30,
        width: "25%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    }

});

const StyledTreeItem = withStyles({
    selected: {
        backgroundColor: "lightGrey",
    }
})(TreeItem);

export default function FileSystemNavigator() {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [zingoCategories, setZingoCategories] = useState<any>([]);
    const [otherCategories, setOtherCategories] = useState<any>([]);
    const [selectedStore, setSelectedStore] = useState<any>("");
    const [allStores, setALlStores] = useState<any>();
    const [selectedCatZingo, setSelectedCatZingo] = useState<any>();
    const [selectedCatOther, setSelectedCatOther] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dataProvider.getList("stores", {
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
            filter: {},
        }).then(resp => {
            setALlStores(resp.data);
        }).catch(err => {
            notify("Couldn't get stores", "warning")
        })
        dataProvider.getList("categories", { filter: { store: "zingo" }, sort: { field: 'title', order: 'ASC' }, pagination: { page: 1, perPage: 2000 } }).then(resp => {
            setZingoCategories(resp.data);
        }).catch(err => {
            notify("Couldn't get categories", "warning")
        });

        if (selectedStore) {
            dataProvider.getList("categories", { filter: { store: selectedStore }, sort: { field: 'title', order: 'ASC' }, pagination: { page: 1, perPage: 2000 } }).then(resp => {
                setOtherCategories(resp.data);
            }).catch(err => {
                notify("Couldn't get other categories", "warning")
            });
        }
    }, [selectedStore])

    const depthOnes = zingoCategories.filter((cat: any) => cat.depth === 1);
    const depthTwos = zingoCategories.filter((cat: any) => cat.depth === 2);
    const depthThrees = zingoCategories.filter((cat: any) => cat.depth === 3);

    const depthOnesOther = otherCategories.filter((cat: any) => cat.depth === 1);
    const depthTwosOther = otherCategories.filter((cat: any) => cat.depth === 2);
    const depthThreesOther = otherCategories.filter((cat: any) => cat.depth === 3);

    const findById = (id: any, array: any) => {
        let returnCat;
        array.forEach((el: any) => {
            if (el.id == id) {
                returnCat = el;
            }
        })
        return returnCat;
    }


    const handleClickZingo = (category: any) => {
        setSelectedCatZingo(findById(category.id, zingoCategories));
    }


    const handleClickOther = (category: any) => {
        setSelectedCatOther(findById(category.id, otherCategories));
    }

    const handleLink = (otherId: any, zingoId: any, depth: number) => {
        dataProvider.update("categories", { id: otherId, data: { id: otherId, zingoCategoryId: zingoId }, previousData: { title: "previous title" } as any }).then(resp => {
            if (depth == 1) {
                depthOnesOther[depthOnesOther.indexOf(findById(otherId, depthOnesOther))].zingoCategoryId = zingoId
            }
            else if (depth == 2) {
                depthTwosOther[depthTwosOther.indexOf(findById(otherId, depthTwosOther))].zingoCategoryId = zingoId
            }
            else if (depth == 3) {
                depthThreesOther[depthThreesOther.indexOf(findById(otherId, depthThreesOther))].zingoCategoryId = zingoId
            }

            notify("Categories matched!")
            setSelectedCatZingo({})
        }).catch(err => {
            setLoading(false);
            notify("Link error: " + err, "warning");
        })
    }

    if (!zingoCategories) return <Loading></Loading>
    if (loading) return <Loading></Loading>

    return (
        <div style={{ display: "flex", justifyItems: "center" }}>
            <Paper className={classes.paper}>
                <h2>Zingo Categories</h2>
                <hr />
                {(zingoCategories.length) ? <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                >
                    {depthOnes.map((depthOne: any) => {
                        return (
                            <StyledTreeItem nodeId={depthOne.id} label={depthOne.categoryName} onClick={() => { handleClickZingo(depthOne) }}>
                                { (findById(depthOne.id, depthOnes) as any).subCategories.map((depthTwo: any) => {
                                    return (
                                        <StyledTreeItem nodeId={depthTwo.id} label={depthTwo.name} onClick={() => { handleClickZingo(depthTwo) }}> {
                                            (findById(depthTwo.id, depthTwos) as any).subCategories.map((depthThree: any) => {
                                                return (
                                                    <StyledTreeItem nodeId={depthThree.id} label={depthThree.name} onClick={() => { handleClickZingo(depthThree) }}> {
                                                        (findById(depthThree.id, depthThrees) as any).subCategories.map((depthFour: any) => {
                                                            return (
                                                                <StyledTreeItem nodeId={depthFour.id} label={depthFour.name} onClick={() => { handleClickZingo(depthFour) }}></StyledTreeItem>
                                                            )
                                                        })
                                                    } </StyledTreeItem>
                                                )
                                            })
                                        } </StyledTreeItem>
                                    );
                                })
                                }
                            </StyledTreeItem>
                        );
                    })}
                </TreeView>
                    : <Loading></Loading>
                }
            </Paper>
            <Paper className={classes.paper}>
                <h2>{`${selectedStore.toUpperCase()} Categories`}</h2>
                <hr />
                {(selectedStore) ?
                    <TreeView
                        className={classes.root}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {depthOnesOther.map((depthOne: any) => {
                            return (
                                <StyledTreeItem nodeId={depthOne.id} label={(depthOne.zingoCategoryId) ? " !!MATCHED!! " + depthOne.categoryName : depthOne.categoryName} onClick={() => { handleClickOther(depthOne) }}>
                                    { (findById(depthOne.id, depthOnesOther) as any).subCategories.map((depthTwo: any) => {
                                        return (
                                            <StyledTreeItem nodeId={depthTwo.id} label={((findById(depthTwo.id, depthTwosOther) as any).zingoCategoryId) ? " !!MATCHED!! " + depthTwo.name : depthTwo.name} onClick={() => { handleClickOther(depthTwo) }}> {
                                                (findById(depthTwo.id, depthTwosOther) as any).subCategories.map((depthThree: any) => {
                                                    return (
                                                        <StyledTreeItem nodeId={depthThree.id} label={((findById(depthThree.id, depthThreesOther) as any).zingoCategoryId) ? " !!MATCHED!! " + depthThree.name : depthThree.name} onClick={() => { handleClickOther(depthThree) }}> {
                                                            (findById(depthThree.id, depthThreesOther) as any).subCategories.map((depthFour: any) => {
                                                                return (
                                                                    <StyledTreeItem nodeId={depthFour.id} label={depthFour.name} onClick={() => { handleClickOther(depthFour) }}></StyledTreeItem>
                                                                )
                                                            })
                                                        } </StyledTreeItem>
                                                    )
                                                })
                                            } </StyledTreeItem>
                                        );
                                    })
                                    }
                                </StyledTreeItem>
                            );
                        })}
                    </TreeView>
                    : <Select
                        labelId="store-select-label"
                        id="store-select"
                        fullWidth
                        value={selectedStore ? selectedStore : "Select"}
                        onChange={(e) => { setSelectedStore(e.target.value) }}
                    >
                        {
                            (allStores) ? allStores.map((store: any) => (
                                <MenuItem value={store.slug}>{store.name}</MenuItem>
                            )) : <Loading></Loading>
                        }
                    </Select>
                }
            </Paper>
            <Paper className={classes.paperRight}>
                <h2>Linking</h2>
                <hr />
                <h4>{selectedStore.toUpperCase()} :</h4>
                <p style={{ fontWeight: "bold", fontSize: 18 }}>
                    {(selectedCatOther && selectedCatOther.zingoCategoryId) ? <div style={{ backgroundColor: "#ffaaca" }}><p style={{ fontWeight: "bold", fontSize: 24 }}>ALREADY MATCHED!</p>  <p> *  {zingoCategories.filter((zingoCat: any) => zingoCat.id === selectedCatOther.zingoCategoryId)[0].categoryName}</p></div> : <div></div>}
                    {
                        (selectedCatOther && selectedCatZingo)
                            ? (findById(selectedCatOther.id, otherCategories) as any)?.categoryList.map((cat: any, index: any) => { if (index !== 0) return " > " + cat; else return cat })
                            : ""
                    }
                </p>
                <hr />
                <h4>Zingo : </h4>
                <p style={{ fontWeight: "bold", fontSize: 18 }}>
                    {
                        (selectedCatOther && selectedCatZingo)
                            ? (findById(selectedCatZingo.id, zingoCategories) as any)?.categoryList.map((cat: any, index: any) => { if (index !== 0) return " > " + cat; else return cat })
                            : ""
                    }
                </p>
                <br />
                {
                    (selectedCatOther && selectedCatZingo)
                        ? <Button onClick={() => { handleLink(selectedCatOther.id, selectedCatZingo.id, selectedCatOther.depth) }}> Link </Button>
                        : <div></div>
                }
            </Paper>
        </div>
    );
}