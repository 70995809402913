import * as React from "react";
import { FC } from "react";
import { ColorInput } from './ColorPicker'

import {
  Create,
  Datagrid,
  List,
  ListProps,
  NumberField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import StoreShow from "./StoreShow";
import StoreFilter from "./StoreFilter";

const useStyles = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const StoreList: FC<ListProps> = (props) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<StoreFilter />}
      perPage={25}
      sort={{ field: "name", order: "desc" }}
    >
      <Datagrid rowClick="expand" expand={<StoreShow />}>
        <TextField source="name" />
        <NumberField source="slug" label="Slug" />
      </Datagrid>
    </List>
  );
};
export const StoreCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="slug" />
      <TextInput source="appLogoUrl" />
      <TextInput source="instagramUrl" />
      <ColorInput source="color" />
    </SimpleForm>
  </Create>
);

export default StoreList;
