import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import zingoLogo from './zingo_logo_latest.png';


import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar color="primary" style={{ backgroundColor: "#263238" }} userMenu={<CustomUserMenu />} children={[
            <img src={zingoLogo}
                style={{ alignSelf: "center", width: "3vw", height: "2.3vw", marginLeft: "41vw", marginRight: "1%", fontFamily: "Roboto" }}
            ></img>,
            <Typography variant='h3' gutterBottom style={{ paddingRight: "32.5%", color: "#ffffff", fontSize: "0.85vw", paddingTop: "5px" }}>
                Zingo Admin Panel
            </Typography>,
            //  <h3 style={{ paddingRight: "30%", color: "#ffffff", fontSize: "0.8vw" }}>Zingo Admin Panel</h3>
        ]}  {...props} />
    );
};

export default CustomAppBar;
