import { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Checkbox, TextField, CircularProgress } from "@material-ui/core";
import { useDataProvider, useNotify } from "ra-core";
import { ArrayInput, Loading } from "react-admin";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarIcon from "@material-ui/icons/Star";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import React from "react";
import CompletedPage from "../shared/CompletedPage";
import axios from "axios";
import Cookies from "js-cookie"

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 24,
        margin: 30,
        width: "55%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    },
    paperLeft: {
        padding: 24,
        margin: 30,
        width: "75%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    },
    root: {
        //margin: "auto",
        height: 400,
        overflow: "scroll"
    },
    cardHeader: {
        padding: "10px",
    },
    list: {
        width: "100%",
        height: 230,
        overflow: "auto",
    },
    button: {
        margin: "20px",
    },
    prod: {},
    popover: {
        pointerEvents: 'none',
    },
    paperName: {
        width: 'auto',
        padding: theme.spacing(2),
        backgroundColor: "#F8F8F8",
        display: "inline-flex",
        alignItems: 'center'
    },
}));

const BarcodeCreate = (props: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const [name, setName] = useState();
    const [firm, setFirm] = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_ZINGO_SERVER + "/barcodes/product-name-fiyatbu/" + props.barcodeScan.barcode, { headers: { "authorization": "Bearer " + Cookies.get("accessToken") } }).then((resppp: any) => {
            setName(resppp.data.name)
            setFirm(resppp.data.firm_name)
        })
    }, [])

    const handleCreate = () => {
        dataProvider.create("barcodes", { data: { barcode: props.barcodeScan.barcode, name: name } }).then(resp => {
            window.location.reload()
            notify("Başarılı bir şekilde barkod ekledim!")
        }).catch(err => {
            notify("Bir şeyler yanlış gitti!", "warning")
        })
    }

    const handleSkip = () => {
        dataProvider.update('barcodescans', {
            id: props.barcodeScan.id,
            data: { id: props.barcodeScan.id, isReviewed: true },
            previousData: { title: "previous title" } as any
        }).then(res => {
            notify("Bir sonraki taramayı getiriyorum!")
            window.location.reload()
        }).catch(err => {
            notify("Bir şeyler yanlış gitti!", "warning")
        })
    }


    if (!props.barcodeScan) return <CircularProgress></CircularProgress>

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Paper className={classes.paperLeft}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <h1>Yeni Barkod Oluşturuyorsunuz.</h1>
                    <p>Kalan : {props.count}</p>
                </div>
                <hr />
                <br />
                <br />
                <TextField
                    id="barkod"
                    label="Barkod"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    disabled
                    value={props.barcodeScan.barcode}
                />
                <br />
                <br />
                <br />
                <br />
                <TextField
                    id="name"
                    label="İsim"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    value={name}
                    fullWidth
                    onChange={(e: any) => { setName(e.target.value) }}
                />
                {firm ? 
                <h4>ÜRETİCİ : {firm}</h4>
                 : <div></div>
                 }

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px", height: "10%", width: "100%" }}>
                    <Button variant="contained" color="default" fullWidth onClick={handleSkip}>Atla</Button>
                    <Button variant="contained" color="primary" fullWidth onClick={handleCreate}>Kaydet</Button>
                </div>
            </Paper>
        </div>
    );
}


export default BarcodeCreate;