import React from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';


export const SendCampaignButton = ({
    isNewProduct,
    campaignSubmission,
    campaignPrice,
    campaignOldPrice,
    listOfProducts,
    listOfPrices,
    campaignTitle,

    discountX,
    discountY,
    discountDescription,
    cardIsNeeded,
    discountPercent,
    discountMin,
    discountPrice,
    isExistStartDate,
    isExistFinishDate,
    startDate,
    finishDate,
    selectDiscountKind,
    selectedStore,
    selectedBrand,
    productName,
    productDescription,
    selectedUnit,
    productAmount,
    productImagePath,
    cropResult,
    selectedCategoryId,
    campaignChips
}: any) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();

    let CampaignDto: any = {};
    let ProductDto = {};

    // const getCategories = () => {     
    //         dataProvider
    //             .getList('Category', {})
    //             .then(response => {
    //                 createProduct();
    //             })
    //             .catch(error => {
    //                 notify(`Get Categories error: ${error}`, 'warning');
    //             });

    // }

    //Kampanya price old manuel alınıyor. Product varsa name id brand ve id otomatik alınıyor.
    //Eğer product yoksa ilk önce product create etmeli, sonra da kampanya oluşturmalıyız!

    if (typeof campaignChips !== 'undefined') {
        CampaignDto.keywords = campaignChips;
    }
    if (typeof selectedStore.id !== 'undefined') {
        CampaignDto.storeId = selectedStore.id
    }
    if (typeof selectedStore.slug !== 'undefined') {
        CampaignDto.store = selectedStore.slug
    }
    //??? Title girilecek!
    CampaignDto.campaignTitle = campaignTitle;

    CampaignDto.image = campaignSubmission[0].image;

    if (isNewProduct === false) {
        //If code is failed here that means product is already exists. 
        //It is enough to send data about campaing.
        //We will take product creditianls from our product service by taking product by id 

        //createCampaignDtoByUsingSelectedStore(campaignPrice, CampaignDto, campaignOldPrice, listOfProducts, listOfPrices);

    } else {   // If code is failed here that means we have new product. 
        // So first of all, we have to have details of it to create new product.
        // if (typeof selectedBrand.id !== 'undefined') {
        //     ProductDto.brandId = selectedBrand.id
        // }
        // if (typeof selectedBrand.name !== 'undefined') {
        //     ProductDto.brand = selectedBrand.name;
        // }
        // if (typeof productName !== 'undefined') {
        //     ProductDto.name = productName;
        // }

        if (typeof productImagePath !== 'undefined' && productImagePath) {
            CampaignDto['images'] = [{ url: productImagePath }]
        }
        if (typeof cropResult !== 'undefined' && cropResult) {
            CampaignDto.base64Image = cropResult
        }
    }

    // if (selectDiscountKind !== 'nothing') {
    //     createDiscountForCampaign(ProductDto, discountX, discountPercent, discountMin, discountY, selectDiscountKind, discountDescription, discountPrice, cardIsNeeded, isExistStartDate, startDate, isExistFinishDate, finishDate);
    // }

    // switch (selectedUnit) {

    //     case 'KG':
    //     case 'G':
    //     case 'MG': ProductDto.kind = 'WEIGHT';
    //         break;

    //     case 'M':
    //     case 'CM':
    //     case 'MM': ProductDto.kind = 'LENGTH';
    //         break;

    //     case 'L':
    //     case 'ML': ProductDto.kind = 'VOLUME';
    //         break;

    //     case 'QTY': ProductDto.kind = 'QUANTITY';
    //         break;

    //     case 'SRV': ProductDto.kind = 'SERVING';
    //         break;

    // }
    // if (typeof selectedUnit !== 'undefined') {
    //     ProductDto.unit = selectedUnit;
    // }
    // if (typeof productAmount !== 'undefined') {
    //     ProductDto.amount = productAmount;
    // }

    const addCategoryToCampaignProduct = async () => {
        if (!finishDate) {
            finishDate = new Date();
            finishDate.setDate(startDate.getDate() + 7)
        }

        if (finishDate && startDate > finishDate) {
            notify("Bitiş tarihi, Başlangıç tarihinden önce olamaz!", "warning")
        } else {
            listOfProducts.forEach((p: any) => {
                if (!p.id)
                    p.categoryIds = selectedCategoryId.categoryIds
                p.categoryList = selectedCategoryId.categoryList
                p.category = selectedCategoryId.categoryName
                p.categoryId = selectedCategoryId.id
            })
            await createProduct()
        }
    }

    const createProduct = async () => {
        await Promise.all(listOfProducts.map(async (p: any) => {
            if (!p.id) {
                await dataProvider
                    .create('products', { data: { ...p, } }).then((response) => {
                        notify('New Product Added');
                        p.id = response.data.id
                    })
            }
        })).then(async () => {
            await addPricesToProducts();
        })
            .catch(error => {
                notify(`New Product error: ${error.message}`, 'warning');
            });
    }

    // const getProductIdentifierId = async (product: any) => {
    //     return await dataProvider.create('productidentifiers', { data: { productId: product.id, productName: product.name, storeId: CampaignDto.storeId.toString(), slug: CampaignDto.store, } }).then(res => {
    //         return res.data.id;
    //     }).catch(err => {
    //         notify("somthng went wrong while getting identifier!!!" + err, "warning");
    //     })
    // }

    const addPricesToProducts = async () => {
        CampaignDto.startFrom = startDate;
        CampaignDto.endTo = finishDate;
        const products = [] as any;

        await Promise.all(listOfProducts.map(async (p: any, index: any) => {
            const product: any = {
                productId: p.id.toString(),
                productName: p.name,
                price: Number(listOfPrices[index].productPrice),
                oldPrice: Number(listOfPrices[index].oldPrice),
            }
            if (p.imageUrl != null) { product.imageUrl = p.imageUrl }
            else if (p.images) { product.imageUrl = p.images[0]?.url }
            else { product.imageUrl = "" };
            products.push(product)
        }))
        CampaignDto.products = products;
        await createPrice();

    }

    const addCampaignRuleToProducts = async () => {
        if (selectDiscountKind !== "nothing")
            CampaignDto.products.forEach((p: any) => {
                p.discountRule = {};

                if (discountX) {
                    p.discountRule.x = discountX;
                }
                if (discountPercent) {
                    p.discountRule.x = discountPercent;
                }
                if (discountMin) {
                    p.discountRule.x = discountMin;
                }
                if (discountY) {
                    p.discountRule.y = discountY;
                }
                p.discountRule.kind = selectDiscountKind;

                if (discountPrice !== 0) {
                    p.discountRule.discountPrice = discountPrice;
                }
                p.discountRule.loyaltyCard = cardIsNeeded;
            })
        await createPrice()
    }

    const createPrice = async () => {
        // const today = new Date();
        // if ((CampaignDto.startFrom.getDate() + (CampaignDto.startFrom.getMonth() * 30)) > (today.getDate() + (today.getMonth() * 30))) {
        //     await Promise.all(CampaignDto.products.map(async (p: any) => {
        //         await dataProvider.create("futureprices", {
        //             data: {
        //                 productId: p.productId.toString(), productName: p.productName,
        //                 storeId: CampaignDto.storeId.toString(), store: CampaignDto.store, price: p.price,
        //                 validFrom: CampaignDto.startFrom, validTo: CampaignDto.endTo
        //             }
        //         }).then(async () => {
        //             notify("Price is created");
        //         }).catch(error => {
        //             notify(`New Price error: ${error.message}`, 'warning');
        //         });
        //     }))
        // } else {
        //     await Promise.all(CampaignDto.products.map(async (p: any) => {
        //         await dataProvider.create("prices", {
        //             data: {
        //                 productId: p.productId.toString(), productIdentifierId: p.productIdentifierId.toString(), productName: p.productName,
        //                 storeId: CampaignDto.storeId.toString(), store: CampaignDto.store, price: p.price,
        //                 validFrom: CampaignDto.startFrom, validTo: CampaignDto.endTo
        //             }
        //         }).then(async () => {
        //             notify("Price is created");
        //         }).catch(error => {
        //             console.log(error);
        //             notify(`New Price error: ${error.message}`, 'warning');
        //         });
        //     }))
        // }
        await createCampaign()
    }

    const createCampaign = async () => {
        CampaignDto.products.map((p: any) => {
            delete p.productIdentifierId;
        })
        await dataProvider
            .create('campaigns', { data: CampaignDto })
            .then(response => {
                notify('New Campaign Added');
                updateCampaignSubmission();
            })
            .catch(error => {
                notify(`New Campaign error: ${error.message}`, 'warning');
            });
    }


    const updateCampaignSubmission = async () => {
        let id = campaignSubmission[0].id;
        await dataProvider
            .update('campaignsubmissions', { id, data: { id, isReviewed: true }, previousData: { title: "previous title" } as any })
            .then(response => {
                notify('CampaignSubmission updated');
                window.location.reload();
            })
            .catch(error => {
                notify(`Label updated error: ${error.message}`, 'warning');
            });
    }

    return <Button className={'m-1'} color="primary" size='large' onClick={addCategoryToCampaignProduct} style={{ float: 'right' }} endIcon={<SendIcon />}  >Send Campaign</Button>;
};


// function createCampaignDtoByUsingSelectedStore(campaignPrice, CampaignDto, campaignOldPrice, selectedProduct, listOfProducts, listOfPrices) {

//     // if (typeof campaignPrice !== 'undefined' && campaignPrice !== 0) {
//     //     CampaignDto.price = campaignPrice;
//     // }
//     // if (typeof campaignOldPrice !== 'undefined' && campaignOldPrice !== 0) {
//     //     CampaignDto.oldPrice = campaignOldPrice;
//     // }
//     // if (typeof selectedProduct.id !== 'undefined') {
//     //     CampaignDto.productId = selectedProduct.id;
//     // }
//     // if (typeof selectedProduct.name !== 'undefined') {
//     //     CampaignDto.productName = listOfProducts[0].name;
//     // }
//     // if (typeof selectedProduct.brandId !== 'undefined') {
//     //     CampaignDto.brandId = selectedProduct.brandId;
//     // }
//     // if (typeof selectedProduct.brand !== 'undefined') {
//     //     CampaignDto.brand = 'brand';
//     // }
// }

// function createDiscountForCampaign(ProductDto, discountX, discountPercent, discountMin, discountY, selectDiscountKind, discountDescription, discountPrice, cardIsNeeded, isExistStartDate, startDate, isExistFinishDate, finishDate) {

// }

