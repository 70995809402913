import { Avatar, Checkbox, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { elasticParenthesesRemover } from '../Helper';
import Button from '@material-ui/core/Button';



export const ProductSearch = ({ callbackFromParent, searchKey, storeSlug }: { callbackFromParent: any, searchKey: any, storeSlug: any }) => {
    const dataProvider = useDataProvider();
    const [productList, setProductList] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedItem, setSelectedItem] = useState(Boolean);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const toggleTip = () => setTooltipOpen(!tooltipOpen);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [checked, setChecked] = React.useState([0]);


    useEffect(() => {
        setSelectedItem(false);

        searchKey = elasticParenthesesRemover(searchKey);
        dataProvider.getList('products', {
            filter: { query: searchKey },
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        })
            .then(({ data }) => {
                if (typeof data !== 'undefined') {
                    setProductList(data);
                    setLoading(false);
                } else {
                    alert("something wrong here")
                }
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [searchKey]);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {

        setSelectedIndex(index);
        return;
    };
    const selectData = (item: any, index: any, e: any) => {
        // let arr = [...selectedIndexes];
        // let arra = [...siblingProducts];

        // if (!selectedIndexes.includes(index)) {
        //     arr.push(index);
        //     arra.push(similarProducts[index])
        // } else {
        //     arr.splice(arr.indexOf(index), 1);
        //     arra.splice(arra.indexOf(index), 1);
        // }
        // setSelectedIndexes(arr);
        // setSiblingProducts([...arra]);
        let arr = [...selectedProducts];
        if (!arr.includes(item as never)) {
            arr.push(item as never);
        } else {
            arr.splice(arr.indexOf(item as never), 1)
        }
        setSelectedProducts([...arr]);
        //callbackFromParent(item)
        handleListItemClick(e, index);
    }

    const handleClick = () => {
        setSelectedItem(true)
        callbackFromParent(selectedProducts)
        setSelectedProducts([]);
    }



    //   console.log(JSON.stringify(productList))
    if (loading) return <Loading />;
    //if (error) return <Error /> ;
    if (!productList) return null;
    if (selectedItem) return null;
    if (searchKey.length < 2) return null;

    const showDetail = (item: any) => {
        let length = item.prices.length;
        if (length > 4) {
            length = 4
        };
        let i = 0;
        var itemsData = '';
        for (i; i < length; i++) {
            itemsData += `${item.prices[i].store} ${item.prices[i].price}`;
        }
        return (
            <div>
                {showBrand(item)}
                <br />
                {showName(item)}
                <br />
                {unitOfMeasurement(item)}
                <br />
                {itemsData}<br />

            </div>
        )
    }

    const showName = (item: any) => {
        if (typeof item.name !== 'undefined') {
            return item.name
        }
    }

    const showBrand = (item: any) => {
        if (typeof item.brand !== 'undefined') {
            return item.brand
        }
    }

    const unitOfMeasurement = (item: any) => {
        if (typeof item.unitOfMeasurement !== 'undefined') {

            return item.unitOfMeasurement.amount + item.unitOfMeasurement.unit;
        }
    }

    const myImageSrc = (item: any) => {
        if (typeof item.images !== 'undefined' && item.images.length) {
            return item.images[0].url;
        }
        else {
            return 'https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg';
        }
    }

    const isInclude = (item: any, key: any) => {
        let arr = []
        let boolVal = false;
        item.prices.forEach((it: any) => {
            arr.push(it.store);
            if (it.store == storeSlug) boolVal = true;
        })
        return boolVal;
    }

    return (
        <div>
            <Button className={'m-1'} size='large' color="primary" onClick={handleClick}> Ürün/leri ekle</Button>
            <List dense >
                {productList.map((item: any, index: any) => {
                    const labelId = `checkbox-list-secondary-label-${item}`;
                    return (
                        isInclude(item, storeSlug) ? (
                            <ListItem
                                key={item.id} button
                                selected={selectedIndex === index}
                                divider
                            > <ListItemIcon>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(item)}
                                        checked={checked.indexOf(item) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        onClick={(e: any) => selectData(item, index, e)}
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    {(myImageSrc(item)) ? (
                                        <Avatar
                                            src={myImageSrc(item)}
                                            className="d-block h-100"
                                        />
                                    ) : (
                                        <Avatar className="d-block h-100" />
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.name}
                                />

                            </ListItem>
                        ) : <ListItem />
                    );
                })}
            </List>
            {/* <List dense={true} style={{ fontSize: "10px" }} >
                {productList.map((item: any, index: any) => {
                    const labelId = `checkbox-list-secondary-label-${item}`;
                    <ListItem
                        key={item.id}
                        button
                        selected={selectedIndex === index}
                        divider
                        onClick={(e: any) => selectData(item, index, e)}
                    >
                        <ListItemAvatar>
                            {(myImageSrc(item)) ? (
                                <Avatar
                                    src={myImageSrc(item)}
                                    className="d-block h-100"
                                />
                            ) : (
                                <Avatar className="d-block h-100" />
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.name}
                        />
                        <ListItemSecondaryAction>
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(item)}
                                checked={checked.indexOf(item) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                })}
            </List> */}

        </div >
    )
};

