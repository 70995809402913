import Alert from "@material-ui/lab/Alert";
export const CompletedPage = () => {
    return (
        <div>
            <Alert color="info">Tebrikler! Burda yapacak bir şey kalmadı!</Alert>
        </div>
    );

}


export default CompletedPage;