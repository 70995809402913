import { Box } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const useStyles = makeStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
});


export const CategoryInProductSubmission = ({ callbackFromParentCategory }: { callbackFromParentCategory: any }) => {
    const classes = useStyles();

    const dataProvider = useDataProvider();
    const [categoryList, setCategoryList] = useState<Record<any, any>>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [categoryDepth, setCategoryDepth] = useState("1");
    const [isClickAnything, setIsClickAnything] = useState(false);

    const [selectedItem, setSelectedItem] = useState<any>();
    const [isLastCat, setIsLastCat] = useState(false);
    const [lastItem, setLastItem] = useState<Record<any, any>>({ categoryName: '' });
    const [isBackNeed, setIsBackNeed] = useState(false);



    useEffect(() => {
        dataProvider.getList('categories', {
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
            filter: { depth: categoryDepth },

        })
            .then(({ data }) => {
                if (data.length) {
                    setCategoryList(data);
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })

    }, [isBackNeed]);


    const selectData = (item: any) => {
        //  console.log('secilen', JSON.stringify(item))
        callbackFromParentCategory(item)


    }

    const handleBack = () => {
        setIsBackNeed(true)
        setIsLastCat(false)
        setIsClickAnything(false);
    }

    const handleClick = (item: any) => {
        selectData(item)
        setLastItem(item)
        dataProvider.getOne('categories', {
            id: item.id
        })
            .then(({ data }) => {
                // console.log(data)
                // console.log('calıstıii')
                let cats = [] as any;
                if (
                    data.subCategories.length === 0
                ) {
                    setIsLastCat(true);
                    setLastItem(data as any);
                    selectData(data);
                } else {
                    //data da
                    data.subCategories.map((i: any, item: any) => {
                        let cat = { id: i.id, categoryName: i.name }
                        cats.push(cat);
                    })
                    setSelectedItem(cats)
                }
                setIsClickAnything(true);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }

    // const showSelectedCategory = () => {

    //   if (!isClickAnything) {
    //     return <span className={"mx-2"}>Kategori seçilmedi</span>
    //   } else {
    //     if (lastItem.categoryName) {
    //       return <span className={"mx-1"}>Seçili<Badge className={"p-1"} color="info">{lastItem.categoryName}</Badge>
    //         </span>
    //     }
    //   }

    // }

    const showParentCats = () => {
        const renderTree = (nodes: any) => (
            <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
                {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
            </TreeItem>
        );
        if (!isLastCat) {
            if (!isClickAnything) {
                return (

                    <Box>
                        <TreeView
                            className={classes.root}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            multiSelect
                        >

                            {categoryList?.map(function (item: any, i: any) {
                                return <TreeItem nodeId={item.id} label={item.categoryName} onClick={() => handleClick(item)}></TreeItem>
                            })}

                        </TreeView>
                    </Box>
                )
            } else {
                return (
                    <Box>
                        <TreeView
                            className={classes.root}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            multiSelect
                        >

                            {selectedItem.map(function (item: any, i: any) {
                                return <TreeItem nodeId={item.id} label={item.categoryName} onClick={() => handleClick(item)}></TreeItem>
                            })}

                        </TreeView>
                    </Box>
                )
            }
        } else {
            return <div>
                <TreeItem nodeId={lastItem?.id} label={lastItem?.categoryName}></TreeItem>
                <br></br>
                Alt kategori bulunmamaktadır!<br />
            </div>
        }

    }

    if (loading) return <Loading />;
    if (!categoryList) return null;

    return (


        <div>


            <Button onClick={() => handleBack()} size="large" color="primary" >Back</Button>
            <Box mt="1em" />
            {/*showSelectedCategory()*/}
            {showParentCats()}

        </div >
    )



};

