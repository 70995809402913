import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {

    TextInput,
    BooleanInput,
    FormWithRedirect,
    Toolbar,
    useTranslate,
    DateInput,
    EditProps,
    Edit,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { FC } from 'react';


export const CommentEdit: FC<EditProps> = props => {
    return (
        <Edit
            // title={<ProductZingoTitle />}
            component="div"
            {...props}
        >
            <CommentForm />
        </Edit>
    );

};

export const CommentForm = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>

                    <form>
                        <CardContent >
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'First Name & Last Name'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="firstName" fullWidth />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="lastName" fullWidth />
                                        </Box>


                                    </Box>
                                    <Box mt="1em" />
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Comment'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="comment" fullWidth />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.customers.fieldGroups.stats'
                                        )}
                                    </Typography>
                                    <div>
                                        <BooleanInput label="isReviewed" source="isReviewed" />
                                        <BooleanInput label="isApproved" source="isApproved" />
                                        <BooleanInput label="isDenied" source="isDenied" />
                                        <DateInput source="date" fullWidth />
                                    </div>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="comments"
                        />
                    </form>
                </Card>
            )}
        />
    );


}
export default CommentEdit;

const useStyles = makeStyles({
    root: { width: 600, margin: 'auto' },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
});



