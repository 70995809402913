import React, { FC } from 'react';

import {
    List,
    ListProps,
    Datagrid,
    TextField,
} from 'react-admin';
import CategoryEdit from './CategoryEdit';
import CategoryFilter from './CategoryFilter';



const CategoryList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            filters={<CategoryFilter />}
            perPage={5}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<CategoryEdit />}>
                <TextField source="categoryName" />

                <TextField source="subCategories[0].name" label="Child Category" sortable={false} />
                <TextField source="depth" />
                {/* <NumberField source="location.coordinates[1]" label="Latitude" />
                <NumberField source="location.coordinates[0]" label="Longitude" />
                <ReferenceManyField label="Districts" reference="districts" target="townId">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceManyField> */}
            </Datagrid>
        </List>
    );
};

export default CategoryList;
