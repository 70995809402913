import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StoreIcon from '@material-ui/icons/Store';
import PostAddIcon from '@material-ui/icons/PostAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import DoneIcon from '@material-ui/icons/DoneAll';
import StepConnector from '@material-ui/core/StepConnector';

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props: any) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33, 242, 47) 0%, rgb(64, 233, 149) 50%, rgb(64, 219, 233) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33, 242, 47) 0%, rgb(64, 233, 149) 50%, rgb(64, 219, 233) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(33, 242, 47) 0%, rgb(64, 233, 149) 50%, rgb(64, 219, 233) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(33, 242, 47) 0%, rgb(64, 233, 149) 50%, rgb(64, 219, 233) 100%)',
    },
});

function ColorlibStepIcon(props: any) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: any = {
        1: <StoreIcon />,
        2: <PostAddIcon />,
        3: <VideoLabelIcon />,
        4: <DoneIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};


export default function CustomizedSteppers(props: any) {
    const [activeStep, setActiveStep] = React.useState(props.step);
    const steps = props.steps;


    React.useEffect(() => {
        if (props.goNext) {
            handleNext()
            props.setNext(false);
        } else if (props.goBack) {
            handleBack();
            props.setBack(false);
        } else if (props.reset) {
            handleReset();
            props.setReset(false);
        }
        setActiveStep(props.step)
    }, [props])

    // eslint-disable-next-line 
    const handleNext = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    };

    // eslint-disable-next-line 
    const handleBack = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };

    // eslint-disable-next-line 
    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStepClick = (index: any) => {
        if (activeStep > index) {
            setActiveStep(index);
            props.setStep(index);
        }
    }

    return (
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label: any, index: any) => (
                <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} style={{ cursor: (activeStep > index) ? "pointer" : "auto" }} onClick={() => { handleStepClick(index) }}>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}