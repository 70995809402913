import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import axios from "axios";
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 215,
    maxWidth: 445,
    margin: "1%",
    display: "block",
    background: "#ababab"
  },
}));

export const ScrapperResult = (props: any) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [scrapperResults, setScrapperResults] = useState<any>([]);
  const date = new Date();
  const yesterDay = date.getTime() - 86400000;
  const [stores, setStores] = useState<any>([]);


  useEffect(() => {
    dataProvider
      .getList("mappings", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "title", order: "ASC" },
        filter: { name: "scrap-result", createdAt: new Date(yesterDay) },
      })
      .then((resp) => {
        resp.data = resp.data.sort((a, b) => Number(b.value.split(" ")[1]) - Number(a.value.split(" ")[1]))
        setScrapperResults(resp.data);
      })
      .catch((err) => {
        notify("Get Scrapper Results Error!", "error");
      });
    dataProvider.getList("stores", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "title", order: "ASC" },
      filter: {},
    }).then(resp => {
      setStores(resp.data);

    }).catch((err) => {
      notify("Get Stores Error!", "error");
    });

  }, []);


  const notWorkingStores = () => {
    let a: string[] = [];
    let b: string[] = [];
    stores.map((store: any) => {
      a.push(store.slug)
    })
    scrapperResults.map((scrap: any) => {
     
        b.push(scrap.value.split(" ")[0])
    })
    return a.filter(x => !b.includes(x))
  }

  const postNonWorkingStores = async (scrap: any) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_ZINGO_SERVER}/price-engine/scrap/${scrap}`,
      headers: { "Authorization": "Bearer " + Cookies.get("accessToken") }
    })
    .then(async (res: any) => {
      return res;
    })
    .catch((err: any) => err)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Scrapper Results
        </Typography>
      </Grid>
      {scrapperResults?.map((scrap: any) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Card className={classes.root}>
            <CardContent style={{ textAlign: "center", fontSize: "30px" }}>
              <img src={(stores.find((a: any) => a.slug == scrap.value.split(" ")[0]))?.appLogoUrl} style={{ maxWidth: "100%", height: "80px" }}></img>
            </CardContent>
            <CardContent style={{ textAlign: "center", fontSize: "30px" }}>
              {scrap.value.split(" ")[0]}
              <br />
              {scrap.value.split(" ")[1]}
            </CardContent>
          </Card>

        </Grid>
      ))}

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Not Working Stores
        </Typography>
      </Grid>
      {notWorkingStores()?.map((scrap: any) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Card className={classes.root}
            onClick={() => postNonWorkingStores(scrap)}
          >
            <CardContent style={{ textAlign: "center", fontSize: "30px" }}>
             <img src={(stores.find((a: any) => a.slug == scrap))?.appLogoUrl} style={{ maxWidth: "100%", height: "80px" }}></img>
            </CardContent>
            <CardContent style={{ textAlign: "center", fontSize: "30px" }}>
              {scrap}
         
            </CardContent>

          </Card>
        </Grid>
      ))}

    </Grid>

  );
};

export default ScrapperResult;
