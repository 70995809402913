import React, { useState, useEffect } from "react";
import { FormGroup } from "reactstrap";
import Alert from "@material-ui/lab/Alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  useDataProvider,
  Loading,
  Error,
  useNotify,
  FormWithRedirect,
} from "react-admin";

import { MismatchButton } from "./MismatchButton";
import { AddPrice } from "./AddPrice";
import { UnitSearch } from "../productsubmission/UnitSearch";
import firebase from "firebase/app";
import "firebase/storage";
import Button from "@material-ui/core/Button";
import LabelCropper from "../productsubmission/LabelCropper";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import SkipIcon from "@material-ui/icons/SkipNext";
import ReactImageZoom from "react-image-zoom";

if (!firebase.apps.length) {
  firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as string);
}

export const PriceSubmission = (props: any) => {
  ///??? eğer unit measurement yoksa göster varsa sürekli girmesin!!!

  const dataProvider = useDataProvider();
  const [labelList, setLabelList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const [product, setProduct] = useState<any>({});

  const notify = useNotify();

  //Product
  const [productName, setProductName] = useState();
  const [productBarcode, setProductBarcode] = useState();
  const [productSku, setProductSku] = useState();
  const [productLabel, setProductLabel] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productOldPrice, setProductOldPrice] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [myFilterUnit, setMyFilterUnit] = useState("");
  const [productAmount, setProductAmount] = useState("");
  const [imgUrl, setImgUrl] = useState();

  //Discount

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [isExistStartDate, SetIsExistStartDate] = useState(false);
  const [isExistFinishDate, SetIsExistFinishDate] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [selectDiscountKind, setSelectDiscountKind] = useState("nothing");
  const [selectDiscountName, setSelectDiscountName] = useState("İndirim Seç");
  const [discountX, setDiscountX] = useState("");
  const [discountY, setDiscountY] = useState("");
  const [discountDescription, setDiscountDescription] = useState("");
  const [cardIsNeeded, setCardIsNeeded] = useState(Boolean);
  const [discountPercent, setDiscountPercent] = useState("");
  const [discountMin, setDiscountMin] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");

  const [unitPriceUnit, setSelectedUnitPriceUnit] = useState();
  const [unitPrice, setProductAmountUnitPrice] = useState<any>(0.0);
  const [myFilterUnitPriceUnit, setMyFilterUnitPriceUnit] = useState("");
  const [isSelectedAnyUnit, setIsSelectedAnyUnit] = useState(false);

  // Product Handle
  const changeBarcode = (e: any) => {
    setProductBarcode(e.target.value);
  };

  const changeName = (e: any) => {
    setProductName(e.target.value);
  };

  const changeSku = (e: any) => {
    setProductSku(e.target.value);
  };

  const changePrice = (e: any) => {
    setProductPrice(e.target.value);
    if (product.unitOfMeasurement) {
      convertToUnitPrice(
        product.unitOfMeasurement.amount,
        unitPriceUnit,
        e.target.value
      );
    }
  };

  const changeOldPrice = (e: any) => {
    setProductOldPrice(e.target.value);
  };

  const changeLabel = (e: any) => {
    setProductLabel(e.target.value);
  };

  // price handle
  const changeStartDate = () => {
    let current = !isExistStartDate;
    SetIsExistStartDate(current);
  };

  const changeFinishDate = () => {
    let current = !isExistFinishDate;
    SetIsExistFinishDate(current);
  };

  const handleUnitChange = (e: any) => {
    setMyFilterUnit(e.target.value);
  };

  const changeAmount = (e: any) => {
    setProductAmount(e.target.value);
  };
  /// Discount Panel Handles
  const changeDiscountX = (e: any) => {
    setDiscountX(e.target.value);
  };
  const changeDiscountY = (e: any) => {
    setDiscountY(e.target.value);
  };
  const changeDiscountDescription = (e: any) => {
    setDiscountDescription(e.target.value);
  };
  const changeCardNeeded = () => {
    let current = !cardIsNeeded;
    setCardIsNeeded(current);
  };
  const changeDiscountPercent = (e: any) => {
    setDiscountPercent(e.target.value);
  };
  const changeDiscountMin = (e: any) => {
    setDiscountMin(e.target.value);
  };
  const changeDiscountPrice = (e: any) => {
    setDiscountPrice(e.target.value);
  };
  ///

  const handleUnitPriceUnitChange = (e: any) => {
    setMyFilterUnitPriceUnit(e.target.value);
  };

  const myCallbackUnitPrice = (dataFromChild: any) => {
    // console.log('Selected Data', JSON.stringify(dataFromChild))

    setSelectedUnitPriceUnit(dataFromChild);
    setMyFilterUnitPriceUnit(dataFromChild);
    setIsSelectedAnyUnit(true);
    convertToUnitPrice(
      product.unitOfMeasurement.amount,
      dataFromChild,
      productPrice
    );

    //   console.log('Selected ... ' + JSON.stringify(selectedProduct))
  };

  const convertToUnitPrice = (element: any, kind: any, price: any) => {
    let cofactor = 1;
    switch (product.unitOfMeasurement.unit) {
      case "KG": {
        if (kind === "G") {
          cofactor = 1 / 1000;
        }
        if (kind === "MG") {
          cofactor = 1 / 1000000;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "G": {
        if (kind === "KG") {
          cofactor = 1000;
        }
        if (kind === "MG") {
          cofactor = 1 / 1000;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "MG": {
        if (kind === "KG") {
          cofactor = 1000000;
        }
        if (kind === "G") {
          cofactor = 1000;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "M": {
        if (kind === "M") {
          cofactor = 1;
        }
        if (kind === "CM") {
          cofactor = 1 / 100;
        }
        if (kind === "MM") {
          cofactor = 1 / 1000;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "CM": {
        if (kind === "M") {
          cofactor = 100;
        }
        if (kind === "MM") {
          cofactor = 1 / 10;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "MM": {
        if (kind === "M") {
          cofactor = 1000;
        }
        if ((kind = "CM")) {
          cofactor = 10;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }

      case "L": {
        if (kind === "ML") {
          cofactor = 1 / 1000;
        }
        if (kind === "CL") {
          cofactor = 1 / 100;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "ML": {
        if (kind === "L") {
          cofactor = 1000;
        }
        if (kind === "CL") {
          cofactor = 10;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "CL": {
        if (kind === "L") {
          cofactor = 100;
        }
        if (kind === "ML") {
          cofactor = 1 / 10;
        }
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }
      case "QTY":
      case "SRV": {
        cofactor = 1;
        setProductAmountUnitPrice(((price * cofactor) / element).toFixed(2));
        break;
      }

      default:
        break;
    }
  };

  //Product functions

  const myCallbackUnit = (dataFromChild: any) => {
    //   console.log('Selected unit', JSON.stringify(dataFromChild))
    setSelectedUnit(dataFromChild);
    setMyFilterUnit(dataFromChild);
  };

  // skue barcode labeldan geleni direkt ekle

  const getProductById = (productId: any) => {
    dataProvider
      .getOne("products", { id: productId })
      .then((response) => {
        if (typeof response.data.id !== "undefined") {
          //veri düzgün geldi
          setProduct(response.data);
        } else {
          // Veri yok
          // console.log('response length = 0')
        }
      })
      .catch((error) => {
        notify(`Get error: ${error.message}`, "warning");
      });
  };

  useEffect(() => {
    dataProvider
      .getList("labelsubmissions", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "title", order: "ASC" },
        filter: {
          storeIdExists: true,
          productIdExists: true,
          priceExists: false,
          isReviewed: false,
        },
      })
      .then(async ({ data }) => {
        if (data.length !== 0) {
          setLabelList(data);
          setImgUrl(
            await firebase
              .storage()
              .refFromURL("gs://zingo-price-labels/labels/")
              .child(data[0].image)
              .getDownloadURL()
          );
          console.log(data[0].productId);
          getProductById(data[0].productId);
          if (typeof data[0].barcode !== "undefined") {
            setProductBarcode(data[0].barcode);
          }
          if (typeof data[0].sku !== "undefined") {
            setProductSku(data[0].sku);
          }
          if (typeof data[0].price !== "undefined") {
            setProductPrice(data[0].price);
          }
          if (typeof data[0].oldPrice !== "undefined") {
            setProductOldPrice(data[0].oldPrice);
          }
          if (typeof data[0].label !== "undefined") {
            setProductLabel(data[0].label);
          }
        } else {
        }

        setLoading(false);
      })
      .catch((error) => {
        //setError(error);
        setLoading(false);
      });
  }, []);

  const handleSkip = () => {
    dataProvider
      .update("labelsubmissions", {
        id: labelList[0].id,
        data: {
          id: labelList[0].id,
          isReviewed: true,
          reviewResult: "SKIPPED",
        },
        previousData: { title: "previous title" } as any,
      })
      .then((resp) => {
        notify("Label Submission Skipped!");
        window.location.reload();
      })
      .catch((err) => {
        notify(`Skip error: ${err}`, "warning");
      });
  };

  //Price discound codes

  //   const clickDropItem = (key: any) => {
  //     switch (key) {
  //       case "nothing":
  //         setSelectDiscountKind(key);
  //         setSelectDiscountName("İndirim Yok");
  //         break;

  //       case "BUY1GET2YOFF":
  //         setSelectDiscountKind(key);
  //         setSelectDiscountName("1 Alana 2");

  //         break;

  //       case "BUYXGETY":
  //         setSelectDiscountKind(key);
  //         setSelectDiscountName("X Alana Y");

  //         break;

  //       case "BUYMINX":
  //         setSelectDiscountKind(key);
  //         setSelectDiscountName("Minumum X adet");

  //         break;

  //       case "ABOVEXTL":
  //         setSelectDiscountKind(key);
  //         setSelectDiscountName("X Lira Üzerine");

  //         break;
  //     }
  //   };

  //   const checkDatePanel = () => {
  //     return (
  //       <div>
  //         {" "}
  //         <FormControlLabel
  //           control={<Checkbox color="primary" />}
  //           label="Başlangıç Tarihi Var mı?"
  //           onClick={() => changeStartDate()}
  //         />
  //         {showStartDatePanel()}
  //         <FormControlLabel
  //           control={<Checkbox color="primary" />}
  //           label="Bitiş Tarihi Var mı?"
  //           onClick={() => changeFinishDate()}
  //         />
  //         {showFinishDatePanel()}
  //       </div>
  //     );
  //   };

  //   const showStartDatePanel = () => {
  //     if (isExistStartDate === true) {
  //       return (
  //         <div>
  //           <div className={"mt-1"}>
  //             Başlangıç &nbsp;
  //             <DatePicker
  //               selected={startDate}
  //               onChange={(date) => setStartDate(date as any)}
  //             />
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div>
  //           {" "}
  //           <br />{" "}
  //         </div>
  //       );
  //     }
  //   };

  //   const showFinishDatePanel = () => {
  //     if (isExistFinishDate === true) {
  //       return (
  //         <div>
  //           <div className={"mt-1"}>
  //             Bitiş &nbsp;
  //             <DatePicker
  //               selected={finishDate}
  //               onChange={(date) => setFinishDate(date as any)}
  //             />
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div>
  //           {" "}
  //           <br />{" "}
  //         </div>
  //       );
  //     }
  //   };

  //   const showDiscountDescription = () => {
  //     return (
  //       <>
  //         <Typography variant="h6" gutterBottom>
  //           Discount Description
  //         </Typography>
  //         <TextareaAutosize
  //           id="discountDescription"
  //           rowsMax={4}
  //           aria-label="maximum height"
  //           placeholder={discountDescription}
  //           onChange={changeDiscountDescription}
  //           style={{ width: 500, height: 100 }}
  //         />
  //       </>
  //     );
  //   };

  //   const showIsCardNeed = () => {
  //     return (
  //       <FormControlLabel
  //         value={cardIsNeeded}
  //         control={<Checkbox color="primary" />}
  //         label="Kart Gerekiyor mu?"
  //         onChange={changeCardNeeded}
  //       />
  //     );
  //   };

  //   const showUnitMeasurement = () => {
  //     if (typeof product.unitOfMeasurement !== "undefined") {
  //       return (
  //         <div>
  //           <Box display="flex">
  //             <Box flex={1} mr="0.5em">
  //               <TextField
  //                 id="filter"
  //                 variant="outlined"
  //                 InputLabelProps={{
  //                   shrink: true,
  //                 }}
  //                 type="number"
  //                 label="Amount"
  //                 onChange={changeLabel}
  //                 defaultValue={product.unitOfMeasurement.amount}
  //                 style={{ width: 500 }}
  //                 autoComplete="off"
  //               />
  //             </Box>
  //             <Box flex={1} mr="0.5em">
  //               <FormGroup>
  //                 <TextField
  //                   id="filter"
  //                   variant="outlined"
  //                   InputLabelProps={{
  //                     shrink: true,
  //                   }}
  //                   label="Unit"
  //                   onChange={changeLabel}
  //                   defaultValue={product.unitOfMeasurement.unit}
  //                   style={{ width: 500 }}
  //                   autoComplete="off"
  //                 />
  //                 <UnitSearch
  //                   callbackFromParent={myCallbackUnit}
  //                   searchKey={myFilterUnit}
  //                 />
  //               </FormGroup>
  //             </Box>
  //             <Box flex={1} mr="0.5em"></Box>
  //           </Box>
  //           <Box mt="1em" />
  //           <Box display="flex">
  //             <Box flex={1} mr="0.5em">
  //               <TextField
  //                 id="filter"
  //                 variant="outlined"
  //                 InputLabelProps={{
  //                   shrink: true,
  //                 }}
  //                 InputProps={{
  //                   readOnly: true,
  //                 }}
  //                 type="number"
  //                 label="Amount"
  //                 defaultValue={unitPrice}
  //                 style={{ width: 500 }}
  //                 autoComplete="off"
  //               />
  //             </Box>
  //             <Box flex={1} mr="0.5em">
  //               <FormGroup>
  //                 <TextField
  //                   type="search"
  //                   id="filter"
  //                   variant="outlined"
  //                   InputLabelProps={{
  //                     shrink: true,
  //                   }}
  //                   label="UnitPrice"
  //                   onChange={handleUnitPriceUnitChange}
  //                   defaultValue={myFilterUnitPriceUnit}
  //                   style={{ width: 500 }}
  //                   autoComplete="off"
  //                 />
  //                 <UnitSearch
  //                   callbackFromParent={myCallbackUnitPrice}
  //                   searchKey={myFilterUnitPriceUnit}
  //                 />
  //               </FormGroup>
  //             </Box>
  //             <Box flex={1} mr="0.5em"></Box>
  //           </Box>
  //           <Box mt="1em" />
  //         </div>
  //       );
  //     }
  //   };

  // const showDiscount = (key: any) => {

  //     switch (key) {

  //         case "nothing":
  //             return (
  //                 <div>
  //                     <Box mt="1em" /> İndirim seçilmedi!
  //                 </div>
  //             )

  //         case "BUY1GET2YOFF":
  //             return (<div className={"mt-2"}>
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountPercent"
  //                     label="%"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountPercent}
  //                     value={discountPercent}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountPrice"
  //                     label="Discount Price"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountPrice}
  //                     value={discountPrice}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 {showDiscountDescription()}
  //                 <Box mt="1em" />
  //                 {showIsCardNeed()}
  //                 {checkDatePanel()}

  //             </div>)

  //         case "BUYXGETY":
  //             return (<div>
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountX"
  //                     label="X Alana"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountX}
  //                     value={discountX}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountY"
  //                     label="Y Bedava"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountY}
  //                     value={discountY}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />

  //                 {showDiscountDescription()}
  //                 <Box mt="1em" />
  //                 {showIsCardNeed()}
  //                 {checkDatePanel()}

  //             </div>)

  //         case "BUYMINX":
  //             return (<div>
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountMin"
  //                     label="Buy Minumum"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountMin}
  //                     value={discountMin}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountPrice"
  //                     label="Discount Price"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountPrice}
  //                     value={discountPrice}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 {showDiscountDescription()}
  //                 <Box mt="1em" />
  //                 {showIsCardNeed()}
  //                 {checkDatePanel()}

  //             </div>)

  //         case "ABOVEXTL":
  //             return (<div>
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountPrice"
  //                     label="X TL Uzeri"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     value={discountPrice}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 <TextField
  //                     id="discountPrice"
  //                     label="Discount Price"
  //                     type="number"
  //                     InputLabelProps={{
  //                         shrink: true,
  //                     }}
  //                     variant="outlined"
  //                     onChange={changeDiscountPrice}
  //                     value={discountPrice}
  //                     style={{ width: 500 }}
  //                 />
  //                 <Box mt="1em" />
  //                 {showDiscountDescription()}
  //                 <Box mt="1em" />

  //                 {showIsCardNeed()}
  //                 {checkDatePanel()}

  //             </div>)

  //     }
  // }

  const emtpyPage = () => {
    if (labelList.length === 0) {
      return (
        <div>
          <Alert color="info">Price Submission'da ürün bulunmamaktadır!</Alert>
        </div>
      );
    } else {
      return (
        <FormWithRedirect
          {...props}
          render={(formProps) => (
            <form>
              <Card>
                <Box p="1em">
                  <Box display="flex">
                    <Box flex={2} mr="1em">
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <Alert
                            color="info"
                            style={{ marginLeft: "35%", width: "32em" }}
                          >
                            Gelen Etiket
                          </Alert>
                        </Box>
                        {/* <Box flex={1} mr="0.5em">
                          <Alert color="info" style={{ width: 440 }}>
                            Cropper
                          </Alert>
                        </Box> */}
                      </Box>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box
                          flex={1}
                          mr="0.5em"
                          style={{
                            marginLeft: "35em",
                          }}
                        >
                          <ReactImageZoom
                            width={400}
                            height={200}
                            style={{
                              borderRadius: 5,
                            }}
                            img={imgUrl}
                            zoomWidth={2000}
                            zoomPosition="original"
                          ></ReactImageZoom>
                          {/* <img
                            alt={"image"}
                            src={imgUrl}
                            className="d-block h-80"
                            style={{ marginLeft: "35%", width: "30%" }}
                          /> */}
                        </Box>
                        {/* <Box flex={1} mr="0.5em">
                          <LabelCropper
                            defaultLabel={imgUrl}
                            productId={product.id}
                          ></LabelCropper>
                        </Box> */}
                      </Box>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Alert
                          severity="success"
                          color="info"
                          style={{ width: 470 }}
                        >
                          İşlenen Veriler
                        </Alert>
                      </Box>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productName"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Name"
                            onChange={changeName}
                            value={product.name}
                            style={{ width: 500 }}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productBarcode"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Barcode"
                            onChange={changeBarcode}
                            defaultValue={productBarcode}
                            style={{ width: 500 }}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productSKU"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="SKU"
                            onChange={changeSku}
                            defaultValue={productSku}
                            style={{ width: 500 }}
                          />
                        </Box>
                      </Box>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productPrice"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Price"
                            onChange={changePrice}
                            defaultValue={product.price}
                            style={{ width: 500 }}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productOldPrice"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="OldPrice"
                            onChange={changeOldPrice}
                            defaultValue={productOldPrice}
                            style={{ width: 500 }}
                          />
                        </Box>
                        <Box flex={1} mr="0.5em">
                          <TextField
                            id="productLabel"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Label"
                            onChange={changeLabel}
                            defaultValue={productLabel}
                            style={{ width: 500 }}
                          />
                        </Box>
                      </Box>
                      <Box mt="1em" />
                      {/* {showUnitMeasurement()}
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Campaign
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Campaign"
                          style={{ width: 500 }}
                        >
                          <MenuItem
                            value={"İndirim Yok"}
                            onClick={() => clickDropItem("NODISCOUNT")}
                          >
                            İndirim Yok
                          </MenuItem>
                          <MenuItem
                            value={"1 Alana 2."}
                            onClick={() => clickDropItem("BUY1GET2YOFF")}
                          >
                            1 Alana 2.
                          </MenuItem>
                          <MenuItem
                            value={"X Alana Y"}
                            onClick={() => clickDropItem("BUYXGETY")}
                          >
                            X Alana Y
                          </MenuItem>
                          <MenuItem
                            value={"Minumum X Adet"}
                            onClick={() => clickDropItem("BUYMINX")}
                          >
                            Minumum X Adet
                          </MenuItem>
                          <MenuItem
                            value={"X lira üzerine"}
                            onClick={() => clickDropItem("ABOVEXTL")}
                          >
                            X lira üzerine
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {showDiscount(selectDiscountKind)} */}
                      <Box mt="1em" />
                      <AddPrice
                        labelText={productLabel}
                        sku={productSku}
                        barcode={productBarcode}
                        price={Number(productPrice)}
                        oldPrice={Number(productOldPrice)}
                        label={labelList[0]}
                        discountX={Number(discountX)}
                        discountY={Number(discountY)}
                        discountDescription={discountDescription}
                        cardIsNeeded={cardIsNeeded}
                        discountPercent={Number(discountPercent)}
                        discountMin={Number(discountMin)}
                        discountPrice={Number(discountPrice)}
                        isExistStartDate={isExistStartDate}
                        isExistFinishDate={isExistFinishDate}
                        startDate={startDate}
                        finishDate={finishDate}
                        selectDiscountKind={selectDiscountKind}
                        product={product}
                        selectedUnit={selectedUnit}
                        productAmount={Number(productAmount)}
                        unitPriceUnit={unitPriceUnit}
                        unitPrice={Number(unitPrice)}
                        isSelectedAnyUnit={isSelectedAnyUnit}
                      />
                      <MismatchButton labelId={labelList[0].id} />
                      <Button
                        color="primary"
                        className={"mt-2 mx-3"}
                        onClick={handleSkip}
                        disabled={loading}
                        endIcon={<SkipIcon />}
                        size="large"
                      >
                        Skip
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </form>
          )}
        />
      );
    }
  };

  if (loading) return <Loading />;
  // if (error) return <Error />;
  if (!labelList) return null;

  return <div>{emtpyPage()}</div>;
};
