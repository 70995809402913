import React, { useEffect, useState } from 'react';
import { Box, Button, } from '@material-ui/core';
import { Loading, number, useDataProvider } from 'react-admin';
import PriceTagCropper from './PriceTagCropper';

const defaultSrc ='https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg';
const PriceTag = (props:any) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [error, setError] = useState();
    const [rotateNumber, setRotateNumber] = useState<number>(0);
    const [check,setCheck] = useState<boolean>(false);
    const [priceTagList, setPriceTagList] = useState<any>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [refresh,setRefresh] = useState<boolean>(false);
    const [resolution,setResolution] = useState<any>({
        width: number,
        height: number
    })

    useEffect(() => {
        dataProvider.getList('pricetags', {
            filter: { isReviewed: false},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                console.log('resolution',data[0].imageResolution)
                setPriceTagList(data);
                setSelectedProduct(data[0]);
                setLoading(false);
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    },[]);
    const handleApprove = () => {
        handleSaveApprove();
        
        
    }
    const handleDecline = () => {
        handleSaveDecline();
    }
    const handleSkip = () => {
        setSelectedIndex(checkLength());
        setSelectedProduct(priceTagList[checkLength()]);
        setRefresh(!refresh)
    }
    const handleSaveDecline = () => {
        dataProvider.update('pricetags', { id: selectedProduct.id, data: { id: selectedProduct.id, isReviewed:true, isApproved:false} } as any)
            .then((data:any ) => {
                        setSelectedIndex(checkLength());
                        setSelectedProduct(priceTagList[checkLength()]);
                        setLoading(false);
            })
            .catch(error => {
                        setError(error);
                        setLoading(false);
            })
            setRefresh(!refresh)
    }
    const checkLength = () => {
        if(priceTagList.length > selectedIndex+1){
            return selectedIndex+1;
        }else{
            setCheck(true);
            return selectedIndex;
        }
    }
    const handleSaveApprove = async () => {
        dataProvider.update('pricetags', { id: selectedProduct.id, data: { id: selectedProduct.id, isReviewed:true, isApproved:true, productName: selectedProduct.productName, sku: selectedProduct.sku,
        price: selectedProduct.price, unitPrice: selectedProduct.unitPrice , barcode: selectedProduct.barcode , priceChangeDate: selectedProduct.priceChangeDate, countryOfOrigin: selectedProduct.countryOfOrigin} } as any)
        .then((data:any ) => {
                    setSelectedIndex(checkLength());
                    setSelectedProduct(priceTagList[checkLength()]);
                    setLoading(false);
        })
        .catch(error => {
                    setError(error);
                    setLoading(false);
        })
        setRefresh(!refresh)
        setRotateNumber(0);
    }
    const sendCheck = () => {
        return check;
    }
    const myCallbackProductName = (dataFromChild:any) => {
        selectedProduct.productName = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                      coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackSku = (dataFromChild:any) => {
        selectedProduct.sku = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                              coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackBarcode = (dataFromChild:any) => {
        selectedProduct.barcode = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                  coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackUnitPrice = (dataFromChild:any) => {
        selectedProduct.unitPrice = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                    coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackPrice = (dataFromChild:any) => {
        selectedProduct.price = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackPriceChangeDate = (dataFromChild:any) => {
        selectedProduct.priceChangeDate = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                          coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackCountryOfOrigin = (dataFromChild:any) => {
        console.log(dataFromChild)
        selectedProduct.countryOfOrigin = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                          coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackUnitOfMeasurement = (dataFromChild:any) => {
        selectedProduct.unitOfMeasurement = {points:{ left: dataFromChild.left, top: dataFromChild.top, right: dataFromChild.right, bottom: dataFromChild.bottom },
                                          coordinates:{ x1: dataFromChild.x1, x2: dataFromChild.x2, y1: dataFromChild.y1, y2: dataFromChild.y2 }}
    }
    const myCallbackRotate = (dataFromChild:any) => {
        setRotateNumber(dataFromChild);
    }
    const myCallbackResolution = () => {
        return selectedProduct.imageResolution;
    }
    const myCallbackImageUrl = () => {
        if(selectedProduct.imageUrl)
            return selectedProduct.imageUrl;
        else 
            return "https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg";
  
    }

    if (loading) return <Loading />;
    if (!priceTagList) return null;
    return (
        
            <div>
                <PriceTagCropper callbackProductName={myCallbackProductName} callbackSku={myCallbackSku} callbackBarcode={myCallbackBarcode} callbackUnitPrice={myCallbackUnitPrice} 
                callbackPrice={myCallbackPrice} callbackPriceChangeDate={myCallbackPriceChangeDate} callbackCountryOfOrigin={myCallbackCountryOfOrigin} callbackResolution={myCallbackResolution()} callbackRotate={myCallbackRotate} imageUrl={myCallbackImageUrl()} check={sendCheck()} clear={refresh}/>
                <Button style={{marginRight:'10px',backgroundColor:'#4DFF42',marginTop:'10px'}} onClick={() => handleApprove()}disabled={check} >APPROVE</Button>
                <Button style={{marginRight:'10px',backgroundColor:'#FF0000',marginTop:'10px'}} onClick={() => handleDecline()}disabled={check} >DECLINE</Button>
                <Button style={{marginRight:'10px',backgroundColor:'#CCCCCC',marginTop:'10px'}} onClick={() => handleSkip()}disabled={check} >SKIP</Button>
                
            </div>
            
        
    )
}
export default PriceTag;