import CategoryIcon from '@material-ui/icons/Category';

import CategoryList from './CategoryList';
import CategoryEdit from './CategoryEdit';
import CategoryFilter from './CategoryFilter';

export default {
    list: CategoryList,
    filter: CategoryFilter,
    show: CategoryEdit,
    icon: CategoryIcon,
};
