import {
  Button,
  Card,
  Grid,
  makeStyles,
  CardMedia,
  CardContent,
  Typography,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Loading, useDataProvider, useNotify } from "react-admin";
import mergesubmissionsreview from "./MergeSubmissionsReview";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  cardTop: {
    padding: theme.spacing(2),
    width: "auto",
    textAlign: "center",
    fontSize: "2rem",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  button: {
    width: 512,
    height: 128,
  },
  root: {
    display: "flex",
    width: "100%"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%"

  },
  content: {
    flex: "1 0 auto",
    width: "90%"
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export const ActionCard = (props: any) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [mergeSubmission, setMergeSubmission] = useState<any>();
  const notify = useNotify();
  const [productIds, setProductIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string>()

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("mergesubmissions", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "title", order: "ASC" },
        filter: { isReviewed: false },
      })
      .then((resp) => {
        if (resp.data && resp.data[0]) {
          setSelectedName(resp.data[0].selectedName)
          setMergeSubmission(resp.data[0]);
          props.setMergeSubmission(resp.data[0])
          props.setProductIds(resp.data[0].productIds);
          props.setSelectedImages();
          setProductIds(resp.data[0].productIds);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        props.setSelectedImages()
        notify("Merge Submission error" + err.message, "error");
      });
  }, [refresh]);

  useEffect(() => {
    console.log(props.clickedProduct)
    let array = [...productIds];
    if (array.includes(props.clickedProduct?.id)) {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === props.clickedProduct?.id) {
          array.splice(i, 1);
        }
      }
    }
    else {
      array.push(props.clickedProduct);
    }
    console.log(array)
    setProductIds(array);
  }, [props.clickedProduct])

  useEffect(() => { }, [mergeSubmission]);

  useEffect(() => {
    if (props.selectedProductName) setSelectedName(props.selectedProductName)
  }, [props])

  const handleDecline = () => {
    dataProvider
      .update("mergesubmissions", {
        id: mergeSubmission.id,
        data: { id: mergeSubmission.id, isReviewed: true },
        previousData: { title: "previous title" } as any,
      })
      .then((_) => {
        dataProvider
          .updateMany("products", {
            ids: productIds,
            data: {
              isMergeReviewed: false,
            }
          }).then((_) => {
            notify("Declined!");
            props.setProductIds([]);
            setSelectedName("");
            setMergeSubmission("");
            setRefresh(!refresh);
            props.setRefresh(!refresh)
          }).catch(err => {
            window.location.reload()
          })
      })
      .catch((err) => {
        setSelectedName("");
        props.setSelectedImages();
        notify("Hata!");
      });
  };

  const handleSend = () => {
    console.log(selectedName)
    dataProvider
      .update("products", {
        id: productIds[productIds.length - 1],
        data: {
          id: productIds[productIds.length - 1],
          mergeProductIds: productIds,
          needNameMerge: true,
          name: selectedName,
          images: props.selectedImages ? props.selectedImages : mergeSubmission.selectedImages,
          category: mergeSubmission.selectedCategory,
          brand: mergeSubmission.selectedBrand,
        },
        previousData: { title: "previous title" } as any,
      })
      .then((_) => {
        dataProvider
          .update("mergesubmissions", {
            id: mergeSubmission.id,
            data: { id: mergeSubmission.id, isReviewed: true },
            previousData: { title: "previous title" } as any,
          })
          .then((_) => {
            notify("Product merge is completed");
            props.setProductIds([]);
            setMergeSubmission("");
            setRefresh(!refresh);
            props.setRefresh(!refresh)
            setSelectedName("");
            props.setSelectedImages();
          });
      })
      .catch((err) => {
        notify("Hata!");
        setSelectedName("");
        props.setSelectedImages();
      });
  };

  if (mergeSubmission) {
    return (
      <Grid item xs={12}>
        <Card className={classes.cardTop}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleDecline}
          >
            {" "}
            Reddet{" "}
          </Button>
          <Card className={classes.root}>
            <CardMedia
              className={classes.cover}
              image={
                props.selectedImages && props.selectedImages[0] ? props.selectedImages[0].url :
                mergeSubmission && mergeSubmission.selectedImages[0]
                  ? mergeSubmission.selectedImages[0].url
                  : ""
              }
              title="Selected Image"
            />
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <TextField
                  id="name"
                  label="İsim"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={selectedName}
                  fullWidth
                  onChange={(e: any) => { setSelectedName(e.target.value) }}
                />
                <Typography variant="subtitle1" color="primary">
                  {mergeSubmission.isAutoGenerated
                    ? "Bu submission otomatik olarak oluşturulmuştur!"
                    : ""}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {mergeSubmission.selectedBrand
                    ? mergeSubmission.selectedBrand
                    : ""}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {mergeSubmission.selectedCategory
                    ? mergeSubmission.selectedCategory
                    : ""}
                </Typography>
              </CardContent>
              <div className={classes.controls}></div>
            </div>
          </Card>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleSend}
          >
            {" "}
            Onayla{" "}
          </Button>
        </Card>
      </Grid>
    );
  }

  return (
    <div>
      <Alert color="info">Merge Submission bulunmamaktadır!</Alert>
    </div>
  );
};

export default ActionCard;
