import { useEffect } from "react";
import { Admin, Resource, DataProvider } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Layout } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";
import cities from "./cities";
import towns from "./towns";
import districts from "./districts";
import neighborhoods from "./neighborhoods";
import brands from "./brands";
import stores from "./stores";
import storelocations from "./storelocations";
import products from "./productszingo";
import productidentifiers from "./productidentifiers";
import categories from "./categories";
import comments from "./comments";
import mappings from "./mappings";
import MappingEdit from "./mappings/MappingEdit";
import CommentEdit from "./comments/CommentEdit";
import mappingnames from "./mappingnames";
import posttemplates from "./posttemplates";
import ProductZingoEdit from "./productszingo/ProductZingoEdit";
import PostTemplateEdit from "./posttemplates/PostTemplateEdit";
import { PostTemplateCreate } from "./posttemplates/PostTemplateList";
import ServerTools from "./servertools/ServerTools";
import labelsets from "./labelsets";
import LabelSetEdit from "./labelsets/LabelSetEdit";
import MergeBarcodes from "./mergebarcodes/MergeBarcodes";
import MergeProducts from "./mergeproducts/MergeProducts";
import MergeSibling from "./mergesiblings/MergeSibling";
import MergeSimilar from "./mergesimilars/MergeSimilar";
import CommentSubmission from "./commentsubmission/CommentSubmission";
import { CampaignSubmissionContainer } from "./campaignsubmissions/CampaignSubmissionContainer";
import MergeBrands from "./mergebrands/MergeBrands";
import { MapContainer } from "./storesubmissions/MapContainer";
import { ProductSubmission } from "./productsubmission/ProductSubmission";
import { PriceSubmission } from "./pricesubmissions/PriceSubmission";
import { DeclinedProducts } from "./declinedproducts/DeclinedProducts";
import CategoryMerger from "./categorymerger/CategoryMerger";
import { LabelProvider } from "./labelsample/LabelProvider";
import PostSubmissions from "./newpostsubmissions/PostSubmissions";
import Linker from "./categorylinker/Linker";
import MergeProductsByName from "./mergeproductbyname/MergeProductsByName";
import DeMerge from "./demerge/DeMerge";
import jobactivities from "./jobactivities";
import MergeSubmissionsReview from "./mergesubmissionsreview/MergeSubmissionsReview";
import AddStoresToCity from "./addStoresToCity/AddStoresToCity";
import { StoreCreate } from "./stores/StoreList";
import ScrapperResult from "./scrapperresults/ScrappperResult";
import AssignBarcodes from "./assignBarcodes/AssignBarcodes";
import JobActivities from "./jobactivities/JobActivities";
import BarcodeSubmissions from "./barcodeSubmission/BarcodeSubmission";
import BarcodeAliases from "./barcodealiases/BarcodeAliases";
import ProductIdentifiersShow from "./productidentifiers/ProductIdentifiersShow";
import AddStoresToTown from "./addStoresToTown/AddStoresToTown";
import DuplicateBarcodes from "./duplicateBarcodes/duplicateBarcodes";
import UnitOfMeasurement from "./unitOfMeasurement/unitOfMeasurement";
import FindBarcode from "./findBarcode/FindBarcode";
import BarcodeList from "./barcodes/BarcodeList";
import BarcodeEdit from "./barcodes/BarcodeEdit";
import scraperesults from "./scraperesults";
import LabelSubmission from "./labelsubmissions/LabelSubmission";
import NewProductSubmission from "./newproductsubmissions/NewProductSubmission";
import PriceTag from "./pricetags/PriceTag";
import LabelSubmissionCropReview from "./labelSubmissionCropReview/LabelSubmission";
const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }
  return englishMessages;
}, "en");
interface AppProps {
  onUnmount: () => void;
  dataProvider: DataProvider;
}
const App = ({ onUnmount, dataProvider }: AppProps) => {
  useEffect(() => onUnmount, [onUnmount]);
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={Layout}
      disableTelemetry
    >
      <Resource name="cities" {...cities} />
      <Resource name="towns" {...towns} />
      <Resource name="districts" {...districts} />
      <Resource name="neighborhoods" {...neighborhoods} />
      <Resource name="brands" {...brands} />
      <Resource name="scraperesults" {...scraperesults} />
      <Resource name="stores" create={StoreCreate} {...stores} />
      <Resource name="mappingnames" {...mappingnames} />
      <Resource name="storelocations" {...storelocations} />
      <Resource name="products" edit={ProductZingoEdit} {...products} />
      <Resource name="productidentifiers" edit={ProductIdentifiersShow} {...productidentifiers} />
      <Resource name="categories" {...categories} />
      <Resource name="comments" edit={CommentEdit} {...comments} />
      <Resource name="mappings" edit={MappingEdit} {...mappings} />
      <Resource name="labelsubmissions" list={LabelSubmission} />
      <Resource name="labelsubmissionscropreview" list={LabelSubmissionCropReview} />
      <Resource name="newproductsubmissions" list={NewProductSubmission} />
      <Resource name="pricetags" list={PriceTag} />
      <Resource name="jobactivities" {...jobactivities} list={JobActivities}  />
      <Resource
        name="posttemplates"
        edit={PostTemplateEdit}
        create={PostTemplateCreate}
        {...posttemplates}
      />
      <Resource name="servertools" list={ServerTools} />
      <Resource name="labelsets" {...labelsets} edit={LabelSetEdit} />
      <Resource name="mergeproducts" list={MergeProducts} />
      <Resource name="mergeproductsbyname" list={MergeProductsByName} />
      <Resource name="mergesiblings" list={MergeSibling} />
      <Resource name="mergesimilars" list={MergeSimilar} />
      <Resource name="mergebrands" list={MergeBrands} />
      <Resource name="commentsubmissions" list={CommentSubmission} />
      <Resource name="campaignsubmissions" list={CampaignSubmissionContainer} />
      <Resource name="storesubmissions" list={MapContainer} />
      <Resource name="productsubmissions" list={ProductSubmission} />
      <Resource name="pricesubmissions" list={PriceSubmission} />
      <Resource name="declinedproducts" list={DeclinedProducts as any} />
      <Resource name="postsubmissions" list={PostSubmissions as any} />
      <Resource name="categorymergers" list={CategoryMerger} />
      <Resource name="labelsamples" list={LabelProvider} />
      <Resource name="linkcategories" list={Linker} />
      <Resource name="demerges" list={DeMerge} />
      <Resource name="mergesubmissionsreview" list={MergeSubmissionsReview} />
      <Resource name="addStoresToCity" list={AddStoresToCity} />
      <Resource name="addStoresToTown" list={AddStoresToTown} />
      <Resource name="scrapperresults" list={ScrapperResult} />
      <Resource name="assignBarcodes" list={AssignBarcodes} />
      <Resource name="barcodeSubmissions" list={BarcodeSubmissions} />
      <Resource name="barcodealiases" list={BarcodeAliases}></Resource>
      <Resource name="duplicateBarcodes" list={DuplicateBarcodes}></Resource>
      <Resource name="unitOfMeasurement" list={UnitOfMeasurement}></Resource>
      <Resource name="findBarcode" list={FindBarcode}></Resource>
      <Resource name="barcodes" list={BarcodeList} edit={BarcodeEdit} ></Resource>
    </Admin>
  );
};
export default App;