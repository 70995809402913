import { List, ListItem, ListItemText } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import * as React from 'react'

export const BrandSearch = ({ callbackFromParent, searchKey }: { callbackFromParent: any, searchKey: any }) => {

    const dataProvider = useDataProvider();
    const [brandList, setBrandList] = useState<any[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [hide, setHide] = useState(Boolean);

    useEffect(() => {

        dataProvider.getList('brands', {
            filter: { q: searchKey },
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
        })
            .then(({ data }) => {
                if (typeof data !== 'undefined') {
                    setBrandList(data);
                    setHide(false)
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [searchKey]);


    const selectData = (item: any) => {
        //  console.log('secilen', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item);
        setHide(true)
    }


    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (hide) return null;
    if (searchKey.length < 2) return null;
    if (!brandList) return null;

    // const textMiddle=(value)=>{
    //   if(selectedItem){
    //   return <div>{selectedItem.name}</div>
    //   }
    //   else {
    //     return <div>{value}</div>
    //   }
    // }

    return (

        <List dense={true} style={{ fontSize: "10px" }} >
            {brandList.map((item, index) => (
                <ListItem
                    key={item.id}
                    button
                    onClick={() => selectData(item)}
                >

                    <ListItemText
                        primary={item.name}
                    />
                </ListItem>
            ))}
        </List>

    )
};

