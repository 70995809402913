import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, Loading, translate, TextInput, FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Box, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    paper: {
        width: '30%',
        padding: theme.spacing(2),
        marginTop: '20px',
        marginLeft: '30%',
        backgroundColor: "#F8F8F8",
        display: "inline-block",

    },
    rootcard: {
        flex: 1,

    },
    header: {
        justifyContent: 'space-around',

    },
    textField: {
        width: 500,
        marginLeft: "1%",
        marginTop: "1%"
    },
}));

const CommentSubmission = (props: any) => {
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [comment, setComment] = useState<any>();
    const [noFound, setNoFound] = useState(false);

    useEffect(() => {
        dataProvider.getList('comments', {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
            filter: { isReviewed: false }
        }).then((resp) => {
            if (resp.data.length == 0) {
                setNoFound(true)
            } else {
                setComment(resp.data[0])
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [])

    const handleApprove = (prop: any) => {
        dataProvider.update('comments', { id: comment.id, data: { id: comment.id, isApproved: true }, previousData: { title: "previous title" } as any }).then((resp) => {
            notify('Comment approved')
            window.location.reload();
        }).catch((err) => {
            notify('Comment approve error: ' + err, 'warning')
        })
    }

    const handleDecline = (prop: any) => {
        dataProvider.update('comments', { id: comment.id, data: { id: comment.id, isDenied: true, isReviewed: true }, previousData: { title: "previous title" } as any }).then((resp) => {
            notify('Comment denied')
            window.location.reload();
        }).catch((err) => {
            notify('Comment deny error: ' + err, 'warning')
        })
    }


    if (comment) {
        return (<FormWithRedirect
            {...props}
            render={formProps => (
                <form>
                    <Card className={classes.rootcard}>
                        <CardHeader title="Comments" className={classes.header} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    id="outlined-read-only-input"
                                    label="Comment"
                                    defaultValue={comment.comment}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"

                                />

                                <TextField
                                    className={classes.textField}
                                    id="outlined-read-only-input"
                                    label="Name"
                                    defaultValue={comment.firstName + " " + comment.lastName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: 500 }}
                                />

                                <TextField
                                    className={classes.textField}
                                    id="outlined-read-only-input"
                                    label="Date"
                                    defaultValue={comment.date}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: 500 }}
                                />

                                <TextField
                                    className={classes.textField}
                                    id="outlined-read-only-input"
                                    label="TargetId"
                                    defaultValue={comment.targetType.toUpperCase() + " " + comment.targetId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    style={{ width: 500 }}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <IconButton aria-label="delete" onClick={handleDecline} style={{ float: 'left', marginLeft: '50px' }}>
                                    <DeleteIcon fontSize="large" />
                                </IconButton>
                                <IconButton aria-label="done" onClick={handleApprove} style={{ float: 'right', marginRight: '50px' }}>
                                    <DoneIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                        </Grid>

                    </Card >

                </form >

            )}
        />
        );
    } else if (noFound) {
        return <div style={{ display: "inline-flex", justifyContent: "center", backgroundColor: "#FF1212" }}><h2>Comment Submission'da comment bulunmamaktadır!</h2></div>
    }
    else {
        return <Loading></Loading>
    }
}

export default CommentSubmission;