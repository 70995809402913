import "./Dashboard.css";
import MainChart from "./MainChart";
import { StatisticArea } from "./IstatistikArea";
import StoreIcon from "@material-ui/icons/Store";
import AddBoxIcon from "@material-ui/icons/AddBox";
import LabelOffIcon from "@material-ui/icons/LabelOff";
import { CardHeader, Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import "firebase/firestore";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDataProvider, Loading } from "react-admin";
import React, { useEffect, useState } from "react";
import axios from "axios"
import Cookies from "js-cookie"

export default () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },
    })
  );
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [mergeSubmissionsCount, setMergeSubmissionsCount] = useState<Number>();
  const [homePagePostCount, setHomePagePostCount] = useState<Number>();
  const [commentCount, setCommentCount] = useState<Number>();
  const [pricesData, setPricesData] = useState<any[]>();
  const [barcodesData, setBarcodesData] = useState<any[]>();

  useEffect(() => {
    dataProvider
      .getList("mergesubmissions", {
        sort: { field: "isReviewed", order: "ASC" },
        filter: { isReviewed: false },
        pagination: { page: 1, perPage: 1 },
      })
      .then((response) => {
        setMergeSubmissionsCount(response.total);
      })
      .catch((err) => console.log(err));

    dataProvider
      .getList("homepageposts", {
        sort: { field: "title", order: "ASC" },
        filter: {},
        pagination: { page: 1, perPage: 1 },
      })
      .then((response) => {
        setHomePagePostCount(response.total);
      }).catch(err => { console.log(err) })

    dataProvider
      .getList("comments", {
        sort: { field: "title", order: "ASC" },
        filter: { isReviewed: false },
        pagination: { page: 1, perPage: 1 },
      })
      .then((response) => {
        setCommentCount(response.total);
      })
      .catch((err) => console.log(err));

    axios({
      method: 'post',
      url: process.env.REACT_APP_ZINGO_SERVER + "/products/all-barcodes-ratio",
      headers: { "Authorization": "Bearer " + Cookies.get("accessToken") },
    }).then(res => {
      setBarcodesData(res.data)
    }
    ).catch(
      (err) =>
        alert(err)
    )
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={10}>
          <Card
            style={{
              backgroundColor: "#ffffff",
              display: "inline-block",
              width: "90%",
              alignItems: "flex",
              maxHeight: "100%",
              margin: "2%"
            }}
          >
            <CardContent>
              {(barcodesData) ? <MainChart barcodesData={barcodesData}></MainChart> : <Loading></Loading>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>

          <CardContent>
            <StatisticArea
              icon={
                <AddBoxIcon
                  style={{ color: "#800080", width: "15%", height: "15%" }}
                  className={"cardIcon"}
                />
              }
              title={"Pending Merges"}
              totalNumber={mergeSubmissionsCount}
              basepath={"mergesubmissionsreview"}
            />
            <StatisticArea
              icon={
                <LabelOffIcon
                  style={{ color: "#800080", width: "15%", height: "15%" }}
                  className={"cardIcon"}
                />
              }
              title={"Home Page Post"}
              totalNumber={homePagePostCount}
              basepath={"postsubmissions"}
            />
            <StatisticArea
              icon={
                <LabelOffIcon
                  style={{ color: "#800080", width: "15%", height: "15%" }}
                  className={"cardIcon"}
                />
              }
              title={"Pending Comments"}
              totalNumber={commentCount}
              basepath={"comments"}
            />
          </CardContent>

        </Grid>
      </Grid>
    </div>
  );
};
