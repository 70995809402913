import { FC, useEffect, useState } from 'react';
import {
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    required,
    TextInput,
    BooleanInput,
    FormWithRedirect,
    Toolbar,
    useTranslate,
    useDataProvider,

} from 'react-admin';
import { Box, Card, CardContent, Typography, Link } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';


const useStyles = makeStyles({
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '50em',
        display: 'block',
    },
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
});
const ProductZingoEdit: FC<EditProps> = props => {
    const transform = (data: any) => ({
        id: data.id,
        name: data.name,
        brand: data.brand,
        slug: data.slug,
        barcode: data.barcode,
        isParent: data.isParent,
        isDeclined: data.isDeclined,
        isBarcodeReviewed: data.isBarcodeReviewed,
        isMergeReviewed: data.isMergeReviewed,
        isSiblingReviewed: data.isSiblingReviewed,
    });
    return (
        <Edit

            component="div"
            {...props}
            transform={transform}
        >
            <ProductZingoForm />
        </Edit>
    );
};


const ProductZingoForm = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [product, setProduct] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedCategoryId, setSelectedCategoryId] = useState<any>();



    useEffect(() => {
        dataProvider.getOne('products', { id: props.record.id })
            .then(({ data }) => {
                setProduct(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })

    }, []);

    useEffect (() => {
        
    },[props]);

    const myCallbackCategory = (dataFromChild: any) => {

        setSelectedCategoryId(dataFromChild);
        props.record.category=dataFromChild;
    }


    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Name and Barcode'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="name" validate={requiredValidate} fullWidth />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="barcode" fullWidth />
                                           
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <ImageField source="images[0].url" title="title" />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                             {/* <Typography variant="h6" gutterBottom>
                                       category : { selectedCategoryId ? selectedCategoryId.categoryName :  props.record.category } 
                                    </Typography> */}
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Brand'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="brand" validate={requiredValidate} fullWidth />
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />

                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Unit Of Measurement'
                                        )}
                                    </Typography>
                                    <NumberInput source="unitOfMeasurement.amount" fullWidth multiline />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput source="unitOfMeasurement.unit" fullWidth choices={[
                                                { id: Unit.KG, name: "KG" },
                                                { id: Unit.G, name: "G" },
                                                { id: Unit.L, name: "L" },
                                                { id: Unit.ML, name: "ML" },
                                                { id: Unit.CL, name: "CL" },
                                                { id: Unit.QTY, name: "Adet" },
                                                { id: Unit.CM, name: "CM" },
                                                { id: Unit.MM, name: "M" },
                                                { id: Unit.SRV, name: "Porsiyon" },
                                            ]} />
                                        </Box>
                                    </Box> */}
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <RichTextInput
                                            source="description"
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Box display={{ xs: 'block', sm: 'flex' }}>

                                    {product?.prices.map((price: any) => (
                                        <Typography variant="h5" component="h2" align="left">
                                            {price && price.url && price.url !== null ? (
                                            <Link
                                            href={
                                                price && price.url && price.url !== null
                                                ? price.url
                                                : ""
                                            }
                                            target="_blank"
                                            >
                                            {price.store} 
                                            </Link>
                                        ) : (
                                            price.store
                                        )}
                                        {` : ${price.price}`}
                                        </Typography>
                                    ))}
                                        
                                        {/* <CategoryInProductSubmission callbackFromParentCategory={myCallbackCategory}  ></CategoryInProductSubmission> */}

                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.customers.fieldGroups.stats'
                                        )}
                                    </Typography>
                                    <div>
                                        <BooleanInput label="isParent" source="isParent" />
                                        <BooleanInput label="isDeclined" source="isDeclined" />
                                        <BooleanInput label="isBarcodeReviewed" source="isBarcodeReviewed" />
                                        <BooleanInput label="isMergeReviewed" source="isMergeReviewed" />
                                        <BooleanInput label="isSiblingReviewed" source="isSiblingReviewed" />
                                    </div>

                                    <ImageInput source="images" label="Related pictures" accept="image/*" placeholder={<p>Drop your file here</p>} >
                                        <ImageField source="src" title="images" />
                                    </ImageInput>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="productszingo"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default ProductZingoEdit;


