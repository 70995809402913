import React, { FC, useEffect, useState } from 'react';
import {
    Edit,
    EditProps,
    NumberInput,
    required,
    TextInput,
    FormWithRedirect,
    Toolbar,
    useTranslate,
    useDataProvider,
    Loading,
} from 'react-admin';
import firebase from "firebase/app";
import 'firebase/storage';
import { Box, Card, CardContent, Typography } from '@material-ui/core';


import { createStyles, makeStyles } from '@material-ui/core/styles';
import LabelSample from './LabelCropper';


const useStyles = makeStyles({
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '50em',
        display: 'block',
    },
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
});
export const LabelSetEdit: FC<EditProps> = props => {
    return (
        <Edit
            component="div"
            {...props}
        >
            <LabelSetForm />
        </Edit>
    );
};
export const LabelSetForm = (props: any) => {
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const translate = useTranslate();
    if (!firebase.apps.length) {
        console.log("abc");
        firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as String);
    }

    const [nameRect, setNameRect] = useState();
    const [priceRect, setPriceRect] = useState();
    const [oldPriceRect, setOldPriceRect] = useState();
    const [barcodeRect, setBarcodeRect] = useState();
    const [skuRect, setSkuRect] = useState();
    const [seedRect, setSeedRect] = useState();
    const [initialRect, setInitialRect] = useState();
    const [labelSet, setLabelSet] = useState<any>();
    const [update, setUpdate] = useState<any>();

    useEffect(() => {
        if (!labelSet) {
            dataProvider.getOne("labelsets", { id: props.record.id }).then(async (resp) => {
                setLabelSet(resp.data);
                setInitialRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_initial`).getDownloadURL())
                setNameRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_name`).getDownloadURL())
                setPriceRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_price`).getDownloadURL())
                setSkuRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_sku`).getDownloadURL())
                setBarcodeRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_barcode`).getDownloadURL())
                setOldPriceRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_oldPrice`).getDownloadURL())
                setSeedRect(await firebase.storage().refFromURL(`gs://zingo-cropped-labels/${resp.data.store}/${resp.data.id}_seed`).getDownloadURL())
            }).catch(err => {
                console.log(err);
            })
        }
        if (update) {
            let updateWithId = update;
            updateWithId.id = labelSet?.id
            dataProvider.update("labelsets", { id: labelSet?.id, data: updateWithId } as any).then(resp => {
                console.log("success");
            }).catch(err => {
                console.log(err);
            })
        }
    }, [labelSet, update, initialRect]);

    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Width and Height'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput source="width" validate={requiredValidate} fullWidth />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput source="height" fullWidth />
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Minimum Height'
                                        )}
                                    </Typography>
                                    <NumberInput source="productNameMinHeight" fullWidth multiline />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={2}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput source="skuMinHeight" fullWidth />

                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <NumberInput source="priceMinHeight" fullWidth />
                                    </Box>
                                    <NumberInput source="oldPriceMinHeight" fullWidth />
                                    <NumberInput source="barcodeMinHeight" fullWidth />
                                    <NumberInput source="seedMinHeight" fullWidth />
                                </Box>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Seed and Store'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="seed" fullWidth />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput source="store" fullWidth />
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'M O E'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <NumberInput source="productNameMOE" fullWidth />
                                            <NumberInput source="skuMOE" fullWidth />
                                            <NumberInput source="priceMOE" fullWidth />
                                            <NumberInput source="oldPriceMOE" fullWidth />
                                            <NumberInput source="barcodeMOE" fullWidth />
                                            <NumberInput source="seedMOE" fullWidth />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <div style={{ display: "inline" }}>
                                <img src={nameRect}></img>
                                <hr></hr>
                                <br></br>
                                <img src={priceRect}></img>
                                <hr></hr>
                                <br></br>
                                <img src={oldPriceRect}></img>
                                <hr></hr>
                                <br></br>
                                <img src={skuRect}></img>
                                <hr></hr>
                                <br></br>
                                <img src={barcodeRect}></img>
                                <hr></hr>
                                <br></br>
                                <img src={seedRect}></img>
                            </div>
                            <div>
                                {<LabelSample setLabelSet={setUpdate} defaultLabel={initialRect}></LabelSample>}
                            </div>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="labelsets"
                        />
                    </form>
                </Card >
            )}
        />
    );
};

const requiredValidate = [required()];

export default LabelSetEdit;


