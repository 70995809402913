import React from 'react';
import { useMutation, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';


export const SuspiciousStoreButton = ({ labelId }: { labelId: any }) => {

    const notify = useNotify();



    const [approve, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'labelsubmissions',
            payload: { id: labelId, data: { id: labelId, isReviewed: true, reviewResult: "SUSPICIOUS" } }
        },
        {
            //undoable: true,
            onSuccess: ({ data }) => {
                notify('İhbar edildi', 'info', {}, true);
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return (<Button variant="outlined" size="large" color="primary" onClick={approve}>
        Report
    </Button>

    );
};


