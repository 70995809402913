import React, { useState, useEffect } from 'react';
import { Loading, Error } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';

export const UnitSearch = ({ callbackFromParent, searchKey }: { callbackFromParent: any, searchKey: any }) => {

    const [unitList, setUnitList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isSelectedBool, setIsSelectedBool] = useState(false);
    const [hide, setHide] = useState(Boolean);
    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        setUnitList([
            { type: "kg", name: "Kilogram" }
            , { type: "g", name: "Gram" }
            , { type: "mg", name: "Miligram" }
            , { type: "l", name: "Litre" }
            , { type: "ml", name: "Mililitre" }
            , { type: "m", name: "Metre" }
            , { type: "cm", name: "Santimetre" }
            , { type: "mm", name: "Milimetre" }
            , { type: "adet", name: "Adet" }
            , { type: "prosiyon", name: "Porsiyon" }] as never);
        setLoading(false)
        setHide(false)

    }, [searchKey]);


    const selectData = (item: any) => {


        //  console.log('secilen unit', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item)
        setIsSelectedBool(true);
        setHide(true)

    }


    if (loading) return <Loading />;
    if (!unitList) return null;
    if (hide) return null;
    // if (selectedItem) return null;
    if (searchKey.length < 1) return null;


    return (


        <div>

            {unitList.map((item: any) =>
                <Container>

                    <div key={item} className={"customListItem"}>

                        <Row>
                            <Col xs="9"><span onClick={() => selectData(item.type)} > {item.name} </span></Col>

                        </Row>

                    </div>
                </Container>
            )}

        </div>
    )



};

