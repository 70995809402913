import React, { useState, useEffect } from 'react';
import { Alert, } from 'reactstrap';
import { useDataProvider, Loading, FormWithRedirect } from 'react-admin';
import { Box, Card, Typography } from '@material-ui/core';


import firebase from "firebase/app";
import "firebase/firestore";
import Map from './Map'


export const MapContainer = (props: any) => {

    if (!firebase.apps.length) {
        firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as String);
    }

    const dataProvider = useDataProvider();
    const [labelSubmissionList, setLabelSubmissionList] = useState<any[]>();
    const [error, setError] = useState();
    const [imgUrl, setImgUrl] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        dataProvider.getList('labelsubmissions', {
            pagination: { page: 1, perPage: 1 },
            filter: { storeLocationIdExists: false, isReviewed: false },
            sort: { field: 'title', order: 'ASC' },
        })
            .then(async (resp) => {
                setLabelSubmissionList(resp.data);
                setImgUrl(await firebase.storage().refFromURL("gs://zingo-price-labels/labels/").child(resp.data[0].image).getDownloadURL());
                setLoading(false);
                setImgUrl(await firebase.storage().refFromURL(`gs://zingo-price-labels/labels/${resp.data[0].image}`).getDownloadURL());
            })
            .catch(error => {
                //setError(error);
                setLoading(false);
            })

    }, []);


    if (loading) return <Loading />;
    // if (error) return <Error />;
    if (!labelSubmissionList) return null;


    const emtpyPage = () => {

        if (labelSubmissionList.length == 0) { return <div><Alert color="danger">Store Submission'da veri bulunmamaktadır!</Alert></div> }

        else {

            return (
                <FormWithRedirect
                    {...props}
                    render={formProps => (
                        <form> <Card>
                            <Box p="1em">
                                <Box display="flex">
                                    <Box flex={2} mr="0.5em">

                                        <Typography variant="h6" gutterBottom>Label Set</Typography>

                                        <img
                                            src={imgUrl}
                                            style={{ maxWidth: "100%", borderRadius: 5 }}
                                        />
                                    </Box>

                                    <Box flex={2} ml="0.5em">

                                        <Typography variant="h6" gutterBottom>Address</Typography>

                                        <Map
                                            google={props.google}
                                            center={{ lat: labelSubmissionList[0].location.coordinates[1], lng: labelSubmissionList[0].location.coordinates[0] }}
                                            height='300px'
                                            zoom={19}
                                            labelId={labelSubmissionList[0].id}
                                            label={labelSubmissionList[0]}
                                            store={labelSubmissionList[0].store}
                                        />
                                    </Box>

                                </Box>
                            </Box>
                        </Card>
                        </form>
                    )}
                />
                // <div >
                //     <Container>
                //         <Row>

                //             <Card style={{ backgroundColor: "#ffffff", display: "inline-block", width: "40%", alignItems: "flex", maxHeight: "flex", height: "100%" }}>
                //                 <CardHeader title="Label Image" style={{ backgroundColor: "#30526a", color: "#ffffff", alignContent: "center", textAlign: "center" }} ></CardHeader>
                //                 <CardContent style={{ padding: "1%", }}>

                //                     <img
                //                         src={imgUrl}
                //                         style={{ maxWidth: "100%", borderRadius: 5 }}
                //                     />
                //                 </CardContent>
                //             </Card>

                //             <Card style={{ display: "inline-block", marginLeft: "6%", width: "50%" }}>
                //                 <CardHeader title="Store Info" style={{ backgroundColor: "#30526a", color: "#ffffff", alignContent: "center", textAlign: "center" }} ></CardHeader>

                //                 <Map
                //                     google={props.google}
                //                     center={{ lat: labelSubmissionList[0].location.coordinates[1], lng: labelSubmissionList[0].location.coordinates[0] }}
                //                     height='300px'
                //                     zoom={19}
                //                     labelId={labelSubmissionList[0].id}
                //                     label={labelSubmissionList[0]}
                //                     store={labelSubmissionList[0].store}
                //                 />
                //             </Card>

                //         </Row>
                //     </Container>
                // </div>
            );
        }

    }
    return (
        emtpyPage()
    )

}
