import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import ReactDOM from 'react-dom';
import dataProviderFactory from './dataProvider';
import App from './App';
import Cookies from 'js-cookie';
import Login from './login/Login';

if (Cookies.get('accessToken')) {
    const prepareDataProvider = async () => {
        const restoreFetch = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER || ''
        );
        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER || ''
        );
        return { dataProvider, restoreFetch };
    };

    prepareDataProvider().then(({ dataProvider, restoreFetch }) => {
        ReactDOM.render(
            <App dataProvider={dataProvider} onUnmount={restoreFetch} />,
            document.getElementById('root')
        );
    }).catch((err) => {
        Cookies.remove("accessToken")
        ReactDOM.render(
            <Login></Login>,
            document.getElementById('root')
        );
    });
} else {
    ReactDOM.render(
        <Login></Login>,
        document.getElementById('root')
    );
}

