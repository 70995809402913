import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Paper,
  TextField,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { useDataProvider, useNotify } from "react-admin";
import CircularProgress from "../newpostsubmissions/CircularProgress";
import { isFunction } from "util";

const useStyles = makeStyles((theme) => ({
  textField: {
    '& > *': {
      margin: theme.spacing(1),
      width: '57ch',
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: "1em",
    backgroundColor: theme.palette.background.paper,
  },
  rootDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  rootCard: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "15em",
    height: "50em",
    backgroundColor: theme.palette.background.paper,
  },
  rootCardFalse: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "15em",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
  },
  card: {
    height: "100%",
    backgroundColor: "lightgray",
  },
  cardFalse: {
    height: "100%",
  },
  media: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  mediaCard: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
    display: "block",
    margin: "auto",
    padding: "auto",
  },
  titleDivider: {
    margin: theme.spacing(5),
  },
  titleDividerMergeProduct: {
    margin: theme.spacing(2),
  },
  titleProductName: {
    height: "0.5em",
    paddingBottom: "5em",
  },
  titleProductPrices: {
    paddingTop: "2em",
    paddingBottom: "5em",
    height: "100%"
  },
  button: {
    width: 512,
    height: 128,
  },
  paperTop: {
    padding: theme.spacing(2),
    width: "auto",
    textAlign: "center",
    fontSize: "2rem",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  divider: {
    marginRight: "1%",
    marginLeft: "1%",
  },
}));

const ProductCards = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const [nameToSend, setNameToSend] = useState("");
  const [brandToSend, setBrandToSend] = useState(String);
  const [brandIdToSend, setBrandIdToSend] = useState("");
  const [categoryToSend, setCategoryToSend] = useState("");
  const [listOfImagesToSend, setListOfImagesToSend] = useState<any>([]);
  const [checkedBrand, setCheckedBrand] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState(false);
  const [clickCard, setClickCard] = useState<any>([]);
  const data = props.product;
  const dataArray = props.products;
  const [productIds, setProductIds] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<any>();
  
  
  
  // const onSelectedID = async () => {
  //   if(props && isFunction(props.setSelectedID) ){
  //   props.setSelectedID(setSelectedId);
  // }
  // }

  const handleClick = (value: any, type: any) => {
    if (value) {
      if (type === "name") {
        if (value === nameToSend) {
          setNameToSend("");
        } else {
          setNameToSend(value);
        }
      }
      if (type === "brand") {
        if (value.brand === brandToSend) {
          setCheckedBrand(false);
          setBrandToSend("");
        } else {
          setCheckedBrand(true);
          setBrandIdToSend(value.brandId);
          setBrandToSend(value.brand);
        }
      }
      if (type === "category") {
        if (value.category === categoryToSend) {
          setCheckedCategory(false);
          setCategoryToSend("");
        } else {
          setCheckedCategory(true);
          setCategoryToSend(value.category);
        }
      }
      if (type === "image") {
        var arr = [...listOfImagesToSend];
        if (listOfImagesToSend.includes(value as never)) {
          arr.splice(arr.indexOf(value as never), 1);
          setListOfImagesToSend(arr);
        } else {
          arr.push(value as never);
          setListOfImagesToSend([...arr]);
        }
      }
    }
  };

  const handleClickCard = (value: boolean, index: any) => {
    let array = [...clickCard];
    array[index] = value;
    setClickCard(array);
    let arr = [...productIds];
    if (!arr.includes(dataArray[index].id)) {
      arr.push(dataArray[index].id);
      setProductIds(arr);
    } else {
      arr.splice(arr.indexOf(dataArray[index].id), 1);
      setProductIds(arr);
    }
  };

  const handleSend = async () => {
    if (productIds.length) {
      let arr = productIds;
      arr.push(data.id);
      dataProvider
        .create("mergesubmissions", {
          data: {
            productIds: arr,
            selectedName: nameToSend,
            selectedBrand: brandToSend,
            selectedCategory: categoryToSend,
            selectedImages: listOfImagesToSend,
          },
        })
        .then((_) => {
          dataProvider
            .update("products", {
              id: data.id,
              data: { id: data.id, isMergeReviewed: true, jobActivityType: `MERGE_SLIDER`, },
              previousData: { title: "previous title" } as any,
            })
            .then((_) => {
              notify("Product merge is completed");
              setNameToSend("");
              setBrandIdToSend("");
              setBrandToSend("");
              setCategoryToSend("");
              setListOfImagesToSend([]);
              setProductIds([]);
              setClickCard([]);
              props.setLoading(true);
              props.setRefresh(!props.refresh);
              props.setSelectedId("");
            });
        })
        .catch((err) => {
          notify("Product Merge Error : ", err.message);
        });
    } else {
      notify("Hiç ürün seçilmedi!");
    }
  };

  const handleSkip = async () => {
    await dataProvider
      .update("products", {
        id: data.id,
        data: { id: data.id, isMergeReviewed: true, jobActivityType: `MERGE_SLIDER`, },
        previousData: { title: "previous title" } as any,
      })
      .then(() => {
        notify("Skipped!");

        setNameToSend("");
        setBrandIdToSend("");
        setBrandToSend("");
        setCategoryToSend("");
        setListOfImagesToSend([]);
        setProductIds([]);
        setClickCard([]);
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      });
  };

  if (props.loading) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paperTop}>
          <Button
            variant="contained"
            onClick={handleSkip}
            className={classes.button}
          >
            {" "}
            Atla{" "}
          </Button>
          <h1 style={{ color: "#000", fontSize: "20px" }}> {data.name}</h1>
          <Button
            variant="contained"
            onClick={handleSend}
            className={classes.button}
          >
            {" "}
            Gönder{" "}
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Card style={{ height: "58em", overflowY: "scroll" }}>
          <Grid container spacing={1} className={classes.rootDiv}>
            {dataArray ? (
              dataArray.map((product: any, index: any) => (
                <Grid xs={12} sm={6} md={4} lg={3} xl={3} item>
                  <Card
                    className={
                      clickCard[index] ? classes.card : classes.cardFalse
                    }
                  >
                    <img
                      src={
                        product.images[0]
                          ? product.images[0]?.url
                          : `https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png`
                      }
                      className={classes.media}
                      onClick={
                        clickCard[index]
                          ? () => handleClick(product.images[0], "image")
                          : () => {}
                      }
                      onError={(e) =>
                        ((e.target as any).src =
                          "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png")
                      }
                    ></img>
                    <CardContent className={classes.titleProductName}>
                      <Typography
                        variant="h5"
                        component="h2"
                        align="center"
                        onClick={() => {
                          handleClickCard(!clickCard[index], index);
                        }}
                      >
                        {product.name}
                      </Typography>
                    </CardContent>
                    <Divider
                      variant="middle"
                      className={classes.titleDivider}
                    />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleClickCard(!clickCard[index], index);
                        }}
                      >
                        Sec
                      </Button>
                    </div>
                    <CardContent className={classes.titleProductPrices}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={nameToSend === product.name ? true : false}
                            onChange={() => {
                              handleClick(product.name, "name");
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            name="checkedName"
                            color="primary"
                            disabled={clickCard[index] ? false : true}
                          />
                        }
                        label={product.name}
                      />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              brandToSend === product.brand ? true : false
                            }
                            onChange={() => {
                              handleClick(product, "brand");
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            name="checkedBrand"
                            color="primary"
                            disabled={clickCard[index] ? false : true}
                          />
                        }
                        label={product.brand}
                      />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              categoryToSend === product.category ? true : false
                            }
                            onChange={() => {
                              handleClick(product, "category");
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            name="checkedCategory"
                            color="primary"
                            disabled={clickCard[index] ? false : true}
                          />
                        }
                        label={product.category}
                      />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            inputProps={{ "aria-label": "primary checkbox" }}
                            name="checkedBarcode"
                            color="primary"
                            disabled
                          />
                        }
                        label={product.barcode}
                      />
                      <br />
                      <Divider
                        variant="middle"
                        className={classes.titleDivider}
                      />
                      {product.prices.map((price: any) => (
                        <Typography variant="h5" component="h2" align="left">
                          {price.store}: {price.price}
                        </Typography>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <br />
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={4} container>
        <Card>
          <Typography
            variant="h5"
            gutterBottom
            style={{  margin: "10px", textAlign: "center" }}
          >
            Merged Product
          </Typography>
          <TextField
          className={classes.textField}
          id="outlined-required"
          label="ID (Press Enter Key)"
          defaultValue={props.selectedID}
          value={props.selectedID}
          onChange={(e) => setSelectedId(e.target.value)}
          onKeyPress= {(e) => {
            if (e.key === 'Enter') {
              props.setSelectedId(selectedId)
            }
    }}
          variant="outlined"
          fullWidth
        />
          <Grid container spacing={2}>
            {data ? (
              <Grid xs={12} sm={6} md={4} lg={3} xl={2} item>
                <Card className={classes.rootCard}>
                  <img
                    src={
                      props &&
                      props.product &&
                      props.product.images &&
                      props.product.images[0]
                        ? props.product.images[0].url
                        : `https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png`
                    }
                    className={classes.media}
                    onClick={
                      props && props.product && props.product.images
                        ? () => handleClick(props.product.images[0], "image")
                        : () => {}
                    }
                    onError={(e) =>
                      ((e.target as any).src =
                        "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png")
                    }
                  ></img>

                  {/* <img src={props.product.images[0]  ? props.product.images[0].url : "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"} className={classes.media} onClick={ ()=> handleClick(data.images[0], "image")} ></img> */}

                  <CardContent className={classes.titleProductName}>
                    <Typography variant="h5" component="h2" align="center">
                      {props.product?.name}
                    </Typography>
                  </CardContent>
                  {/* <Divider
                    variant="middle"
                    className={classes.titleDividerMergeProduct}
                  /> */}
                  <CardContent className={classes.titleProductPrices}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            nameToSend === props.product.name ? true : false
                          }
                          onChange={() => {
                            handleClick(props.product.name, "name");
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          name="checkedName"
                          color="primary"
                        />
                      }
                      label={props.product.name}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            brandToSend === props.product.brand ? true : false
                          }
                          onChange={() => {
                            handleClick(props.product, "brand");
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          name="checkedBrand"
                          color="primary"
                        />
                      }
                      label={props.product.brand}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            categoryToSend === props.product.category
                              ? true
                              : false
                          }
                          onChange={() => {
                            handleClick(props.product, "category");
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          name="checkedCategory"
                          color="primary"
                        />
                      }
                      label={props.product.category}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ "aria-label": "primary checkbox" }}
                          name="checkedBarcode"
                          color="primary"
                          disabled
                        />
                      }
                      label={props.product.barcode}
                    />
                    <br />
                    <Divider
                      variant="middle"
                      className={classes.titleDividerMergeProduct}
                    />
                    {data.prices?.map((price: any) => (
                      <Typography variant="h5" component="h2" align="left">
                        {price && price.url && price.url !== null ? (
                        <Link
                          href={
                            price && price.url && price.url !== null
                              ? price.url
                              : ""
                          }
                          target="_blank"
                        >
                          {price.store} 
                        </Link>
                      ) : (
                        price.store
                      )}
                      {` : ${price.price}`}
                      </Typography>
                    ))}
                    <hr />
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <br />
            )}

            <Grid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              item
              style={{ marginLeft: "10em" }}
            >
              <Card
                className={
                  listOfImagesToSend?.length
                    ? classes.rootCardFalse
                    : classes.rootCard
                }
              >
                <img
                  className={classes.media}
                  alt="b"
                  src={
                    listOfImagesToSend?.length
                      ? listOfImagesToSend[0].url
                      : "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"
                  }
                />

                <CardContent className={classes.titleProductName}>
                  <TextField
                    id="outlined-read-only-input"
                    label="isim"
                    value={nameToSend}
                    onChange={(e: any) => {
                      setNameToSend(e.target.value);
                    }}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                  />
                  <Divider
                    variant="middle"
                    className={classes.titleDividerMergeProduct}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Marka"
                    value={brandToSend}
                    onChange={(e) => {
                      setBrandToSend(e.target.value);
                    }}
                    onClick={(e) => {
                      setBrandToSend("");
                    }}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                  />
                  <Divider
                    variant="middle"
                    className={classes.titleDividerMergeProduct}
                  />
                  <TextField
                    id="outlined-read-only-input"
                    label="Kategori"
                    value={categoryToSend}
                    onChange={(e) => {
                      setCategoryToSend(e.target.value);
                    }}
                    onClick={(e) => {
                      setCategoryToSend("");
                    }}
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                  />
                </CardContent>

                <CardContent className={classes.titleProductPrices}>
                  {listOfImagesToSend.map((image: any) => (
                    <div style={{ cursor: "pointer" }}>
                      <img
                        onClick={() => {
                          handleClick(image, "image");
                        }}
                        className={classes.mediaCard}
                        style={{ alignSelf: "center", marginTop: "8em" }}
                        alt="complex"
                        src={image.url}
                      />
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ProductCards;
