import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CardHeader } from '@material-ui/core';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import CompletedPage from '../shared/CompletedPage';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 3,
    },
    input: {
        width: "100%",
        margin: "1%",
        marginBottom: "2%",
        alignSelf: "center",
        color: "#ffffff",
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
    button: {
        paddingLeft: 20,
        display: "inline-block"
    },
    view: {
        color: "#ffffff",
        width: "400px"
    },
    backgroundClicked: {
        backgroundColor: "#d7d7d7"
    },
    list: {
        paddingRight: 40
    },
    imageView: {
        height: 180,
        maxWidth: "100%"
    },
    listImageView: {
        height: 80,
        width: 80,
        marginLeft: 10,
    },
    label: {
        width: 80,
        color: "#30526a",
        paddingRight: 10
    },
    tile: {
        display: "inline-block;",
        padding: theme.spacing(1),
    },
    control: {
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },
    unit: {
        width: "100%",
        margin: "1%",
        marginBottom: "2%",
        alignSelf: "center",
        color: "#ffffff",
        '& > *': {
            margin: theme.spacing(1),
            width: '95%',
        },
    },
}));

const BarcodelessProduct = (props: any) => {
    const notify = useNotify();
    const classes = useStyles();
    const [product, setProduct] = useState<any>([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    const [unit, setUnit] = useState<any>();
    const [amount, setAmount] = useState<any>();
    const [kind, setKind] = useState<any>();
    const [packageQuantity, setPackageQuantity] = useState<any>();
    const [name, setName] = useState<any>();
    const [brand, setBrandName] = useState<any>("");
    const [barcode, setBarcode] = useState("");
    const [tenProducts, setTenProducts] = useState<any>();
    const [refresh, setRefresh] = useState<any>(false);
    const [allStores, setAllStores] = useState<any>([]);
    const [selectedStore, setSelectedStore] = useState<any>();
    const [isUOFChanged, setIsUOFChanged] = useState(false);
    const MeasurementKind = {
        WEIGHT: 'WEIGHT',
        LENGTH: 'LENGTH',
        VOLUME: 'VOLUME',
        QUANTITY: 'QUANTITY',
        SERVING: 'SERVING'
    }

    const Unit = {
        KG: 'KG',
        G: 'G',
        M: 'M',
        CM: 'CM',
        MM: 'MM',
        L: 'L',
        CL: 'CL',
        ML: 'ML',
        QTY: 'QTY',
        SRV: 'SRV'
    }

    let unitOfMeasurement = { amount: amount, kind: kind, unit: unit, description: "" };

    const handleChange = (event: any) => {
        if (isUOFChanged) {
            setIsUOFChanged(true)
            if (event.target.value === "KG") {
                setKind(MeasurementKind.WEIGHT)
            }
            if (event.target.value === "G") {
                setKind(MeasurementKind.WEIGHT)
            }
            if (event.target.value === "M") {
                setKind(MeasurementKind.LENGTH)
            }
            if (event.target.value === "CM") {
                setKind(MeasurementKind.LENGTH)
            }
            if (event.target.value === "MM") {
                setKind(MeasurementKind.LENGTH)
            }
            if (event.target.value === "L") {
                setKind(MeasurementKind.VOLUME)
            }
            if (event.target.value === "CL") {
                setKind(MeasurementKind.VOLUME)
            }
            if (event.target.value === "ML") {
                setKind(MeasurementKind.VOLUME)
            }
            if (event.target.value === "QTY") {
                setKind(MeasurementKind.QUANTITY)
            }
            if (event.target.value === "SRV") {
                setKind(MeasurementKind.SERVING)
            }
            setUnit(event.target.value)
            return unitOfMeasurement;
        } else {
            setUnit("")
        }
    };

    const handleChangeAmount = (e: any) => {
        setIsUOFChanged(true)
        var value: any = Number(e.target.value);
        setAmount(parseFloat(value))
    };

    const sendUpdate = (barcode: any, id: any, unitOfMeasurement: any, name: any) => {
        const isBarcodeReviewed = true;
        const bundleQuantity = Number(packageQuantity);
        if (barcode !== "" && typeof barcode !== "undefined") {
            if (isUOFChanged && amount) {
                unitOfMeasurement.amount = amount
                dataProvider
                    .update('products', { id, data: { id, barcode, unitOfMeasurement, name, bundleQuantity, isBarcodeReviewed, jobActivityType: "MERGE_BARCODE", isOnBarcodeSession: false, brand }, previousData: { title: "previous title" } as any })
                    .then(_ => {
                        notify("Product updated successfully.");
                        setRefresh(!refresh)
                    })
                    .catch(error => {
                        notify("Send update error: " + error)
                    });
            } else {
                dataProvider
                    .update('products', { id, data: { id, barcode, name, bundleQuantity, isBarcodeReviewed, jobActivityType: "MERGE_BARCODE", isOnBarcodeSession: false, brand }, previousData: { title: "previous title" } as any })
                    .then(_ => {
                        notify("Product updated successfully.");
                        setRefresh(!refresh)
                    })
                    .catch(error => {
                        notify("Send update error: " + error)
                    });
            }
        } else {
            if (isUOFChanged) {
                unitOfMeasurement.amount = amount
                dataProvider
                    .update('products', { id, data: { id, unitOfMeasurement, name, bundleQuantity, isBarcodeReviewed, jobActivityType: "MERGE_BARCODE", isOnBarcodeSession: false, brand }, previousData: { title: "previous title" } as any })
                    .then(_ => {
                        notify("Product updated successfully.");
                        setRefresh(!refresh)
                    })
                    .catch(error => {
                        notify("Send update error: " + error)
                    });
            } else {
                dataProvider
                    .update('products', { id, data: { id, name, bundleQuantity, isBarcodeReviewed, jobActivityType: "MERGE_BARCODE", isOnBarcodeSession: false, brand }, previousData: { title: "previous title" } as any })
                    .then(_ => {
                        notify("Product updated successfully.");
                        setRefresh(!refresh)
                    })
                    .catch(error => {
                        notify("Send update error: " + error)
                    });
            }
        }

        props.setClickedProductName("")
        props.setClickedProductBarcode("")
        props.setClickedProductBrand("")
        setBarcode("")
        setBrandName("")
        setUnit("")
        setAmount("")
        setKind("")
        setIsUOFChanged(false)
        unitOfMeasurement = {}
    };


    const sendSkip = (id: any) => {
        dataProvider
            .update('products', { id, data: { id, isBarcodeReviewed: true, jobActivityType: "MERGE_BARCODE", isOnBarcodeSession: false }, previousData: { title: "previous title" } as any })
            .then(_ => {
                notify("Product skipped successfully.")
                setRefresh(!refresh)
            })
            .catch(error => {
                notify("Skip error: " + error)
            });

        props.setClickedProductName("")
        props.setClickedProductBarcode("")
        props.setClickedProductBrand("")
        setBarcode("")
        setBrandName("")
        setUnit("")
        setAmount("")
        setIsUOFChanged(false)
    }

    const ApproveButton = ({ barcode, id, unitOfMeasurement, name }: any) => {
        return <Button variant="contained" className={classes.button} onClick={() => sendUpdate(barcode, id, unitOfMeasurement, name)} disabled={loading}> Kaydet </Button>;
    };

    const SkipButton = ({ id }: any) => {
        return <Button variant="contained" className={classes.button} onClick={() => sendSkip(id)} disabled={loading}> Atla </Button>;
    }


    const checkBarcode = () => {
        if (props.clickedProductBarcode && props.clickedProductBarcode != barcode) {
            setBarcode(props.clickedProductBarcode)
            return props.clickedProductBarcode
        }
        if(!props.clickedProductBarcode){
            return product.barcode
        }

        return barcode
    }

    useEffect(() => {
        setBarcode(props.clickedProductBarcode)
    }, [props.clickedProductBarcode])

    useEffect(() => {
        setLoading(true)
        dataProvider.getList('stores', {
            pagination: { page: 1, perPage: 200 },
            sort: { field: 'title', order: 'ASC' },
            filter: {}
        }).then(res => {
            setAllStores([...res.data]);
            if (selectedStore !== null) {
                dataProvider.getList('products', {
                    pagination: { page: 1, perPage: 10 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: { isBarcodeReviewed: false, store: selectedStore ,notExistsMergeBarcode: true }
                }).then(({ data }) => {
                    if (data && data.length) {
                        setTenProducts(data);
                        setProduct(data[0])
                        setBrandName(data[0].brand)
                        setName(data[0].name);
                        (data[0].bundleQuantity) ? setPackageQuantity(data[0].bundleQuantity) : setPackageQuantity("")
                        let searchTerm = data[0].name;
                        if(selectedStore == "sok" && data[0].brand){
                            props.setBarcodelessProduct(data[0].brand + " " + searchTerm);
                        } else {
                            props.setBarcodelessProduct(searchTerm);
                        }
                        setLoading(false)
                    } else {
                        props.setDone(true)
                    }
                })
                    .catch(error => {
                        setError(error);
                    });
            } else {
                let arr = tenProducts;
                arr = arr.slice(1);
                setTenProducts(arr)
                setProduct(arr[0])
                setBrandName(arr[0].brand)
                setName(arr[0].name);
                (arr[0].bundleQuantity) ? setPackageQuantity(arr[0].bundleQuantity) : setPackageQuantity("")
                let searchTerm = arr[0].name;
                props.setBarcodelessProduct(searchTerm);
                setLoading(false)
            }
        }).catch(error => {
            notify("Get product error: " + error)
        });

    }, [refresh, selectedStore]);

    if (loading) return <Loading />;
    if (error) return <Error {...props} />;
    if (!product) return <Loading></Loading>;


    const checkName = () => {
        //if (name || typeof name !== 'undefined') return name;
        if (props.clickedProductName) {
            return props.clickedProductName
        } else {
            return product.name;
        }

    };

    const checkBrandName = () => {
        //console.log(props.clickedProductBrand)
        if (props.clickedProductBrand && props.clickedProductBrand != brand) {
            setBrandName(props.clickedProductBrand)
            return props.clickedProductBrand
        }
       
        
        return brand

        // if (brand || typeof brand !== 'undefined') return brand;

        // if (props.clickedProductBrand) {
        //     return props.clickedProductBrand;
        // } else {
        //     return product.brand;
        // }

    };

    return (
        <Grid item>
            <Card className={classes.view}>
                <CardHeader style={{ backgroundColor: "#bdc3c7", alignContent: "center", textAlign: "center" }} title="Yeni Gelen Ürün"></CardHeader>
                <div style={{ background: "#ffffff", textAlign: "center", justifyItems: "center", margin: "25px" }}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="selected-store">Selected Store</InputLabel>
                        <Select
                            style={{ width: '22em' }}
                            labelId="select-store"
                            id="store-select"
                            value={selectedStore}
                            label="Selected Store"
                            onChange={(e: any) => { setSelectedStore(e.target.value) ; props.setClickedProductBarcode(null) }}
                        >
                            {
                                allStores.map((store: any) => (
                                    <MenuItem value={store.slug}>{store.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <a href={(product.images[0]) ? product.images[0].url : ""} target="blank">
                        <img
                            className={classes.imageView}
                            src={(product.images[0]) ? product.images[0].url : ""}
                            title="Ürün Resmi"
                        />
                    </a>
                </div>

                <CardContent >
                    <form noValidate autoComplete="off">

                        <TextField

                            className={classes.input}
                            defaultValue={product.name}
                            variant="filled"
                            value={checkName()}
                            label="Ürün ismi"
                            style={{ backgroundColor: "#bdc3c7", borderRadius: 5, padding: "1%" }}
                            onChange={(e) => { setName(e.target.value) }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField

                            className={classes.input}
                            defaultValue={product.brand}
                            value={checkBrandName()}
                            style={{ backgroundColor: "#bdc3c7", borderRadius: 5, padding: "1%" }}
                            label="Marka"
                            variant="filled"
                            onChange={e => setBrandName(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Barkod"
                            defaultValue={product.barcode}
                            className={classes.input}
                            style={{ backgroundColor: "#bdc3c7", borderRadius: 5, }}
                            value={(props.clickedProductBarcode) ? props.clickedProductBarcode : product.barcode}
                            onChange={e => {props.setClickedProductBarcode(e.target.value); setBarcode(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"

                        />

                        {/* <label className={classes.label} style={{ margin: "5px" }}> Birimi </label> */}


                        {/* <TextField
                            type="number"
                            label="Unit"
                            style={{ backgroundColor: "#bdc3c7", borderRadius: 5, }}
                            defaultValue={(product.unitOfMeasurement) ? product.unitOfMeasurement.amount : ""}
                            value={amount}
                            variant="filled"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.unit}
                            onChange={e => handleChangeAmount(e)}
                        /> */}



                        {/* <FormControl className={classes.formControl} style={{ display: "flex", padding: 0 }} label={"Unit"}> */}
                        {/* <InputLabel id="demo-simple-select-label" style={{ color: "#30526a", margin: 0, padding: 0 }}>Unit</InputLabel> */}
                        {/* <TextField
                            label="Birimi"
                            select
                            id="demo-simple-select"
                            className={classes.unit}
                            variant="filled"

                            style={{ backgroundColor: "#bdc3c7", color: "#ffffff", borderRadius: 5, }}
                            value={unit}
                            onChange={event => { handleChange(event) }}
                        >
                            <MenuItem style={{ textAlign: "center" }} value={"KG"}>KG</MenuItem>
                            <MenuItem value={"G"}>Gram</MenuItem>
                            <MenuItem value={"M"}>Metre</MenuItem>
                            <MenuItem value={"CM"}>CM</MenuItem>
                            <MenuItem value={"MM"}>MM</MenuItem>
                            <MenuItem value={"L"}>Litre</MenuItem>
                            <MenuItem value={"CL"}>CL</MenuItem>
                            <MenuItem value={"ML"}>ML</MenuItem>
                            <MenuItem value={"QTY"}>Adet</MenuItem>
                            <MenuItem value={"SRV"}>Porsiyon</MenuItem>
                        </TextField> */}
                        {/* </FormControl> */}



                        <TextField

                            label="Paketteki ürün sayısı"
                            style={{ backgroundColor: "#bdc3c7", color: "#ffffff", borderRadius: 5, }}
                            className={classes.unit}
                            type="number"
                            value={packageQuantity}
                            onChange={(q) => setPackageQuantity(q.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"


                        />


                    </form>
                    <div style={{ alignSelf: "center", marginLeft: "17%" }}>
                        <div className={classes.button}>
                            <ApproveButton name={checkName()} brand={checkBrandName()} barcode={barcode} id={product.id} unitOfMeasurement={unitOfMeasurement}></ApproveButton>
                        </div>
                        <div className={classes.button}>
                            <SkipButton id={product.id}></SkipButton>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default BarcodelessProduct;