import * as React from 'react';
import { FC } from 'react';
import {
    Filter,
    SearchInput,
    FilterProps,
    TextInput,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,

} from 'react-admin';


const ProductZingoFilter: FC<Omit<FilterProps, 'children'>> = props => {
    // const classes = useFilterStyles();
    return (
        <Filter {...props}>
            <SearchInput source="query" alwaysOn />
            {/* <TextInput label="Search" source="query" /> */}
            <TextInput label="Brand" source="brand" />
            <TextInput label="Barcode" source="barcode" />
            <TextInput label="Store" source="store" />
            <TextInput label="ID" source="id" />
            <BooleanInput label="isMergeReviewed" source="isMergeReviewed" />
            <BooleanInput label="isBarcodeReviewed" source="isBarcodeReviewed" />
            <BooleanInput label="isSiblingReviewed" source="isSiblingReviewed" />
            <BooleanInput label="isSimilerReviewed" source="isSimilarReviewed" />
            <BooleanInput label="isActive" source="isActive" />
            <BooleanInput label="isDeclined" source="isDeclined" />
            <BooleanInput label="isDeleted" source="deleted" />
            <ReferenceInput label="Category" source="categoryId" reference="categories">
                <AutocompleteInput optionText="categoryName" ></AutocompleteInput>
            </ReferenceInput>
            <TextInput label="UpdatedBy" source="updatedBy" />

        </Filter>
    );
};

export default ProductZingoFilter;
