import React, { useState, useEffect, FC } from 'react';
import { useDataProvider, useNotify, Loading, Link, ListProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import { Avatar, Chip, Grid, GridList, GridListTile, GridListTileBar, List, ListItem, ListItemAvatar, ListItemText, Paper, Popover, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paperpopup: {
        padding: theme.spacing(1),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: "75%",
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },

    paper: {
        width: '30%',
        padding: theme.spacing(2),
        marginTop: '20px',
        marginLeft: '20px',
        backgroundColor: "#F8F8F8",
        display: "inline-flex"
    },
    paperName: {
        width: 'auto',
        padding: theme.spacing(2),
        backgroundColor: "#F8F8F8",
        display: "inline-flex",
        alignItems: 'center'
    },
    paperTop: {
        padding: theme.spacing(2),
        textAlign: 'center',
        fontSize: "2rem",
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
    },
    paperRight: {
        width: '50%',
        padding: theme.spacing(2),
        marginTop: '20px',
        marginRight: '20px',
        backgroundColor: "#F8F8F8",
        display: "inline-flex"
    },

    img: {
        width: 128,
        height: 128,
    },
    button: {
        width: 512,
        height: 128,
    },
    text: {
        fontSize: '2em'
    },
    imgHeader: {
        justifyContent: "space-around",
    },
    similar: {
        backgroundColor: "#D1EACA",
        height: "100px",
        marginTop: "10px",
        cursor: "pointer",
    },
    similarSelected: {
        backgroundColor: "#52FF57",
        height: "100px",
        marginTop: "10px",
        cursor: "pointer",
    }
}));

const MergeSimilar: FC<ListProps> = (props: any) => {
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [product, setProduct] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [siblingProducts, setSiblingProducts] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [anchorElName, setAnchorElName] = React.useState<HTMLElement | null>(null);
    const [openedPopoverId, setOpenedPopoverId] = React.useState<any>();
    const openName = Boolean(anchorElName);

    const handlePopoverOpenName = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: any) => {
        setOpenedPopoverId(id);
        setAnchorElName(event.currentTarget);
    };

    const handlePopoverCloseName = () => {
        setOpenedPopoverId(null);
        setAnchorElName(null);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };



    useEffect(() => {
        setLoading(true)
        dataProvider.getList('products', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'title', order: 'ASC' },
            filter: { isSimilarReviewed: null, categoryExists: true }
        }).then(({ data }) => {
            setProduct(data[0])
            dataProvider.getList('products', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'title', order: 'ASC' },
                filter: { query: data[0].name, querySimilar: true, brand: data[0].brand, category: data[0].category }
            }).then((resp) => {
                setLoading(false)
                var arr = [] as any;
                resp.data.forEach((simP) => {
                    if (simP.id !== data[0].id) {
                        arr.push(simP);
                    }
                })
                setSimilarProducts(arr);
            })
            setSiblingProducts(data)
        }).catch((err) => {
            notify("Something went wrong with get product", err);
        })
    }, [refresh])

    const handleClick = (index: any) => {

        let arraySiblingProducts = [...siblingProducts];
        let arraySimilarProducts = [...similarProducts];

        if (!siblingProducts.includes(arraySimilarProducts[index])) {
            arraySiblingProducts.push(arraySimilarProducts[index])

        } else {
            for (let i = 1; i < arraySiblingProducts.length; i++) {
                if (arraySiblingProducts[i] === arraySimilarProducts[index]) {
                    arraySiblingProducts.splice(i, 1);
                }
            }
        }

        setSiblingProducts([...arraySiblingProducts]);
    }

    const handleSend = async (id: any) => {
        dataProvider.update('products', { id: product.id, data: { id: product.id, similarIds: getSiblingIds(product.id), isSimilarReviewed: true }, previousData: { title: "previous title" } as any }).then(resp => {
            notify("Similar merge completed....")
            setRefresh(!refresh)
        })
    }

    const handleSkip = () => {
        dataProvider.update('products', { id: product.id, data: { id: product.id, isSimilarReviewed: true }, previousData: { title: "previous title" } as any }).then(resp => {
            notify("Product skipped!");
            setRefresh(!refresh)
        });
    }

    const getSiblingIds = (id: any) => {
        let arr = [] as any;
        siblingProducts.forEach((realProduct) => {
            if (realProduct.id !== id && !arr.includes(realProduct.id)) {
                arr.push(realProduct.id);
            }
        })
        return arr;
    }
    const handleClickChip = (name: any) => {
        let arra = [...siblingProducts];
        for (let i = 1; i < arra.length; i++) {
            if (arra[i].name === name) {
                arra.splice(i, 1);
            }
        }
        setSiblingProducts([...arra]);
    };


    if (product && siblingProducts && !loading) {
        return (
            <div className={classes.root} >
                <Grid item xs={12}>
                    <Paper className={classes.paperTop} >
                        <Button variant="contained" onClick={handleSkip} className={classes.button} > Atla </Button>
                        <h1 style={{ color: "#000", fontSize: "10px" }}>
                            <List dense={true} style={{ fontSize: "10px" }} >
                                {product.images.map((image: any) => (
                                    <>
                                        <ListItem
                                            key={product.id}
                                            button
                                            component={Link}
                                            to={`/products/${product.id}`}
                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}
                                        >
                                            <ListItemAvatar>
                                                {(image) ? (
                                                    <Avatar
                                                        src={image.url}
                                                        className={classes.img}
                                                    />
                                                ) : (
                                                    <Avatar className={classes.img} />
                                                )}
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={product.name}
                                                secondary={`${(product.prices) ? product.prices.map((price : any) => (`${price.store} : ${price.price}`)) : "Price Yok"},  ${(product.category) ? product.category : "Kategori Yok"}`}
                                                className={classes.text}
                                            />
                                        </ListItem>
                                        <Popover
                                            id="mouse-over-popover"
                                            className={classes.popover}
                                            classes={{
                                                paper: classes.paperpopup,
                                            }}
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <img src={image.url} alt="" style={{ width: 400, height: 400 }} />
                                        </Popover>
                                    </>
                                ))}
                            </List>
                        </h1>
                        <Button variant="contained" onClick={() => handleSend(product.id)} className={classes.button}> Gönder </Button>
                    </Paper>

                </Grid>
                <List dense={true} >
                    {siblingProducts.map((sib: any, index: any) => (

                        <Chip
                            avatar={<Avatar alt="Natacha" src={(sib.images[0] && sib.images[0].url) ? sib.images[0].url : ""} />}
                            label={sib.name}
                            clickable
                            onClick={() => handleClickChip(sib.name)}
                            deleteIcon={<DoneIcon />}
                        />

                    ))}
                </List>
                <GridList cellHeight={200} cols={6} >
                    {similarProducts.map((prd: any, index: number) => (
                        <GridListTile
                            component={Link}
                            key={prd.id}
                            to={"/mergesimilars"}
                            onClick={() => handleClick(index)}
                            aria-owns={openName ? 'mouse-over-popover-name' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(e: any) => handlePopoverOpenName(e, prd.id)}
                            onMouseLeave={handlePopoverCloseName}
                        >
                            <img src={(prd.images[0] && prd.images[0].url) ? prd.images[0].url : "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"} onError={() => prd.images[0].url = "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png"} alt="bofo"  />
                            <GridListTileBar style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow:'ellipsis',
                            }}
                                title={prd.name}
                            />
                            {/* <Popover
                                id="mouse-over-popover-name"
                                className={classes.popover}
                                classes={{
                                    paper: classes.paperName,
                                }}
                                open={openedPopoverId === prd.id}
                                anchorEl={anchorElName}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverCloseName}
                                disableRestoreFocus
                            >
                                <Typography >{prd.name}</Typography>
                            </Popover> */}
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    } else if (!product && !loading) {
        return <div><Alert color="info">Tüm ürünler incelendi!</Alert></div>
    }
    else {
        return <Loading></Loading>
    }
}

export default MergeSimilar;