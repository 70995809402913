import axios from 'axios';
import Cookies from 'js-cookie';

export const fixTlSeperator = (price: any) => {
    if (price && price.match(/[0-9\.]+\,[0-9]{2}/)) {
        price = price.match(/[0-9\.]+\,[0-9]{2}/)[0]
        if (price.length >= 3) {
            const kurus = price.substring(price.length - 2)
            let lira = price.substring(0, price.length - 3)
            lira = lira.replace('.', '').replace(',', '')
            return `${lira}.${kurus}`;
        } else {
            alert(`Price error: ${price}`)
        }
    }
}

export const elasticParenthesesRemover = (searchKey: any) => {
    let varas = "";
    varas = searchKey;

    varas = varas.replaceAll("(", "");
    varas = varas.replaceAll(")", "");
    varas = varas.replaceAll("\"", "");
    varas = varas.replaceAll("\\", "");
    varas = varas.replaceAll("/", "");
    varas = varas.replaceAll(":", "");
    varas = varas.replaceAll(";", "");
    varas = varas.replaceAll("!", "");
    varas = varas.replaceAll("-", "");

    return varas;
}

export const extractAmount = (key: any) => {
    if (key && key.match(/[0-9]+/)) {
        key = key.match(/[0-9]+/)[0]
    }
    return key;
}

export const extractUnit = (key: any) => {
    if (key && key.match(/[0-9]+/)) {
        key = key.replace(key.match(/[0-9]+/)[0], "");
        key = key.trim();
    }

    switch (key) {
        case "Ml":
        case "ml":
        case "ML":
        case "mL":
        case "MI":
            key = "ML";
            break;

        case "L":
        case "l":
        case "litre":
        case "Litre":
            key = "L"
            break;

        case "g":
        case "G":
        case "Gr":
        case "gr":
        case "gram":
        case "Gram":
        case "GRAM":
            key = "G"
            break;

        case "mg":
        case "MG":
        case "MGr":
        case "mgr":
        case "miligram":
        case "Miligram":
        case "MILIGRAM":
            key = "MG"
            break;

        case "kg":
        case "KG":
        case "KGr":
        case "kgr":
        case "kilogram":
        case "Kilogram":
        case "KILOGRAM":
            key = "KG"
            break;

        case "m":
        case "M":
        case "Metre":
        case "metre":
            key = "M"
            break;

        case "cm":
        case "CM":
        case "Santimetre":
        case "santimetre":
            key = "CM"
            break;

        case "mm":
        case "MM":
        case "Milimetre":
        case "milimetre":
            key = "MM"
            break;

        case "adet":
        case "Adet":
        case "ad":
        case "Ad":
        case "tane":
        case "tane":
        case "TANE":
            key = "QTY"
            break;

        case "servis":
        case "porsiyon":
        case "Porsiyon":
        case "Servis":
            key = "SRV"
            break;

        default: break;
    }

    return key;
}

export const sendToBannerBear = async (title: any, postToSend: any) => {
    
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ZINGO_SERVER}/homepage-posts/create-placid-post`,
        headers: { "Authorization": "Bearer " + Cookies.get("accessToken") },
        data: {
            data: {
                templateId: postToSend.payload,
                productName: postToSend.product.name,
                productImage: postToSend.product.images[0].url,
                storeLogo3: postToSend.store3 ? (postToSend.logoColor == "white") ? postToSend.store3?.whiteLogoUrl : postToSend.store3?.logoUrl : null,
                storeLogo2: postToSend.store2 ? (postToSend.logoColor == "white") ? postToSend.store2?.whiteLogoUrl : postToSend.store2?.logoUrl : null,
                storeLogo1: postToSend.store1 ? (postToSend.logoColor == "white") ? postToSend.store1?.whiteLogoUrl : postToSend.store1?.logoUrl : null,
                price3: postToSend.price3?.price.toFixed(2) + "₺",
                price2: postToSend.price2?.price.toFixed(2) + "₺",
                price1: postToSend.price1?.price.toFixed(2) + "₺",
                title: title || null,
            }
        },
    }).then(
        async (res: any) => {
            window.localStorage.setItem("imageUrlToSend", res.data.image_url)
            return res;
        }
    ).catch(
        (err) =>
            err
    )
}

export const minPrice = (product: any) => {
    let minPrice = { price: 99999999999 };
    product.prices.forEach((el: any) => {
        if (el.price < minPrice.price) minPrice = el;
    })
    return product.prices.indexOf(minPrice);
}

export const maxPrice = (product: any) => {
    let maxPrice = { price: 0 };
    product.prices.forEach((el: any) => {
        if (el.price > maxPrice.price) maxPrice = el;
    })
    return product.prices.indexOf(maxPrice);
}

export const kurusDivider = (price: any) => {
    let kurus = price + "";
    if (kurus.includes(".")) {
        kurus = kurus.substring(kurus.indexOf("."));
        if (kurus.length === 2) kurus = kurus + "0";
        return kurus;
    }
    return ".00"
}

export const liraDivider = (price: any) => {
    let lira = price + "";
    if (lira.includes(".")) {
        lira = lira.substring(0, lira.indexOf("."));
    }
    return lira;
}

export const jaroWinkler = (a: string, b: string) => {
    var jaro_winkler: any = { a, b };

    jaro_winkler.adjustments = {
        'A': 'E',

        'B': 'V',
        'E': 'I',

        'I': 'O',
        'O': 'U',
        'C': 'G',
        'W': 'U',
        'X': 'K',
        'S': 'Z',
        'Q': 'C',
        'U': 'V',
        'M': 'N',
        'L': 'I',
        'P': 'R',
        '2': 'Z',
        '5': 'S',
        '8': 'B',
        '1': 'I',
        '0': 'O',
        'G': 'J',
        'Y': ' ',
    }

    if (!a || !b) { return 0.0; }

    a = a.trim().toUpperCase();
    b = b.trim().toUpperCase();
    var a_len = a.length;
    var b_len = b.length;
    var a_flag = []; var b_flag = [];
    var search_range = Math.floor(Math.max(a_len, b_len) / 2) - 1;
    var minv = Math.min(a_len, b_len);

    // Looking only within the search range, count and flag the matched pairs. 
    var Num_com = 0;
    var yl1 = b_len - 1;
    for (var i = 0; i < a_len; i++) {
        var lowlim = (i >= search_range) ? i - search_range : 0;
        var hilim = ((i + search_range) <= yl1) ? (i + search_range) : yl1;
        for (var j = lowlim; j <= hilim; j++) {
            if (b_flag[j] !== 1 && a[j] === b[i]) {
                a_flag[j] = 1;
                b_flag[i] = 1;
                Num_com++;
                break;
            }
        }
    }

    // Return if no characters in common
    if (Num_com === 0) { return 0.0; }

    // Count the number of transpositions
    var k = 0; var N_trans = 0;
    for (let i = 0; i < a_len; i++) {
        if (a_flag[i] === 1) {
            let j = 0;
            for (j = k; j < b_len; j++) {
                if (b_flag[j] === 1) {
                    k = j + 1;
                    break;
                }
            }
            if (a[i] !== b[j]) { N_trans++; }
        }
    }
    N_trans = Math.floor(N_trans / 2);

    // Adjust for similarities in nonmatched characters
    var N_simi = 0; var adjwt = jaro_winkler.adjustments;
    if (minv > Num_com) {
        for (let i = 0; i < a_len; i++) {
            if (!a_flag[i]) {
                for (let j = 0; j < b_len; j++) {
                    if (!b_flag[j]) {
                        if (adjwt[a[i]] === b[j]) {
                            N_simi += 3;
                            b_flag[j] = 2;
                            break;
                        }
                    }
                }
            }
        }
    }

    var Num_sim = (N_simi / 10.0) + Num_com;

    // Main weight computation
    var weight = Num_sim / a_len + Num_sim / b_len + (Num_com - N_trans) / Num_com;
    weight = weight / 3;

    // Continue to boost the weight if the strings are similar
    if (weight > 0.7) {
        // Adjust for having up to the first 4 characters in common
        let j = (minv >= 4) ? 4 : minv;
        let i = 0;
        for (i = 0; (i < j) && a[i] === b[i]; i++) { }
        if (i) { weight += i * 0.1 * (1.0 - weight) };

        // Adjust for long strings.
        // After agreeing beginning chars, at least two more must agree
        // and the agreeing characters must be more than half of the
        // remaining characters.
        if (minv > 4 && Num_com > i + 1 && 2 * Num_com >= minv + i) {
            weight += (1 - weight) * ((Num_com - i - 1) / (a_len * b_len - i * 2 + 2));
        }
    }

    return weight

    // The char adjustment table used above



}