import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';

export const BrandSearch = ({ callbackFromParent, searchKey, brandSearchIsNew }: any) => {

    const dataProvider = useDataProvider();
    const [brandList, setBrandList] = useState<Record<any, any>>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();


    const [selectedItem, setSelectedItem] = useState();


    useEffect(() => {

        if (typeof selectedItem === 'undefined' || brandSearchIsNew) {


            dataProvider.getList('brands', {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'title', order: 'ASC' },
                filter: { q: searchKey },

            })
                .then(({ data }) => {
                    setBrandList(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }
    }, [searchKey]);


    const selectData = (item: any) => {


        console.log('secilen', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item)


    }


    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (!brandList) return null;
    if (searchKey.length < 2) return null;


    const showResult = () => {

        if (selectedItem && !brandSearchIsNew) { //seçili item var ve yeni arama yok demektir!

            return <div></div>

        }
        if (brandSearchIsNew) { //seçili item yok ve arama var demek.
            return (
                <List dense={true} style={{ fontSize: "10px" }} >
                    {brandList.map((item: any) => (
                        <ListItem
                            key={item.id}
                            button
                            onClick={() => selectData(item)}
                        >

                            <ListItemText
                                primary={item.name}

                            />
                        </ListItem>
                    ))}
                </List>
            )
        }


    }


    return (


        <div>

            {showResult()}



        </div>
    )



};

