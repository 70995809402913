import react, { useState, useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify } from 'ra-core';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import stores from "../stores";

const useStyles = makeStyles({
    paper: {
        padding: 24,
        margin: 30,
        width: "35%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    },
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: "10px",
    },
    list: {
        width: "100%",
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: "20px",
    },
});

function not(a: any[], b: any[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: any[], b: any[]) {
    return [...a, ...not(b, a)];
}

const AddStoresToCity = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const [cityList, setCityList] = useState<any[]>([])
    const [storeList, setStoreList] = useState<any[]>([])
    const [store, setStore] = useState<any>("");
    const [checkedCities, setCheckedCities] = useState<any>([])
    const [addStoreToCities, setAddStoreToCities] = useState<any[]>([])
    const [refresh, setRefresh] = useState<boolean>(false)


    useEffect(() => {
        dataProvider.getList("cities", {
            pagination: { page: 1, perPage: 82 },
            sort: { field: 'name', order: 'ASC' },
            filter: {}
        }).then(resp => {
            setCityList(resp.data);
        }).catch(_ => {
            notify("Cities cannot be reached at the moment. Please try again later", "warning")
        })
    }, [])

    useEffect(() => {
        dataProvider.getList("stores", {
            pagination: { page: 1, perPage: 82 },
            sort: { field: 'name', order: 'ASC' },
            filter: {}
        }).then(resp => {
            setStoreList(resp.data);
        }).catch(_ => {
            notify("Cities cannot be reached at the moment. Please try again later", "warning")
        })
    }, [])

    useEffect(() => {
        setStore("")
        setCheckedCities([])
        setAddStoreToCities([])
    }, [refresh])

    const numberOfChecked = (items: any) => intersection(checkedCities, items).length;

    const handleToggle = (value: any) => () => {
        const currentIndex = checkedCities.indexOf(value);
        const newChecked = [...checkedCities];
        if (currentIndex === -1) {
            newChecked.push(value);

        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedCities(newChecked);
    };

    const handleToggleAll = (items: number[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setCheckedCities(not(checkedCities, items));
        } else {
            setCheckedCities(union(checkedCities, items));
        }
    };


    const handleAddCities = () => {

        setAddStoreToCities(checkedCities)
        setCheckedCities([])
    }

    const handleSave = async () => {
        await Promise.all(addStoreToCities.map(async (city: any) => {
            const cityStores = city.stores;
            if (!cityStores.includes(store.slug)) {
                cityStores.push(store.slug);
            }
            await dataProvider.update('cities',
                {
                    id: city.id,
                    data: { id: city.id, stores: cityStores }
                } as any)
                .then(async () => {
                    notify("Updated!")
                    setRefresh(!refresh)
                })
        }))
            .catch(_ => {
                notify("City update failed!", "warning")
                setRefresh(!refresh)
            })
    }

    const getCities = (selectedStore: any) => {
        const selectedStoreCities: any[] = [];

        cityList.map((city: any) => {
            city.stores.map((value: any, index: any) => {
                console.log(value + 'value')
                console.log(selectedStore)
                if (value === selectedStore.slug) {
                    selectedStoreCities.push(city);
                    console.log(city)
                }
            })
        })
        setAddStoreToCities(selectedStoreCities);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Paper className={classes.paper}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2>Stores</h2>
                    <Button color="secondary" onClick={() => { setStore("") }} disabled={!store}>Geri Dön</Button>
                </div>
                <hr />
                {(!store) ?
                    <Autocomplete
                        options={storeList}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        onChange={(_, value) => { setStore(value); getCities(value) }}
                        renderInput={(params) => <TextField {...params} label="Stores" variant="outlined" />}
                    /> :
                    <div style={{ justifyContent: "center" }}>
                        <Card>
                            <CardHeader
                                className={classes.cardHeader}
                                avatar={
                                    <MyLocationIcon />
                                }
                                title={store.name}
                                subheader={(addStoreToCities) ? `${addStoreToCities.length} stores.` : "0 stores."}
                            />
                            <List className={classes.list} dense component="div" role="list">
                                {(addStoreToCities && addStoreToCities.length) ? addStoreToCities.map((value) => {
                                    return (
                                        <ListItem key={value} role="listitem" button>
                                            <ListItemText id={value.id} primary={value.name} />
                                        </ListItem>
                                    );
                                }) :
                                    <ListItem key={"#"} role="listitem">
                                        <ListItemText id={"#"} primary={"No cities found!"} />
                                    </ListItem>}
                                <ListItem />
                            </List>
                        </Card>
                        <div style={{ height: "20px" }}></div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                        </div>
                    </div>
                }
            </Paper>
            <Paper className={classes.paper}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2>Cities</h2>
                </div>
                <hr />
                <Card>
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={
                            <Checkbox
                                onClick={handleToggleAll(cityList)}
                                checked={numberOfChecked(cityList) === cityList.length && cityList.length !== 0}
                                indeterminate={numberOfChecked(cityList) !== cityList.length && numberOfChecked(cityList) !== 0}
                                disabled={cityList.length === 0}
                            />
                        }
                        title={"Select All"}
                        subheader={`${numberOfChecked(cityList)}/${cityList.length} selected`}
                    />
                    <Divider />
                    <List className={classes.list} dense component="div" role="list">
                        {cityList.map((value) => {
                            return (
                                <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checkedCities.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={value.id} primary={value.name} />
                                </ListItem>
                            );
                        })}
                        <ListItem />
                    </List>
                </Card>
                <div style={{ height: "20px" }}></div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" color="primary" onClick={handleAddCities}>Add Cities</Button>
                </div>
            </Paper>
        </div>
    );
}

export default AddStoresToCity