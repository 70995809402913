import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
    Edit,
    EditProps,
    TextInput,
    FormWithRedirect,
    Toolbar,
    useTranslate,
    DateInput,
    Button

} from 'react-admin';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom'




export const ProductIdentifiersShow: FC<EditProps>  = (props : any) => {
    const transform = (data: any) => ({
        id: data.id,
        productName: data.productName,
        brand: data.brand,
        sku: data.sku,
        slug: data.slug,
        barcode: data.barcode,
        updateAt: data.updatedAt,
        productId: data.productId
        
    });

    return (
        <Edit
            component="div"
            {...props}
            transform={transform}
        >
            <ProductIdentifiersForm   />
        </Edit>
    );
};




const ProductIdentifiersForm = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();

    
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Product Identifiers Edit'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput label='Product Name' source="productName"  fullWidth />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput label='Link ID' source="linkId" fullWidth />
                                           
                                        </Box>
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput label='Slug' source="slug" fullWidth />
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                          <TextInput label='Sku' source="sku" fullWidth />
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput label='Barcode' source="barcode"  fullWidth />
                                        </Box>
                                    </Box>
                                    <Box mt="1em" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput label='Product Id' source="productId"  fullWidth />
                                        </Box>
                                    </Box>
                                   
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <DateInput source="updatedAt" />
                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Box display={{ xs: 'block', sm: 'flex' }}>

                                    </Box>
                                </Box>
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                   
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="productidentifiers"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default ProductIdentifiersShow;

const useStyles = makeStyles({
    root: { width: 600, margin: 'auto' },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
});
