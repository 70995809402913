import { useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { StoreSearch } from "./StoreSearch";
import { CitySearch } from "./CitySearch";
import { SendStoreSubmission } from "./SendStoreSubmission";
import { StoreLocationSearch } from "./StoreLocationSearch";
import { DeclinedStoreButton } from "./DeclinedStoreButton";
import { SuspiciousStoreButton } from "./SuspiciousStoreButton";
import Button from "@material-ui/core/Button";
import { FormGroup } from "reactstrap";
import { TownSearch } from "./TownSearch";
import { TextField } from "@material-ui/core";

export const SelectStore = ({ allData }: { allData: any }) => {
  const dataProvider = useDataProvider();
  const [storeDataWillSend, setStoreDataWillSend] = useState();

  const [selectedStore, setSelectedStore] = useState();
  const [selectedStoreLocation, setSelectedStoreLocation] = useState();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedTown, setSelectedTown] = useState("");

  const [storeName, SetStoreName] = useState("");

  const notify = useNotify();
  const [myFilter, setMyFilter] = useState("");
  const [myFilterStoreLocation, setMyFilterStoreLocation] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [townFilter, setTownFilter] = useState("");

  const handleChange = (e: any) => {
    setMyFilter(e.target.value);
  };

  const handleChangeStoreLoc = (e: any) => {
    setMyFilterStoreLocation(e.target.value);
  };

  const handleChangeCity = (e: any) => {
    setCityFilter(e.target.value);
  };

  const handleChangeTown = (e: any) => {
    setTownFilter(e.target.value);
  };

  //   const handleAddLabelSet = () => {
  //     dataProvider
  //       .create("Mapping", {
  //         data: {
  //           name: "new-label-mapping",
  //           key: allData.label.store,
  //           value: allData.label.image,
  //         },
  //       })
  //       .then((resp) => {
  //         notify("Label Set oluşturuldu.");
  //       })
  //       .catch((err) => {
  //         notify("Label Set oluşturma hatası", err);
  //       });
  //   };

  const createDataWillBeSend = () => {
    const myRecord = {} as any;

    myRecord.address = allData.address;
    myRecord.lat = allData.mapPosition.lat;
    myRecord.long = allData.mapPosition.lng;

    setStoreDataWillSend(myRecord);
    //  console.log(storeDataWillSend)
  };

  const changeName = async (e: any) => {
    SetStoreName(e.target.value);
  };

  const myCallback = (dataFromChild: any) => {
    //  console.log('Selected Store', JSON.stringify(dataFromChild))

    setSelectedStore(dataFromChild);
    setMyFilter(dataFromChild.name);
  };

  const myCallbackStoreLocation = (dataFromChild: any) => {
    setSelectedStoreLocation(dataFromChild);
    setMyFilterStoreLocation(dataFromChild.name);
  };

  const myCallbackCity = (dataFromChild: any) => {
    setSelectedCity(dataFromChild);
    setCityFilter(dataFromChild.name);
    //   console.log('Selected ... ' + JSON.stringify(selectedProduct))
  };

  const myCallbackTown = (dataFromChild: any) => {
    //console.log('Selecteddd town', JSON.stringify(dataFromChild))

    setSelectedTown(dataFromChild);
    setTownFilter(dataFromChild.name);
  };

  const cityParameterId = () => {
    if (typeof selectedCity !== "undefined") {
      return selectedCity.id;
    } else {
      return 0;
    }
  };

  const cityParameterName = () => {
    if (typeof selectedCity !== "undefined") {
      return selectedCity.name;
    } else {
      return "";
    }
  };

  const townArea = () => {
    if (selectedCity) {
      return (
        <div>
          <FormGroup>
            <TextField
              id="outlined-read-only-input"
              label="Town"
              value={townFilter}
              placeholder="Search in Town"
              onChange={handleChangeTown}
              className={"mt-1"}
              autoComplete="off"
              disabled={true}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
            />
            <br />
            <br />

            <TownSearch
              callbackFromParent={myCallbackTown}
              searchKey={allData.city}
              cityId={cityParameterId()}
              cityName={cityParameterName()}
            />
          </FormGroup>
        </div>
      );
    } else {
      return (
        <div>
          <FormGroup>
            <TextField
              id="outlined-read-only-input"
              label="Please select city first"
              type="search"
              value="Please select city first"
              className={"mt-1"}
              autoComplete="off"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
            />

            <br />
            <br />
          </FormGroup>
        </div>
      );
    }
  };

  return (
    <div style={{ backgroundColor: "#ffffff", padding: "1%" }}>
      <FormGroup>
        <br />

        <TextField
          id="outlined-read-only-input"
          label="Please select city first"
          type="search"
          value={cityFilter}
          placeholder="Search in City"
          onChange={handleChangeCity}
          className={"mt-1"}
          autoComplete="off"
          disabled={true}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          fullWidth
        />

        <br />
        <br />

        <CitySearch
          callbackFromParent={myCallbackCity}
          searchKey={allData.state}
        />
      </FormGroup>

      {townArea()}

      <FormGroup>
        <TextField
          id="outlined-read-only-input"
          label="Store"
          type="search"
          value={myFilter}
          placeholder="Search in Store"
          onChange={handleChange}
          className={"mt-1"}
          autoComplete="off"
          disabled={true}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          fullWidth
        />

        <br />
        <br />

        <StoreSearch
          callbackFromParent={myCallback}
          searchKey={allData.store}
        />
        <TextField
          id="outlined-read-only-input"
          label="Search in StoreLocations"
          type="search"
          value={myFilter}
          onChange={handleChangeStoreLoc}
          className={"mt-1"}
          autoComplete="off"
          disabled={false}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          fullWidth
        />

        <StoreLocationSearch
          labelId={allData.labelId}
          callbackFromParent={myCallbackStoreLocation}
          searchKey={myFilterStoreLocation}
          slug={myFilter.toLowerCase()}
        />
      </FormGroup>
      <TextField
        id="outlined-helperText"
        label="Store Name"
        defaultValue="Default Value"
        helperText="Some important text"
        variant="outlined"
        value={storeName}
        onChange={changeName}
        fullWidth
      />
      <br />

      <SendStoreSubmission
        selectedStore={selectedStore}
        storeName={storeName}
        selectedTown={selectedTown}
        record={allData}
        selectedCity={selectedCity}
        selectedStoreLocation={selectedStoreLocation}
      />
      <DeclinedStoreButton labelId={allData.labelId} />
      <SuspiciousStoreButton labelId={allData.labelId} />
      {/* <Button
        variant="outlined"
        size="large"
        color="primary"
        onClick={handleAddLabelSet}
      >
        {" "}
        Label Set Ekle{" "}
      </Button> */}
    </div>
  );
};
