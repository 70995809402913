import { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import { useDataProvider, useNotify } from "ra-core";
import { Loading } from "react-admin";
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import React from "react";
import CompletedPage from "../shared/CompletedPage";
import BarcodeCreate from "./BarcodeCreate";
import { elasticParenthesesRemover } from "../Helper";
import axios from "axios";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 24,
        margin: 30,
        width: "55%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    },
    paperLeft: {
        padding: 24,
        margin: 30,
        width: "25%",
        height: "100%",
        backgroundColor: "#cdcdcd",
    },
    root: {
        height: 400,
        overflow: "scroll"
    },
    cardHeader: {
        padding: "10px",
    },
    list: {
        width: "100%",
        height: 230,
        overflow: "auto",
    },
    button: {
        margin: "20px",
    },
    prod: {},
    prodSelected: { backgroundColor: "#808080", },
    popover: {
        pointerEvents: 'none',
    },
    paperName: {
        width: 'auto',
        padding: theme.spacing(2),
        backgroundColor: "#F8F8F8",
        display: "inline-flex",
        alignItems: 'center'
    },
}));

const BarcodeSubmissions = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const [count, setCount] = useState<number>(21);
    const [barcode, setBarcode] = useState<any>();
    const [barcodeScan, setBarcodeScan] = useState<any>();
    const [newBarcode, setNewBarcode] = useState<any>();
    const [productList, setProductList] = useState<any>();
    const [productIds, setProductIds] = useState<any>([]);
    const [anchorElName, setAnchorElName] = React.useState<HTMLElement | null>(null);
    const [openedPopoverId, setOpenedPopoverId] = React.useState<any>();
    const openName = Boolean(anchorElName);
    const [selectedName, setSelectedName] = useState("");
    const [done, setDone] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);

    const handlePopoverOpenName = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: any) => {
        setOpenedPopoverId(id);
        setAnchorElName(event.currentTarget);
    };

    const handlePopoverCloseName = () => {
        setOpenedPopoverId(null);
        setAnchorElName(null);
    };

    useEffect(() => {
        if (selectedName) {
            dataProvider
                .getList("products", {
                    pagination: { page: 1, perPage: 50 },
                    sort: { field: "title", order: "ASC" },
                    filter: { query: elasticParenthesesRemover(selectedName) },
                })
                .then((response) => {
                    setProductList(response.data);
                })
        }
    }, [selectedName])


    useEffect(() => {
        setBarcode("");
        setBarcodeScan("");
        setSelectedName("");
        setProductIds([]);
        setProductList([]);

        dataProvider.getList("barcodescans", {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "title", order: "ASC" },
            filter: {
                isReviewed: false
            },
        }).then((res: any) => {
            setCount(res.total)
            if (res.data[0]) {
                if(!(res.data[0].barcode.length == 8 || res.data[0].barcode.length == 13))  {
                    handleWrongBarcode(res.data[0]);
                }
                else  {
                    setBarcodeScan(res.data[0])
                    dataProvider
                        .getList("barcodes", {
                            pagination: { page: 1, perPage: 1 },
                            sort: { field: "title", order: "ASC" },
                            filter: { barcode: res.data[0].barcode },
                        })
                        .then((resp) => {
                            if (resp.data && resp.data[0]) {
                                if (resp.data[0].brand) {
                                    resp.data[0].name = resp.data[0].brand + " " + resp.data[0].name;
                                }
                                setBarcode(resp.data[0]);

                                dataProvider
                                    .getList("products", {
                                        pagination: { page: 1, perPage: 50 },
                                        sort: { field: "title", order: "ASC" },
                                        filter: { query: elasticParenthesesRemover(resp.data[0].name) },
                                    })
                                    .then((response) => {
                                        setProductList(response.data);
                                    })
                            } else {
                                setNewBarcode(true)
                            }
                        })
                        .catch((_) => {
                            notify("Barkodları çekerken hata aldım.", "warning");
                        });
                }
            } else {
                setDone(true)
            }
        })

    }, [refresh]);

    const handleClick = (productId: any) => {
        const array: any[] = productIds;
        if (!array.includes(productId)) {
            array.push(productId);
        }
        else {
            for (let i = 0; i < array.length; i++) {
                if (array[i] === productId) {
                    array.splice(i, 1);
                }
            }
        }
        setProductIds(array);
    }

    const handleSave = () => {
        dataProvider.updateMany('products', { ids: productIds, data: { barcode: barcode.barcode, jobActivityType: "MERGE_BARCODE" } }).then(_ => {
            const product = productList.find((e: any) => e.id == productIds[0])
            dataProvider
                .create("mergesubmissions", {
                    data: {
                        productIds: productIds,
                        selectedName: product.name,
                        selectedBrand: product.brand,
                        selectedCategory: product.category,
                        selectedImages: product.images,
                    },
                }).then(_ => {
                    dataProvider.update('barcodescans', {
                        id: barcodeScan.id,
                        data: { id: barcodeScan.id, isReviewed: true },
                        previousData: { title: "previous title" } as any
                    }).then(_ => {
                        if (productIds.length == 0) {
                            notify("Bir sonraki taramayı getiriyorum!")
                        } else {
                            notify("Seçili ürünlere " + barcode.barcode + " barkod eklendi!")
                        }
                        setRefresh(!refresh)
                    })
                })
        }).catch(err => {
            notify("Ürünleri güncellerken hata aldım!", "warning")
        })
    }

    const handleSkip = () => {
        dataProvider.update('barcodescans', {
            id: barcodeScan.id,
            data: { id: barcodeScan.id, isReviewed: true },
            previousData: { title: "previous title" } as any
        }).then(_ => {
            if (productIds.length == 0) {
                notify("Bir sonraki taramayı getiriyorum!")
            } else {
                notify("Seçili ürünlere " + barcode.barcode + " barkod eklendi!")
            }
            setRefresh(!refresh)
        }).catch(_ => {
            notify("Atlayamadım!", "warning")
        })
    }

    const handleWrongBarcode = (barcodeScan:any) => {
        dataProvider.update('barcodescans', {
            id: barcodeScan.id,
            data: { id: barcodeScan.id, isReviewed: true },
            previousData: { title: "previous title" } as any
        }).then(_ => {
            setRefresh(!refresh)
        }).catch(_ => {
            notify("???!", "warning")
        })
    }

    const handleChangeName = (alias: string) => {
        if(!selectedName.includes(barcode.barcode))
            setSelectedName(barcode.brand + " " + alias);
        if(!barcode.aliases.includes(barcode.name))
            barcode.aliases.push(barcode.name)
        barcode.name = alias;
        setBarcode(barcode);
    }

    if (done) return <CompletedPage></CompletedPage>
    if (newBarcode) return <BarcodeCreate barcodeScan={barcodeScan} count={count} />
    if (!barcode) return <Loading></Loading>;

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Paper className={classes.paperLeft}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "inline-block" }}>
                        <h3>Barkod</h3>
                        <p>{barcode.barcode}</p>
                    </div>
                </div>
                <hr />
                <List component="nav" className={classes.root} aria-label="contacts">
                    <div style={{ display: "flex" }}>
                        <h4>{barcode.brand ? barcode.brand + " " + barcode.name : barcode.name}</h4>
                    </div>
                    <hr />
                    {barcode.aliases.map((alias: string) => (
                        <div style={{ display: "flex" }} onClick={() => handleChangeName(alias)}>
                            <p>{alias}</p>
                        </div>
                    ))}

                </List>
            </Paper>
            <Paper className={classes.paper}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2>Ürünler</h2>
                    <p>Kalan : {count}</p>
                </div>

                <hr />
                <List dense className={classes.root}>
                    {productList?.map((product: any) => {
                        const labelId = `checkbox-list-secondary-label-${product.id}`;
                        return (

                            <ListItem
                                key={product.id}
                                aria-owns={openName ? 'mouse-over-popover-name' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={(e: any) => handlePopoverOpenName(e, product.id)}
                                onMouseLeave={handlePopoverCloseName}
                                className={openedPopoverId === product.id ? classes.prodSelected : classes.prod}
                            >

                                <ListItemText
                                    id={labelId}
                                    primary={`${product.name} (${(product.barcode) ? product.barcode : ""})`}
                                />

                                <Popover
                                    id="mouse-over-popover-name"
                                    className={classes.popover}
                                    classes={{
                                        paper: classes.paperName,
                                    }}
                                    open={openedPopoverId === product.id}
                                    anchorEl={anchorElName}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    onClose={handlePopoverCloseName}
                                    disableRestoreFocus
                                >
                                    <img src={(product.images && product.images[0]) ? product.images[0].url : ""} alt="" style={{ width: 200, height: 200 }} />
                                </Popover>
                                <Checkbox
                                    defaultChecked={productIds.includes(product.id)}
                                    onClick={() => handleClick(product.id)}
                                />
                            </ListItem>
                        );
                    })}
                </List>
                <div style={{ height: "2em" }}></div>
                <Typography >{productIds.length} Ürün birleştirilecek.</Typography>
                <hr />
                <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", width: "100%" }}>
                    <Button variant="contained" color="inherit" fullWidth onClick={handleSkip}>Atla</Button>
                    <Button variant="contained" color="primary" fullWidth onClick={handleSave} disabled={!productIds.length}>Kaydet</Button>
                </div>
            </Paper>
        </div>
    );
};



export default BarcodeSubmissions;
