import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { Col, Container, Row } from 'reactstrap';

export const TownSearch = ({ callbackFromParent, searchKey, cityId, cityName }: { callbackFromParent: any, searchKey: any, cityId: any, cityName: any }) => {


    const dataProvider = useDataProvider();
    const [townList, setTownList] = useState<any>();
    const [loading, setLoading] = useState(true);


    const [selectedItem, setSelectedItem] = useState<any>();


    useEffect(() => {
        dataProvider.getList('towns', {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'title', order: 'ASC' },
            filter: { q: searchKey, city: cityName },

        })
            .then((data) => {
                setSelectedItem(data.data[0]);
                callbackFromParent(data.data[0]);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }, [searchKey]);


    const selectData = (item: any) => {


        //  console.log('secilen Town', JSON.stringify(item))
        setSelectedItem(item);
        callbackFromParent(item)


    }

    if (cityId === 0) {
        return null
    }
    if (cityName === '') {
        return null
    }

    if (loading) return <Loading />;
    //if (error) return <Error />;
    if (!townList) return null;
    if (selectedItem) return null;

    if (searchKey.length < 1) return null;

    const textMiddle = (value: any) => {

        if (selectedItem) {
            return <div>{selectedItem.name}</div>
        }
        else {
            return <div>{value}</div>
        }

    }

    return (


        <div>

            {townList.map((item: any) =>
                <Container key={item.id}>

                    <div key={item.id} className={"customListItem"}>


                        <Row>
                            <Col xs="9"><span onClick={() => selectData(item)} >{textMiddle(item.name)} </span></Col>
                        </Row>



                    </div>
                </Container>
            )}

        </div>
    )



};

