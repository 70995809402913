import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    SelectInput,
    ReferenceInput,
    useDataProvider,
    useNotify,

}
    from 'react-admin';

import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import Aside from './MappingAside';

const MappingEdit: FC<EditProps> = props => {

    return (
        <Edit
            aside={<Aside />}
            component="div"
            {...props}
        >
            <MappingForm />
        </Edit>
    );
};

const MappingForm = (props: any) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [uniqueMappings, setUniqueMappings] = useState<any>([]);
    const notify = useNotify();

    useEffect(() => {
        dataProvider.getList("mappings", {
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'title', order: 'ASC' },
            filter: { uniqueMappings: true }
        }).then((resp) => {
            if (resp.data) setUniqueMappings(resp.data);
        }).catch((err) => {
            notify(err);
        })
    }, [])
    // const [newMappingName, setNewMappingName] = useState<boolean>();

    // let mappingName = <ReferenceInput label="Mapping Name" source="name" reference="mappingnames">
    //     <SelectInput optionText="name" fullWidth />
    // </ReferenceInput>
    // if (newMappingName) {
    //     mappingName = <TextInput
    //         source="name"
    //         resource="mappingnames"
    //         validate={requiredValidate}
    //         fullWidth
    //     />
    // }
    return (
        <FormWithRedirect

            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'Key'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="key"
                                                resource="mappings"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="value"
                                                resource="mappings"
                                                validate={requiredValidate}
                                                fullWidth
                                            />

                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <SelectInput source="name" optionText="name" choices={uniqueMappings} fullWidth />
                                            {/* {mappingName}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => newMappingName ? setNewMappingName(false) : setNewMappingName(true)} fullWidth
                                            >
                                                New Name
                                            </Button> */}

                                            {/* <ReferenceInput source="name" reference="mappingnames"> */}
                                            {/* <AutocompleteInput
                                                    optionText={(choice: MappingName) =>
                                                        choice.name // the empty choice is { id: '' }
                                                    }
                                                /> */}

                                            {/* <SelectInput source="name" reference="brands" /> */}
                                            {/* 
                                            </ReferenceInput> */}
                                            {/* <SelectInput source="mappingnames" optionText="name" choices={[
                                                { id: MappingName.name, name: "careffour-brand" },
                                                { id: MappingName.name, name: "sok-sku" },
                                                { id: MappingName.name, name: "sok-image" },
                                                { id: MappingName.name, name: "sok-brand" },
                                                { id: MappingName.name, name: "careffour-barcode" },
                                                { id: MappingName.name, name: "migros-barcode" },
                                                { id: MappingName.name, name: "fiyatinabak-page" },
                                                { id: MappingName.name, name: "marul-barcode" },
                                                { id: MappingName.name, name: "fiyatinabak-remove" },
                                                { id: MappingName.name, name: "cimri-category-brands" },
                                                { id: MappingName.name, name: "marketpaketi-barcode" },
                                                { id: MappingName.name, name: "careffour-migros" },
                                                { id: MappingName.name, name: "careffour-a101" },
                                                { id: MappingName.name, name: "barcode-brands" },
                                                { id: MappingName.name, name: "cimri-product" },
                                                { id: MappingName.name, name: "a101-barcode" },
                                                { id: MappingName.name, name: "Repeatable Rediss-Job" },
                                                { id: MappingName.name, name: "price-refresh-rate" }
                                            ]
                                            } fullWidth /> */}

                                        </Box>
                                    </Box>
                                    <Box mt="1em" />
                                    <Box mt="1em" />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="mappings"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default MappingEdit;
