import { Component } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-cropper";
import Button from '@material-ui/core/Button';
import "cropperjs/dist/cropper.css";
import { Box, TextField, Typography } from "@material-ui/core";
import cropper from "cropperjs";



const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

const App = () => (
    <div >
        <LabelSample />
    </div>
);

interface IProps {
    defaultLabel?: {
        url: any,
    };
    storeSlugs?: any;
    setState?: {};

}

interface IState {
    src: any,
    cropResult: string,
    labelSet: {},
    barcodeMOE: number,
    priceMOE: number,
    oldPriceMOE: number,
    nameMOE: number,
    skuMOE: number,
    seedMOE: number,
    cropHeight: number,
    nameCropped: string | undefined,
    priceCropped: string | undefined,
    oldPriceCropped: string | undefined,
    skuCropped: string | undefined,
    barcodeCropped: string | undefined,
    seedCropped: string | undefined,
    nameHeight: number | undefined,
    priceHeight: number | undefined,
    oldPriceHeight: number | undefined,
    skuHeight: number | undefined,
    barcodeHeight: number | undefined,
    seedHeight: number | undefined,
    alreadySetStoreSlugs: boolean,
    dropdownStatus: boolean,
    selectedStore: "Select Store",
}

const src = "";
let labelObject: any = {};
let alreadySet = false;
let labelArea = 0;
export default class LabelSample extends Component<IProps, IState> {
    cropper: cropper;
    labelArea: any;
    state: any;
    props: any;
    constructor(props: any) {
        super(props);
        this.state = {
            src,
            cropResult: null,
            labelSet: {},
            barcodeMOE: 0,
            priceMOE: 0,
            oldPriceMOE: 0,
            nameMOE: 0,
            skuMOE: 0,
            seedMOE: 0,
            cropHeight: 0,
            nameCropped: "",
            priceCropped: "",
            oldPriceCropped: "",
            skuCropped: "",
            barcodeCropped: "",
            seedCropped: "",
            nameHeight: "",
            priceHeight: "",
            oldPriceHeight: "",
            skuHeight: "",
            barcodeHeight: "",
            seedHeight: "",
            alreadySetStoreSlugs: false,
            dropdownStatus: false,
            selectedStore: "Select Store",
        };
        this.cropImage = this.cropImage.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
        this.onChange = this.onChange.bind(this);

    }
    initializeCropper(e: any) {
        this.cropper = e;
    }
    onChange(e: any) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result });
        };
        reader.readAsDataURL(files[0]);
    }

    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            return;
        }
        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL()
        });
    }

    useDefaultImage() {
        this.setState({ src: this.props.defaultLabel });
    }

    handleChange(e: any) {
        labelObject.store = e;
        this.setState({ dropdownStatus: false, selectedStore: e });
    }

    handleClick = () => {
        this.setState({
            cropHeight: this.cropper.getData().height,
        })
    }

    handleInitialCrop = () => {

        this.setState({ src: this.cropper.getCroppedCanvas().toDataURL() })
        labelObject.width = this.cropper.getData().width;
        labelObject.height = this.cropper.getData().height;
        this.labelArea = labelObject.height * labelObject.width;
    }

    handleSet(type: any) {
        if (type == "name") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper.getData().x && this.cropper.getData().x > 0) left = this.cropper?.getData().x;
            else left = 0;
            if (this.cropper?.getData().width) {
                right = left + this.cropper.getData().width;
            }
            if (this.cropper!.getData().y > 0) top = this.cropper!.getData().y;
            else top = 0;
            if (this.cropper?.getData().height) {
                bottom = top + this.cropper.getData().height;
            }
            labelObject.productNameRect = { left, top, right, bottom }
            this.setState({ nameCropped: this.cropper?.getCroppedCanvas().toDataURL() })
            this.setState({ nameHeight: this.cropper?.getData().height })
            if (this.cropper?.getData().height) {
                labelObject.productNameMinHeight = this.cropper.getData().height;
            }
            this.setState({ nameMOE: this.calculateMarginOfError(labelObject.productNameRect) });
            labelObject.productNameMOE = this.calculateMarginOfError(labelObject.productNameRect);
            labelObject.croppedName = this.cropper?.getCroppedCanvas();
        }
        if (type == "sku") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper?.getData().x && this.cropper.getData().x > 0) left = this.cropper.getData().x;
            else left = 0;
            right = left + this.cropper!.getData().width;
            if (this.cropper?.getData().y && this.cropper!.getData().y > 0) top = this.cropper.getData().y;
            else top = 0;
            if (this.cropper?.getData().height) {
                bottom = top + this.cropper.getData().height;
            }
            labelObject.skuRect = { left, top, right, bottom }
            this.setState({ skuCropped: this.cropper?.getCroppedCanvas().toDataURL() })
            this.setState({ skuHeight: this.cropper?.getData().height })
            if (this.cropper?.getData().height) {
                labelObject.skuMinHeight = this.cropper?.getData().height / 2;
            }
            this.setState({ skuMOE: this.calculateMarginOfError(labelObject.skuRect) });
            labelObject.skuMOE = this.calculateMarginOfError(labelObject.skuRect);
            labelObject.croppedSku = this.cropper!.getCroppedCanvas();
        }
        if (type == "price") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper?.getData().x && this.cropper.getData().x > 0) left = this.cropper.getData().x;
            else left = 0;
            if (this.cropper?.getData().width)
                right = left + this.cropper.getData().width;
            if (this.cropper?.getData().y && this.cropper.getData().y > 0) top = this.cropper.getData().y;
            else top = 0;
            if (this.cropper?.getData().height) {
                bottom = top + this.cropper.getData().height;
            }
            labelObject.priceRect = { left, top, right, bottom }
            this.setState({ priceCropped: this.cropper?.getCroppedCanvas().toDataURL() })
            this.setState({ priceHeight: this.cropper?.getData().height })
            if (this.cropper?.getData().height) {
                labelObject.priceMinHeight = this.cropper.getData().height / 2;
            }
            this.setState({ priceMOE: this.calculateMarginOfError(labelObject.priceRect) });
            labelObject.priceMOE = this.calculateMarginOfError(labelObject.priceRect);
            labelObject.croppedPrice = this.cropper?.getCroppedCanvas();
        }
        if (type == "oldPrice") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper?.getData().x && this.cropper.getData().x > 0) left = this.cropper.getData().x;
            else left = 0;
            if (this.cropper) {
                right = left + this.cropper.getData().width;
            }
            if (this.cropper?.getData().y && this.cropper.getData().y > 0) top = this.cropper.getData().y;
            else top = 0;
            if (this.cropper?.getData().height) {
                bottom = top + this.cropper.getData().height;
            }
            labelObject.oldPriceRect = { left, top, right, bottom }
            this.setState({ oldPriceCropped: this.cropper?.getCroppedCanvas().toDataURL() })
            this.setState({ oldPriceHeight: this.cropper?.getData().height })
            if (this.cropper?.getData().height) {
                labelObject.oldPriceMinHeight = this.cropper.getData().height / 2;
            }
            this.setState({ oldPriceMOE: this.calculateMarginOfError(labelObject.oldPriceRect) });
            labelObject.oldPriceMOE = this.calculateMarginOfError(labelObject.oldPriceRect);
            labelObject.croppedOldPrice = this.cropper?.getCroppedCanvas();
        }
        if (type == "barcode") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper?.getData().x && this.cropper.getData().x > 0) left = this.cropper.getData().x;
            else left = 0;
            if (this.cropper?.getData().width) {
                right = left + this.cropper.getData().width;
            }
            if (this.cropper?.getData().y && this.cropper.getData().y > 0) top = this.cropper.getData().y;
            else top = 0;
            if (this.cropper?.getData().height) {
                bottom = top + this.cropper.getData().height;
            }
            labelObject.barcodeRect = { left, top, right, bottom }
            this.setState({ barcodeCropped: this.cropper?.getCroppedCanvas().toDataURL() })
            this.setState({ barcodeHeight: this.cropper?.getData().height })
            if (this.cropper?.getData().height) {
                labelObject.barcodeMinHeight = this.cropper.getData().height / 2;
            }
            this.setState({ barcodeMOE: this.calculateMarginOfError(labelObject.barcodeRect) });
            labelObject.barcodeMOE = this.calculateMarginOfError(labelObject.barcodeRect);
            labelObject.croppedBarcode = this.cropper?.getCroppedCanvas();
        }

        if (type == "seed") {
            let left;
            let top;
            let right;
            let bottom;
            if (this.cropper?.getData().x && this.cropper.getData().x > 0) left = this.cropper.getData().x;
            else left = 0;
            if (this.cropper?.getData().width) {
                right = left + this.cropper.getData().width;
            }
            if (this.cropper?.getData().y && this.cropper.getData().y > 0) top = this.cropper.getData().y;
            else top = 0;
            if (this.cropper?.getData().height) {
                bottom = top + this.cropper.getData().height;
            }
            labelObject.seedRect = { left, top, right, bottom }
            this.setState({ seedCropped: this.cropper?.getCroppedCanvas().toDataURL() })
            this.setState({ seedHeight: this.cropper?.getData().height })
            if (this.cropper?.getData().height) {
                labelObject.seedMinHeight = this.cropper.getData().height / 2;
            }
            this.setState({ seedMOE: this.calculateMarginOfError(labelObject.seedRect) });
            labelObject.seedMOE = this.calculateMarginOfError(labelObject.seedRect);
            labelObject.croppedSeed = this.cropper?.getCroppedCanvas();
        }
    }

    calculateMarginOfError(rect: any) {

        let area = (rect.right - rect.left) * (rect.bottom - rect.top);
        let formulaResult = (this.labelArea * 1.4) / (this.labelArea * 0.12727 + area);
        if (formulaResult > 10) return 10;
        if (formulaResult < 2) return 2;
        if (Number(formulaResult.toFixed(1).charAt(1)) > 5) return Number(formulaResult.toFixed(0)) + 1;
        return Number(formulaResult.toFixed(0));
    }

    render() {
        if (this.props.defaultLabel && !alreadySet) {
            alreadySet = true;
            this.setState({ src: this.props.defaultLabel })
        }
        return (


            <div>
                <Box p="1em">
                    <Box display="flex">
                        <Box flex={2} mr="1em">

                            <Typography variant="h6" gutterBottom>Cropper</Typography>
                            <Cropper
                                style={{ height: 340, width: '100%' }}
                                preview=".img-preview"
                                guides={true}
                                src={this.state.src}
                                onInitialized={(instance) => {
                                    this.initializeCropper(instance);
                                }}
                                viewMode={1}
                                cropBoxMovable={true}
                                zoomable={false}
                                movable={false}
                                onClick={this.handleClick}
                            />
                            <Box mt="1em" />
                            <Button onClick={this.handleInitialCrop} size="large"> Initial Crop  </Button>
                            <Button onClick={() => { this.handleSet("name") }} size="large"> Select Name  </Button>
                            <Button onClick={() => { this.handleSet("price") }} size="large"> Select Price  </Button>
                            <Button onClick={() => { this.handleSet("oldPrice") }} size="large"> Select OldPrice  </Button>
                            <Button onClick={() => { this.handleSet("sku") }} size="large"> Select Sku  </Button>
                            <Button onClick={() => { this.handleSet("barcode") }} size="large"> Select Barcode  </Button>
                            <Button onClick={() => { this.handleSet("seed") }} size="large"> Select Seed  </Button>
                            <Button color="primary" onClick={() => { this.props.setLabelSet(labelObject) }} size="large"> Update  </Button>




                        </Box>

                        <Box flex={1} ml="1em">

                            <Typography variant="h6" gutterBottom>Stats</Typography>

                            <TextField
                                value={this.state.skuMOE}
                                id="outlined-number"
                                label="SKU Margin of Error"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                value={this.state.barcodeMOE}
                                id="outlined-number"
                                label="Barcode Margin of Error"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                value={this.state.nameMOE}
                                id="outlined-number"
                                label="Name Margin of Error"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                value={this.state.priceMOE}
                                id="outlined-number"
                                label="Price Margin of Errorumber"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                value={this.state.oldPriceMOE}
                                id="outlined-number"
                                label="OldPrice Margin of Error"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                value={this.state.seedMOE}
                                id="outlined-number"
                                label="Seed Margin of Error"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                        </Box>
                        <Box flex={1} ml="1em">

                            <Typography variant="h6" gutterBottom>Stats</Typography>

                            <TextField
                                onChange={(e: any) => labelObject.skuMinHeight = Number(e.target.value)}
                                id="outlined-number"
                                label="SKU Minimum Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                onChange={(e: any) => labelObject.barcodeMinHeight = Number(e.target.value)}
                                id="outlined-number"
                                label="Barcode Minimum Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                onChange={(e: any) => labelObject.productNameMinHeight = Number(e.target.value)}
                                id="outlined-number"
                                label="Name Minimum Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                onChange={(e: any) => labelObject.priceMinHeight = Number(e.target.value)}
                                id="outlined-number"
                                label="Price Minimum Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                onChange={(e: any) => labelObject.oldPriceMinHeight = Number(e.target.value)}
                                id="outlined-number"
                                label="OldPrice Minimum Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                        </Box>
                        <Box flex={1} ml="1em">

                            <Typography variant="h6" gutterBottom>Stats</Typography>

                            <TextField
                                value={this.cropper ? this.cropper.getCropBoxData().height : ""}
                                id="outlined-number"
                                label="Cropper Height "
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                value={(this.cropper) ? 0.5 * this.cropper.getCropBoxData().height : ""}
                                id="outlined-number"
                                label="0.5 * Cropper Height "
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                onChange={(e: any) => labelObject.seed = Number(e.target.value)}
                                id="outlined-number"
                                label="Seed"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                            <Box mt="1em" />
                            <TextField
                                onChange={(e: any) => labelObject.skuRegex = Number(e.target.value)}
                                id="outlined-number"
                                label="Sku Regex"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </Box>

            </div>

        );


    }
}

ReactDOM.render(<App />, document.getElementById("root"));
