import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CategorylessProduct from "./CategorylessProduct";
import SimilarProducts from "./SimilarProduct";
import TransferList from "./TransferList"
import SelectionComponent from "./SelectionComponent"
import Grid from '@material-ui/core/Grid';
import { CardHeader } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default function CategoryMerger() {

    const [rootProduct, setRootProduct] = useState<any>();
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryIds, setCategoryIds] = useState([]);
    const [productList, setProductList] = useState<any>([]);


    const setRoot = (p: any) => {
        setRootProduct(p);
        p ? setSearchTerm(p.name) : setSearchTerm("elma");
    };

    useEffect(() => {
        if (productList.length === 0) {
            setProductList([rootProduct]);
        };

    }, [rootProduct, categoryIds]);


    return (
        <div style={{ justifyContent: "top", flex: 1, flexDirection: "row", display: "inline-block" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                        <Grid key={"1"} >
                            <Card elevation={10} style={{ height: "500px", width: "400px", marginLeft: "20px", marginTop: "10px", alignItems: "center", }}>
                                <CardHeader style={{ backgroundColor: "#30526a", color: "#ffffff", textAlign: "center" }} title="Kategorize Edilmemiş Ürün"></CardHeader>
                                <CardContent style={{ padding: "1%" }}>
                                    <CategorylessProduct outProduct={setRoot} setsearchTerm={setSearchTerm}></CategorylessProduct>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid key={"2"} item>
                            <Card elevation={10} style={{ alignItems: "center", height: "500px", width: "400px", marginLeft: "40px", marginTop: "10px" }}>
                                <CardHeader style={{ backgroundColor: "#30526a", color: "#ffffff", textAlign: "center" }} title="Kategori Seçimi"></CardHeader>
                                <CardContent style={{ padding: "1%" }}>

                                    <SelectionComponent transferData={categoryIds} productList={productList}></SelectionComponent>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid key={"3"} item>
                            <Card elevation={10} style={{ alignItems: "center", height: "500px", width: "400px", marginLeft: "40px", marginTop: "10px" }}>
                                <CardHeader style={{ backgroundColor: "#30526a", color: "#ffffff", textAlign: "center" }} title="Benzer Ürün    "></CardHeader>
                                <CardContent style={{ padding: "1%" }}>
                                    <SimilarProducts searchTerm={searchTerm} outCategories={setCategoryIds}></SimilarProducts>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid key={4} item>
                            <Paper elevation={10} style={{ alignItems: "center", height: "450px", width: "1312px", marginLeft: "20px", marginTop: "10px" }}>
                                <TransferList searchTerm={searchTerm} root={rootProduct} outProductList={setProductList}></TransferList>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>);
}