import React, { FC } from "react";

import { Filter, SearchInput, FilterProps, SelectInput } from "react-admin";

const JobActivityFilter: FC<Omit<FilterProps, "children">> = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput
        source="jobType"
        alwaysOn
        choices={[
          { id: "MERGE_SLIDER", name: "MERGE_SLIDER" },
          { id: "MERGE_BARCODE", name: "MERGE_BARCODE" },
          { id: "CAMPAIGN_SUBMISSION", name: "CAMPAIGN_SUBMISSION" },
          { id: "STORE_SUBMISSION", name: "STORE_SUBMISSION" },
          { id: "PRODUCT_SUBMISSION", name: "PRODUCT_SUBMISSION" },
          { id: "PRICE_SUBMISSION", name: "PRICE_SUBMISSION" },
          { id: "LABEL_SUBMISSION", name: "LABEL_SUBMISSION" },
          { id: "POST_SUBMISSION", name: "POST_SUBMISSION" },
          { id: "BRAND_SANITIZE", name: "BRAND_SANITIZE" },
          { id: "BARCODE_SANITIZE", name: "BARCODE_SANITIZE" },
          { id: "CATEGORY_MERGE", name: "CATEGORY_MERGE" },
          { id: "MERGE_SIBLING", name: "MERGE_SIBLING" },
          { id: "MERGE_SIMILAR", name: "MERGE_SIMILAR" },
        ]}
      ></SelectInput>
    </Filter>
  );
};

export default JobActivityFilter;
