import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import BarcodelessProduct from "./GetBarcodelessProduct";
import GetBarcodes from "./GetBarcodes";
import { CardHeader, FilledInput } from '@material-ui/core';
import CompletedPage from '../shared/CompletedPage';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 3,
    },
    list: {
        padding: "2%",
        borderRadius: 5,

    },
}));

export default function SpacingGrid() {
    const [barcodelessProduct, setBarcodelessProduct] = useState<any>();
    const [clickedProductBarcode, setClickedProductBarcode] = useState();
    const [clickedProductBrand, setClickedProductBrand] = useState();
    const [clickedProductName, setClickedProductName] = useState();
    const [done, setDone] = useState<boolean>(false);
    const classes = useStyles();
    const [searchKey, setSearchkey] = useState("");

    useEffect(() => {
        setSearchkey(barcodelessProduct);
    }, [barcodelessProduct])


    if (done) return <CompletedPage></CompletedPage>
    return (

        <Grid container justify="center" style={{ width: "100%" }} >

            <Grid item style={{ display: "inline-block" }}>
                <BarcodelessProduct setDone={setDone} setClickedProductName={setClickedProductName} setClickedProductBarcode={setClickedProductBarcode} setClickedProductBrand={setClickedProductBrand} clickedProductBarcode={clickedProductBarcode} clickedProductBrand={clickedProductBrand} clickedProductName={clickedProductName} setBarcodelessProduct={setBarcodelessProduct}></BarcodelessProduct>
            </Grid>

            <Grid item style={{ display: "inline-block", maxWidth: "33%", marginRight: "2%", marginLeft: "2%" }}>
                <Card >
                    <CardHeader style={{ backgroundColor: "#bdc3c7", color: "#ffffff", alignContent: "center", textAlign: "center" }} title="Olası Barkodlar"></CardHeader>
                    <CardContent>
                        <GetBarcodes clickedProductBrand={clickedProductBrand} clickedProductBarcode={clickedProductBarcode} clickedProductName={clickedProductName} name={barcodelessProduct} setClickedProductBarcode={setClickedProductBarcode} setClickedProductBrand={setClickedProductBrand} setClickedProductName={setClickedProductName}></GetBarcodes>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item style={{ display: "inline-block", maxWidth: "33%" }}>
                <Card >
                    <CardHeader style={{ backgroundColor: "#bdc3c7", color: "#ffffff", justifyItems: "center" }} title="Google Search Sonuçları"></CardHeader>
                    <CardContent>
                        <Button style={{display: "flex", width: "100%", height: "20em"}} variant="outlined" disabled={!searchKey} onClick={() => { window.open(`https://www.google.com/search?q=${searchKey}`, "_blank") }}>Google'da Ara!</Button>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>

    );
}