import { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Checkbox } from "@material-ui/core";
import { useDataProvider, useNotify } from "ra-core";
import { ArrayInput, Loading } from "react-admin";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarIcon from "@material-ui/icons/Star";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import React from "react";
import CompletedPage from "../shared/CompletedPage";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    margin: 30,
    width: "55%",
    height: "100%",
    backgroundColor: "#cdcdcd",
  },
  paperLeft: {
    padding: 24,
    margin: 30,
    width: "25%",
    height: "100%",
    backgroundColor: "#cdcdcd",
  },
  root: {
    //margin: "auto",
    height: 400,
    overflow: "scroll"
  },
  cardHeader: {
    padding: "10px",
  },
  list: {
    width: "100%",
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: "20px",
  },
  prod: {},
  popover: {
    pointerEvents: 'none',
  },
  paperName: {
    width: 'auto',
    padding: theme.spacing(2),
    backgroundColor: "#F8F8F8",
    display: "inline-flex",
    alignItems: 'center'
  },
}));

const AssignBarcodes = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const [aliasesCount, setAliasesCount] = useState<number>(8);
  const [barcode, setBarcode] = useState<any>();
  const [productList, setProductList] = useState<any>();
  const [productIds, setProductIds] = useState<any>([]);
  const [anchorElName, setAnchorElName] = React.useState<HTMLElement | null>(null);
  const [openedPopoverId, setOpenedPopoverId] = React.useState<any>();
  const openName = Boolean(anchorElName);
  const [done, setDone] = useState<boolean>(false);

  const handlePopoverOpenName = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: any) => {
    setOpenedPopoverId(id);
    setAnchorElName(event.currentTarget);
  };

  const handlePopoverCloseName = () => {
    setOpenedPopoverId(null);
    setAnchorElName(null);
  };

  useEffect(() => {
    dataProvider
      .getList("barcodes", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "title", order: "ASC" },
        filter: { aliasesCount, isMultipleUpdateReviewed: false },
      })
      .then((resp) => {
        if (resp.data && resp.data[0]) {
          setBarcode(resp.data[0]);
          let allNames = resp.data[0].aliases;
          allNames.push(resp.data[0].name)
          dataProvider
            .getList("products", {
              pagination: { page: 1, perPage: 50 },
              sort: { field: "title", order: "ASC" },
              filter: { query: allNames.toString() },
            })
            .then((response) => {
              setProductList(response.data);
            })
        } else {
          setDone(true)
        }
      })
      .catch((_) => {
        notify("Barkodları çekerken hata aldım.", "warning");
      });
  }, [aliasesCount]);

  const handleClick = (productId: any) => {

    const array: any[] = productIds;
    if (!array.includes(productId)) {
      array.push(productId);
    }
    else {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === productId) {
          array.splice(i, 1);
        }
      }
    }
    setProductIds(array);

  }
  const handleSave = () => {
    dataProvider.updateMany('products', { ids: productIds, data: { barcode: barcode.barcode } }).then(_ => {
      dataProvider.update('barcodes', {
        id: barcode.id,
        data: { id: barcode.id, isMultipleUpdateReviewed: true },
        previousData: { title: "previous title" } as any
      }).then(_ => {
        notify("Seçili ürünlere " + barcode.barcode + " barkod eklendi!")
        window.location.reload()
      })
    }).catch(err => {
      notify("Ürünleri güncellerken hata aldım!", "warning")
    })
  }

  if (done) return <CompletedPage></CompletedPage>
  if (!barcode) return <Loading></Loading>;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Paper className={classes.paperLeft}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "inline-block" }}>
            <h3>Barkod</h3>
            <p>{barcode.barcode}</p>
          </div>
          <Select
            labelId="aliases count"
            id="aliases_count"
            value={aliasesCount}
            onChange={(e: any) => {
              setAliasesCount(e.target.value);
            }}
          >
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
          </Select>
        </div>
        <hr />
        <List component="nav" className={classes.root} aria-label="contacts">
          <ListItem >
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary={barcode.name} />
          </ListItem>
          {barcode.aliases.map((alias: string) => (
            <ListItem >
              <ListItemText inset primary={alias} />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Paper className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Ürünler</h2>
        </div>
        <hr />
        <List dense className={classes.root}>
          {productList?.map((product: any) => {
            const labelId = `checkbox-list-secondary-label-${product.id}`;
            return (
              <ListItem   key={product.id}  >
                <ListItemText
                  id={labelId}
                  disableTypography
                  primary={<span>{`${product.name}`}</span>}
                  className={classes.prod}
                  aria-owns={openName ? 'mouse-over-popover-name' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(e: any) => handlePopoverOpenName(e, product.id)}
                  onMouseLeave={handlePopoverCloseName}
                />
                <Popover
                  id="mouse-over-popover-name"
                  className={classes.popover}
                  classes={{
                    paper: classes.paperName,
                  }}
                  open={openedPopoverId === product.id}
                  anchorEl={anchorElName}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  onClose={handlePopoverCloseName}
                  disableRestoreFocus
                >
                  <img src={(product.images && product.images[0]) ? product.images[0].url : ""} alt="" style={{ width: 200, height: 200 }} />
                </Popover>
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    inputProps={{ "aria-labelledby": labelId }}
                    defaultChecked={productIds.includes(product.id)}
                    onClick={() => handleClick(product.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </div>
      </Paper>
    </div>
  );
};

export default AssignBarcodes;
