import { BarChart, Bar } from 'recharts';
import { useEffect, useState } from "react";
import { useDataProvider, useNotify, Loading } from "react-admin";
import { PieChart, Pie, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import ReactDatePicker from 'react-datepicker';


function calculateEmployee(data: any[]): any[] {
    if (data) {
        let employee: any[] = [];
        data.forEach(a => {
            if (employee.find(b => b.userName == a.userName)) {
                employee.find(b => b.userName == a.userName).jobs.push(a)
            } else {
                let object = { userName: a.userName, jobs: [a] }
                employee.push(object)
            }
        })
        return employee;
    }
    return []
}

function createDataForEachEmployeePerDay(data: any[]): any[] {
    let arr: any[] = []
    data.forEach(element => {
        if (new Date(element.createdAt).getDate() == new Date().getDate()) {
            arr.push({
                name: element.jobType,
                count: element.count
            })
        }
    })
    return arr
}


function createDataForEachEmployeeWeek(data: any[]): any[] {
    let arr: any[] = []
    data.forEach(element => {
        if (arr.find(a => a.name == new Date(element.createdAt).getDate())) {
            arr.find(a => a.name == new Date(element.createdAt).getDate())[element.jobType] = element.count
        } else {
            arr.push({
                name: new Date(element.createdAt).getDate(),
                [element.jobType]: element.count
            })
        }

    })
    return arr
}

function createDataForGeneralComparison(data: any[]): any[] {
    let arr: any[] = []
    data.forEach(element => {
        if (new Date(element.createdAt).getDate() == new Date().getDate()) {
            if (arr.find(a => a.name == element.userName)) {
                arr.find(a => a.name == element.userName)[element.jobType] = element.count
            } else {
                arr.push({
                    name: element.userName,
                    [element.jobType]: element.count
                })
            }
        }
    })
    return arr
}


export const JobActivities = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify()
    let date = new Date().getTime() - 7 * 86400000;

    const [data, setData] = useState<any>();
    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;
    if (dateRange) {
        date = new Date(dateRange[0]).getTime() - 7 * 86400000
    }

    useEffect(() => {
        dataProvider.getList("jobactivities", {
            pagination: { page: 1, perPage: 40 },
            sort: { field: "title", order: "ASC" },
            filter: {
                fromDate: date,
            },
        }).then(resp => {
            setData(resp.data)
        }).catch(_ => {
            notify("Get JobActivities Error!", "error")
        })
    }, [dateRange])


    if (data) return (
        <div>
            <Card >
                <CardActionArea>
                    <CardContent style={{ textAlign: "start", fontSize: "30px" }}>
                        Kişiler
                    </CardContent>
                </CardActionArea>
            </Card>
            {calculateEmployee(data).map((emp) => (
                <Card >
                    <CardActionArea>
                        <CardContent style={{ textAlign: "start", fontSize: "30px" }}>
                            {emp.userName}
                        </CardContent>
                        <CardContent style={{ textAlign: "center", fontSize: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <BarChart width={480} height={380} data={createDataForEachEmployeePerDay(emp.jobs)}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="count" fill="#82ca9d" />
                                </BarChart>
                                <BarChart width={720} height={380} data={createDataForEachEmployeeWeek(emp.jobs)}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="MERGE_SLIDER" fill="#8884d8" />
                                    <Bar dataKey="MERGE_BARCODE" fill="#82ca9d" />
                                    <Bar dataKey="CAMPAIGN_SUBMISSION" fill="#FF2121" />
                                </BarChart>
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </div>
    );

    return <Loading></Loading>

}



export default JobActivities;

