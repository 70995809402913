import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    SelectInput,
    ReferenceInput,
    useDataProvider,
    useNotify,
    SimpleForm,

}
    from 'react-admin';

import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';

const BarcodeEdit: FC<EditProps> = props => {

    return (
        <Edit
            {...props}
        >
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="brand" />
                <TextInput source="barcode" />
                <AliasesField source="aliases" />
            </SimpleForm>
        </Edit>
    );
};

const AliasesField = (props: any) => (
    <ul>
        {props.record?.aliases.map((item : any) => (
            <li key={item}>{item}</li>
        ))}
    </ul>
)
AliasesField.defaultProps = {
    addLabel: true
};

export default BarcodeEdit;
