
import ProductCards from "./ProductCards";




const MergeProduct = () => {

    return (
        <ProductCards></ProductCards>
    );

}


export default MergeProduct;