import { TextFields } from '@material-ui/icons';
import * as React from 'react';
import { FC } from 'react';
import {
    Filter,
    SearchInput,
    FilterProps,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    DateInput,
    TextField,
    RichTextField,
    SelectInput,

} from 'react-admin';

const ScrapeResultsFilter: FC<Omit<FilterProps, 'children'>> = props => {

    return (
        <Filter {...props}> 
            <TextInput label="Store" source="store" />
            <SelectInput label="Date Selection" source="dateSelection" choices={[
                { id: 'today', name: 'Today' },
                { id: 'yesterday', name: 'Yesterday' },
                { id: 'lastWeek', name: 'Last Week' },
            ]} />
        </Filter>
    );
};

export default ScrapeResultsFilter;
