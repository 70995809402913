import React, { FC } from 'react';

import {
    List,
    ListProps,
    Datagrid,
    TextField,
    NumberField,
    DateField,
} from 'react-admin';
import ScrapeResultsFilter from './ScrapeResultsFilter';

const ScrapeResultList: FC<ListProps> = props => {

    return (
        <List
            {...props}
            filters={<ScrapeResultsFilter />}
            perPage={25}
            sort={{ field: 'date', order: 'desc' }}
        >
            <Datagrid>
                <TextField source="store" label="Store" />
                <TextField source="type" label="Type" />
                <NumberField source="productIdentifierCount" label="Product Identifier Count" />
                <NumberField source="productIdentifierwithBarcodeCount" label="Product Identifier with Barcode Count" />      
                <NumberField source="productCount" label="Product Count" />
                <NumberField source="duration" label="Duration in seconds" />
                <DateField source="date" label="Date" options={{ timeZone: 'UTC' }} />
            </Datagrid>
        </List>
    );
};

export default ScrapeResultList;
