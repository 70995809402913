import React, { useCallback, useEffect } from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    FilterProps,
    List,
    ListProps,
    SearchInput,
    TextField,
    useDataProvider,
    useVersion,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { ReactElement } from 'react';
import MappingEdit from './MappingEdit';
import { MappingName } from '../types';

const MappingFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);



const MappingList = (props: ListProps): ReactElement => {
    // const version = useVersion();

    // const dataProvider = useDataProvider();
    // const fetchMappingNames = useCallback(async () => {
    //     const { data: mappingNames } = await dataProvider.getList<MappingName>(
    //         'mappingnames',
    //         {
    //             filter: {},
    //             sort: { field: 'name', order: 'DESC' },
    //             pagination: { page: 1, perPage: 50 },
    //         }
    //     );
    //     console.log(mappingNames)
    // }, [dataProvider]);

    // const classes = useStyles();
    // useEffect(() => {
    //     fetchMappingNames();
    // }, [version]);
    return (
        <List
            {...props}
            filters={<MappingFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit" >
                <TextField source="name" />
                <TextField source="key" />
                <TextField source="value" />
                <TextField source="createdAt" />
            </Datagrid>
        </List>
    );


};

export default MappingList;

