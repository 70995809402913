import  { useState} from 'react';
import Button from '@material-ui/core/Button';
import Cropper from 'react-cropper';
import { withWidth } from '@material-ui/core';
let rotateCount=0
const CropperComponent = ({ imageUrl,callbackFromParent, myCallbackRotate,callbackCoordinates,resolution }: { imageUrl: any , callbackFromParent: any,myCallbackRotate: any,callbackCoordinates:any,resolution:any }) => {
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState<any>();
  const getCoordinates = () => {
    if (typeof cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    let x1 = cropper.getData().x;
    let x2 = cropper.getData().x + cropper.getData().width;
    let y1 = cropper.getData().y;
    let y2 = cropper.getData().y + cropper.getData().height;
    //console.log("x1:",x1,"x2:",x2,"y1:",y1,"y2:",y2);
    
    convertCoordinates(x1,x2,y1,y2);
    
    callbackCoordinates({
      x1,
      x2,
      y1,
      y2
    })

    myCallbackRotate(rotateCount);
    rotateCount=0
  }
  const rotateImage=()=>  {
    cropper.rotate(90);
    rotateCount = (rotateCount+1)%4
  }
  const convertCoordinates = (x1:any,x2:any,y1:any,y2:any) => {

        //console.log("Before Rounded: Top(x1):",xy1 / cropper.getImageData().height,"Left(y1):",y1 / cropper.getImageData().height,"Bottom(x2):",x2 / cropper.getImageData().width,"Right(y2):",y2 / cropper.getImageData().height);
        //console.log('Width:',resolution.width,'Height:',resolution.height)
        const left = Math.round((x1*10000) / resolution.width ) / 10000;
        const top = Math.round((y1*10000) / resolution.height) / 10000;
        const right = Math.round((x2*10000) / resolution.width ) / 10000;
        const bottom = Math.round((y2*10000) / resolution.height ) / 10000;
        //console.log("After Rounded: Left:",left ,"Top:",top,"Right:",right,"Bpttom:",bottom );
        callbackFromParent({
          left,
          top,
          right,
          bottom
        })

  }

  return (
      <div style = {{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div>
            <Cropper
              style={{maxWidth:'640px',maxHeight:'640px',minWidth:'640px',minHeight:'640px'}}
              guides={false}
              src={imageUrl}
              autoCropArea={0.1}
              zoomable={false}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
          />
        </div>
        <div>
          <Button  style={{backgroundColor: '#92BBE1',marginTop:'10px', marginRight:'10px'}} onClick={() => getCoordinates()}>Get Coordinates</Button>
          <Button style={{backgroundColor: '#92BBE1',marginTop:'10px'}} onClick={() => rotateImage()}>Rotate Image</Button>
        </div>
      </div>
  );
};
export default CropperComponent;