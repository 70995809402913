import * as React from 'react';
import { FC } from 'react';
import {
    Filter,
    SearchInput,
    FilterProps,
    TextInput,
    ReferenceInput,
    AutocompleteInput,

} from 'react-admin';

const ProductIdentifiersFilter: FC<Omit<FilterProps, 'children'>> = props => {

    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <TextInput label="Sku" source="sku" />
            <TextInput label="Barcode" source="barcode" />
            <TextInput label="ProductId" source="productId" />
            <ReferenceInput source="storeId" reference="stores">
                <AutocompleteInput source="storeId" />
            </ReferenceInput>
        </Filter>
    );
};

export default ProductIdentifiersFilter;
