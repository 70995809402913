import React, { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from "ra-core";
import { TextField, makeStyles, List, ListItem, ListItemText, Button } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles({
    body: {
        padding: '45px',
        display: 'flex',
    },
    left: {
        float: 'left',
        marginRight: "50px"
    },
    right: {
        float: 'right',
        marginLeft: "50px"
    },
    text: {
        marginBottom: "50px",
        width: "100%"
    },
    paper: {
        padding: 8,
        width: "400px",
        height: "auto",
        backgroundColor: "#cdcdcd",
    },
    btn: {
        marginLeft: "35%",
        padding: "25px 40px",
        marginBottom: "100px"
    }

})



const BarcodeAliases = (props: any) => {
    const [barcode, setBarcode] = useState<any>()
    const [barcodealias, setBarcodealias] = useState<any>()
    const [productidentifiers, setProductidentifiers] = useState<any>([])
    const [productidentifiersalias, setProductidentifiersalias] = useState<any>([])
    const classes = useStyles()
    const dataProvider = useDataProvider()
    const notify = useNotify();

    useEffect(() => {
        if (barcode) {
            dataProvider
                .getList('productidentifiers', {
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: "productName", order: 'ASC' },
                    filter: { barcode: barcode }
                }).then(res => {
                    if (typeof (barcode) !== "undefined") {
                        setProductidentifiers(res.data)
                    }

                })
        }
    }, [barcode])

    useEffect(() => {
        if (barcodealias) {
            dataProvider
                .getList('productidentifiers', {
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: "productName", order: 'ASC' },
                    filter: { barcode: barcodealias }
                }).then(res => {
                    if (typeof (barcodealias) !== "undefined") {
                        setProductidentifiersalias(res.data)
                    }
                })
        }
    }, [barcodealias])

    const handleSend = async () => {
        let distinctProductIds: any[] = []
        let distinctProductIdsForAliases: any[] = []
        let productName = ""
        if (productidentifiers[0]) {
            productName = productidentifiers[0].productName
        } else if (productidentifiersalias[0]) {
            productName = productidentifiersalias[0].productName
        } else {
            notify("Couldn't find a name for the identifier!", "error");
        }
         
        
        if (productName) {
            
            productidentifiers.forEach((identifier: any) => {
                if (!distinctProductIds.includes(identifier.productId)) {
                    distinctProductIds.push(identifier.productId)
                }
            });
    
            productidentifiersalias.forEach((identifier: any) => {
                if (!distinctProductIdsForAliases.includes(identifier.productId)) {
                    distinctProductIdsForAliases.push(identifier.productId)
                }
            });
           

            await Promise.all(distinctProductIds.map(productId => {
                dataProvider.create("productidentifiers", { data: { productId: productId, productName: productName, storeId: "611101fbba5a46000ae39815", slug: "zingo", barcode: barcodealias, sku: barcodealias } })
            })).then(async _ => {
                await Promise.all(distinctProductIdsForAliases.map(productId => {
                    dataProvider.create("productidentifiers", { data: { productId: productId, productName: productName, storeId: "611101fbba5a46000ae39815", slug: "zingo", barcode: barcode, sku: barcode } })
                }))
            }).catch(err => {
                notify("Couldn't create identifiers" + err.message, "warning")
            })
            
            dataProvider.create("mergesubmissions", {
                data: {
                    selectedName : productName,
                    isAutoGenerated: true,
                    productIds: distinctProductIdsForAliases.concat(distinctProductIds)
                }
            }).then(_ => {
                notify("Merge submission created!")
            }).catch(err => {
                notify("Couldn't create merge submission" + err.message, "warning")
            })
        }

        setBarcode("");
        setBarcodealias("");
        setProductidentifiers([])
        setProductidentifiersalias([])
    }

    return (
        <div>
            <div className={classes.body}>
                <div className={classes.left}>
                    <TextField id="outlined-basic" className={classes.text} label="Enter the first known barcode" variant="outlined" value={barcode} onChange={(e: any) =>
                        setBarcode(e.target.value)
                    } />

                    <Paper className={classes.paper} variant="outlined">
                        <List component="nav" aria-label="contacts">
                            {productidentifiers?.map((item: any) => (
                                <ListItem button>
                                    <ListItemText inset primary={item.productName} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </div>
                <div className={classes.right}>
                    <TextField id="outlined-basic" className={classes.text} label="Enter the aliase" variant="outlined" value={barcodealias} onChange={(e: any) =>
                        setBarcodealias(e.target.value)
                    } />

                    <Paper className={classes.paper} variant="outlined" >
                        <List component="nav" aria-label="contacts">
                            {productidentifiersalias?.map((item: any) => (
                                <ListItem button>
                                    <ListItemText inset primary={item.productName} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </div>
            </div>


            <Button variant="contained" color="primary" className={classes.btn} onClick={handleSend}>
                Birleştir
            </Button>

        </div>
    )
}

export default BarcodeAliases
