import React, { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import LabelSample from "./LabelSample";
import firebase from "firebase/app";
import 'firebase/storage';

export const LabelProvider = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as string);
    }

    const [labelSet, setLabelSet] = useState();
    const [defaultLabel, setDefaultLabel] = useState({} as any);
    const [storeSlugs, setStoreSlugs] = useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        if (labelSet) {
            dataProvider.create('labelsets', { data: labelSet }).then((resp) => {
                notify("Label Set Created");
                handleCroppedFirebase(labelSet, resp.data.id);
                // window.location.reload();
            }).catch(err => {
                console.log(err)
            });
        }
        dataProvider.getList("mappings", {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'title', order: 'ASC' },
            filter: { name: "new-label-mapping" }
        }).then(async (resp) => {
            let imgUrl = await firebase.storage().refFromURL("gs://zingo-price-labels/labels/").child(resp.data[0].value).getDownloadURL();
            setDefaultLabel({ url: imgUrl, store: resp.data[0].key });
        }).catch(err => {
            console.log(err)
        })

        dataProvider.getList("stores", {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'title', order: 'ASC' },
            filter: {},

        }).then(resp => {
            let arr: any = [];
            resp.data.forEach(e => {
                arr.push(e.slug);
            })
            setStoreSlugs(arr);
        })
    }, [labelSet])


    const handleCroppedFirebase = async (labelSet: any, id: any) => {
        if (!firebase.apps.length) {
            firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as string);
        }
        const rightNow = Date.now().toString();
        const storageRef = firebase.app().storage().refFromURL("gs://zingo-cropped-labels");
        const fileRef = storageRef.child(`${labelSet.store}`);

        await sendFirebase(labelSet.croppedName, "name", id, fileRef);
        await sendFirebase(labelSet.croppedSku, "sku", id, fileRef);
        await sendFirebase(labelSet.croppedBarcode, "barcode", id, fileRef);
        await sendFirebase(labelSet.croppedPrice, "price", id, fileRef);
        await sendFirebase(labelSet.croppedOldPrice, "oldPrice", id, fileRef);
        await sendFirebase(labelSet.croppedSeed, "seed", id, fileRef);
        await sendFirebase(labelSet.croppedInitial, "initial", id, fileRef);
    }

    const sendFirebase = async (object: any, type: any, id: any, fileRef: any) => {
        if (object) {
            object.toBlob(function (blob: any) {
                var newImg = document.createElement('img'),
                    url = URL.createObjectURL(blob);

                newImg.onload = function () {
                    URL.revokeObjectURL(url);
                };
                newImg.src = url;
                document.body.appendChild(newImg);
                fileRef.child(`${id}_${type}`).put(blob).then(async (resp: any) => {
                });
            });
        }
    }

    return (
        <div>
            <LabelSample setLabelSet={setLabelSet} defaultLabel={defaultLabel} storeSlugs={storeSlugs}  ></LabelSample>
        </div>
    );
}
