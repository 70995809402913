import React, { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from "ra-core";
import { TextField, makeStyles, List, ListItem, ListItemText, Button, Grid, Card, CardContent, Checkbox, Divider, FormControlLabel, Typography } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import axios from 'axios';
import Cookies from "js-cookie"
import CircularIndeterminate from '../newpostsubmissions/CircularProgress';
import { RestorePageRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    textField: {
        '& > *': {
            margin: theme.spacing(1),
            width: '57ch',
        },
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        marginTop: "1em",
        backgroundColor: theme.palette.background.paper,
    },
    rootDiv: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    rootCard: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        width: "15em",
        height: "50em",
        backgroundColor: theme.palette.background.paper,
    },
    rootCardFalse: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        width: "15em",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: "100%",
    },
    card: {
        height: "100%",
        backgroundColor: "lightgray",
    },
    cardFalse: {
        height: "100%",
        width: "30%"
    },
    cardFalse2: {
        height: "100%",
        width: "70%"
    },
    media: {
        height: "30%",
        width: "30%",
        objectFit: "contain",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    mediaCard: {
        height: "auto",
        width: "100%",
        objectFit: "contain",
        display: "block",
        margin: "auto",
        padding: "auto",
    },
    titleDivider: {
        margin: theme.spacing(5),
    },
    titleDividerMergeProduct: {
        margin: theme.spacing(2),
    },
    titleProductName: {
        height: "0.5em",
        paddingBottom: "5em",
    },
    titleProductPrices: {
        paddingTop: "2em",
        paddingBottom: "5em",
        height: "100%"
    },
    button: {
        width: 512,
        height: 128,
    },
    paperTop: {
        padding: theme.spacing(2),
        width: "auto",
        textAlign: "center",
        fontSize: "2rem",
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
    },
    divider: {
        marginRight: "1%",
        marginLeft: "1%",
    },
}));



export const UnitOfMeasurement = (props: any) => {

    const dataProvider = useDataProvider();
    const classes = useStyles();
    const notify = useNotify();
    const [product, setProduct] = useState<any>();
    const [selectedUnit, setSelectedUnit] = useState<string>("")
    const [gramaj, setGramaj] = useState<any>();
    const [bundle, setBundle] = useState<any>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [saveClicked, setSaveClicked] = useState<boolean>(false);

    useEffect(() => {
        setGramaj("");
        setBundle("");
        setSelectedUnit("");
        dataProvider.getList("products", { filter: { isUOMReviewed: false }, pagination: { page: 1, perPage: 1 }, sort: { field: "name", order: "ASC" } }).then(resp => {
            setProduct(resp.data[0])
        }).catch(_ => {
            notify("Ürün çekerken hata aldım!")
        })
    }, [refresh])


    const handleSave = () => {
        setSaveClicked(true);
        if (gramaj && selectedUnit && bundle) {

            let gramage = gramaj;
            let selectedUnita = selectedUnit;
            if (selectedUnit == "KG") {
                gramage = 1000 * gramage
                selectedUnita = "G"
            } else if (selectedUnit == "M") {
                gramage = 100 * gramage
                selectedUnita = "CM"
            } else if (selectedUnit == "L") {
                gramage = 1000 * gramage
                selectedUnita = "ML"
            }
            let unitOfMeasurement = gramage + " " + selectedUnita;
            dataProvider.update("products", { id: product.id, data: { id: product.id, unitOfMeasurement: unitOfMeasurement, bundleQuantity: bundle, jobActivityType: "PRODUCT_SUBMISSION" } } as any).then(_ => {
                notify(product.name + " Güncellendi!", "info")
                setRefresh(!refresh)
            }).catch(_ => {
                notify("Ürün güncellerken hata aldım!")
            })
        } else {
            notify("Zorunlu bilgileri giriniz!", "warning")
        }

    }


    if (!product) return <CircularIndeterminate></CircularIndeterminate>

    return (
        <div style={{ display: "flex" }}>
            <Card className={classes.cardFalse}>
                <a href={product.images[0]?.url} target="_blank">
                    <img
                        src={
                            product.images[0]
                                ? product.images[0]?.url
                                : `https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png`
                        }
                        className={classes.media}
                    ></img>
                </a>
                <CardContent className={classes.titleProductPrices}>
                    <TextField disabled
                        variant="outlined"
                        fullWidth
                        value={product.name}
                    ></TextField>
                    <br />
                    <TextField disabled
                        variant="outlined"
                        fullWidth
                        value={product.category}
                    ></TextField>
                    <br />
                    <TextField disabled
                        variant="outlined"
                        fullWidth
                        value={product.barcode}
                    ></TextField>
                    <br />
                    {product.prices.map((price : any) => (
                        <Button onClick={() => {window.open(price.url, "_blank")}}>{price.store} : {price.price}</Button>
                    ))}
                    <br />
                    <div style={{ height: "10px" }}></div>
                    <Button variant="outlined" style={{ background: "#abFFab" }} color="primary" fullWidth onClick={() => { handleSave() }}>Kaydet</Button>
                    <div style={{ height: "10px" }}></div>

                </CardContent>
            </Card>
            <Card className={classes.cardFalse2}>
                <CardContent className={classes.titleProductPrices}>
                    <div style={{ display: "flex" }}>
                        <TextField
                            variant="outlined"
                            type="number"
                            label="Gramaj"
                            error={!gramaj && saveClicked}
                            value={gramaj}
                            onChange={(e) => { setGramaj(Number(e.target.value)) }}
                        ></TextField>
                        <div style={{ width: "2em" }}></div>
                        <TextField
                            variant="outlined"
                            label="Birim"
                            error={!selectedUnit && saveClicked}
                            disabled
                            value={selectedUnit}
                        ></TextField>
                        <div style={{ width: "2em" }}></div>
                        <TextField
                            variant="outlined"
                            type="number"
                            label="Ürün Sayısı"
                            error={!bundle && saveClicked}
                            value={bundle}
                            onChange={(e) => { setBundle(Number(e.target.value)) }}
                        ></TextField>
                        <div style={{ width: "2em" }}></div>
                        <TextField
                            variant="outlined"
                            fullWidth
                            disabled
                            label="Ölçü Birimi Önizleme"
                            value={`${gramaj ? gramaj : ""} ${selectedUnit ? selectedUnit : ""} ${(bundle && bundle > 1) ? bundle + " adet" : ""}`}
                        ></TextField>
                    </div>
                    <div style={{ height: "2em" }}></div>

                    <div style={{ height: "30%", background: "#26DDFF", padding: "2em" }}>
                        <ul>
                            <hr />
                            <li>
                                1 Kilogram, 1 Metre ve 1 Litre'nin altındaki miktarlarda mililitre, gram ve santimetre kullanılacak.
                                Örnek; 0.5 ML su için 500 mililitre yazılacak!
                            </li>
                            <hr />
                            <li>
                                Çoklu paketli ürünlerde birim ürünün gramaj bilgisi yazılacak.
                                Örnek; Maden Suyu 200 Ml 6'Lı için 200 mililitre yazılacak. Paketteki ürün sayısı 6 girilecek.
                            </li>
                            <hr />
                        </ul>
                    </div>
                    <div style={{ height: "2em" }}></div>
                    <div style={{ display: "flex" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ cursor: "pointer", display: "block", background: "#FF0000", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("KG") }}> Kilogram</div>
                            <div style={{ width: "2em" }}></div>
                            <div style={{ cursor: "pointer", display: "block", background: "#FF0000", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("G") }}> Gram</div>
                        </div>
                        <hr />
                        <div style={{ display: "flex" }}>
                            <div style={{ cursor: "pointer", display: "block", background: "#00FF00", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("L") }}> Litre</div>
                            <div style={{ width: "2em" }}></div>
                            <div style={{ cursor: "pointer", display: "block", background: "#00FF00", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("ML") }}> Mililitre</div>
                        </div>
                        <hr />

                        <div style={{ display: "flex" }}>
                            <div style={{ cursor: "pointer", display: "block", background: "#0000FF", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("M") }}> Metre</div>
                            <div style={{ width: "2em" }}></div>
                            <div style={{ cursor: "pointer", display: "block", background: "#0000FF", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("CM") }}> Santimetre</div>
                        </div>
                        <hr />

                        <div style={{ display: "flex" }}>
                            <div style={{ cursor: "pointer", display: "block", background: "#FFFF00", width: "8em", height: "5em", textAlign: "center", paddingTop: "5em", fontWeight: "bolder" }} onClick={() => { setSelectedUnit("Adet") }}> Adet</div>
                        </div>
                    </div>

                </CardContent>
            </Card>
        </div >
    )
}

export default UnitOfMeasurement;
