import { useState, useEffect } from "react";
import { useDataProvider, Loading, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from "@material-ui/core/TextField"
import { FixedSizeList } from 'react-window';
import { sendToBannerBear, minPrice, maxPrice } from '../Helper';
import Button from '@material-ui/core/Button';
import ShowTemplates from './ShowTemplates';
import { Card, MenuItem, Select, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SkipIcon from '@material-ui/icons/SkipNext';


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paperLeft: {
        height: 1000,
        width: 300,
        marginTop: 40
    },
    paperMiddle: {
        height: 500,
        width: 500,
        marginTop: 40
    },
    paperRight: {
        height: 500,
        width: 500,
        marginTop: 40,
    },
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,

    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },

        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
        lists: {
            width: '100%',
            height: 500,
            maxWidth: 200,
            backgroundColor: theme.palette.background.paper,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
    },
    inline: {
        display: 'inline',
    },
    textField: {
        width: 480
    },
    button: {
        paddingLeft: 20,
    },


}));

export default function PostSubmission() {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [product, setProduct] = useState<any>();
    const [productList, setProductList] = useState<any>([]);
    const [allStores, setAllStores] = useState<any>([]);
    const [selectedTemplate, setTemplate] = useState<any>("");
    const [post, setPost] = useState<any>();
    const [selectedStore, setSelectedStore] = useState("migall");
    const [selectedStore1, setSelectedStore1] = useState<any>();
    const [selectedStore2, setSelectedStore2] = useState<any>();
    const [selectedPrice1, setSelectedPrice1] = useState<any>();
    const [selectedPrice2, setSelectedPrice2] = useState<any>();
    let postToSend: any = {};
    const [title1, setTitle1] = useState("");
    const [title2, setTitle2] = useState("");
    const [loading, setLoading] = useState(false);

    const title1Change = (e: any) => {
        setTitle1(e.target.value)
    };
    const title2Change = (e: any) => {
        setTitle2(e.target.value)
    };
    const dateChange = (e: any) => {
        postToSend = post;
        postToSend.campaignDates = e.target.value;
        setPost(postToSend);
    };

    const handleClickProduct = async (e: any) => {
        if (selectedTemplate == "") {
            notify("Lütfen önce template seçiniz!!!", "warning")
        } else {
            postToSend.product = e;
            postToSend.payload = selectedTemplate.payload;
            postToSend.logoColor = selectedTemplate.logoColor;
            allStores.forEach((s: any) => {
                if (s.slug == e.prices[maxPrice(e)].store) {
                    postToSend.store1 = s;
                } else if (s.slug == e.prices[minPrice(e)].store) {
                    postToSend.store2 = s;
                }
            })
            setPost(postToSend);
            setSelectedStore1({});
            setSelectedStore2({});
            setProduct(e);
        }
    }

    useEffect(() => {
        setLoading(true)
        dataProvider
            .getList('products', {
                pagination: { page: 1, perPage: 25 },
                sort: { field: 'title', order: 'ASC' },
                filter: { noticablePriceDifferenceExists: true, noticablePriceDifferenceStore: selectedStore }
            })
            .then(response => {
                setProductList(response.data)
                dataProvider.getList('stores', {
                    pagination: { page: 1, perPage: 50 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: {}
                }).then(async (res) => {
                    setLoading(false)
                    setAllStores([...res.data]);
                }).catch((err) => {
                    notify("Add store error", "warning");
                })
            })
            .catch(error => {
                notify("Get product error: " + error)
            });
    }, [selectedStore]);

    const showTemplate = () => {
        if (selectedTemplate.name) {
            return <div> <img width="100%" height="100%" src={selectedTemplate.exampleUrl}></img>
            </div>;
        } else {
            return <Loading loadingPrimary="Template seçiniz" loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."></Loading>
        }
    }

    const sendUpdate = async () => {
        postToSend.store1 = selectedStore1;
        postToSend.store2 = selectedStore2;
        postToSend.price1 = selectedPrice1;
        postToSend.price2 = selectedPrice2;
        postToSend.product = product;
        console.log(postToSend.title1);
        post.store1 = selectedStore1;
        post.store2 = selectedStore2;
        postToSend.payload = selectedTemplate.payload;
        postToSend.logoColor = selectedTemplate.logoColor;
        if (product.images && product.images.length && post.store1 && post.store2) {
            await sendToBannerBear(title1, postToSend);
            if (window.localStorage.getItem("imageUrlToSend")) {
                let title = (title1) ? (title1) : "" + (title2) ? (" " + title2) : "";

                dataProvider.create('homepagepost', { data: { title, prices: [{ price: selectedPrice1.price, store: selectedPrice1.store, storeId: selectedPrice1.storeId }, { price: selectedPrice2.price, store: selectedPrice2.store, storeId: selectedPrice2.storeId }], productImageUrl: product.images[0].url, productId: postToSend.product.id, productName: postToSend.product.name, imageUrl: window.localStorage.getItem("imageUrlToSend") } })
                    .then((resp) => {
                        window.localStorage.removeItem("imageUrlToSend");
                        window.location.reload();
                        notify("HomePage Post oluşturuldu")
                    }).catch((err) => {
                        window.localStorage.removeItem("imageUrlToSend");
                        notify(err, "warning")
                    })
            }
        } else {
            if (!product.images && !product.images.length) notify("Product Resmi yok", "warning")
            if (!post.store1 && !post.store2) notify("Store eklenemedi", "warning")
            else notify("Product seçilmedi!", "warning")
        }


    }

    const showRenderedList = () => {
        return productList.map((p: any) => {
            return (
                <div onClick={() => handleClickProduct(p)}>
                    <ListItem button key={p.id}>
                        <ListItemText primary={p.name} />
                    </ListItem>
                </div>
            );
        });
    }

    // const handleChange = (event: any) => {
    //     setTemplate(event.target.value);
    // };

    const handleSelectStores = (price: any) => {
        let theStore;
        allStores.forEach((eventStore: any) => {
            if (eventStore.slug === price.store) { theStore = eventStore };
        })
        if (theStore && selectedStore1.name) {
            setSelectedStore2(theStore);
            setSelectedPrice2(price);
            setLoading(true)
        } else {
            setSelectedStore1(theStore);
            setSelectedPrice1(price);
            setLoading(true)
        }
    }

    const showFields = () => {
        if (selectedTemplate.name) {
            if (product) {
                return (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField onChange={(e) => title1Change(e)}
                                    id="title1"
                                    label="Title1"
                                    placeholder="please write smthng cool  :)"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField onChange={(e) => title2Change(e)}
                                    id="title2"
                                    label="Title2"
                                    disabled={selectedTemplate.numberOfTitles == 1}
                                    placeholder="please write smthng cool  :)"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField onChange={(e) => { dateChange(e) }}
                                    id="dates"
                                    label="Tarih"
                                    defaultValue=" -  tarihleri arasında geçerlidir."
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    {product.name}
                                </Typography>
                            </Grid>

                            {product.prices.map((pri: any) => {
                                return (<Grid item xs={12} sm={6}>
                                    <TextField
                                        onClick={() => { handleSelectStores(pri) }}
                                        disabled={selectedStore1 && selectedStore2}
                                        id={`price${pri.store}`}
                                        label={`Price ${pri.store}`}
                                        value={pri.price}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                );
                            })}
                            <h4>Selected Stores:</h4>
                            <p>{(selectedStore1) ? selectedStore1.name : "Lütfen Seçiniz"}</p>
                            <p>{(selectedStore2) ? selectedStore2.name : "Lütfen Seçiniz"}</p>
                        </Grid>

                    </div>
                );
            } else {
                return <Loading loadingPrimary="Product seçiniz" loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."></Loading>;
            }
        } else {
            return <Loading loadingPrimary="Template seçiniz" loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."></Loading>
        }
    };

    if (loading) return <Loading loadingPrimary="Template seçiniz" loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."></Loading>
    else return (
        <Card >
            <Typography variant="h6" style={{ paddingLeft: "50px", paddingTop: "20px" }} gutterBottom>
                Post Submissions
        </Typography>
            <Grid container spacing={4}>
                <Grid container justify="flex-start" style={{ paddingLeft: "30px" }} spacing={3} item xs={12}>
                    <Grid item xs={3}>
                        <Paper className={classes.paperLeft}>
                            {(selectedStore) ? <p style={{ textAlign: "center", fontWeight: "bold", paddingTop: "20px" }} onClick={() => { setSelectedStore('') }}>{selectedStore}</p> : <Select
                                labelId="store-select-label"
                                id="store-select"
                                fullWidth
                                value={selectedStore ? selectedStore : "Select"}
                                onChange={(e: any) => { setSelectedStore(e.target.value) }}
                            >
                                {
                                    allStores.map((store: any) => (
                                        <MenuItem value={store.slug}>{store.name}</MenuItem>
                                    ))
                                }
                            </Select>}
                            <FixedSizeList height={920} width={300} itemSize={46} itemCount={1}>
                                {showRenderedList}
                            </FixedSizeList>
                            <br />
                            <div style={{ width: 25, display: "inline-block" }}></div>
                            <Button variant="contained" className={classes.button} size="large" endIcon={<SkipIcon />}> Atla </Button>
                            <div style={{ width: 15, display: "inline-block" }}></div>
                            <Button onClick={sendUpdate} variant="contained" className={classes.button} size="large" endIcon={<SaveIcon />} >Kaydet</Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid item>
                            <Paper className={classes.paperMiddle}>
                                {showFields()}
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper className={classes.paperRight}>
                                {showTemplate()}
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} >

                        <ShowTemplates numberOfProducts={1} setTemplate={setTemplate}></ShowTemplates>

                    </Grid>
                    <Grid item xs={3}>
                        <ShowTemplates numberOfProducts={2} setTemplate={setTemplate}></ShowTemplates>
                    </Grid>

                </Grid>
            </Grid>
        </Card>);
}
