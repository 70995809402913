import React, { FC } from 'react';

import {
    Filter,
    FilterProps,
    TextInput,
} from 'react-admin';


const BarcodeFilter: FC<Omit<FilterProps, 'children'>> = props => {
    return (
        <Filter {...props}>
            <TextInput source="barcode" alwaysOn />
        </Filter>
    );
};

export default BarcodeFilter;
