import { useDataProvider, useNotify } from "ra-core"
import { useState, useEffect } from "react"
import { Grid, Paper, makeStyles, Select, MenuItem, TextField, List, ListItemText, ListItem, Typography, Button, ListItemAvatar, Avatar } from "@material-ui/core"
import CircularIndeterminate from "../newpostsubmissions/CircularProgress"
import CompletedPage from "../shared/CompletedPage"



export const FindBarcode = () => {

    const dataProvider = useDataProvider()
    const [product, setProduct] = useState<any>()
    const [barcodeList, setBarcodeList] = useState<any>()
    const [barcode, setBarcode] = useState<string>();
    const [storeList, setStoreList] = useState<any>()
    const [refresh, setRefresh] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true);
    const [done, setDone] = useState<boolean>(false);
    const notify = useNotify();
    const [selectedStore, setSelectedStore] = useState<string>();

    useEffect(() => {
        if (!storeList) {
            dataProvider.getList("stores", { filter: {}, pagination: { page: 1, perPage: 100 }, sort: { field: "name", order: "ASC" } }).then(resp => {
                setStoreList(resp.data);
                setLoading(false);
            })
        } else if (!product) {
            dataProvider.getList("products", { filter: { isBarcodeReviewed: false, store: selectedStore }, pagination: { page: 1, perPage: 1 }, sort: { field: "name", order: "ASC" } }).then(resp => {
                if (resp.data[0]) {
                    dataProvider.getList("barcodes", { filter: { q: resp.data[0].name }, pagination: { page: 1, perPage: 100 }, sort: { field: "name", order: "ASC" } }).then(res => {
                        setBarcodeList(res.data);
                        setLoading(false);
                    })
                    setProduct(resp.data[0])
                    setBarcode(resp.data[0].barcode)
                } else {
                    setDone(true)
                }
            }).catch(_ => {
                notify("Ürün Çekemedim", "warning")
            })
        }

    }, [refresh, selectedStore])


    const handleUpdate = () => {
        dataProvider.update("products", { id: product.id, data: { id: product.id, barcode: barcode, isBarcodeReviewed: true, jobActivityType: "MERGE_BARCODE" } } as any).then(_ => {
            notify("Ürünü güncelledim")
            setBarcode("")
            setBarcodeList([])
            setProduct("")
            setLoading(true)
            setRefresh(!refresh)
        }).catch(_ => {
            notify("Ürünü güncellerken hata aldım", "warning");
            window.location.reload();
        })
    }


    if (done) return <CompletedPage></CompletedPage>
    else if (loading) return <CircularIndeterminate></CircularIndeterminate>

    else if (!selectedStore) {
        return (
            <Grid style={{ display: "block" }}>
                <Paper style={{ padding: "4em" }}>
                    <h3>Mağaza Seçimi</h3>
                    <Select value={selectedStore} variant="outlined" fullWidth onChange={(e: any) => { setSelectedStore(e.target.value); setLoading(true); setProduct("") }}>
                        {storeList.map((store: any) => (
                            <MenuItem value={store.slug}>{store.name}</MenuItem>
                        ))}
                    </Select>
                </Paper>
            </Grid>

        )
    }


    return (
        <Grid style={{ display: "flex", width: "100%" }}>
            <Paper style={{ display: "block", padding: "1em", marginTop: "2em", width: "20%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px" }}>
                    <h4>Ürün</h4>
                    <button style={{ height: "2em" }} onClick={handleUpdate}>Kaydet!</button>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <img onClick={() => { window.open(product.images[0].url) }} style={{ maxWidth: "100%", maxHeight: "40%" }} src={product.images ? product.images[0]?.url : "https://scontent-sof1-1.cdninstagram.com/v/t51.2885-19/s150x150/241740121_6017987218242384_5420338563926567243_n.jpg?_nc_ht=scontent-sof1-1.cdninstagram.com&_nc_ohc=zcagQg5OGekAX91LnW5&tn=QcmRfO_uI852Hcd_&edm=ABfd0MgBAAAA&ccb=7-4&oh=bb7173608e871c053a4d455dd0da7f34&oe=616392B2&_nc_sid=7bff83"}></img>
                </div>
                <hr />
                <TextField variant="outlined" multiline value={product.name} disabled fullWidth></TextField>
                <br />
                <hr />
                <TextField variant="outlined" value={product.brand} disabled fullWidth></TextField>
                <br />
                <TextField variant="outlined" value={product.unitOfMeasurement} disabled fullWidth></TextField>
                <br />
                <TextField variant="outlined" value={barcode} onChange={(e) => setBarcode(e.target.value)} fullWidth></TextField>
            </Paper>
            <Paper style={{ display: "block", justifyContent: "space-between", padding: "1em", marginTop: "2em", width: "75%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px" }}>
                    <h4 style={{ display: "block" }}>Barkodlar</h4>
                    <div style={{ display: "flex" }}>
                        <button style={{ height: "2em" }} onClick={() => { setSelectedStore("") }}>Market Değiştir!</button>
                        <div style={{ width: "1em", height: "2em" }}></div>
                        <button style={{ height: "2em" }} onClick={() => { window.open(`https://www.google.com/search?q=${product.name} barkod`) }}>Google'da Ara!</button>
                    </div>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ padding: "1em", marginTop: "2em", width: "45%" }}>
                        <List style={{
                            width: "100%",
                            height: 500,
                            overflow: 'auto',
                        }} dense component="div" role="list">
                            {barcodeList.map((barcode: any, index: number) => {
                                if (index % 2 == 0) {
                                    return (
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "5em", height: "5em", padding: "1em" }}>
                                                <img onClick={(e) => { window.open(`https://www.google.com/search?q=${barcode.barcode}&tbm=isch`) }} style={{ cursor: "pointer", display: "flex", width: "5em" }} src={barcode.googleImages[0]?.imageUrl} />
                                            </div>
                                            <ListItem onClick={() => { setBarcode(barcode.barcode) }} key={barcode.barcode} role="listitem" button style={{ justifyContent: "space-between" }}>
                                                <ListItemText
                                                    aria-multiline
                                                    aria-disabled
                                                    disableTypography
                                                    primary={<p style={{ fontWeight: "bolder" }}>{barcode.name}</p>}
                                                    secondary={<p>{barcode.barcode}</p>}
                                                />
                                            </ListItem>
                                        </div>
                                    )
                                }
                            })}
                        </List>
                    </div>
                    <div style={{ padding: "1em", marginTop: "2em", width: "45%" }}>
                        <List style={{
                            width: "100%",
                            height: 500,
                            overflow: 'auto',
                        }} dense component="div" role="list">
                            {barcodeList.map((barcode: any, index: number) => {
                                if (index % 2 == 1) {
                                    return (
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "5em", height: "5em", padding: "1em" }}>
                                                <img onClick={(e) => { window.open(`https://www.google.com/search?q=${barcode.barcode}&tbm=isch`) }} style={{ cursor: "pointer", display: "flex", width: "5em" }} src={barcode.googleImages[0]?.imageUrl} />
                                            </div>
                                            <ListItem onClick={() => { setBarcode(barcode.barcode) }} key={barcode.barcode} role="listitem" button style={{ justifyContent: "space-between" }}>
                                                <ListItemText
                                                    aria-multiline
                                                    aria-disabled
                                                    disableTypography
                                                    primary={<p style={{ fontWeight: "bolder" }}>{barcode.name}</p>}
                                                    secondary={<p>{barcode.barcode}</p>}
                                                />
                                            </ListItem>
                                        </div>
                                    )
                                }
                            })}
                        </List>
                    </div>
                </div>
            </Paper>
        </Grid>
    )
}



export default FindBarcode