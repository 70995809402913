import React from "react";
import { useMutation, useNotify } from "react-admin";
import Button from "@material-ui/core/Button";
import SuspiciousIcon from "@material-ui/icons/Call";

export const SuspiciousButton = ({ labelId }: any) => {
  //
  const notify = useNotify();

  // console.log(labelId)

  const [approve, { loading }] = useMutation(
    {
      type: "update",
      resource: "labelsubmissions",
      payload: {
        id: labelId,
        data: { id: labelId, isReviewed: true, reviewResult: "SUSPICIOUS" },
      },
    },
    {
      //undoable: true,
      onSuccess: ({ data }) => {
        notify("İhbar edildi", "info", {}, true);
        window.location.reload();
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );
  return (
    <Button
      color="primary"
      className={"my-1"}
      onClick={approve}
      disabled={loading}
      size="large"
      style={{
        position: "absolute",
        left: 1200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      endIcon={<SuspiciousIcon />}
    >
      İhbar et
    </Button>
  );
};
