import React, { useEffect, useState } from 'react';
import { Button, TextField, } from '@material-ui/core';
import CropperComponent from './CropperComponent';
import { Loading, useDataProvider } from 'react-admin';
import { UnitSearch } from '../campaignsubmissions/UnitSearch';
import { ProductSearch } from './ProductSearch';

const defaultSrc ='https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg';

const NewProductSubmission = (props:any) => {
    const dataProvider = useDataProvider();
    const [labelList, setLabelList] = useState<any>([]);
    const [productList,setProductList] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [error, setError] = useState();
    const [selectedProduct,setSelectedProduct] = useState<any>();
    const [approvePermission,setApprovePermission] = useState<boolean>(false);
    const [productName,setProductName]=useState<string>('');
    const [selectedUnit,setSelectedUnit]=useState<string>('adet');
    const [myFilterUnit,setMyFilterUnit]=useState<string>('');
    const [myFilterProductName,setMyFilterProductName]=useState<string>('');
    const [buttonApproved,setButtonApproved] = useState<string>('#C2C2C2');
    const [selectedProductBarcode,setSelectedProductBarcode]= useState<string>('');
    const [productNameInBarcodes,setProductNameInBarcodes]= useState<string>('');
    const [productInBarcodes,setProductInBarcodes]= useState<any>();
    const [rotateNumber,setRotateNumber] = useState<number>(0);
    const [unit,setUnit] = useState<string>('1'); 
    const [check,setCheck] = useState<boolean>(false);
    const [isInBarcodes,setIsInBarcodes] = useState<boolean>(true);
    const [bundleQuantity,setBundleQuantity] = useState<number>(1);
    const [brand,setBrand] = useState<string>('');
    const [sku,setSku] = useState<string>('');
    const [newImageUrl,setNewImageUrl] = useState<string>('');
    useEffect(() => {
        dataProvider.getList('labelsubmissions', {
            filter: { isReviewed: false,isApproved:false, type:'image'},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                setLabelList(data);
                setSelectedProduct(data[0]);
                getSelectedProductBarcode(data[0]);
                setLoading(false);
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    },[]);
    useEffect(() => {
            dataProvider.getList('productlistings', {
                filter: {  barcode: selectedProductBarcode },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'title', order: 'ASC' },
            }).then(({ data }) => {
                if (typeof data !== 'undefined') {
                    setProductList(data)
                    setLoading(false);
                } else {
                    alert("something wrong here")
                }
            }).catch(error => {
                setError(error);
                setLoading(false);
            })
    },[selectedProductBarcode]);

    useEffect(() => {
        dataProvider.getList('barcodes', {
            filter: { barcode:selectedProductBarcode},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                if(data.length)  {
                    setProductInBarcodes(data[0])
                    setProductNameInBarcodes(data[0].name)
                    setIsInBarcodes(true)
                } else {
                    setIsInBarcodes(false)
                    setProductNameInBarcodes('NO BARCODE FOUND')
                }
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    },[selectedProductBarcode]);
    
    const createProductListing = () =>  {
        dataProvider.getList('productlistings', {
            filter: { barcode:selectedProductBarcode},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                if(data.length==0)  {
                    const temp = dataProvider.create("productlistings", {
                        data: {
                            unitOfMeasurementAmount: Number(unit),
                            unitOfMeasurementType: selectedUnit,
                            barcode: selectedProductBarcode,
                            brand: brand,
                            productLongName: productName,
                            bundleQuantity: Number(bundleQuantity),
                            imageUrls: [newImageUrl],
                            isBundle: false, //düzeltilecek
                            isActive: true, // düzeltilecek
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                alert("something wrong here")
            }
        }).then(() => {
            saveNotification(true);
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
        
    }
    const clearFields = () => {
        setProductInBarcodes('');
        setProductName('');
        setUnit('1');
        setSelectedUnit('adet');
        setBrand('');
        setSku('');  
        setBundleQuantity(1);
        setNewImageUrl('');
    }
    const declineImage = () => {
        labelList[selectedIndex].isReviewed=true;
        saveNotification(false);
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        handleDeclineSave();
        clearFields();
    }
    const approveImage = () => {
        labelList[selectedIndex].isReviewed=true;
        labelList[selectedIndex].isApproved=true;
        handleApproveSave();
        createProductListing();
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        checkProductName();
        clearFields();
    }
    const checkProductName = () => {
        if(productNameInBarcodes != productName ){
            if(isInBarcodes){
                updateProductNameInBarcodes();  
            }
        }
            
                   
    }
    const handleApproveSave = async () => {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , isReviewed: labelList[selectedIndex].isReviewed, isApproved: labelList[selectedIndex].isApproved,rotationCount: rotateNumber },
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setButtonApproved('#C2C2C2');
        setApprovePermission(false);
        setRotateNumber(0);
    }
    const handleDeclineSave=() => {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , isReviewed: labelList[selectedIndex].isReviewed, isApproved: labelList[selectedIndex].isApproved},
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setButtonApproved('#C2C2C2');
        setApprovePermission(false);
        setRotateNumber(0);
    }
    const sendToLabelSubmission=()=>  {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , type:'priceImage' },
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        clearFields();
    }
    const saveNotification= async (isApproved:boolean) =>  {
        if(!isApproved)  {
            dataProvider.create('usernotifications',{data:{userId:labelList[selectedIndex].uid,status:"pending",isApproved:isApproved,messageType:"productSubmission",imageUrl:labelList[selectedIndex].imageUrl,productListingId:''}});
        }
        dataProvider.getList('productlistings', {
            filter: {barcode:labelList[selectedIndex].barcode},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {

            if (typeof data !== 'undefined') {      
                const productListingId=String(data[0].id)
                dataProvider.create('usernotifications',{data:{userId:labelList[selectedIndex].uid,status:"pending",isApproved:isApproved,messageType:"productSubmission",imageUrl:labelList[selectedIndex].imageUrl,productListingId:productListingId}});
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    }
    const callBackProductName = (dataFromChild: any) => {
        setProductName(dataFromChild);
    }
    const handleUnitTypeChange = (e: any) => {
        setMyFilterUnit(e.target.value);
        myCallbackUnit(e.target.value);
    }
    const handleProductNameChange = (e: any) => {
        setMyFilterProductName(e.target.value)
        setProductName(e.target.value)
    }
    const myCallbackUnit = (dataFromChild: any) => {
        setSelectedUnit(dataFromChild);
    }
    const myCallbackProductName = (dataFromChild: any) => {
        setProductName(dataFromChild.name);
    }
    const getSelectedProductBarcode=(product:any)=>  {
        let barcode:string=product.barcode;
        setSelectedProductBarcode(barcode);
    }
    const cloudText=()=>  {
        if (!labelList[selectedIndex].extractedString) {
            dataProvider.update('labelsubmissions', { id: labelList[selectedIndex].id, data: { id: labelList[selectedIndex].id, isExtracted: true } } as any)
                .then((data:any ) => {
                    setSelectedProduct(data.data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }
        setApprovePermission(true)
        setButtonApproved('#4DFF42');
    }
    const skip=()=>  {
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        clearFields();
    }
    const myCallbackRotate = (dataFromChild: any) => {
        setRotateNumber(dataFromChild);
    }
    const checkImage = () => {
        if(check || labelList.length==0)
            return defaultSrc;
        else
            return labelList[selectedIndex].imageUrl;
    }
    const checkLength = () => {
        if(labelList.length > selectedIndex+1){
            return selectedIndex+1;
        }else{
            setCheck(true);
            return selectedIndex;
        }
    }
    const checkBarcode = () => {
        if(check){
            return '';
        }else {
            return selectedProductBarcode;
        }
    }
    const handleUnitChange = (e:any) => {
        setUnit(e.target.value)
    }
    const handleBrandChange = (e:any) => {
        setBrand(e.target.value)
    }
    const handleSkuChange = (e:any) => {
        setSku(e.target.value)
    }

    const handleBundleQuantityChange = (e:any) => {
        setBundleQuantity(e.target.value)
    }

    const handleBarcodeProductNameChange = (e:any) => {
        setProductNameInBarcodes(e.target.value)
    }
    const handleImageUrlChange = (e:any) => {
        setNewImageUrl(e.target.value)
    }
    const updateProductNameInBarcodes =()=>  {
        dataProvider.update('barcodes', {
            id: productInBarcodes.id,
            data: { id: productInBarcodes.id , name:productName},
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
    }
    const handleBarcodeChange = (dataFromChild:any) => {
        setSelectedProductBarcode(dataFromChild.replace(/\D/g, ''));
    }
    const handleCroppedUnitChange = (dataFromChild:any) => {
        setUnit(dataFromChild.replace(/\D/g, ''));
    }
    const handleBrand = (dataFromChild:any) => {
        setBrand(dataFromChild);
    }
    const handleSku = (dataFromChild:any) => {
        setSku(dataFromChild);
    }

    if (loading) return <Loading />;
    if (!labelList) return null;

    return (
        <div>
            <div style={{flexDirection:'row',display:'flex'}}>
                <div style={{marginBottom:'10px'}}>
                    <CropperComponent callbackSelectedProduct= {selectedProduct} callbackText={callBackProductName} myCallbackRotate={myCallbackRotate} 
                    imageUrl={checkImage()} callbackBarcode={handleBarcodeChange} callbackUnit={handleCroppedUnitChange} callbackBrand= {handleBrand} callbackSku={handleSku} lockdown = {check} />
                </div>
                <div style={{flexDirection:'column'}}>
                    <div style={{marginTop:'10px', width:'250px'}}>
                        <TextField 
                            value={checkBarcode()}
                            placeholder="Barcode"
                            variant="outlined"
                            label="Barcode"
                            style={{ width: 250 }}
                        />
                        <TextField
                            id="filter"
                            value={productNameInBarcodes}
                            placeholder="Matched Barcode Product Name"
                            variant="outlined"
                            label="Update Product Name in Barcodes"
                            onChange={handleBarcodeProductNameChange}
                            style={{ marginTop:'10px', width: 250 }}
                        />
                    </div>
                    <div style={{marginTop:'10px'}} >
                            <TextField
                                id="filter"
                                label="Image Url"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            value={newImageUrl}
                            placeholder="Enter the ImageUrl from web"
                            onChange={handleImageUrlChange}
                            autoComplete="off"
                            variant="outlined"
                            style={{ width: 250 }}
                            />
                    </div>
                    <div style={{marginTop:'10px'}} >
                            <TextField
                                id="filter"
                                label="Unit"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            value={unit}
                            placeholder="Enter Measurement(EX:100)"
                            onChange={handleUnitChange}
                            autoComplete="off"
                            variant="outlined"
                            style={{ width: 250 }}
                            />
                    </div>
                    <div style={{marginTop:'10px'}}>
                        <TextField
                            id="filter"
                            label="Unit Type"
                            InputLabelProps={{
                            shrink: true,
                        }}
                        value={selectedUnit}
                        placeholder="Select Unit Type(EX:KG)"
                        onChange={handleUnitTypeChange}
                        autoComplete="off"
                        variant="outlined"
                        style={{ width: 250 }}
                        />
                        <UnitSearch callbackFromParent={myCallbackUnit} searchKey={myFilterUnit} />
                    </div>
                    <div style={{marginTop:'10px'}} >
                            <TextField
                                id="filter"
                                label="Brand"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            value={brand}
                            placeholder="Crop Brand Name"
                            onChange={handleBrandChange}
                            autoComplete="off"
                            variant="outlined"
                            style={{ width: 250 }}
                            />
                    </div>
                    <div style={{marginTop:'10px'}} >
                            <TextField
                                id="filter"
                                label="Sku"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            value={sku}
                            placeholder="Crop SKU(Optional)"
                            onChange={handleSkuChange}
                            autoComplete="off"
                            variant="outlined"
                            style={{ width: 250 }}
                            />
                    </div>
                    <div style={{marginTop:'10px'}} >
                            <TextField
                                id="filter"
                                label="Bundle Quantity"
                                InputLabelProps={{
                                shrink: true,
                            }}
                            value={bundleQuantity}
                            onChange={handleBundleQuantityChange}
                            placeholder="Number of Product.(Default 1"
                            autoComplete="off"
                            variant="outlined"
                            style={{ width: 250 }}
                            />
                    </div>
                </div>
                <div>
                    <div style={{marginTop:'10px',marginLeft: '10px'}}>
                        <TextField
                            id="filter"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            value = {productName}
                            placeholder="Search in Database"
                            variant="outlined" 
                            label="Search Product Name"
                            autoComplete="off"
                            style={{ width: 250 }}
                            onChange={handleProductNameChange}
                        />
                        <ProductSearch callbackFromParent={myCallbackProductName} searchKey={myFilterProductName}/>
                    </div>
                </div>
            </div>
            <Button style={{backgroundColor:'#FFFC7F',marginRight:'10px'}} onClick={() => sendToLabelSubmission()} disabled={check} >SEND TO LABEL SUBMISSION</Button>
            <Button style={{backgroundColor:'#FF2929',marginRight:'10px'}}  onClick={() => declineImage()}disabled={check}>DECLINE</Button>
            <Button style={{backgroundColor: buttonApproved,marginRight:'10px'}} onClick={() => approveImage()} disabled= {!approvePermission}>CREATE PRODUCT</Button>
            <Button style={{backgroundColor: '#92BBE1'}} onClick={() => cloudText()}disabled={check}>FIND TEXT</Button>
            <Button style={{backgroundColor: '#92BBE1', marginLeft: '10px'}} onClick={() => skip()}disabled={check}>Skip</Button>     
        </div>
    )
}
export default NewProductSubmission;