import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDataProvider, useNotify } from 'react-admin';
import ProductCardsByName from "./ProductCardsByName";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: "100%",
    },
}));

export const MergeProductsByName = (props: any) => {

    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState<boolean>(false); //Loading parameter
    const [refresh, setRefresh] = useState(false);  // Page Refresh or Page not Refresh changed parameter
    const [product, setProduct] = useState<any>([]);  // Merged one Product
    const [productsMerged, setProductsMerged] = useState<any>();  // Choice Merged Products
    const [selectedID, setSelectedID] = useState('');

    useEffect(() => {
        setLoading(true)
        if(selectedID){
            dataProvider.getList('products', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'title', order: 'ASC' },
                filter: { id : selectedID }
            }).then(({ data }) => {
                setProduct(data[0]);    
                if(data[0]){
                dataProvider.getList('products', {
                    pagination: { page: 1, perPage: 27 },
                    sort: { field: 'title', order: 'DSC' },
                    filter: { query: data[0].name, querySibling: true, brand: data[0].brand, category: data[0].category, store: data[0].stores[0] }
                }).then((response) => {
                    var array = [] as any;  
                    response.data.forEach((mergeProduct) => {
                        if (mergeProduct.id !== data[0].id) {
                            array.push(mergeProduct);
                        }
                    })
                    setProductsMerged(array);  
                    setLoading(false);
                })
            }
            }).catch((err) => {
                notify("Something went wrong with get product", err);
            })
        }else{
        dataProvider.getList('products', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'title', order: 'ASC' },
            filter: { isMergeReviewed: false }
        }).then(({ data }) => {
            setProduct(data[0]);    //Merged Product
            if(data[0]){
            dataProvider.getList('products', {
                pagination: { page: 1, perPage: 27 },
                sort: { field: 'title', order: 'DSC' },
                filter: { query: data[0].name, querySibling: true, brand: data[0].brand, category: data[0].category, store: data[0].stores[0] }
            }).then((response) => {
                var array = [] as any;  //Defined Product List
                response.data.forEach((mergeProduct) => {
                    if (mergeProduct.id !== data[0].id) {
                        array.push(mergeProduct);
                    }
                })
                setProductsMerged(array);    //Set Products Merged
                setLoading(false);
            })
        }
        }).catch((err) => {
            notify("Something went wrong with get product", err);
        })
    }
    }, [refresh ,selectedID])

    return (
        <Box p="1em">
            <Box display="flex">

                <ProductCardsByName products={productsMerged} product={product} refresh={refresh}  setRefresh={setRefresh} setLoading={setLoading} loading={loading} setSelectedId={setSelectedID} selectID={selectedID} {...props}></ProductCardsByName>

            </Box>
        </Box>
    );

}

export default MergeProductsByName;