import { useEffect, useState } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '99%',
        flexWrap: 'wrap',
        justifySelf: 'center',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 400,
        height: 1100,
        transform: 'translateX(0)'
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

export default function ShowTemplates(props: any) {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [tileData, setTileData] = useState<any>();

    useEffect(() => {
        dataProvider.getList('posttemplates', {
            sort: { field: 'title', order: 'ASC' },
            filter: {},
            pagination: { page: 1, perPage: 100 }
        }).then((resp) => {
            setTileData([...resp.data]);
        }).catch(err => alert(err))
    }, [])

    const handleClick = (e: any) => {
        props.setTemplate(e)
    }


    if (!tileData) return <Loading></Loading>
    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={1} >
                {tileData.map((tile: any) => (
                    (tile.numberOfProducts == props.numberOfProducts) ?
                        <GridListTile key={tile.exampleUrl} rows={2} >
                            <img src={tile.exampleUrl} alt={tile.name} onClick={() => handleClick(tile)} />
                            <GridListTileBar
                                title={tile.name}
                                classes={{
                                    root: classes.titleBar,
                                    title: classes.title,
                                }}
                            />
                        </GridListTile>
                        : null
                ))}
            </GridList>
        </div >
    );
}