import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    FilterProps,
    Filter,
    TextInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));
const LabelSetFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="store" />
        <TextInput source="seed" />
    </Filter>
);

const LabelSetList: FC<ListProps> = props => {
    const classes = useStyles();
    return (
        <List
            {...props}
            filters={<LabelSetFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="store" />
                <TextField source="seed" />
            </Datagrid>
        </List>
    );
};

export default LabelSetList;
