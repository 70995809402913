import React from 'react';
import { useNotify, useRedirect, useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import * as helper from '../shared/HelperFunctions'
import firebase from "firebase/app";
import "firebase/firestore";
import SendIcon from '@material-ui/icons/Send';


export const SendProductSubmission = (
    {
        isProductExists,
        productIdentifierId,
        labelSubmissionId,
        selectedBrand,
        productBarcode,
        productId,
        productName,
        productLabel,
        productPrice,
        productOldPrice,
        productSku,
        selectedUnit,
        productAmount,
        selectedStoreId,
        selectedStoreSlug,
        record,
        discountX,
        discountY,
        discountDescription,
        cardIsNeeded,
        discountPercent,
        discountMin,
        discountPrice,
        isExistStartDate,
        isExistFinishDate,
        startDate,
        finishDate,
        selectDiscountKind,
        selectedStoreLocationId,
        unitPrice: priceOfUnitPrice,
        unitPriceUnit,
        selectedCategoryId,
        tensorLabel
    }: any) => {

    let unitPrice = {};
    const dataIWillSend = {} as any;


    // if (selectedUnit) {
    //     unitPrice.price = priceOfUnitPrice;
    //     //unitPrice.unit = { unit: unitPriceUnit, amount: 1 }
    //     //unitPrice.unit.kind = assignMeasurementType(unitPriceUnit);
    //     dataIWillSend.unitPrice = unitPrice;

    // }

    dataIWillSend.name = productName;
    dataIWillSend.storeId = selectedStoreId;
    dataIWillSend.sku = productSku;
    dataIWillSend.label = productLabel;
    dataIWillSend.barcode = productBarcode;
    dataIWillSend.isParent = false;
    //dataIWillSend.prices=[{storeId:selectedStoreId,store:selectedStoreSlug, price:productPrice, oldPrice:productOldPrice}];
    dataIWillSend.store = selectedStoreSlug;


    if (typeof selectedBrand !== 'undefined') {
        dataIWillSend.brandId = selectedBrand.id;
        dataIWillSend.brand = selectedBrand.name;
    }
    let unitOfMeasurement = {};


    // console.log(selectedUnit)
    // console.log(selectedCategoryId);

    // if (selectedUnit) {
    //     unitOfMeasurement.kind = assignMeasurementType(selectedUnit);

    //     unitOfMeasurement.unit = selectedUnit;
    //     unitOfMeasurement.amount = productAmount;
    //     dataIWillSend.unitOfMeasurement = unitOfMeasurement;
    // }

    dataIWillSend.categoryId = selectedCategoryId;
    // console.log(dataIWillSend)

    const dataProvider = useDataProvider();


    const notify = useNotify();
    const redirect = useRedirect();

    const handleTensorFlow = (productId: any) => {
        if (!firebase.apps.length) {
            firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as string);
        }
        const rightNow = Date.now().toString();
        const storageRef = firebase.app().storage().refFromURL("gs://zingo-label-product-names");
        const fileRef = storageRef.child(`${productId}`);
        tensorLabel.toBlob(function (blob: any) {
            var newImg = document.createElement('img'),
                url = URL.createObjectURL(blob);

            newImg.onload = function () {
                URL.revokeObjectURL(url);
            };
            newImg.src = url;
            document.body.appendChild(newImg);
            fileRef.child(`${productId}_${rightNow}`).put(blob).then(async (resp) => {
                alert("Tensor Flow Sent");
            });
        });
    }

    const createProduct = () => {
        dataProvider
            .create('products', { data: { ...dataIWillSend } })
            .then(response => {
                notify('New Product Added');
                dataIWillSend.productId = response.data.id;
                dataIWillSend.productName = productName;
                handleTensorFlow(response.data.id);
                updateIdentifier(productId);
            })
            .catch(error => {
                notify(`New Product error: ${error.message}`, 'warning');
            });
    }

    const createPrice = (identifier: any) => {
        let priceRecord: any = {};
        priceRecord.productId = identifier.productId;
        priceRecord.storeId = selectedStoreId;
        priceRecord.price = productPrice;
        priceRecord.oldPrice = (productOldPrice) ? productOldPrice : 0;
        priceRecord.productName = productName;
        priceRecord.store = selectedStoreSlug;
        priceRecord.storeLocationId = selectedStoreLocationId;
        //priceRecord.unitPrice = identifier.unitPrice;
        if (selectDiscountKind !== "NODISCOUNT") {
            createDiscount(priceRecord, selectedBrand, discountX, discountPercent, discountMin, discountY, selectDiscountKind, discountDescription, discountPrice, cardIsNeeded, isExistStartDate, startDate, isExistFinishDate, finishDate);
        }

        dataProvider
            .create('prices', { data: { ...priceRecord } })
            .then(response => {
                notify('New Price Added');
                labelSubmissionUpdate(identifier);
            })
            .catch(error => {
                notify(`New Price error: ${error.message}`, 'warning');
            });
    }


    const updateIdentifier = (identifier: any) => {
        if (!identifier) {
            const myData: any = {};
            if (productSku !== "null") myData.sku = productSku;
            myData.productName = productName;
            if (productBarcode !== "null") myData.barcode = productBarcode;
            myData.storeId = selectedStoreId;
            myData.brand = selectedBrand.name;
            myData.label = productLabel;
            myData.productId = dataIWillSend.productId;

            dataProvider
                .create('productidentifiers', { data: dataIWillSend }).then((resp) => {
                    notify("Identifier created")
                    createPrice(dataIWillSend);
                }
                ).catch((err) => {
                    notify(err, "warning")
                })
        } else {
            let data = {
                id: identifier.id,
                sku: productSku,
                barcode: productBarcode,
                label: productLabel,
                productName: productName,
                brand: selectedBrand.name,
                productId: identifier.productId,
                storeId: identifier.storeId,
            };

            helper.sanitizeFields(data);

            dataProvider
                .update('productidentifiers', { id: identifier.id, data: { ...data }, previousData: { title: "previous title" } as any })
                .then(response => {
                    notify('Product Identifier updated');
                })
                .catch(error => {
                    notify(`Product Identifier updated error: ${error}`, 'warning');
                });
        }

    }


    const getCategories = () => {
        if (!productName) {
            notify("Please enter Product Name");
        }

        if (selectedCategoryId) {
            dataProvider
                .getOne('categories', { id: selectedCategoryId })
                .then(response => {
                    //console.log(response.data)
                    dataIWillSend.categoryIds = response.data.categoryIds;
                    dataIWillSend.categoryList = response.data.categoryList;
                    dataIWillSend.category = response.data.categoryName;
                    dataIWillSend.subCategories = response.data.subCategories;
                    if (productId.length < 8)
                        createProduct();
                })
                .catch(error => {
                    notify(`Get Categories error: ${error}`, 'warning');
                });
        } else {
            notify("Please select category");
        }
    }

    const handleSendAlreadyExists = () => {
        if (selectedStoreSlug == "migros" && !productSku.includes("200000")) productSku = "200000" + productSku;
        dataProvider.getOne('productidentifiers', { id: productIdentifierId }).then((res) => {
            if (!res.data.sku || !res.data.barcode) {
                dataProvider.update('productidentifiers', { id: productIdentifierId, data: { id: productIdentifierId, label: productLabel, sku: productSku, barcode: productBarcode }, previousData: { title: "previous title" } as any }).then((resp) => {
                    const dataForPrice: any = {}
                    dataForPrice.productId = resp.data.productId;
                    createPrice(dataForPrice)
                    dataProvider.update('labelsubmissions', { id: labelSubmissionId, data: { id: labelSubmissionId, isReviewed: true, reviewResult: "ACCEPTED", price: productPrice, oldPrice: productOldPrice }, previousData: { title: "previous title" } as any }).then(() => {
                        notify("Product Submission Completed!")
                        // window.location.reload();
                    })
                }).catch((err) => {
                    alert(err)
                })
            } else {
                dataProvider.update('productidentifiers', { id: productIdentifierId, data: { id: productIdentifierId, label: productLabel }, previousData: { title: "previous title" } as any }).then((resp) => {
                    const dataForPrice: any = {}
                    dataForPrice.productId = resp.data.productId;
                    createPrice(dataForPrice)
                    dataProvider.update('labelsubmissions', { id: labelSubmissionId, data: { id: labelSubmissionId, isReviewed: true, reviewResult: "ACCEPTED", price: productPrice, oldPrice: productOldPrice }, previousData: { title: "previous title" } as any }).then(() => {
                        notify("Product Submission Completed!")
                        // window.location.reload();
                    })
                }).catch((err) => {
                    alert(err)
                })
            }
        })
    }

    const checkStatus = () => {
        if (isProductExists) {
            handleSendAlreadyExists();
            return;
        }
        if (selectedStoreSlug === "migros" && productSku != "null") {
            productSku = "200000" + productSku;
        }
        if (!productBarcode && !productSku) {
            createProduct()
            //calling updateIdentifier() wihtout any parameter results with a new created productIdentifier.
        }
        else if (productSku == "null") {
            dataProvider
                .getList('productidentifiers', {
                    pagination: { page: 1, perPage: 5 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: { storeId: selectedStoreId, barcode: productBarcode }
                }).then((resp) => {
                    if (resp.data[0]) {
                        const dataForPrice: any = {}
                        dataForPrice.productId = resp.data[0].productId;
                        updateIdentifier(resp.data[0]);
                        createPrice(dataForPrice)
                        window.location.reload();
                    } else {
                        createProduct()
                        //calling updateIdentifier() wihtout any parameter results with a new created productIdentifier.        
                    }
                }).catch(err => notify(err, "warning"));
        }
        else if (productSku) {
            dataProvider
                .getList('productidentifiers', {
                    pagination: { page: 1, perPage: 5 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: { sku: productSku }
                }).then((resp) => {
                    if (resp.data[0]) {
                        const dataForPrice: any = {}
                        dataForPrice.productId = resp.data[0].productId;
                        updateIdentifier(resp.data[0]);
                        createPrice(dataForPrice)
                        window.location.reload();
                    } else {
                        createProduct()
                        //calling updateIdentifier() wihtout any parameter results with a new created productIdentifier.
                    }
                }).catch(err => notify(err, "warning"));
        }

    }

    const labelSubmissionUpdate = (identifier: any) => {
        //.update('LabelSubmission', { id: record.labelId, data: { id:record.labelId ,store: data.slug, storeId: data.storeId ,storeLocationId:data.id } })
        // console.log(identifier.productId)

        let record = { id: labelSubmissionId, productId: identifier.productId } as any;
        record.productName = productName;
        record.sku = productSku;
        record.barcode = productBarcode;
        record.price = productPrice;
        record.oldPrice = productOldPrice;
        record.label = productLabel;
        record.isReviewed = true;
        record.reviewResult = "ACCEPTED";
        //record.unitPrice='';

        dataProvider
            .update('labelsubmissions', { id: labelSubmissionId, data: { ...record }, previousData: { title: "previous title" } as any })
            .then(response => {
                notify('Label Submission updated');
                window.location.reload();
            })
            .catch(error => {
                notify(`Label Submission updated error: ${error.message}`, 'warning');
            });

    }

    /**
     * 
     * DEC VE SUS İÇİN İKİ BUTON
     * 
     * buton labelsubmi review
     * isReviewed true
     * 
     * reviewResult
     * DECLINED / SUSPICIOUS 
     * Reddet / Ihbar et
     * 
     * 
     */

    const labelSubmissionUpdateReviewed = (productId: any) => {


        let record = { id: labelSubmissionId, productId: productId, isReviewed: true, reviewResult: "ACCEPTED" };

        dataProvider
            .update('labelsubmissions', { id: labelSubmissionId, data: { ...record }, previousData: { title: "previous title" } as any })
            .then(response => {
                notify('Label Submission completed');
                window.location.reload();
            })
            .catch(error => {
                notify(`Label Submission error: ${error.message}`, 'warning');
            });

    }

    return (

        <Button color="primary" onClick={checkStatus} size='large' style={{ float: 'right', textAlign: 'center' }} endIcon={<SendIcon />}> Gonder </Button>);
};



function assignMeasurementType(selectedUnit: any) {
    switch (selectedUnit) {
        case 'KG':
        case 'G':
        case 'MG':
            return 'WEIGHT';
            break;
        case 'M':
        case 'CM':
        case 'MM':
            return 'LENGTH';
            break;
        case 'L':
        case 'CL':
        case 'ML':
            return 'VOLUME';
            break;
        case 'QTY':
            return 'QUANTITY';
            break;
        case 'SRV':
            return 'SERVING';
            break;
        default: break;
    }
}

function createDiscount(priceRecord: any, selectedBrand: any, discountX: any, discountPercent: any, discountMin: any, discountY: any, selectDiscountKind: any, discountDescription: any, discountPrice: any, cardIsNeeded: any, isExistStartDate: any, startDate: any, isExistFinishDate: any, finishDate: any) {
    priceRecord.discountRule = {};
    if (typeof selectedBrand !== 'undefined') {
        priceRecord.brandId = selectedBrand.id;
        priceRecord.brand = selectedBrand.name;
    }
    if (discountX) {
        priceRecord.discountRule.x = discountX;
    }
    if (discountPercent) {
        priceRecord.discountRule.x = discountPercent;
    }
    if (discountMin) {
        priceRecord.discountRule.x = discountMin;
    }
    if (discountY) {
        priceRecord.discountRule.y = discountY;
    }
    if (discountDescription) {
        priceRecord.discountRule.discountDescription = discountDescription;
    }
    priceRecord.discountRule.kind = selectDiscountKind;
    if (discountPrice !== 0) {
        priceRecord.discountRule.discountPrice = discountPrice;
    }
    priceRecord.discountRule.loyaltyCard = cardIsNeeded;
    if (isExistStartDate === true) {
        priceRecord.discountRule.validFrom = startDate;
    }
    if (isExistFinishDate === true) {
        priceRecord.discountRule.validTo = finishDate;
    }


}

