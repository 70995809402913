
import React, { useState, useEffect } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useDataProvider, Loading, Error, useNotify } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Checkbox, Icon, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 275,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        card: {
            marginTop: "20px",
            backgroundColor: "#5F5F5F"
        },
        form: {
            alignContent: "center",
            alignSelf: "center",
            alignItems: "center",
            '& > *': {


                width: '25ch',
            },
        },
        button: {
            margin: theme.spacing(1),
        },
    }),
);





const IncomingBrands = ((props: any) => {
    const [brandList, setBrandList] = useState<any>();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [currentSlug, setCurrentSlug] = useState();
    const [brand, setBrand] = useState<any>();
    const [name, setName] = useState("");
    const notify = useNotify();
    const today = new Date();
    const date1 = today.toISOString().substring(0, 10);
    const link = process.env.REACT_APP_ZINGO_SERVER?.toString();


    useEffect(() => {

        if (typeof brandList === "undefined") {
            dataProvider.getList("brands", {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'title', order: 'ASC' },
                filter: { isReviewed: false }
            }).then((response) => {
                setCurrentSlug(response.data[0].slug);
                dataProvider.getList("brands", {
                    pagination: { page: 1, perPage: 5 },
                    sort: { field: 'title', order: 'ASC' },
                    filter: { slug: response.data[0].slug }
                }).then((response) => {
                    setBrandList(response.data);
                }).catch((e) => {
                    console.log(e);
                })
            }).catch((e) => {
                console.log(e);
            });

        }
    }, [brand]);

    const handleClick = ((b: any) => {
        setBrand(b);
        setName(b.name)
    });

    const handleChangeText = (t: any) => {
        setName(t.target.value);
    }
    const handleSend = async () => {

        if (name) {
            await axios({
                method: 'post',
                url: `${link}/system/brand/sanitizer/${brand.id}`,
                headers: { "authorization": `Bearer ${Cookies.get("accessToken")}` }
            }).then(async res => {
                notify("Succesfull");
                window.location.reload();
            }).catch(err => {
                console.log(err)
            })
        }
        else {
            notify("Name is required!")
        }

    }

    const showBrandList = (() => {

        return (<div style={{ alignItems: "center" }}>
            <br></br>
            <br></br>
            <form className={classes.form} noValidate autoComplete="off">
                <TextField
                    required
                    id="outlined-required"
                    label="Brand Name"
                    defaultValue={brand ? brand.name : ""}
                    variant="outlined"
                    value={name}
                    onChange={(t) => handleChangeText(t)}
                    style={{ width: "400px", marginLeft: "15px" }}

                />
                <TextField
                    required
                    id="outlined-required"
                    label="Brand ID"
                    defaultValue={brand ? brand.id : ""}
                    variant="outlined"
                    value={brand ? brand.id : ""}
                    style={{ width: "250px", marginLeft: "35px" }}

                />

                <Button
                    variant="contained"
                    className={classes.button}
                    endIcon={<SendIcon />}
                    onClick={handleSend}
                    style={{ width: "200px", marginLeft: "35px", marginTop: 'center', marginRight: "10px" }}
                    size="large"
                >
                    Send
                </Button>

            </form>
            <br />
            <br />
            <List dense={true} style={{ fontSize: "10px" }} >
                {typeof brandList === "undefined" ? <Loading></Loading> : brandList.map((b: any) => (
                    <ListItem
                        key={b.id}
                        button
                        onClick={() => handleClick(b)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={b.name}

                        />
                    </ListItem>
                ))}
            </List>
        </div>
        );
    });

    return showBrandList();
});

export default IncomingBrands;