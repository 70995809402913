import React from 'react';
import { useMutation, useNotify, useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';

export const SendStoreSubmission = ({ record, selectedStore, storeName, selectedTown, selectedCity, selectedStoreLocation }: { record: any, selectedStore: any, storeName: any, selectedTown: any, selectedCity: any, selectedStoreLocation: any }) => {
    const dataWillBeSend = {} as any;

    dataWillBeSend.name = storeName;
    if (typeof selectedStore !== 'undefined') {
        dataWillBeSend.storeId = selectedStore.id;
    }

    if (typeof selectedCity !== 'undefined') {
        dataWillBeSend.cityId = selectedCity.id;
        dataWillBeSend.city = selectedCity.name;
    }

    if (typeof selectedTown !== 'undefined') {
        dataWillBeSend.townId = selectedTown.id;
        dataWillBeSend.town = selectedTown.name;
    }


    dataWillBeSend.address = record.address;
    dataWillBeSend.lat = record.mapPosition.lat;
    dataWillBeSend.long = record.mapPosition.lng;

    const notify = useNotify();

    const dataProvider = useDataProvider();

    const check = () => {
        if (typeof selectedStoreLocation !== 'undefined') {
            dataProvider
                .update('labelsubmissions', { id: record.labelId, data: { id: record.labelId, store: selectedStore.slug, storeId: selectedStore.id, storeLocationId: selectedStoreLocation.id }, previousData: { title: "previous title" } as any })
                .then((res) => {
                    notify("Label Updated");
                    window.location.reload()
                })
                .catch(err => {
                    notify(err, "error")
                });
        } else {
            approve();
        }
    }

    const updateStoreFunc = (data: any) => {
        dataProvider
            .update('labelsubmissions', { id: record.labelId, data: { id: record.labelId, store: data.slug, storeId: data.storeId, storeLocationId: data.id }, previousData: { title: "previous title" } as any })
            .then(response => {
                if (response.data.storeId &&
                    response.data.storeLocationId &&
                    response.data.productId &&
                    response.data.price) {
                    dataProvider.update('labelsubmissions', { id: record.labelId, data: { id: record.labelId, isReviewed: true }, previousData: { title: "previous title" } as any }).then(() => {
                        notify("Label Submission Completed")
                    });
                    window.location.reload();
                }
                notify('Label updated');
                window.location.reload();
            })
            .catch(error => {
                notify(`Label updated error: ${error.message}`, 'warning');
            });

    }

    const [approve, { loading }] = useMutation(
        {
            type: 'create',
            resource: 'storelocations',
            payload: {
                data: {
                    ...dataWillBeSend
                }
            }
        },
        {
            onSuccess: ({ data }) => {

                notify(`Store location added, : ${data.name}`, 'info', {}, true);
                //console.log(data);
                updateStoreFunc(data);
            },
            onFailure: (error) => notify("Error occured"),
        }
    );

    return (<Button variant="outlined" size="large" color="primary" onClick={check} disabled={loading}>
        Add Store Location
    </Button>);
};


