import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Badge,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { Loading, useDataProvider, useNotify } from "react-admin";
import CircularProgress from "../newpostsubmissions/CircularProgress";

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
  },
  card: {
    height: "100%",
  },
  media: {
    height: "auto",
    width: "100%",
    objectFit: "contain",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleDivider: {
    margin: theme.spacing(5),
  },
  titleDividerMergeProduct: {
    margin: theme.spacing(2),
  },
  titleProductName: {
    height: "0.5em",
    paddingBottom: "5em",
  },
  titleProductPrices: {
    paddingTop: "2em",
  },
}));

const ProductCard = (props: any) => {
  const classes = useStyles();
  let product = props.product;

  useEffect(() => {
  }, [props.product]);

  return (
    <Grid container spacing={1} className={classes.rootDiv}>
      <Grid xs={12} sm={6} md={4} lg={3} xl={6} item>
        <Card className={classes.card}>
          <img
            src={
              product && product.images
                ? product.images[0]?.url
                : `https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png`
            }
            className={classes.media}
            onError={(e) =>
              ((e.target as any).src =
                "https://www.softwarearge.com/wp-content/uploads/2018/09/no-image-icon-6.png")
            }
          ></img>
          <CardContent className={classes.titleProductName}>
            <Typography variant="h5" component="h2" align="center">
              {product?.name}
            </Typography>
          </CardContent>
          <Divider variant="middle" className={classes.titleDivider} />

          <CardContent className={classes.titleProductPrices}>
            <Typography variant="h6" component="h2" align="left">
              {"Brand :"}
              {product?.brand}
            </Typography>
            <Typography variant="h6" component="h2" align="left">
              {"Category :"}
              {product?.category}
            </Typography>
            <Typography variant="h6" component="h2" align="left">
              {"Barcode :"}
              {product?.barcode}
            </Typography>
            <Divider variant="middle" className={classes.titleDivider} />
            {product?.prices?.map((price: any) => (
              <Typography variant="h6" component="h2" align="left" onClick={() => {window.open(price.url, "_blank")}}>
                {price?.store}: {price?.price}
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default ProductCard;
