import React, { Component, useRef } from 'react';
// import '../../src/cropper.css';
import Cropper from 'react-cropper';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { ProductSearch } from "./ProductSearch";
import DatePicker from "react-datepicker";
import { SendCampaignButton } from './SendCampaignButton';
import { BrandSearch } from './BrandSearch'
import { UnitSearch } from './UnitSearch'
import { DeclineButton } from './DeclineButton'
import { CategoryInProductSubmission } from './CategoryInProductSubmission'
import { Avatar, Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from '@material-ui/core';
import { CardContent, Card, } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { fixTlSeperator, extractAmount, extractUnit } from '../Helper';
import Button from '@material-ui/core/Button';
import campaignsubmissions from '.';
import { FormWithRedirect, ReferenceManyFieldController } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


/**
 * This component main use is to crop images.
 * We used react-cropper.
 * http://roadmanfong.github.io/react-cropper/
 * We can set max weight and height size of cropped image to get smaller.
 * I used 400*400 as you can see below
 * Also, this component is writting as standart react-component. 
 * I didn't use hooks here because tutorial of cropper library used this form.
 * So, there is no special reason to not use hooks. It can be modified it.
 */

const src = '';
interface TodoItemProps { // Added this interface for props
    store: any,
    campaignSubmission: any[],
    needToExtract: any,
}

export default class CampaignSubmission extends Component<TodoItemProps, {
    src: any,
    campaignChips: any[],
    cropResult: any,
    cropResultForGoogle: any,
    myFilter: string,
    selectedProduct: {},
    selectedProductBool: boolean,
    productName: string,
    dropdownOpen: boolean,
    selectDiscountName: string,
    selectDiscountKind: string,
    discountPercent: string,
    discountPrice: string,
    discountX: string,
    discountY: string,
    discountDescription: string,
    cardIsNeeded: any,
    discountMin: string,
    startDate: any,
    finishDate: any,
    isExistStartDate: boolean,
    isExistFinishDate: boolean,
    productPrice: any,
    productOldPrice: string,
    isNewProduct: boolean,
    myFilterBrand: string,
    selectedBrand: any,
    productDescription: string,
    productAmount: string,
    myFilterUnit: string,
    selectedUnit: string,
    productImagePath: string,
    selectedCategoryId: string,
    listOfProducts: any[],
    listOfPrices: any[],
    campaignTitle: string,
    openNotify1: boolean,
    openNotify2: boolean,
    sendThis: string
}> {
    cropper: any;

    constructor(props: any) {
        super(props);
        this.state = {
            src,
            campaignChips: [],
            cropResult: null,
            cropResultForGoogle: null,
            myFilter: '',
            selectedProduct: {},
            selectedProductBool: false,
            productName: '',
            dropdownOpen: false,
            selectDiscountName: 'İndirim seç',
            selectDiscountKind: '',
            discountPercent: '',
            discountPrice: '',
            discountX: '',
            discountY: '',
            discountDescription: '',
            cardIsNeeded: false,
            discountMin: '',
            startDate: new Date(),
            finishDate: "",
            isExistStartDate: false,
            isExistFinishDate: false,
            productPrice: '',
            productOldPrice: '',
            isNewProduct: false,
            myFilterBrand: '',
            selectedBrand: {},
            productDescription: '',
            productAmount: '',
            myFilterUnit: '',
            selectedUnit: '',
            productImagePath: '',
            selectedCategoryId: '',
            listOfProducts: [],
            listOfPrices: [],
            campaignTitle: "",
            openNotify1: false,
            openNotify2: false,
            sendThis: ""
        };
        this.cropImage = this.cropImage.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
        this.cropImageForGoogle = this.cropImageForGoogle.bind(this);

    }
    initializeCropper(e: any) {
        this.cropper = e;
    }

    componentDidMount() {
        this.useDefaultImage();
    }

    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        this.setState({
            //Burada min max verebiliyoruz, denemelere göre en doğrusunu buluruz.
            cropResult: this.cropper.getCroppedCanvas({ maxWidth: 400, maxHeight: 400, }).toDataURL(),
        });
    }

    //this result will be send to google for text recognation. 
    //The data coming from google will be used in textfields 
    cropImageForGoogle() {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        this.setState({
            cropResultForGoogle: this.cropper.getCroppedCanvas().toDataURL(),
        });
    }

    handleChange = (e: any) => {
        this.setState({ myFilter: e.target.value })
    }

    handleSend = () => {
        this.setState({ sendThis: this.state.myFilter })
    }

    myCallback = (dataFromChild: any) => {
        if (dataFromChild.length === 1) {
            dataFromChild = dataFromChild[0];
            this.setState({ selectedProduct: dataFromChild });
            this.setState({ productName: dataFromChild.name })
            this.setState({ selectedProductBool: true });
            if (dataFromChild.prices.length) {
                this.getRelevantPrice(dataFromChild);
            }
        } else if (dataFromChild.length > 1) {
            this.setState({ selectedProductBool: true });
            let priceArr: any = [];
            dataFromChild.forEach((el: any) => {
                let pr;
                let oldPr;
                el.prices.forEach((price: any) => {
                    if (price.store === this.props.store.slug) {
                        pr = price.price;
                        if (price.oldPrice) {
                            oldPr = price.oldPrice;
                        }
                    }
                })
                priceArr.push({ productPrice: pr, oldPrice: (oldPr) ? oldPr : "" })
            })

            this.setState({
                listOfProducts: [...this.state.listOfProducts, ...dataFromChild],
            })
            this.setState({
                listOfPrices: [...this.state.listOfPrices, ...priceArr]
            })
        }
    }

    myCallbackCategory = (dataFromChild: any) => {
        this.setState({ selectedCategoryId: dataFromChild })
    }

    getRelevantPrice = (dataFromChild: any) => {
        dataFromChild.prices.forEach((price: any) => {
            if (price.store === this.props.store.slug) {
                this.setState({ productPrice: price.price })
                if (price.oldPrice) {
                    this.setState({ productOldPrice: price.oldPrice })
                }
            }
        })
    }
    //Product changes start
    changeName = (e: any) => {
        this.setState({ productName: e.target.value })
    }
    changeDescription = (e: any) => {
        this.setState({ productDescription: e.target.value })
    }
    changeAmount = (e: any) => {
        this.setState({ productAmount: e.target.value })
    }
    setNameByImage = (e: any) => {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        const x1 = this.cropper.getData().x;
        const x2 = this.cropper.getData().x + this.cropper.getData().width;
        const y1 = this.cropper.getData().y;
        const y2 = this.cropper.getData().y + this.cropper.getData().height;

        const extractedJSON = JSON.parse(window.localStorage.getItem("extractedString") as string);
        let nameFromImage = "";
        extractedJSON?.forEach((el: any) => {
            if (el.boundingPoly.vertices[0].x > x1 &&
                el.boundingPoly.vertices[0].y > y1 &&
                el.boundingPoly.vertices[1].x < x2 &&
                el.boundingPoly.vertices[2].y < y2) {
                nameFromImage = nameFromImage + " " + el.description;
            }
        });
        this.setState({ myFilter: nameFromImage })
        this.setState({ productName: nameFromImage })
    }

    setPriceByImage = (e: any) => {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        const x1 = this.cropper.getData().x;
        const x2 = this.cropper.getData().x + this.cropper.getData().width;
        const y1 = this.cropper.getData().y;
        const y2 = this.cropper.getData().y + this.cropper.getData().height;

        const extractedJSON = JSON.parse(window.localStorage.getItem("extractedString") as any);
        let priceFromImage = "";
        extractedJSON?.forEach((el: any) => {
            if (el.boundingPoly.vertices[0].x > x1 &&
                el.boundingPoly.vertices[0].y > y1 &&
                el.boundingPoly.vertices[1].x < x2 &&
                el.boundingPoly.vertices[2].y < y2) {
                priceFromImage = priceFromImage + " " + el.description;
            }
        });
        this.setState({ productPrice: fixTlSeperator(priceFromImage) })
    }

    setTitleByImage = (e: any) => {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        const x1 = this.cropper.getData().x;
        const x2 = this.cropper.getData().x + this.cropper.getData().width;
        const y1 = this.cropper.getData().y;
        const y2 = this.cropper.getData().y + this.cropper.getData().height;

        const extractedJSON = JSON.parse(window.localStorage.getItem("extractedString") as any);
        let titleFromImage = "";
        extractedJSON.forEach((el: any) => {
            if (el.boundingPoly.vertices[0].x > x1 &&
                el.boundingPoly.vertices[0].y > y1 &&
                el.boundingPoly.vertices[1].x < x2 &&
                el.boundingPoly.vertices[2].y < y2) {
                titleFromImage = titleFromImage + " " + el.description;
            }
        });
        this.setState({ campaignTitle: titleFromImage })
    }
    //Product changes end

    //If image is null, we have to return smtg not to be crashed. We return a 'not found folder' image
    myImageSrc = (product: any) => {
        if (this.state.selectedProductBool) {
            if (typeof product.images !== 'undefined' && product.images.length) {
                return product.images[0].url;
            }
            else {
                return 'https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg';
            }
        }
    }

    //If there is no discount, we hide discount area thanks to this toogle
    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    }

    //discount handle start!!!
    changeStartDate = () => {
        let current = (!this.state.isExistStartDate);
        this.setState({ isExistStartDate: current });
    }

    changeFinishDate = () => {
        let current = (!this.state.isExistFinishDate);
        this.setState({ isExistFinishDate: current })
    }

    /// Discount Panel Handles
    changeDiscountX = (e: any) => {
        this.setState({ discountX: e.target.value });
    }
    changeDiscountY = (e: any) => {
        this.setState({ discountY: e.target.value });
    }
    changeDiscountDescription = (e: any) => {
        this.setState({ discountDescription: e.target.value });
    }
    changeCardNeeded = () => {
        let current = (!this.state.cardIsNeeded);
        this.setState({ cardIsNeeded: current });
    }
    changeDiscountPercent = (e: any) => {
        this.setState({ discountPercent: e.target.value });
    }
    changeDiscountMin = (e: any) => {
        this.setState({ discountMin: e.target.value });
    }
    changeDiscountPrice = (e: any) => {
        this.setState({ discountPrice: e.target.value });
    }
    //discount handle end
    //Campaign handle start
    changePrice = (e: any) => {
        this.setState({ productPrice: e.target.value });
    }

    changeOldPrice = (e: any) => {
        this.setState({ productOldPrice: e.target.value });
    }

    changeTitle = (e: any) => {
        this.setState({ campaignTitle: e.target.value })
    }

    handleAddChip(chip: any) {
        this.setState({
            campaignChips: [...this.state.campaignChips, chip]
        })
    }

    handleDeleteChip(chip: any, index: any) {
        this.setState({
            campaignChips: this.state.campaignChips.filter((_, i) => i !== this.state.campaignChips.indexOf(chip))
        });
    }
    //end
    //brand codes
    myCallbackBrand = (dataFromChild: any) => {
        this.setState({ selectedBrand: dataFromChild });
        // this.setState({ myFilterBrand: dataFromChild.name });

    }
    myCallbackUnit = (dataFromChild: any) => {
        // console.log(dataFromChild)
        this.setState({ selectedUnit: dataFromChild });
        // this.setState({ myFilterUnit: dataFromChild });
    }

    handleChangeBrand = (e: any) => {

        this.setState({ myFilterBrand: e.target.value });
    }

    handleUnitChange = (e: any) => {
        this.setState({ myFilterUnit: e.target.value });
    }

    needToExt = () => {
        this.props.needToExtract(true);
    }

    clickDropItem = (key: any) => {

        switch (key) {

            case "nothing":
                this.setState({ selectDiscountKind: key })
                this.setState({ selectDiscountName: "İndirim Yok" })
                break;

            case "BUY1GET2YOFF":
                this.setState({ selectDiscountKind: key })
                this.setState({ selectDiscountName: "1 Alana 2" })
                break;

            case "BUYXGETY":
                this.setState({ selectDiscountKind: key })
                this.setState({ selectDiscountName: "X Alana Y" })
                break;

            case "BUYMINX":
                this.setState({ selectDiscountKind: key })
                this.setState({ selectDiscountName: "Minumum X adet" });
                break;

            case "ABOVEXTL":
                this.setState({ selectDiscountKind: key })
                this.setState({ selectDiscountName: "X Lira Üzerine" });
                break;
            default:
                break;

        }

    }

    showDiscountDescription = () => {
        return (
            <TextField
                id="discountDescription"
                label="Discount Description"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
                value={this.state.discountDescription}
                onChange={this.changeDiscountDescription}
                style={{ width: 500 }}
            />
        )
    }

    showIsCardNeed = () => {
        return (
            <FormControlLabel
                value={this.state.cardIsNeeded}
                control={<Checkbox color="primary" />}
                label="Kart Gerekiyor mu?"
                onChange={this.changeCardNeeded}
            />
        )
    }

    checkDatePanel = () => {

        return (<div>

            <FormControlLabel
                value={this.state.cardIsNeeded}
                control={<Checkbox color="primary" />}
                label="Başlangıç Tarihi Var mı?"
                onClick={() => this.changeStartDate()}
            />

            {this.showStartDatePanel()}
            <FormControlLabel
                value={this.state.cardIsNeeded}
                control={<Checkbox color="primary" />}
                label="Bitiş Tarihi Var mı?"
                onClick={() => this.changeFinishDate()}
            />

            {this.showFinishDatePanel()}

        </div>)
    }

    showStartDatePanel = () => {
        if (this.state.isExistStartDate === true) {
            return (
                <div>
                    <div className={"mt-1"}>Baslangic  &nbsp;
    <DatePicker selected={this.state.startDate} onChange={(date): any => this.setState({ startDate: date })} /></div>
                </div>
            )
        }
        else {
            return (<div> </div>)
        }
    }
    showFinishDatePanel = () => {
        if (this.state.isExistFinishDate === true) {
            return (

                <div>
                    <div className={"mt-1"}>Bitiş  &nbsp;
    <DatePicker selected={this.state.finishDate} onChange={(date): any => this.setState({ finishDate: date })} /></div>
                </div>
            )
        }
        else {
            return (<div> <br />  </div>)
        }
    }
    showDiscount = (key: any) => {

        switch (key) {

            case "nothing":

                return (<div> İndirim seçilmedi!</div>)

            case "BUY1GET2YOFF":
                return (<div className={"mt-2"}>
                    <Box mt="1em" />
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextField
                                id="productDiscountPercent"
                                label="%"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountPercent}
                                value={this.state.discountPercent}
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                id="productDiscountPrice"
                                label="Discount Price"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountPrice}
                                value={this.state.discountPrice}
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">

                            {this.showDiscountDescription()}
                        </Box>
                    </Box>

                    <Box mt="1em" />
                    {this.showIsCardNeed()}

                </div>)

            case "BUYXGETY":
                return (<div>
                    <Box mt="1em" />
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextField
                                id="productDiscountX"
                                label="X Alana"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountX}
                                value={this.state.discountX}
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">

                            <TextField
                                id="productDiscountY"
                                label="Y Bedava"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountY}
                                value={this.state.discountY}
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            {this.showDiscountDescription()}
                        </Box>

                    </Box>
                    <Box mt="1em" />
                    {this.showIsCardNeed()}

                </div>)

            case "BUYMINX":
                return (<div>
                    <Box mt="1em" />
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextField
                                id="productDiscountMin"
                                label="Buy Minimum"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountMin}
                                value={this.state.discountMin}
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">

                            <TextField
                                id="productDiscountPrice"
                                label="Discount Price"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountPrice}
                                value={this.state.discountPrice}
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">

                            {this.showDiscountDescription()}
                        </Box>

                    </Box>

                    <Box mt="1em" />
                    {this.showIsCardNeed()}

                </div>)

            case "ABOVEXTL":
                return (<div>
                    <Box mt="1em" />
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextField
                                id="productDiscountPrice"
                                label="X TL Uzeri"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                style={{ width: 500 }}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                id="productDiscountPrice"
                                label="Discount Price"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={this.changeDiscountPrice}
                                value={this.state.discountPrice}
                                style={{ width: 500 }}
                            />
                        </Box><Box flex={1} mr="0.5em">

                            {this.showDiscountDescription()}
                        </Box>
                        <Box mt="1em" />

                    </Box>
                    {this.showIsCardNeed()}
                </div>)

            default:
                break;
        }
    }

    showImageAndName = () => {

        if (this.state.selectedProductBool) {
            return (<FormGroup>

                <div className="imageClassCustom position-relative">
                    <img
                        src={this.myImageSrc(this.state.selectedProduct)}
                        style={{ width: "20%" }}
                    />
                    
                </div>
                
                <TextField
                    id="productName"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Name"
                    defaultValue={this.state.productName}
                    style={{ width: 500 }}
                />
                <IconButton aria-label="delete"  onClick={(e) => { this.setState({ isNewProduct: false, productName : '' , selectedProductBool : false ,selectedProduct : {} ,productPrice: '' }) }} >
                        <DeleteIcon  fontSize="large"/>
                    </IconButton>
                <Box mt="1em" />
            </FormGroup>)
        } else {
            return (
                <div>
                    <Box mt="1em" />
                    <Typography variant="h6" gutterBottom>
                        {
                            'Eğer ürün sistemdeyse lütfen seçin...'
                        }
                    </Typography>
                    <Box mt="1em" />
                </div>)
        }

    }
    showNameOrSearch = () => {

        if (this.state.isNewProduct) {
            return (
                <div>
                    <Box mt="1em" />
                    <Typography variant="h6" gutterBottom>
                        {
                            'Yeni product ve campaign create ediyorsunuz...'
                        }
                    </Typography>
                    <Box mt="1em" />

                    <img style={{ width: '20%' }} src={this.state.cropResult} alt="cropped image" />

                    <Button className={'m-1'} color="primary" onClick={this.cropImage}>Ürün resmi ekle</Button>
                    <Box mt="1em" />
                    <FormGroup>
                        <Box display="flex">

                            <Box flex={2} mr="1em">

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextField
                                            id="productName"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Product Name"
                                            onChange={this.changeName}
                                            defaultValue={this.state.productName}
                                            style={{ width: 500 }}
                                        />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextField
                                            type="search"
                                            name="search"
                                            id="outlined-read-only-input"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={(this.state.selectedBrand) ? this.state.selectedBrand.name : this.state.myFilterBrand}
                                            label="Search in Brands"
                                            onChange={this.handleChangeBrand}
                                            variant="outlined"
                                            onClick={(e) => { this.setState({ myFilterBrand: '' }) }}
                                            autoComplete="off"
                                            style={{ width: 500 }} />
                                        <BrandSearch callbackFromParent={this.myCallbackBrand} searchKey={this.state.myFilterBrand} />
                                    </Box>
                                    {/* <Box flex={1} ml="0.5em">
                                        <TextField
                                            id="productDescription"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Description"
                                            onChange={this.changeDescription}
                                            defaultValue={this.state.productDescription}
                                            style={{ width: 500 }}
                                        />
                                    </Box> */}
                                </Box>

                            </Box>

                            <Box flex={1} ml="1em">
                                <CategoryInProductSubmission callbackFromParentCategory={this.myCallbackCategory} />
                                <Box mt="2em" />
                            </Box>
                        </Box>
                    </FormGroup>

                </div >
            )
        }
        else {
            return (
                <div>
                    <Box mt="1em" />
                    <FormGroup>
                        <TextField
                            label="Product Name"
                            type="search"
                            name="search"
                            id="filter"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={this.state.myFilter}
                            placeholder="Search in Products"
                            onChange={this.handleChange}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    this.handleSend()
                                }
                            }}
                            className={"mt-1"}
                            autoComplete="off"
                            fullWidth
                        />

                        <ProductSearch callbackFromParent={this.myCallback} searchKey={this.state.sendThis} storeSlug={this.props.store.slug} />
                    </FormGroup>

                    {this.showImageAndName()}

                </div >
            )
        }

    }

    buttonisntNewProduct = () => {
        this.setState({ isNewProduct: false });
    }

    buttonNewProduct = () => {
        this.setState({ isNewProduct: true });
    }


    useDefaultImage() {
        this.setState({ src: this.props.campaignSubmission[0].image });
    }


    addToListOfProducts = (product: any) => {
        if (this.state.productOldPrice && this.state.productPrice > this.state.productOldPrice) {
            this.setState({ openNotify1: true });
        }
        else {
            if (this.state.isNewProduct) {
                if (!this.state.productPrice) {
                    this.setState({ openNotify2: true })

                } else {

                    let x = { name: this.state.productName, description: this.state.productDescription, amount: this.state.productAmount, unit: this.state.myFilterUnit, base64Image: this.state.cropResult, storeId: this.props.store.id, store: this.props.store.name, brand: this.state.selectedBrand, imageUrl: this.state.cropResult };
                    this.setState({
                        listOfProducts: [...this.state.listOfProducts, x]
                    })
                    this.setState({
                        listOfPrices: [...this.state.listOfPrices, { productPrice: this.state.productPrice, oldPrice: this.state.productOldPrice }]
                    })
                }
            } else {
                this.setState({
                    listOfProducts: [...this.state.listOfProducts, product],
                })

                this.setState({
                    listOfPrices: [...this.state.listOfPrices, { productPrice: this.state.productPrice, oldPrice: this.state.productOldPrice }]
                })
            }
            this.setState({
                productName: "", productOldPrice: "", productPrice: "", description: "",
                searchKey: "", productDescription: "", base64Image: "", selectedProduct: "", myFilter: "", myFilterBrand: "", productAmount: "", myFilterUnit: "", myCallbackCategory: ""
            } as any)
        }
    }

    showProduct = () => {
        return (
            <div>
                {this.state.listOfProducts.map((product, index) => (
                    <Card style={{ minWidth: 540 }}>
                        <CardContent>

                            <div>
                                <br></br>
                                <hr></hr>

                                <Avatar src={(this.state.listOfProducts[index].base64Image) ? this.state.listOfProducts[index].base64Image : this.myImageSrc(this.state.listOfProducts[index])} style={{ width: 100, height: 100, float: "left" }}></Avatar>
                                <div style={{ fontSize: 18, fontWeight: "bold" }}>
                                    {this.state.listOfProducts[index].name}
                                    <br></br>
                                    {this.state.listOfPrices[index].oldPrice + "₺ ==> " + this.state.listOfPrices[index].productPrice + "₺"}
                                    <br />
                                    {this.state.listOfProducts[index].selectedBrand}

                                </div>
                                <br></br>
                                <hr></hr>
                            </div>
                        </CardContent>
                    </Card>

                ))}
            </div>
        )
    }


    render() {
        return (
            <FormWithRedirect
                {...this.props}
                render={formProps => (
                    <form>
                        <Card>
                            <Box p="1em">
                                <Box display="flex">
                                    <Box flex={2} mr="1em">
                                        <Typography variant="h6" gutterBottom>Cropper</Typography>
                                        <Box display="flex">
                                            <Box flex={1} mr="0.5em">
                                                <Cropper
                                                    style={{ height: 500, width: '80%' }}
                                                    guides={false}
                                                    src={this.state.src}
                                                    zoomable={false}
                                                    onInitialized={(instance) => {
                                                        this.initializeCropper(instance);
                                                    }}
                                                />
                                            </Box>
                                            <Box flex={1} mr="0.5em">
                                                {this.showProduct()}

                                            </Box>
                                        </Box>

                                        <Box mt="1em" />
                                        <Box display="flex">
                                            <Button
                                                className={'m-1'}
                                                color="primary"
                                                fullWidth
                                                size='large'
                                                onClick={this.needToExt}
                                            >
                                                <campaignsubmissions.icon />
                                                {"Text Çıkart"}
                                            </Button>
                                            <Button
                                                color="primary"
                                                className={'m-1'}
                                                onClick={this.setNameByImage}
                                                fullWidth
                                                size='large'
                                            >
                                                <campaignsubmissions.icon />
                                                {"Name Ekle"}
                                            </Button>

                                            <Button
                                                color="primary"
                                                className={'m-1'}
                                                onClick={this.setPriceByImage}
                                                fullWidth
                                                size='large'
                                            >
                                                <campaignsubmissions.icon />
                                                {"Price Ekle"}
                                            </Button>
                                           
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>

                                            <br style={{ clear: 'both' }} />
                                            <Button className={'m-1'} size='large' color="primary" onClick={this.buttonisntNewProduct}> Ürün var</Button>
                                            <Button className={'m-1'} size='large' onClick={this.buttonNewProduct}> Yeni ürün</Button>

                                        </Box>
                                        {this.showNameOrSearch()}



                                        <Box mt="1em" />

                                        <Box display={{ xs: 'block', sm: 'flex' }}>

                                            {/* <Box flex={1} mr="0.5em">

                                                <TextField
                                                    id="productAmount"
                                                    label="Amount"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    onChange={this.changeAmount}
                                                    value={this.state.productAmount}
                                                    style={{ width: 500 }}
                                                />
                                            </Box> */}
                                            <Box flex={1} mr="0.5em">
                                                <TextField
                                                    id="productPrice"
                                                    label="Price"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    onChange={this.changePrice}
                                                    value={this.state.productPrice}
                                                    style={{ width: 500 }}
                                                />
                                            </Box>
                                            <Box flex={1} mr="0.5em">
                                                <TextField
                                                    id="productOldPrice"
                                                    label="Old Price"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    onChange={this.changeOldPrice}
                                                    value={this.state.productOldPrice}
                                                    style={{ width: 500 }}
                                                />
                                            </Box>
                                            <Box flex={1} mr="0.5em"></Box>
                                            {/* <Box flex={1} mr="0.5em">
                                                <TextField
                                                    id="filter"
                                                    label="Unit"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={this.state.selectedUnit}
                                                    placeholder="Unit"
                                                    onChange={this.handleUnitChange}
                                                    autoComplete="off"
                                                    variant="outlined"
                                                    style={{ width: 500 }}
                                                />
                                                <UnitSearch callbackFromParent={this.myCallbackUnit} searchKey={this.state.myFilterUnit} />
                                            </Box> */}
                                            {/* <Box flex={1} mr="0.5em">
                                                <TextField
                                                    id="storeName"
                                                    label="Store Name"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    value={this.props.store.name}
                                                    style={{ width: 500 }}
                                                />
                                            </Box> */}
                                        </Box>
                                        <Box mt="1em" />
                                        {this.checkDatePanel()}

                                        <Box mt="1em" />
                                        {/* <FormControl variant="outlined" >
                                            <InputLabel htmlFor="outlined-age-native-simple">Campaign</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Campaign"
                                                style={{ width: 500 }}
                                            >
                                                <MenuItem value={"İndirim Yok"} onClick={() => this.clickDropItem("nothing")}>İndirim Yok</MenuItem>
                                                <MenuItem value={"1 Alana 2."} onClick={() => this.clickDropItem("BUY1GET2YOFF")}>1 Alana 2.</MenuItem>
                                                <MenuItem value={"X Alana Y"} onClick={() => this.clickDropItem("BUYXGETY")}>X Alana Y</MenuItem>
                                                <MenuItem value={"Minumum X Adet"} onClick={() => this.clickDropItem("BUYMINX")}>Minumum X Adet</MenuItem>
                                                <MenuItem value={"X lira üzerine"} onClick={() => this.clickDropItem("ABOVEXTL")}>X lira üzerine</MenuItem>
                                            </Select>
                                        </FormControl> */}

                                        {this.showDiscount(this.state.selectDiscountKind)}
                                        <Box mt="1em" />
                                        {/* <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Tags</InputGroupText>
                                            </InputGroupAddon>

                                            <ChipInput
                                                value={this.state.campaignChips}
                                                onAdd={(chip) => this.handleAddChip(chip)}
                                                onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                style={{ width: 500 }}
                                            />

                                        </InputGroup> */}
                                        <Box mt="1em" />
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Button className={'m-1'} color="primary" onClick={() => { this.addToListOfProducts(this.state.selectedProduct) }}>Ürünü ekle</Button>
                                        </Box>
                                        <Box mt="1em" />
                                        <DeclineButton campaignSubmission={this.props.campaignSubmission} />

                                        <SendCampaignButton
                                            isNewProduct={this.state.isNewProduct}
                                            selectedProduct={this.state.selectedProduct}
                                            campaignSubmission={this.props.campaignSubmission}
                                            campaignPrice={Number(this.state.productPrice)}
                                            campaignOldPrice={Number(this.state.productOldPrice)}
                                            listOfProducts={this.state.listOfProducts}
                                            listOfPrices={this.state.listOfPrices}
                                            campaignTitle={this.state.campaignTitle}

                                            discountX={Number(this.state.discountX)}
                                            discountY={Number(this.state.discountY)}
                                            discountDescription={this.state.discountDescription}
                                            cardIsNeeded={this.state.cardIsNeeded}
                                            discountPercent={Number(this.state.discountPercent)}
                                            discountMin={Number(this.state.discountMin)}
                                            discountPrice={Number(this.state.discountPrice)}
                                            isExistStartDate={this.state.isExistStartDate}
                                            isExistFinishDate={this.state.isExistFinishDate}
                                            startDate={this.state.startDate}
                                            finishDate={this.state.finishDate}
                                            selectDiscountKind={this.state.selectDiscountKind}
                                            selectedStore={this.props.store}
                                            selectedBrand={this.state.selectedBrand}
                                            productName={this.state.productName}
                                            productDescription={this.state.productDescription}
                                            selectedUnit={this.state.selectedUnit}
                                            productAmount={Number(this.state.productAmount)}
                                            productImagePath={this.state.productImagePath}
                                            cropResult={this.state.cropResult}
                                            selectedCategoryId={this.state.selectedCategoryId}
                                            campaignChips={this.state.campaignChips}

                                        />
                                        <Snackbar anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                            open={this.state.openNotify1}
                                            onClose={() => { this.setState({ openNotify1: false }) }}
                                            autoHideDuration={2000}
                                            message="Old Price, Fiyattan küçük olamaz!" />


                                        <Snackbar anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                            open={this.state.openNotify2}
                                            onClose={() => { this.setState({ openNotify2: false }) }}
                                            autoHideDuration={2000}
                                            message="Fiyatı olmayan ürün listeye eklenemez!" />


                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </form >

                )}
            />
        );
    }
}