import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    NumberField,
} from 'react-admin';
import NeighborhoodsShow from './NeighborhoodsShow';
import NeighborhoodsFilter from './NeighborhoodsFilter';



const NeighborhoodsList: FC<ListProps> = props => {

    return (
        <List
            {...props}
            filters={<NeighborhoodsFilter />}
            perPage={5}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="expand" expand={<NeighborhoodsShow />}>
                <TextField source="name" />
                <NumberField source="city" label="City" />
                <NumberField source="town" label="Town" />
                <NumberField source="zip_code" label="Zip Code" />
            </Datagrid>
        </List>
    );
};

export default NeighborhoodsList;
