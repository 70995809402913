import { Grid, MenuItem, Paper, Select } from "@material-ui/core";
import axios from "axios";
import { useDataProvider, useNotify } from "ra-core";
import { useState, useEffect } from "react";
import Cookies from "js-cookie"


export const ServerTools = () => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const serverUrl = process.env.REACT_APP_ZINGO_SERVER;
    const [allStores, setAllStores] = useState<any>([]);
    const [needStore, setNeedStore] = useState("");


    useEffect(() => {
        if (!allStores.length) {
            dataProvider.getList("stores", {
                pagination: { page: 1, perPage: 82 },
                sort: { field: 'name', order: 'ASC' },
                filter: {}
            }).then(resp => {
                setAllStores(resp.data)
            }).catch(_ => {
                notify("getAllStores Error", "warning")
            })
        }
    }, [needStore])

    const handleClick = (operation: string) => {
        if (window.confirm("Are you sure " + operation + " ? ") == true){
            axios.post(serverUrl + operation, {}, {
                headers: {
                    "authorization" : "Bearer " + Cookies.get("accessToken")
                }
            }).then(_ => {
                notify("It's done!")
            }).catch(_ => {
                notify("Error!!")
            })
        }
        else notify("It's not done!", "warning")
    }


    return (
        <Grid style={{ display: "flex", width: "100%" }}>
            <Paper style={{ display: "block", padding: "1em", marginTop: "2em", width: "100%" }}>
                <Select fullWidth label="Operation">
                    <MenuItem onClick={() => { handleClick("/price-engine/scrap-all"); }} value="/price-engine/scrap-all">Scrap All</MenuItem>
                    <MenuItem onClick={() => { handleClick("/price-engine/process-all"); }} value="/price-engine/process-all">Process All</MenuItem>
                    <MenuItem onClick={() => { setNeedStore("scrap") }} value="/price-engine/scrap">Scrap Store</MenuItem>
                    <MenuItem onClick={() => { setNeedStore("process") }} value="/price-engine/process">Process Store</MenuItem>
                    <MenuItem onClick={() => { handleClick("/elastic/update-all-indices"); }} value="/elastic/update-all-indices">Update Elastic</MenuItem>
                </Select>
                <br />
                <br />
                <br />
                <br />
                {needStore ?
                    <Select fullWidth label="Store">
                        {allStores.map((store: any) => (
                            <MenuItem onClick={() => { handleClick("/price-engine/" + needStore + "/" + store.slug) }} value={store.slug}>{store.name}</MenuItem>
                        ))}
                    </Select> : <></>}
            </Paper>
        </Grid>
    );

}

export default ServerTools