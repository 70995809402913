import CommentIcon from '@material-ui/icons/Comment';
import CommentList from './CommentList';
import CommentEdit from './CommentEdit';
import CommentFilter from './CommentFilter';

export default {
    list: CommentList,
    filter: CommentFilter,
    show: CommentEdit,
    icon: CommentIcon,
};
