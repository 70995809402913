import React, { FC } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
    useShowController,
    ReferenceField,
    TextField,
    FieldProps,
    useRecordContext,
} from 'react-admin';

import { City } from '../types';

const CustomerField: FC<FieldProps<City>> = ({ record }) =>
    record ? (
        <Typography>
            {record.name}
            <br />
            {record.address}
            <br />
            {record.city}, {record.zipcode}
        </Typography>
    ) : null;

const CityShow = (props: any) => {
    const { record } = useShowController<City>(props);
    const classes = useStyles();

    if (!record) return null;
    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            {record.name}
                        </Typography>
                    </Grid>

                </Grid>
                <div className={classes.spacer}>&nbsp;</div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <StoresField source="stores" ></StoresField>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" gutterBottom >
                            Longitude : {' '}
                        </Typography>
                        <Typography gutterBottom >
                            {record.location.coordinates[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" gutterBottom >
                            Longitude : {' '}
                        </Typography>
                        <Typography gutterBottom >
                            {record.location.coordinates[0]}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CityShow;

const useStyles = makeStyles({
    root: { width: "90%", margin: 'auto' },
    spacer: { height: 20 },
    invoices: { margin: '10px 0' },
});


const StoresField = (props: any) => {
    const record = useRecordContext(props);
    let stores : any[] = record.stores;
    let divides = []
    while(stores.length){
        divides.push(stores.slice(0,5))
        stores = stores.slice(5);
    }
    return (
        <div style={{display:"flex"}}>
           {divides.map(division => {
               return (
                   <ul>
                       {division.map(store => (
                           <li>{store}</li>
                       ))}
                   </ul>
               )
           } )}
        </div>
    )
}

StoresField.defaultProps = {
    addLabel: true
};