import React, { useEffect, useState } from 'react';
import { Box, Button, } from '@material-ui/core';
import CropperComponent from './CropperComponent';
import { Loading, number, useDataProvider } from 'react-admin';
const defaultSrc ='https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg';
const LabelSubmission = (props:any) => {
    const dataProvider = useDataProvider();
    const [labelList, setLabelList] = useState<any>([]);
    const [productListingList,setProductListingList] = useState<any>([]);
    const [selectedProduct,setSelectedProduct] = useState<any>();
    const [selectedProductBarcode,setSelectedProductBarcode]= useState<string>('');
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [error, setError] = useState();
    const [approvePermission,setApprovePermission] = useState<boolean>(false);
    const [buttonApproved,setButtonApproved] = useState<string>('#C2C2C2');
    const [rotateNumber, setRotateNumber] = useState<number>(0);
    const [check,setCheck] = useState<boolean>(false);
    const [points,setPoints] = useState<any>({
        left: number,
        top: number,
        right: number,
        bottom: number
    })
    const [coordinates,setCoordinates] = useState<any>({
        x1: number,
        x2: number,
        y1: number,
        y2: number
    })
    useEffect(() => {
        dataProvider.getList('labelsubmissions', {
            filter: { isReviewed: false, type: 'priceImage'},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                setLabelList(data);
                setSelectedProduct(data[0]);
                getSelectedProductBarcode(data[0]);
                setLoading(false);
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    },[]);

    useEffect(() => {
            dataProvider.getList('productlistings', {
                filter: {  barcode: selectedProductBarcode },
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'title', order: 'ASC' },
            }).then(({ data }) => {
                if (typeof data !== 'undefined') {
                    // if(data.length==0)  {
                    //     sendToProductSubmission();
                    // }
                    setProductListingList(data);
                    setLoading(false);
                } else {
                    alert("something wrong here")
                }
            }).catch(error => {
                setError(error);
                setLoading(false);
            })
    },[selectedProductBarcode]);

    const getSelectedProductBarcode=(product:any)=>  {
        let barcode:string=product.barcode;
        setSelectedProductBarcode(barcode);
    }

    const checkImage = () => {
        if(check  || labelList.length==0)
            return defaultSrc;
        else
            return labelList[selectedIndex].imageUrl;
    }
    const sendResolution = () => {
        if(labelList.length==0) {
            return {width:720,height:1280}
        }
        return labelList[selectedIndex].imageResolution;
    }
    const checkLength = () => {
        if(labelList.length > selectedIndex+1){
            return selectedIndex+1;
        }else{
            setCheck(true);
            return selectedIndex;
        }
    }

    const saveNotification= async (isApproved:boolean) =>  {
        dataProvider.getList('productlistings', {
            filter: {barcode:labelList[selectedIndex].barcode},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {       
                const productListingId=String(data[0].id)
                dataProvider.create('usernotifications',{data:{userId:labelList[selectedIndex].uid,status:"pending",isApproved:isApproved,messageType:"priceSubmission",imageUrl:labelList[selectedIndex].imageUrl,productListingId:productListingId}});
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    }

    const declineImage = async () => {
        labelList[selectedIndex].isReviewed=true;
        saveNotification(false);
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        handleSaveDecline();
    }
    const approveImage = async () => {
        labelList[selectedIndex].isReviewed=true;
        labelList[selectedIndex].isApproved=true;
        labelList[selectedIndex].labelCoordinates=coordinates;
        labelList[selectedIndex].labelPoints=points;
        saveNotification(true);
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        handleSaveApprove();
    }
    const skip=()=>  {
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
    }
    const sendToProductSubmission= async ()=>  {
        if(labelList.length>0){
            dataProvider.update('labelsubmissions', {
                id: labelList[selectedIndex].id,
                data: { id: labelList[selectedIndex].id ,type: 'image'},
                previousData: { title: "previous title" } as any
            }).catch(error => {
                console.log(error)
            })
            setSelectedIndex(checkLength());
            setSelectedProduct(labelList[checkLength()]);
            getSelectedProductBarcode(labelList[checkLength()]);
        }
        
    }
    
    const handleSaveApprove = async () => {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , isReviewed: labelList[selectedIndex].isReviewed, isApproved: labelList[selectedIndex].isApproved , 
                labelPoints:labelList[selectedIndex].labelPoints, labelCoordinates:labelList[selectedIndex].labelCoordinates,rotationCount: rotateNumber 
                },
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setButtonApproved('#C2C2C2');
        setApprovePermission(false);
        setRotateNumber(0);
        
    }
    const handleSaveDecline = async () => {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , isReviewed: labelList[selectedIndex].isReviewed, isApproved: labelList[selectedIndex].isApproved},
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setButtonApproved('#C2C2C2');
        setApprovePermission(false);
        setRotateNumber(0);
    }
    const myCallback = (dataFromChild: any) => {
        setPoints(dataFromChild)  
        setApprovePermission(true)
        setButtonApproved('#4DFF42');
    }
    const myCallBackCoordinates = (dataFromChild:any) => {
        setCoordinates(dataFromChild);
    }
    const myCallbackRotate = (dataFromChild: any) => {
        setRotateNumber(dataFromChild);
    }
    if (loading) return <Loading />;
    if (!labelList) return null;
    return (
        <div style= {{display: 'flex',justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
            <div>
                <CropperComponent imageUrl= {checkImage()} callbackFromParent={myCallback} myCallbackRotate={myCallbackRotate} callbackCoordinates={myCallBackCoordinates} resolution={sendResolution()}/>
            </div>
            <div>
                <div style={{justifyContent: 'space-between'}}>
                    <Button style={{backgroundColor:'#FF2929',marginRight:'20px',marginTop:'10px'}} onClick={() => declineImage()} disabled= {check}>DECLINE</Button>
                    <Button style={{backgroundColor:'#FFFC7F',marginTop:'10px'}} onClick={() => sendToProductSubmission()} disabled= {check} >SEND TO PRODUCT SUBMISSION</Button>
                    <Button style={{backgroundColor: buttonApproved,marginLeft:'20px',marginTop:'10px'}} onClick={() => approveImage()} disabled= {!approvePermission}>APPROVE</Button>
                    <Button style={{backgroundColor: '#92BBE1', marginLeft: '10px',marginTop:'10px'}} onClick={() => skip()}disabled={check}>Skip</Button>
                </div>
            </div>
        </div>
    )
}
export default LabelSubmission;