import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props: any) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.isValid) {
            setOpen(true);
        }
    }, [props])

    const handleClose = () => {
        setOpen(false);
        props.setIsValid(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title" > {"Login Error!"} </DialogTitle>
                < DialogContent >
                    <DialogContentText id="alert-dialog-description" >
                        {(props.err) ? props.err.message : "Unknown error!"
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button onClick={handleClose} color="primary" >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}