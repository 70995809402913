import React, { useRef } from "react";
import { useState } from "react";
import { useDataProvider, Loading, useNotify } from "react-admin";
import {
  Paper,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Chip,
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from "@material-ui/core";
import Stepper from "./Stepper";
import SelectStore from "./SelectStore";
import ShowTemplates from "./ShowTemplates";
import CircularProgress from "./CircularProgress";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { sendToBannerBear } from "../Helper";
import DoneIcon from "@material-ui/icons/Done";
import ShareIcon from "@material-ui/icons/Share";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff",
    paddingTop: "5vh",
  },
  divider: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    height: "1px",
  },
  button: {
    marginTop: "2%",
    float: "right",
  },
  buttonSkip: {
    marginTop: "2%",
    float: "left",
  },
  buttonSave: {
    height: "40px",
    width: "100px",
    margin: "-20px",
    marginBottom: "3%",
    position: "relative",
    top: "50%",
    left: "50%",
  },
  buttonShare: {
    margin: "0.5%",
    marginBottom: "1%",
    position: "relative",
    top: "50%",
    left: "47%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "start",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "#000",
    paddingTop: "3%",
    paddingBottom: "3%",
  },
  paperFirstPage: {
    padding: theme.spacing(2),
    textAlign: "start",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "#000",
    paddingBottom: "3%",
  },
  paperSecondPage: {
    padding: theme.spacing(1),
    textAlign: "start",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-around",
    color: "#000",
    paddingBottom: "1%",
  },
  paperThirdPage: {
    padding: theme.spacing(1),
    textAlign: "start",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-around",
    color: "#000",
    paddingBottom: "1%",
  },
  paperTemplate: {
    padding: theme.spacing(2),
    textAlign: "start",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "#000",
    paddingBottom: "2%",
  },
  paperControl: {
    padding: theme.spacing(2),
    textAlign: "start",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "8%",
    paddingTop: "1%",
  },
  chipField: {
    marginLeft: "1%",
  },
  image: {
    height: "40em",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50 %",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

export const PostSubmissions = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const steps = [
    "Select Store Name",
    "Select Template",
    "Create Post Submissions",
    "Preview",
  ];
  const [stepperNext, setStepperNext] = React.useState(false);
  const [stepperBack, setStepperBack] = React.useState(false);
  const [stepperReset, setStepperReset] = React.useState(false);
  const [page, setPage] = useState(0);
  const [selectedTemplate, setTemplate] = useState<any>("");
  const [selectedTemplatePriceCount, setSelectedTemplatePriceCount] = useState<any>("");
  const [product, setProduct] = useState<any>();
  const [selectedStore1, setSelectedStore1] = useState<any>();
  const [selectedStore2, setSelectedStore2] = useState<any>();
  const [selectedStore3, setSelectedStore3] = useState<any>();
  const [selectedPrice1, setSelectedPrice1] = useState<any>();
  const [selectedPrice2, setSelectedPrice2] = useState<any>();
  const [selectedPrice3, setSelectedPrice3] = useState<any>();
  const [post, setPost] = useState<any>();
  let postToSend: any = {};
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const valueRef = useRef("");

  const title1Change = (e: any) => {
    setTitle1(e.target.value);
  };
  const title2Change = (e: any) => {
    setTitle2(e.target.value);
  };
  const dateChange = (e: any) => {
    postToSend = post;
    postToSend.campaignDates = e.target.value;
    setPost(postToSend);
  };

  const handleChangeImage = (e: any) => {
    let newProd = product;
    newProd.images[0].url = e.target.value;
    setProduct(newProd);
  };

  const selectDisabled = () => {
    if (
      selectedTemplate.numberOfProducts == 2 &&
      selectedStore2 &&
      selectedStore1 !== selectedStore2
    )
      return true;
    else if (
      selectedTemplate.numberOfProducts == 3 &&
      selectedStore3 &&
      selectedStore1 !== selectedStore2 &&
      selectedStore2 !== selectedStore3
    )
      return true;
    return false;
  };

  const sendUpdate = async () => {
    console.log(12121212)
    await dataProvider
      .getList("categories",{
        filter: {  },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "categoryName", order: "ASC" },
      })
      .then(async (category) => {
        console.log(category)
      })
    console.log(14141414)
    setLoading(true);
    await dataProvider
      .getList("stores", {
        filter: { slug: selectedStore1 },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "slug", order: "ASC" },
      })
      .then(async (store1) => {
        postToSend.store1 = store1.data[0];
        await dataProvider
          .getList("stores", {
            filter: { slug: selectedStore2 },
            pagination: { page: 1, perPage: 1 },
            sort: { field: "slug", order: "ASC" },
          })
          .then(async (store2) => {
            postToSend.store2 = store2.data[0];
            await dataProvider
              .getList("stores", {
                filter: { slug: selectedStore3 },
                pagination: { page: 1, perPage: 1 },
                sort: { field: "slug", order: "ASC" },
              })
              .then(async (store3) => {
                postToSend.store3 = store3.data[0];
                postToSend.price1 = selectedPrice1;
                postToSend.price2 = selectedPrice2;
                postToSend.price3 = selectedPrice3;
                postToSend.product = product;
                post.store1 = selectedStore1;
                post.store2 = selectedStore2;
                post.store3 = selectedStore3;
                postToSend.payload = selectedTemplate.payload;
                postToSend.storyPayload = selectedTemplate.storyPayload;
                postToSend.logoColor = selectedTemplate.logoColor;
                if (
                  product.images &&
                  product.images.length &&
                  post.store1 &&
                  post.store2
                ) {
                  await sendToBannerBear(title1, postToSend);
                  if (window.localStorage.getItem("imageUrlToSend")) {
                    setPage(3);
                    setLoading(false);
                  }
                } else {
                  if (!product.images && !product.images.length)
                    notify("Product Resmi yok", "warning");
                  if (!post.store1 && !post.store2)
                    notify("Store eklenemedi", "warning");
                  else notify("Product seçilmedi!", "warning");
                }
              });
          });
      })
      .catch((err) => {
        console.log(err)
        notify("Something went wrong!");
      });
  };

  const showTemplate = () => {
    if (selectedTemplate.name) {
      return (
        <div style={{ alignItems: "center" }}>
          <Typography variant="h6" gutterBottom>
            {product.name}
          </Typography>
          <img
            width="80%"
            height="100%"
            src={selectedTemplate.exampleUrl}
          ></img>
        </div>
      );
    } else {
      return (
        <Loading
          loadingPrimary="Template seçiniz"
          loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."
        ></Loading>
      );
    }
  };

  const handleSelectStores = (price: any) => {
    let theStore;
    let allStores: string[] = [];

    product.prices.forEach((element: any) => {
      allStores.push(element.store);
    });

    allStores.forEach((eventStore: string) => {
      if (eventStore === price.store) {
        theStore = eventStore;
      }
    });
    if (theStore && selectedStore1 && selectedStore2) {
      setSelectedStore3(theStore);
      setSelectedPrice3(price);
    } else if (theStore && selectedStore1) {
      setSelectedStore2(theStore);
      setSelectedPrice2(price);
    } else {
      setSelectedStore1(theStore);
      setSelectedPrice1(price);
    }
  };

  const handleServer = () => {
    let title = title1 ? title1 : "" + title2 ? " " + title2 : "";
    postToSend.product = product;
    let serverPrices = [];
    serverPrices.push(
      {
        price: selectedPrice1.price,
        store: selectedPrice1.store,
      },
      {
        price: selectedPrice2.price,
        store: selectedPrice2.store,
      }
    );
    if (selectedStore3) {
      serverPrices.push({
        price: selectedPrice3.price,
        store: selectedPrice3.store,
      });
    }
    dataProvider
      .create("homepageposts", {
        data: {
          title,
          prices: serverPrices,
          productImageUrl: product.images[0].url,
          productId: postToSend.product.id,
          productName: postToSend.product.name,
          imageUrl: window.localStorage.getItem("imageUrlToSend"),
        },
      })
      .then((_) => {

        window.localStorage.removeItem("imageUrlToSend");
        window.location.reload();
        var today = new Date();
        dataProvider
          .update("products", {
            id: postToSend.product.id,
            data: { id: postToSend.product.id, postCreateDate: today },
            previousData: { title: "previous title" } as any,
          })
          .then((_) => {
            notify("HomePage Post oluşturuldu");
          });
      })
      .catch((err) => {
        window.localStorage.removeItem("imageUrlToSend");
        notify(err, "warning");
      });
  };

  const showFields = () => {
    if (selectedTemplate.name) {
      if (product) {
        return (
          <div style={{ marginTop: "3%", marginRight: "2%" }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => title1Change(e)}
                  id="title1"
                  label="Title1"
                  placeholder="Sky is the limit! Be creative :)"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              {selectedTemplate.numberOfTitles == 2 ? (
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => title2Change(e)}
                    id="title2"
                    label="Title2"
                    placeholder="Sky is the limit! Be creative :)"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
              ) : (
                <div></div>
              )}
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => handleChangeImage(e)}
                  id="productImageUrl"
                  label="productImageUrl"
                  placeholder="If you cannot see the black part behind the image try to change it."
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                <div
                  style={{
                    backgroundColor: "#000",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={product.images[0] ? product.images[0].url : ""}
                  ></img>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    dateChange(e);
                  }}
                  id="dates"
                  label="Tarih"
                  defaultValue=" -  tarihleri arasında geçerlidir."
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              {product.prices.map((pri: any) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onClick={() => {
                        handleSelectStores(pri);
                      }}
                      disabled={selectDisabled()}
                      id={`price${pri.store}`}
                      label={`Price ${pri.store}`}
                      value={pri.price}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <h4>Selected Stores:</h4>
              <Chip
                className={classes.chipField}
                avatar={<Avatar>S</Avatar>}
                label={selectedStore1 ? selectedStore1 : "Lütfen Seçiniz"}
                clickable
                onClick={() => setSelectedStore1("")}
                color="primary"
                variant="outlined"
                deleteIcon={<DoneIcon />}
              />
              <Chip
                className={classes.chipField}
                avatar={<Avatar>S</Avatar>}
                label={selectedStore2 ? selectedStore2 : "Lütfen Seçiniz"}
                clickable
                onClick={() => setSelectedStore2("")}
                color="primary"
                variant="outlined"
                deleteIcon={<DoneIcon />}
              />
              {selectedTemplate.numberOfProducts == 3 ? (
                <Chip
                  className={classes.chipField}
                  avatar={<Avatar>S</Avatar>}
                  label={selectedStore3 ? selectedStore3 : "Lütfen Seçiniz"}
                  onClick={() => setSelectedStore3("")}
                  clickable
                  color="primary"
                  variant="outlined"
                  deleteIcon={<DoneIcon />}
                />
              ) : null}
              <br />
              <br />
              <Button
                variant="outlined"
                color="primary"
                onClick={sendUpdate}
                className={classes.button}
                startIcon={<CheckIcon />}
              >
                {" "}
                Önizleme
              </Button>
            </Grid>
          </div>
        );
      } else {
        return (
          <Loading
            loadingPrimary="Product seçiniz"
            loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."
          ></Loading>
        );
      }
    } else {
      return (
        <Loading
          loadingPrimary="Template seçiniz"
          loadingSecondary="Template ve ürün seçtikten sonra fieldlar gelecektir."
        ></Loading>
      );
    }
  };

  if (loading)
    return (
      <div className={classes.root}>
        <Stepper
          step={3}
          steps={steps}
          goNext={stepperNext}
          goBack={stepperBack}
          reset={stepperReset}
          setStep={setPage}
          setNext={setStepperNext}
          setBack={setStepperBack}
          setReset={setStepperReset}
        ></Stepper>
        <Paper className={classes.paperSecondPage}>
          <CircularProgress></CircularProgress>
        </Paper>
      </div>
    );
  else if (page === 0) {
    return (
      <div className={classes.root}>
        <Stepper
          step={0}
          steps={steps}
          goNext={stepperNext}
          goBack={stepperBack}
          reset={stepperReset}
          setStep={setPage}
          setNext={setStepperNext}
          setBack={setStepperBack}
          setReset={setStepperReset}
        ></Stepper>

        <SelectStore
          setProduct={setProduct}
          setPage={setPage}
          setSelectedStore1={setSelectedStore1}
          setSelectedStore2={setSelectedStore2}
          setPost={setPost}
          setLoading={setLoading}
        />
      </div>
    );
  } else if (page === 1) {
    return (
      <div className={classes.root}>
        <Stepper
          step={1}
          steps={steps}
          goNext={stepperNext}
          goBack={stepperBack}
          reset={stepperReset}
          setStep={setPage}
          setNext={setStepperNext}
          setBack={setStepperBack}
          setReset={setStepperReset}
        ></Stepper>
        <Paper className={classes.paperTemplate}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={6} style={{ marginTop: "1%" }}>
                <h2>{product.name}</h2>
                <div style={{ height: "5px" }}></div>
                {product.prices.map((pri: any) => {
                  return (
                    <Chip
                      className={classes.chipField}
                      avatar={<Avatar>₺</Avatar>}
                      label={pri.store + " " + pri.price}
                      color="primary"
                      variant="outlined"
                      deleteIcon={<DoneIcon />}
                      style={{ marginTop: "15px" }}
                    />
                  );
                })}
              </Grid>
              <div style={{ height: "10px" }}></div>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="selected-store">
                  Selected Price Count
                </InputLabel>
                <Select
                  labelId="select-store"
                  id="store-select"
                  label="One Product or Two Product"
                  onChange={(e: any) => {
                    setSelectedTemplatePriceCount(e.target.value);
                  }}
                >
                  <MenuItem value={"onePrice"}>{"One Price"}</MenuItem>
                  <MenuItem value={"twoPrices"}>{"Two Prices"}</MenuItem>
                  <MenuItem value={"threePrices"}>{"Three Prices"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {selectedTemplatePriceCount === "twoPrices" ? (
              <Grid item xs={12}>
                <ShowTemplates
                  numberOfProducts={2}
                  setTemplate={setTemplate}
                  setPage={setPage}
                ></ShowTemplates>
              </Grid>
            ) : selectedTemplatePriceCount == "threePrices" ? (
              <Grid item xs={12}>
                <ShowTemplates
                  numberOfProducts={3}
                  setTemplate={setTemplate}
                  setPage={setPage}
                ></ShowTemplates>
              </Grid>
            ) : <Grid item xs={12}>
              <ShowTemplates
                numberOfProducts={1}
                setTemplate={setTemplate}
                setPage={setPage}
              ></ShowTemplates>
            </Grid>}
          </Grid>
        </Paper>
      </div>
    );
  } else if (page === 2) {
    return (
      <div className={classes.root}>
        <Stepper
          step={2}
          steps={steps}
          goNext={stepperNext}
          goBack={stepperBack}
          reset={stepperReset}
          setStep={setPage}
          setNext={setStepperNext}
          setBack={setStepperBack}
          setReset={setStepperReset}
        ></Stepper>
        <Paper className={classes.paperSecondPage}>
          {showTemplate()}
          {showFields()}
        </Paper>
      </div>
    );
  } else if (page === 3) {
    return (
      <div className={classes.root}>
        <Stepper
          step={3}
          steps={steps}
          goNext={stepperNext}
          goBack={stepperBack}
          reset={stepperReset}
          setStep={setPage}
          setNext={setStepperNext}
          setBack={setStepperBack}
          setReset={setStepperReset}
        ></Stepper>
        <Paper className={classes.paperThirdPage}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                src={window.localStorage.getItem("imageUrlToSend")?.toString()}
                className={classes.image}
              ></img>
              <div style={{ height: "20px" }}></div>

              <TextField
                id={`Post_Url`}
                label={`Post Url`}
                value={window.localStorage
                  .getItem("imageUrlToSend")
                  ?.toString()}
                fullWidth
                disabled
                variant="outlined"
                inputRef={valueRef}
                onClick={() => {
                  copied
                    ? window.open(
                      window.localStorage
                        .getItem("imageUrlToSend")
                        ?.toString(),
                      "_blank"
                    )
                    : (document.getElementById("Post_Url") as any).value ===
                      window.localStorage.getItem("imageUrlToSend")?.toString()
                      ? console.log(
                        window.localStorage
                          .getItem("imageUrlToSend")
                          ?.toString()
                      )
                      : console.log("");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {copied ? (
                        <div className="coupon-copied">Copied!</div>
                      ) : (
                        <CopyToClipboard
                          text={
                            window.localStorage
                              .getItem("imageUrlToSend")
                              ?.toString() as string
                          }
                          onCopy={() => {
                            setCopied(true);
                            setCopied1(false);
                            setCopied2(false);
                          }}
                        >
                          <div className="copy-code">✂️</div>
                        </CopyToClipboard>
                      )}
                    </InputAdornment>
                  ),
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleServer}
                disabled={loading}
                className={classes.buttonSave}
                startIcon={<ShareIcon />}
              >
                {" "}
                Yayınla!
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FacebookShareButton
                url={window.localStorage.getItem("imageUrlToSend")!.toString()}
                quote={title1}
                className={classes.buttonShare}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={window.localStorage.getItem("imageUrlToSend")!.toString()}
                title={title1}
                className={classes.buttonShare}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={window.localStorage.getItem("imageUrlToSend")!.toString()}
                title={title1}
                separator=":: "
                className={classes.buttonShare}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={window.localStorage.getItem("imageUrlToSend")!.toString()}
                className={classes.buttonShare}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
};

export default PostSubmissions;
