import React, { useEffect, useState } from 'react';
import { Box, Button, } from '@material-ui/core';
import { Loading, number, useDataProvider } from 'react-admin';
const defaultSrc ='https://image.shutterstock.com/image-vector/no-image-available-icon-template-260nw-1036735675.jpg';
const LabelSubmissionCropReview = (props:any) => {
    const dataProvider = useDataProvider();
    const [labelList, setLabelList] = useState<any>([]);
    const [selectedProduct,setSelectedProduct] = useState<any>();
    const [selectedProductBarcode,setSelectedProductBarcode]= useState<string>('');
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [error, setError] = useState();
    const [buttonApproved,setButtonApproved] = useState<string>('#C2C2C2');
    const [check,setCheck] = useState<boolean>(false);
    const [selectedProductCoordinates,setSelectedProductCoordinates] = useState<any>({
        x1: number,
        x2: number,
        y1: number,
        y2: number
    })
    const [selectedProductImageResolution,setSelectedProductImageResolution] = useState<any>({
        width: number,
        height: number
    })
    useEffect(() => {
        dataProvider.getList('labelsubmissions', {
            filter: { canReview:true},
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'title', order: 'ASC' },
        }).then(({ data }) => {
            if (typeof data !== 'undefined') {
                setLabelList(data);
                setSelectedProduct(data[0]);
                getSelectedProductCoordinates(data[0])
                getSelectedProductBarcode(data[0]);
                getSelectedProductImageResolution(data[0])
                setLoading(false);
            } else {
                alert("something wrong here")
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        })
    },[]);

    const getSelectedProductBarcode=(product:any)=>  {
        let barcode:string=product.barcode;
        setSelectedProductBarcode(barcode);
    }
    const getSelectedProductImageResolution=(product:any)=>  {
        let resolution:string=product.imageResolution;
        setSelectedProductImageResolution(resolution);
    }
    const getSelectedProductCoordinates=(product:any)=>  {
        let coordinates=product.labelCoordinates;
        setSelectedProductCoordinates(coordinates);
    }
    const checkImage = () => {
        if(check  || labelList.length==0)
            return defaultSrc;
        else
            return labelList[selectedIndex].imageUrl;
    }
    const checkLength = () => {
        if(labelList.length > selectedIndex+1){
            return selectedIndex+1;
        }else{
            setCheck(true);
            return selectedIndex;
        }
    }
    const declineImage = async () => {
        labelList[selectedIndex].isVerified='declined';
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        getSelectedProductCoordinates(labelList[checkLength()])
        getSelectedProductImageResolution(labelList[checkLength()])
        handleSaveDecline();
    }
    const approveImage = async () => {
        labelList[selectedIndex].isVerified='verified';
        setSelectedIndex(checkLength());
        setSelectedProduct(labelList[checkLength()]);
        getSelectedProductBarcode(labelList[checkLength()]);
        getSelectedProductCoordinates(labelList[checkLength()])
        getSelectedProductImageResolution(labelList[checkLength()])
        handleSaveApprove();
    }
    
    const handleSaveApprove = async () => {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , isVerified: labelList[selectedIndex].isVerified },
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setButtonApproved('#C2C2C2');
        
    }
    const handleSaveDecline = async () => {
        dataProvider.update('labelsubmissions', {
            id: labelList[selectedIndex].id,
            data: { id: labelList[selectedIndex].id , isVerified: labelList[selectedIndex].isVerified},
            previousData: { title: "previous title" } as any
        }).catch(error => {
            console.log(error)
        })
        setButtonApproved('#C2C2C2');
    }
    if (loading) return <Loading />;
    if (!labelList) return null;
    return (
        <div>
            <div style={{display: "block"}}>
                <svg style={{height:selectedProductImageResolution.height*(360/selectedProductImageResolution.width), width:selectedProductImageResolution.width*(360/selectedProductImageResolution.width), position: "absolute",top:50,left:"40em"}}>
                    <mask id="svgmask1">
                        <rect fill="#ffffff" x={selectedProductCoordinates.x1*(360/selectedProductImageResolution.width)} y={selectedProductCoordinates.y1*(360/selectedProductImageResolution.width)} width={(selectedProductCoordinates.x2*(360/selectedProductImageResolution.width))-(selectedProductCoordinates.x1*(360/selectedProductImageResolution.width))} height={(selectedProductCoordinates.y2*(360/selectedProductImageResolution.width))-(selectedProductCoordinates.y1*(360/selectedProductImageResolution.width))}></rect>
                    </mask>
                    <image xlinkHref={checkImage()} mask="url(#svgmask1)" width="100%" height="100%"></image>
                </svg>
                <svg style={{height:selectedProductImageResolution.height*(360/selectedProductImageResolution.width), width:selectedProductImageResolution.width*(360/selectedProductImageResolution.width), position: "absolute", opacity: 0.2,top:50,left:"40em"}}>
                    <image xlinkHref={checkImage()} width="100%" height="100%"></image>
                </svg>
            </div>
            <div style={{height: "580px", width: "580px"}}></div>
            <div style={{justifyContent: 'space-between', position: "absolute",left:"45em"}}>
                <Button style={{backgroundColor:'#FF2929', marginRight:'20px', marginTop:'10px'}} onClick={() => declineImage()} disabled= {check}>DECLINE</Button>
                <Button style={{backgroundColor: '#4DFF42', marginLeft:'20px', marginTop:'10px'}} onClick={() => approveImage()} >APPROVE</Button>
            </div>
        </div>
    )
}
export default LabelSubmissionCropReview;