import MappingIcon from '@material-ui/icons/Map';
import MappingList from './MappingList';
import MappingEdit from './MappingEdit';
//import MappingFilter from './MappingFilter';

export default {
    list: MappingList,
    //   filter: MappingFilter,
    show: MappingEdit,
    icon: MappingIcon,
};