import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

function createDataBarcodes(data: any[]): any[] {
  let arr: any[] = []
  data.forEach(element => {

      arr.push({ Store: element.store, Count: element.count - element.barcodeCount, Barcode: element.barcodeCount })

  })
  arr = arr.sort((a,b) => b.Count - a.Count)
  return arr
}

const MainChart = (props: any) => {
  return (
    <div >
      <h4>Products</h4>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart height={380} data={createDataBarcodes(props.barcodesData)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Store" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="Count" stackId="a" fill="#82FF9d" />
          <Bar dataKey="Barcode" stackId="a" fill="#FF000d" />
        </BarChart>
      </ResponsiveContainer>
      <hr />
    </div>
  );


}


export default MainChart;