import * as React from 'react';
import { FC } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ProductIdentifiersFilter from './ProductIdentifiersFilter';


const useStyles = makeStyles(theme => ({
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const ProductIdentifiersList: FC<ListProps> = props => {
    const classes = useStyles();
    return (
        <List
            {...props}
            filters={<ProductIdentifiersFilter />}
            perPage={25}
            sort={{ field: 'name', order: 'desc' }}
        >
            <Datagrid rowClick="edit">
                <TextField source="sku" />
                <TextField label="Product Name" source="productName" />
                <TextField source="barcode" />
                <TextField source="storeId" />
                <ReferenceField source="productId" reference="products" >
                    <TextField source="id" />
                </ReferenceField>
                <ReferenceField source="storeId" reference="stores" >
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default ProductIdentifiersList;
