import Cookies from "js-cookie";

const authProvider = { 
    login: (params: any) => Promise.resolve(),
    logout: () => {
        Cookies.remove('accessToken', { path: '' });
        Cookies.remove('userName', { path: '' });
        Cookies.remove('userId', { path: '' });
        Cookies.remove('userRole', { path: '' });
        window.location.reload()
        return Promise.resolve();
    },
    checkAuth: (params: any) => Promise.resolve(),
    checkError: (error: any) => {
        if(Cookies.get("userName") == "bedo" || Cookies.get("userName") == "atahan"|| Cookies.get("userName") == "baturay"  ){
            console.log(error)
            return Promise.resolve();
        } else {
            Cookies.remove('userRole', { path: '' });
            Cookies.remove('userId', { path: '' });
            Cookies.remove('userName', { path: '' });
            Cookies.remove('accessToken', { path: '' });
            window.location.reload()
            return Promise.resolve();
        }
    },
    getPermissions: (params: any) => Promise.resolve(),

};

export default authProvider;
