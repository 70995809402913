import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Container, Col, Row, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import Cropper from "react-cropper";
import { Box, Button, Icon } from "@material-ui/core";
import "cropperjs/dist/cropper.css";
import firebase from "firebase/app";
import "firebase/firestore";
import SendIcon from '@material-ui/icons/Send';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

const App = () => (
    <div >
        <LabelSample style={styles} />
    </div>
);

const src = "";
let alreadySet = false;
export default class LabelSample extends Component<{}, { src: any, cropResult: any }> {
    cropper: any;
    props: any;

    constructor(props: any) {
        super(props);
        this.state = {
            src,
            cropResult: null,
        };

        this.cropImage = this.cropImage.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    initializeCropper(e: any) {
        this.cropper = e;
    }

    onChange(e: any) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result });
        };
        reader.readAsDataURL(files[0]);
    }

    handleSend = () => {
        if (!this.props.isNewProduct && this.cropper.getCroppedCanvas()) {
            if (!firebase.apps.length) {
                firebase.initializeApp(process.env.REACT_APP_FIREBASE_CONFIG as string);
            }
            const rightNow = Date.now().toString();
            const storageRef = firebase.app().storage().refFromURL("gs://zingo-label-product-names");
            const fileRef = storageRef.child(`${this.props.productId}`);
            const productId = this.props.productId;
            this.cropper.getCroppedCanvas().toBlob(function (blob: any) {
                var newImg = document.createElement('img'),
                    url = URL.createObjectURL(blob);

                newImg.onload = function () {
                    URL.revokeObjectURL(url);
                };
                newImg.src = url;
                document.body.appendChild(newImg);
                fileRef.child(`${productId}_${rightNow}`).put(blob).then(async (resp) => {
                    alert("Tensor Flow Sent")
                });
            });
        } else {
            if (this.props.isNewProduct) {
                this.props.setTensorLabel(this.cropper.getCroppedCanvas());
            }
            else {

            }
        }
    }

    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            return;
        }
        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL()
        });
    }

    useDefaultImage() {
        this.setState({ src: this.props.defaultLabel.url });
    }



    render() {
        if (this.props.defaultLabel && !alreadySet) {
            alreadySet = true;
            this.setState({ src: this.props.defaultLabel })
        }

        return (
            <Box>
                <div style={{ display: "inline-flex" }}>
                    <div style={{ width: "80%" }}>

                        <div >
                            <Cropper
                                style={{ height: 240, width: '100%' }}
                                preview=".img-preview"
                                guides={true}
                                src={this.state.src}
                                onInitialized={(instance) => {
                                    this.initializeCropper(instance);
                                }}
                                viewMode={1}
                                cropBoxMovable={true}
                                zoomable={false}
                                movable={false}
                            />
                            <Box mt="1em" />
                            <Button color="primary" onClick={this.handleSend} style={{ float: 'right' }} size="large" endIcon={<SendIcon />}>Send To Tensor Flow</Button>
                        </div>

                    </div>
                </div>
            </Box>

        );
    }
}

ReactDOM.render(<App />, document.getElementById("root"));
