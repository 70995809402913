import React from 'react';
import { useMutation, useNotify, useRedirect } from 'react-admin';
import Button from '@material-ui/core/Button';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';

export const MismatchButton = ({ labelId }: any) => {
    // 
    const notify = useNotify();
    const redirect = useRedirect();



    const [mismatch, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'labelsubmissions',
            payload: { id: labelId, data: { id: labelId, isReviewed: true, isMismatch: true } }
        },
        {
            //undoable: true,
            onSuccess: ({ data }) => {
                notify('Mismatch submitted', 'info', {}, true);
                window.location.reload();
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }
    );
    return <Button color="primary" className={'mt-2 mx-3'} onClick={mismatch} disabled={loading} endIcon={<ThumbsUpDownIcon />} size="large" style={{
        position: 'absolute',
        left: 1200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>Mismatch</Button>;
};


