import * as React from 'react';
import { FC } from 'react';
import {
    Filter,
    SearchInput,
    FilterProps,

} from 'react-admin';
//import { makeStyles } from '@material-ui/core/styles';
//import { City } from '../types';

// const useFilterStyles = makeStyles({
//     status: { width: 150 },
// });

const StoreFilter: FC<Omit<FilterProps, 'children'>> = props => {
    // const classes = useFilterStyles();
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />

        </Filter>
    );
};

export default StoreFilter;
