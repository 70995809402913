import * as React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Chart } from "react-charts";
import { useShowController } from "react-admin";
import { JobActivity } from "../types";
import { Box, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import jobactivities from ".";

const JobActivityShow = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const { record } = useShowController<JobActivity>(props);
  const [jobActivity, setJobActivity] = React.useState<any>([]); // Merged one Product
  var ourDate = new Date(record?.date as string);
  var pastDate = ourDate.getDate() - 5;
  ourDate.setDate(pastDate);

  useEffect(() => {
    dataProvider
      .getList("jobactivities", {
        pagination: { page: 1, perPage: 2 },
        sort: { field: "title", order: "ASC" },
        filter: {
          userName: record?.userName,
          fromDate: ourDate,
          jobType: record?.jobType,
        },
      })
      .then(({ data }) => {
        setJobActivity(data);
      })
      .catch((err) => {
        notify("Something went wrong with get jobActivities", "warning");
      });
  }, [record]);

  const series = React.useMemo(
    () => ({
      type: "bar",
    }),
    []
  );
  const axes = React.useMemo(
    () => [
      { primary: true, position: "bottom", type:"ordinal" },
      { position: "left", type: "linear", stacked: true },
    ],
    []
  );

  const createData = () => {
    let js: any[] = [];
    jobActivity.forEach((element: any) => {
      if((new Date(element.createdAt as string)).getDate() > ourDate.getDate()){
        js.push(element)
      }
    });
    js = js.splice(js.length - 5)
    let arr: any[] = []
    js.forEach((element: any) => {
      arr.push({y: element.count, x: new Date (element.createdAt as string).getDate()});
    });
    return arr
  }

  const data = React.useMemo(
    () => [
      {
        label: record?.jobType,
        datums: createData()
      },
    ],
    [jobActivity]
  );
  console.log(data);
  return (
    <Box p="1em">
      <Box display="flex">
        <Box flex={1} mr="1em">
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <Card className={classes.root}>
                {jobActivity[1] && jobActivity[1].date ? (
                  <Chart data={data} series={series} axes={axes} tooltip />
                ) : (
                  <br />
                )}
              </Card>
            </Box>
          </Box>
          <Box mt="1em" />
        </Box>

        <Box flex={1} ml="1em">
          <Box flex={1} mr="0.5em">
            <TextField
              id="outlined-required"
              label="Count"
              defaultValue={record?.count}
              variant="outlined"
            />
          </Box>
          <Box mt="1em" />
          <Box flex={1} mr="0.5em">
            <TextField
              id="outlined-required"
              label="Job Type"
              defaultValue={record?.jobType}
              variant="outlined"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobActivityShow;

const useStyles = makeStyles({
  root: { width: 400, height: 400 },
  spacer: { height: 20 },
  invoices: { margin: "10px 0" },
});
